import { FirebaseMapClientRepository } from "@dataRepositories/index";
import { WatchMapClientsUseCase } from "@dataUseCases/index";
import mapClientStore from "@stores/mapClient";
import userStore from "@stores/user";
import { useEffect } from "react";
import { useStore } from "zustand";

export default function useMapClients() {
  const { mapClients } = useStore(mapClientStore);

  const getMapClients = async () => {
    const useCase = new WatchMapClientsUseCase(
      userStore.getState(),
      new FirebaseMapClientRepository(mapClientStore.getState())
    );

    useCase.execute();
  };

  useEffect(() => {
    if (mapClients.length === 0) {
      getMapClients();
    }
  }, []);

  return {
    mapClients,
  };
}
