import { useEffect, useState } from "react";
import useAlgolia from "./useAlgolia";
import { Client } from "@models/Client";

type TableData = {
  items: Client[];
  totalItems: number;
};

export default function useTable({ filters, paging }) {
  const { algoliaSearch, algoliaFilter, filteredResult, searchedResult } = useAlgolia();

  const [draftSearch, setDraftSearch] = useState("");
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (filters.length > 0) {
      const page = paging.offset / 25;

      algoliaFilter(filters, page);
    }
  }, [filters, paging]);

  useEffect(() => {
    if (search) {
      const page = paging.offset / 25;

      algoliaSearch(search, page);
    }
  }, [search, paging]);

  const clearSearch = () => {
    setSearch("");
    setDraftSearch("");
  };

  const clients = search ? searchedResult.clients : filteredResult.clients;

  const totalClients = search ? searchedResult.total : filteredResult.total;

  const filteredTableData: TableData = {
    items: clients,
    totalItems: totalClients,
  };

  return {
    filteredTableData,

    search,
    setSearch,
    clearSearch,

    draftSearch,
    setDraftSearch,
  };
}
