import { useEffect } from "react";

import { useStore } from "zustand";
import { Timestamp } from "firebase/firestore";
import attendClientsStore from "@stores/attendClient";
import { WatchCardsAttendClientsUseCase } from "@dataUseCases/index";
import { Filter } from "@models/Filters";
import { datetimeHelper } from "@helpers/datetime";
import { FirebaseAttendClientRepository } from "@dataRepositories/index";
import userStore from "@stores/user";

type Props = {
  headerFilters: Filter[];
};

const dateNow = new Date();
const firstDay = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);
const defaultStartDate = Timestamp.fromDate(firstDay);
const defaultEndDate = Timestamp.fromDate(dateNow);

export default function useCardsAttendClients({ headerFilters }: Props) {
  const { dashboardCard } = useStore(attendClientsStore);

  const getCardsAttendClients = async (startDate: Timestamp, endDate: Timestamp) => {
    const useCase = new WatchCardsAttendClientsUseCase(
      userStore.getState(),
      new FirebaseAttendClientRepository(attendClientsStore.getState())
    );

    useCase.execute({ startDate, endDate });
  };

  useEffect(() => {
    if (dashboardCard.length === 0) {
      getCardsAttendClients(defaultStartDate, defaultEndDate);
    }
  }, []);

  useEffect(() => {
    const headerDateFilter = headerFilters.find((x) => x.type === "date");

    if (headerDateFilter && headerDateFilter?.dateValue) {
      const startDate = datetimeHelper.getTimestampFromFilter(
        headerDateFilter.dateValue.selectedStart
      );
      const endDate = datetimeHelper.getTimestampFromFilter(headerDateFilter.dateValue.selectedEnd);

      getCardsAttendClients(startDate, endDate);
    } else {
      getCardsAttendClients(defaultStartDate, defaultEndDate);
    }
  }, [headerFilters]);

  return {
    cardsAttendClients: dashboardCard,
  };
}
