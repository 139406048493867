import React from "react";

import * as S from "./styles";
import { Avatar } from "@mui/material";
import { avatarHelper } from "@helpers/avatar";

type Props = {
  operatorId: string;
  operatorName: string;
  onClose?: () => void;
};

export default function OperatorLocation({ operatorId, operatorName, onClose }: Props) {
  return (
    <S.Container title={operatorName} onClick={onClose}>
      <S.Content>
        <Avatar
          style={{
            width: "100%",
            flex: 1,
            borderRadius: 0,
          }}
          src={avatarHelper.getUserAvatar(operatorId)}
        />
        <h4>{operatorName}</h4>
      </S.Content>
    </S.Container>
  );
}
