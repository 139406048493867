import { ReportRepository } from "@domainRepositories/ReportRepository";
import UseCase from "@domainUseCases/UseCase";
import { Client } from "@models/Client";
import { Report } from "@models/Report";

type Input = {
  clientId: Client["id"];
  reports: Pick<Report, "id" | "result" | "description">[];
};

type Output = Promise<Report[]>;

export class UpdateClientReportsUseCase implements UseCase<Input, Output> {
  constructor(private readonly reportRepository: ReportRepository) {
    this.reportRepository = reportRepository;
  }

  async execute({ clientId, reports }: Input) {
    return this.reportRepository.save(clientId, reports);
  }
}
