import { LogRepository } from "@domainRepositories/LogRepository";
import UseCase from "@domainUseCases/UseCase";
import { Log } from "@models/Log";
import { User } from "@models/User";

type Input = User["id"];

type Output = Promise<Log[]>;

export class GetUserLogsUseCase implements UseCase<Input, Output> {
  constructor(private readonly logRepository: LogRepository) {}

  async execute(userId: Input) {
    return this.logRepository.getUserLogs(userId);
  }
}
