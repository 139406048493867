import DatabaseReportRepository from "@domainRepositories/DatabaseReportRepository";
import { ReportStore } from "@domainStores/ReportStore";
import { firebase } from "@infra/firebase/config";
import { reportResultOptions } from "@models/Report";
import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";

export class FirebaseReportRepository implements DatabaseReportRepository {
  constructor(private readonly reportStore: ReportStore) {}

  watchMonthSignedReports() {
    const dateNow = new Date();

    const firstDay = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1).getTime();

    const firebaseQuery = query(
      collection(firebase.db, "reports"),
      where("createdAt", ">=", firstDay),
      where("result", "in", [reportResultOptions.SIGNED, reportResultOptions.RECOMMENDATION_SIGNED])
    );

    onSnapshot(
      firebaseQuery,
      (querySnapshot) => {
        const monthReports = querySnapshot.docs.length;

        this.reportStore.updateMonthReports(monthReports);
      },
      (error) => console.log("watchMonthSignedReports error", error)
    );
  }

  watchOperatorReturnReports() {
    const firebaseQuery = query(
      collection(firebase.db, "reports"),
      where("result", "in", [reportResultOptions.RETURN]),
      orderBy("createdAt", "desc")
    );

    onSnapshot(
      firebaseQuery,
      (querySnapshot) => {
        const monthReports = querySnapshot.docs.length;

        this.reportStore.updateMonthReports(monthReports);
      },
      (error) => console.log("watchMonthSignedReports error", error)
    );
  }
}
