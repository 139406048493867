import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
  max-width: 850px;

  padding: 20px 20px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 550px;
  overflow-y: scroll;
  padding: 0 10px 0px 0px;
`;

export const ImageStreetView = styled.img`
  width: 100%;
  height: 144px;

  margin-bottom: 10px;
  border-radius: 10px;

  cursor: pointer;
`;

export const ClientHeader = styled.div`
  display: grid;
  grid-template-columns: 1.8fr 1.25fr 0.75fr;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;

  gap: 10px;

  h3 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    padding-bottom: 5px;

    /* 2 */

    color: #808b9f;
  }
`;

export const ClientHeaderLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const ImageEspecie = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 52px;
  width: 52px;

  border-radius: 100px;
  background-color: var(--grey1);
`;

export const ClientNameContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  h4 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;

    /* 1 */

    color: #4a5e83;
  }

  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    /* 2 */

    color: #808b9f;
  }
`;

export const Divider = styled.div`
  position: relative;
  width: 100%;
  height: 0px;

  margin: 20px 0;

  /* 3 */

  border: 0.5px dashed #b4c1d5;
`;

export const HistoryHeader = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;

  height: 80px;
`;

export const HistoryLine = styled.div`
  width: 1px;
  height: 2px;
  width: 100%;
  border-radius: 20px;
  background: #68cfc9;
`;

export const HistoryItemContent = styled.div`
  display: flex;
  flex-direction: row-reverse;

  justify-content: space-between;

  align-items: center;

  margin: 0 20px;

  gap: 2px;
`;

type HistoryItemProps = { selected: boolean };
export const HistoryItem = styled.div<HistoryItemProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  top: -30%;

  align-items: center;

  cursor: pointer;
  user-select: none;

  h1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: ${({ selected }) => (selected ? 700 : 400)};
    font-size: 14px;
    line-height: 19px;
    text-align: center;

    color: var(--blue4);
  }

  div {
    width: ${({ selected }) => (selected ? "20px" : "16px")};
    height: ${({ selected }) => (selected ? "20px" : "16px")};
    background: #68cfc9;

    border-radius: 100px;
  }
`;
