import React from "react";

import { Container, Text } from "./styles";

type Props = {
  text: string | number;
  color?: string;
  background: string;
  size: number;
  fontSize: number;
  fontWeight: string;
};

const ElipseIcon = ({ text, color, background, size, fontSize, fontWeight }: Props) => {
  return (
    <Container background={background} size={size}>
      <Text color={color} fontSize={fontSize} fontWeight={fontWeight}>
        {text}
      </Text>
    </Container>
  );
};

export default ElipseIcon;
