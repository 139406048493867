import algoliasearch from "algoliasearch";

export const algoliaSearchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY
);

export const algoliaIndexName = process.env.REACT_APP_ALGOLIA_INDEX_NAME;

export const algoliaIndex = algoliaSearchClient.initIndex(algoliaIndexName);
export type AlgoliaIndex = typeof algoliaIndex;
