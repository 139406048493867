import React from "react";
import useAuthentication from "@hooks/useAuthentication";
import PageLoading from "../components/PageLoading/PageLoading";

type TDefaultRouteProps = {
  children: JSX.Element;
};

export const DefaultRoute = ({ children }: TDefaultRouteProps): JSX.Element => {
  const { loading } = useAuthentication();

  if (loading) {
    return <PageLoading />;
  }

  return children;
};
