import styled from "styled-components";

export const Container = styled.div`
  /* position: absolute;
    top: 100px;
    right: 2%; */
  background: var(--white);
  padding: 15px 20px 10px 20px;
  border-radius: 20px;
  user-select: none;
  min-width: 15vw;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  padding: 5px 0;
  gap: 10px;
  margin-bottom: 10px;

  h3 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    color: #4d6289;
  }
`;

export const ToolBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  h5 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 19px;
    /* identical to box height */

    color: var(--grey2);
  }
`;

export const ClearText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  user-select: none;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */

  text-decoration-line: underline;

  /* 1 */

  color: var(--grey2);
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 10px;
  margin-top: 10px;
  box-sizing: border-box;

  @media screen and (max-width: 1450px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media screen and (max-width: 1360px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

type ItemProps = { selected: boolean };
export const Item = styled.div<ItemProps>`
  display: flex;
  height: 34px;
  width: 34px;
  align-items: center;
  justify-content: center;
  background: var(--background);
  border-radius: 10px;
  cursor: pointer;

  img {
    height: 24px;
    width: 24px;
    opacity: ${({ selected }) => (selected ? 1 : 0.4)};
  }
`;
