import { create } from "zustand";
import { User as FirebaseUser } from "firebase/auth";
import { UserStore } from "@domainStores/UserStore";

type InitialState = Pick<UserStore, "user" | "firebaseUser" | "loading">;

const initialState: InitialState = {
  user: null,
  firebaseUser: {} as FirebaseUser,
  loading: true,
};

const userStore = create<UserStore>((set) => ({
  ...initialState,
  updateUser: (newUserData) =>
    set((state) => {
      return {
        ...state,
        loading: false,
        user: newUserData,
      };
    }),
  updateFirebaseUser: (firebaseUser) =>
    set((state) => {
      return {
        ...state,
        firebaseUser: firebaseUser,
      };
    }),
  clear: () =>
    set((state) => {
      return {
        ...state,
        ...initialState,
      };
    }),
}));

export default userStore;
