import { Timestamp } from "firebase/firestore";
import { Address, Client } from "./Client";

export const reportResultOptions = {
  SIGNED: "Contrato fechado",
  RECOMMENDATION_SIGNED: "Contrato fechado por indicação",
  ADDRESS_NOT_FOUND: "Endereço não encontrado",
  PENDENCY: "Litispendência",
  HAVE_LAWYER: "Já tem advogado",
  RETURN: "Retornar",
  NO_RIGHT: "Não tem direito",
  NOT_INTERESSED: "Não tem interesse",
  DIVERSE_LOCATION: "Localidade diversa",
} as const;

export type ReportResult = (typeof reportResultOptions)[keyof typeof reportResultOptions];

export interface Report {
  id: string;
  result: ReportResult;
  description: string;

  clientId: string;
  clientName: string;
  clientCpf: string;
  clientSpecie: Client["specie"];

  operatorName: string;
  operatorId: string;

  routeId: string;

  clientAddress: Address;
  clientValidAddress: Client["validAddress"];

  returnSchedule: Timestamp | "";

  createdAt: Timestamp;
  updatedAt: Timestamp;
}
