import React from "react";

//pages
import DashboardPage from "@pages/Dashboard/DashboardPage";
import MapPage from "@pages/Map/MapPage";

//react route
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import { PrivateRoute } from "./PrivateRoute";

//Util
// import MenuBar from '../components/MenuBar';
import LoginPage from "@pages/Login/LoginPage";
import { AdminRoute } from "./AdminRoute";
import { DefaultRoute } from "./DefaultRoute";
import MenuBar from "@components/MenuBar/MenuBar";
import NewContractToast from "@components/NewContractToast";
import RoutesPage from "@pages/Routes/RoutesPage";
import ReturnsPage from "@pages/Returns/ReturnsPage";
import UsersPage from "@pages/Users/UsersPage";
import { PrivateRoute } from "./PrivateRoute";
import OperatorMap from "@pages/OperatorMap/OperatorMap";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/map"
          element={
            <PrivateRoute redirectTo={"/"}>
              <OperatorMap />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <AdminRoute redirectTo={"/"}>
              <>
                <MenuBar />
                <DashboardPage />
                <NewContractToast />
              </>
            </AdminRoute>
          }
        />
        <Route
          path="/adminMapPage"
          element={
            <AdminRoute redirectTo={"/"}>
              <>
                <MenuBar />
                <MapPage />
              </>
            </AdminRoute>
          }
        />
        <Route
          path="/adminRoutesPage"
          element={
            <AdminRoute redirectTo={"/"}>
              <>
                <MenuBar />
                <RoutesPage />
              </>
            </AdminRoute>
          }
        />
        <Route
          path="/adminReturnsPage"
          element={
            <AdminRoute redirectTo={"/"}>
              <>
                <MenuBar />
                <ReturnsPage />
              </>
            </AdminRoute>
          }
        />
        <Route
          path="/manageUsers"
          element={
            <AdminRoute redirectTo={"/"}>
              <>
                <MenuBar />
                <UsersPage />
              </>
            </AdminRoute>
          }
        />
        <Route
          path="/"
          element={
            <DefaultRoute>
              <LoginPage />
            </DefaultRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
