import { markerIcons } from "../resources/markerIcons";

import birthday from "../assets/markers/birthday34.png";
import { Client } from "@models/Client";
import { MapClient } from "@models/MapClient";

export const markersHelper = {
  getMarkerIcons,
  getSpecieIcon,
};

function getMarkerIcons(client: MapClient) {
  if (client.specialIcon) {
    return client.iconUrl;
  }

  const hoje = new Date();

  const dia = client.birthDate.slice(0, 2);
  const month = Number(client.birthDate.slice(3, 5));
  const ano = Number(client.birthDate.slice(-4)) + 65;

  let alertMonth;
  if (month - 6 < 0) {
    alertMonth = 12 + (month - 6);
  } else {
    alertMonth = month - 6;
  }

  const dateAlert = new Date(`${alertMonth}-20-${ano}`);
  const dateBirthday = new Date(`${month}-${dia}-${ano}`);

  if (hoje.getTime() >= dateAlert.getTime() && hoje.getTime() <= dateBirthday.getTime()) {
    return birthday;
  }

  return client.iconUrl;
}

function getSpecieIcon(specie: Client["specie"]) {
  const index = markerIcons.findIndex((x) => x.specie === specie);

  if (index != -1) {
    return markerIcons[index].icon;
  }

  return null;
}
