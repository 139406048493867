import { firebase } from "@infra/firebase/config";
import { Client, clientReturnStatus } from "@models/Client";
import { collection, onSnapshot, orderBy, query, Timestamp, where } from "firebase/firestore";
import { fireabseAdapter } from "./firebaseAdapter";
import DatabaseReturnClientRepository from "@domainRepositories/DatabaseReturnClientRepository";
import { ReturnClientStore } from "@domainStores/ReturnClientStore";

export class FirebaseReturnClientRepository implements DatabaseReturnClientRepository {
  constructor(private readonly returnClientStore: ReturnClientStore) {}

  watchReturnClientsByDateAdmin(startDate: Timestamp, endDate: Timestamp) {
    const consulta = query(
      collection(firebase.db, "clients"),
      where("returnStatus", "in", [
        clientReturnStatus.COMPLETED,
        clientReturnStatus.PENDING,
        clientReturnStatus.PREMIUM,
        clientReturnStatus.EXPIRED,
      ]),
      where("lastReportDate", ">=", startDate),
      where("lastReportDate", "<=", endDate),
      orderBy("lastReportDate", "desc")
    );

    onSnapshot(
      consulta,
      async (querySnapshot) => {
        const mappedDocs = fireabseAdapter.mapFirebaseDoc<Client>(querySnapshot);

        const mappedReturn = fireabseAdapter.mapReturnClients(mappedDocs);

        this.returnClientStore.updateDashboardReturns(mappedReturn);
      },
      (error) => console.log("watchReturnClientsByDateAdmin error", error)
    );
  }

  watchReturnClientsByDateManager(
    startDate: Timestamp,
    endDate: Timestamp,
    operatorsIds: string[]
  ) {
    const consulta = query(
      collection(firebase.db, "clients"),
      where("returnStatus", "in", [
        clientReturnStatus.COMPLETED,
        clientReturnStatus.PENDING,
        clientReturnStatus.PREMIUM,
        clientReturnStatus.EXPIRED,
      ]),
      where("lastReportDate", ">=", startDate),
      where("lastReportDate", "<=", endDate),
      orderBy("lastReportDate", "desc")
    );

    onSnapshot(
      consulta,
      async (querySnapshot) => {
        const mappedDocs = fireabseAdapter.mapFirebaseDoc<Client>(querySnapshot);

        const mappedReturn = fireabseAdapter.mapReturnClients(
          mappedDocs.filter((x) => operatorsIds.includes(x.returnOperatorId))
        );

        this.returnClientStore.updateDashboardReturns(mappedReturn);
      },
      (error) => console.log("watchReturnClientsByDateManager error", error)
    );
  }

  watchReturnClientsAdmin() {
    const consulta = query(
      collection(firebase.db, "clients"),
      where("returnStatus", "in", [
        clientReturnStatus.COMPLETED,
        clientReturnStatus.PENDING,
        clientReturnStatus.PREMIUM,
        clientReturnStatus.EXPIRED,
      ]),
      orderBy("lastReportDate", "desc")
    );

    onSnapshot(
      consulta,
      async (querySnapshot) => {
        const mappedDocs = fireabseAdapter.mapFirebaseDoc<Client>(querySnapshot);

        const mappedReturn = fireabseAdapter.mapReturnClients(mappedDocs);

        this.returnClientStore.updateReturns(mappedReturn);
      },
      (error) => console.log("watchReturnClientsAdmin error", error)
    );
  }

  watchReturnClientsManager(operatorsIds: string[]) {
    const consulta = query(
      collection(firebase.db, "clients"),
      where("returnStatus", "in", [
        clientReturnStatus.COMPLETED,
        clientReturnStatus.PENDING,
        clientReturnStatus.PREMIUM,
        clientReturnStatus.EXPIRED,
      ]),
      orderBy("lastReportDate", "desc")
    );

    onSnapshot(
      consulta,
      async (querySnapshot) => {
        const mappedDocs = fireabseAdapter.mapFirebaseDoc<Client>(querySnapshot);

        const mappedReturn = fireabseAdapter.mapReturnClients(
          mappedDocs.filter((x) => operatorsIds.includes(x.returnOperatorId))
        );

        this.returnClientStore.updateReturns(mappedReturn);
      },
      (error) => console.log("watchReturnClientsManager error", error)
    );
  }
}
