export const userRolesOptions = [
  {
    label: "Admin",
    value: "admin",
  },
  {
    label: "Operador",
    value: "operator",
  },
  {
    label: "Gerente",
    value: "manager",
  },
];
