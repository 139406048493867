import React from "react";

import { Container } from "./styles";
import Loading from "@components/Loading/Loading";

type CardRootProps = {
  children: React.ReactNode;
  loading?: boolean;
  onClick: () => void;
};

function CardRoot({ children, loading, onClick }: CardRootProps) {
  if (loading) {
    return (
      <Container>
        <Loading size={30} />
      </Container>
    );
  }

  return <Container onClick={onClick}>{children}</Container>;
}

export default CardRoot;
