import React, { useEffect, useState } from "react";

import {
  ClientHeader,
  ClientNameContent,
  Container,
  Divider,
  ImageEspecie,
  ImageStreetView,
  Content,
  ClientHeaderLeft,
  HistoryHeader,
  HistoryLine,
  HistoryItem,
  HistoryItemContent,
} from "./styles";

//Custom components
import Loading from "@components/Loading/Loading";
import ModalHeader from "@components/Modal/ModalHeader/ModalHeader";
import ModalStreetView from "@components/Modal/ModalStreetView/ModalStreetView";

//Utils
import Modal from "react-modal";

//utils
import ClientBadges from "@components/ClientBadges/ClientBadges";

//APIS
import useClientHistory from "@hooks/useClientHistory";
import axios from "axios";
import DataSection from "../ModalDetailsAdmin/DataSection/DataSection";
import AddressSection from "../ModalDetailsAdmin/AddressSection/AddressSection";
import ContactSection from "../ModalDetailsAdmin/ContactSection/ContactSection";
import RejectionSection from "../ModalDetailsAdmin/RejectionSection/RejectionSection";
import ProcessesSection from "../ModalDetailsAdmin/ProcessesSection/ProcessesSection";
import AnalysisSection from "../ModalDetailsAdmin/AnalysisSection/AnalysisSection";
import { datetimeHelper } from "@helpers/datetime";
import { markersHelper } from "@helpers/markers";
import useUser from "@hooks/useUser";
import { Client } from "@models/Client";

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

type Props = {
  data: Client;
  onClose: () => void;
};

function ModalDetailsHistory({ data, onClose }: Props) {
  const { isAdmin } = useUser();

  const { clientHistory, historyLoading } = useClientHistory();

  const [imageStreetView, setImageStreetView] = useState<Blob>();

  const [modalStreetView, setModalStreetView] = useState(false);

  const [client, setClient] = useState<Client>(data);

  useEffect(() => {
    (async () => {
      await axios
        .get(
          `https://maps.googleapis.com/maps/api/streetview?size=724x144&location=${
            client.lat
          },${client.lng}&fov=80&heading=70&pitch=0&key=${apiKey}`,
          { responseType: "blob" }
        )
        .then((response) => {
          setImageStreetView(response.data);
        });
    })();
  }, [client]);

  if (historyLoading) {
    return (
      <Container>
        <Loading background="transparent" />
      </Container>
    );
  }

  return (
    <Container>
      <ModalHeader title="Histórico do cliente" onClose={onClose} />

      <HistoryHeader>
        <HistoryLine />

        <HistoryItemContent>
          {clientHistory &&
            clientHistory
              .concat([data])
              .reverse()
              .map((item, index) => (
                <HistoryItem
                  key={index}
                  selected={client.createdAt === item.createdAt}
                  onClick={() => setClient(item)}
                >
                  <div />
                  <h1>
                    {datetimeHelper.getDate(item.createdAt)} {index === 0 ? "(atual)" : ""}
                  </h1>
                </HistoryItem>
              ))}
        </HistoryItemContent>
      </HistoryHeader>

      <Content>
        <ImageStreetView
          src={imageStreetView ? URL.createObjectURL(imageStreetView) : null}
          onClick={() => setModalStreetView(true)}
        />

        <ClientHeader>
          <ClientHeaderLeft>
            <ImageEspecie>
              <img
                src={markersHelper.getSpecieIcon(client.specie)}
                style={{ width: 30, height: 30, objectFit: "cover" }}
              />
            </ImageEspecie>
            <ClientNameContent>
              <h4>{client.name}</h4>
              <p>{client.specie}</p>
            </ClientNameContent>
          </ClientHeaderLeft>
          <div>
            <ClientBadges client={client} isModalDetails />
          </div>
          <h3>Data de inclusão: {datetimeHelper.getDate(client.createdAt)}</h3>
        </ClientHeader>

        <Divider />

        <DataSection client={client} inEdit={false} isAdmin={isAdmin} />

        <Divider />

        <AddressSection client={client} inEdit={false} isAdmin={isAdmin} />

        <Divider />

        <ContactSection client={client} inEdit={false} isAdmin={isAdmin} />

        <Divider />

        <RejectionSection client={client} inEdit={false} isAdmin={isAdmin} />

        <Divider />

        <ProcessesSection client={client} inEdit={false} isAdmin={isAdmin} />

        <Divider />

        <AnalysisSection client={client} inEdit={false} isAdmin={isAdmin} />
      </Content>

      <Modal
        isOpen={modalStreetView}
        onRequestClose={() => setModalStreetView(false)}
        overlayClassName="modal-overlay"
        className="modal-content-auto-width"
        contentLabel="Example Modal"
      >
        <ModalStreetView client={client} onClose={() => setModalStreetView(false)} />
      </Modal>
    </Container>
  );
}

export default ModalDetailsHistory;
