import { ToastContainer } from "react-toastify";
import GlobalStyle from "./GlobalStyles";
import Router from "./presentation/routes/Router";

import "react-toastify/dist/ReactToastify.css";
import { createTheme, ThemeProvider } from "@mui/material";
import { InstantSearch } from "react-instantsearch";
import { algoliaIndexName, algoliaSearchClient } from "@infra/algolia/config";
// import { ptBR } from "@mui/material/locale";

const theme = createTheme({
  palette: {
    primary: {
      main: "#DE6161",
    },
    secondary: {
      main: "#000",
    },
  },
});

function App() {
  return (
    <InstantSearch searchClient={algoliaSearchClient} indexName={algoliaIndexName}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <ToastContainer
          progressStyle={{
            background: "#DE6161",
          }}
          style={{
            zIndex: 500000,
          }}
        />
        <Router />
      </ThemeProvider>
    </InstantSearch>
  );
}

export default App;
