import React, { useEffect, useState } from "react";

import { Container, Name, Especie, Result, Date, Options } from "./styles";

//Icons
import { GoDotFill } from "react-icons/go";
import { AiOutlineEye } from "react-icons/ai";
import { FiMap } from "react-icons/fi";
import { BsArrow90DegRight } from "react-icons/bs";

//MUI
import { Avatar } from "@mui/material";
import StatusIcon from "@components/Icons/StatusIcon/StatusIcon";
import { datetimeHelper } from "@helpers/datetime";
import { avatarHelper } from "@helpers/avatar";
import { speciesHelper } from "@helpers/species";
import { Client } from "@models/Client";
import { ReturnModalData } from "@pages/Returns/components/ReturnsCards/ReturnCards";

import { Route } from "@models/Route";
import useRoutes from "@hooks/useRoutes";

const options = [
  "Contrato fechado",
  "Contrato fechado por indicação",
  "Endereço não encontrado",
  "Litispendência",
  "Já tem advogado",
  "Retornar",
  "Não tem direito",
  "Não tem interesse",
  "Localidade diversa",
];

const optionsColors = [
  "var(--success)",
  "var(--success)",
  "var(--red)",
  "var(--red)",
  "var(--red)",
  "var(--yellow)",
  "var(--red)",
  "var(--red)",
  "var(--red)",
];

type Props = {
  data: Client;
  type: ReturnModalData["type"];
  columns: number;
  onViewFullData: () => void;
  onReleaseMap: () => void;
  onAssignReturn: () => void;
};
function ModalReturnItem({
  data,
  type,
  columns,
  onViewFullData,
  onReleaseMap,
  onAssignReturn,
}: Props) {
  const { routesOfDay } = useRoutes();

  // const [returnDate, setReturnDate] = useState("");
  // const [returnHour, setReturnHour] = useState("");
  const [route, setRoute] = useState<Route>(null);

  useEffect(() => {
    if (type === "inRoute") {
      const currentRoute = routesOfDay.find((x) => x.id === data.currentRouteId);

      setRoute(currentRoute);
    }
  }, [type, data, routesOfDay]);

  return (
    <Container columns={columns}>
      <Name>{data.name}</Name>
      <Especie
        color={speciesHelper.getSpecieTag(data.specie).tagColor}
        textColor={speciesHelper.getSpecieTag(data.specie).textColor}
      >
        {data.specie}
      </Especie>
      {(type == "completed" || type == "signed") && (
        <Date color="var(--grey2)">{datetimeHelper.getDate(data.lastReportDate)}</Date>
      )}
      {type == "pending" && <h4>Sem data e hora</h4>}
      {type == "inRoute" && (
        <Date color="var(--grey2)">{datetimeHelper.getDate(route?.startAt)}</Date>
      )}
      {type == "expired" && (
        <Date color="var(--red)">{datetimeHelper.getDate(data.returnExpireDate)}</Date>
      )}
      {type == "inRoute" && <StatusIcon status={route?.status} />}
      {type == "signed" && (
        <Result color={optionsColors[options.findIndex((x) => x == data.lastReportResult)]}>
          <GoDotFill
            size={10}
            color={optionsColors[options.findIndex((x) => x == data.lastReportResult)]}
          />
          {data.lastReportResult}
        </Result>
      )}
      <div>
        <Avatar
          alt="Avatar"
          src={avatarHelper.getUserAvatar(data.returnOperatorId)}
          sx={{
            width: 20,
            height: 20,
            borderRadius: 100,
            borderColor: "white",
            borderWidth: 10,
          }}
        />
        <h4>{data.operatorName}</h4>
      </div>
      <Options>
        <AiOutlineEye
          onClick={onViewFullData}
          cursor="pointer"
          size={20}
          color="#808B9F"
          fill="#808B9F"
        />
        {type == "expired" && (
          <FiMap onClick={onReleaseMap} cursor="pointer" size={15} color="#808B9F" />
        )}
        {type == "expired" && (
          <BsArrow90DegRight
            onClick={onAssignReturn}
            cursor="pointer"
            size={15}
            color="#808B9F"
            strokeWidth="0.5"
          />
        )}
      </Options>
    </Container>
  );
}

export default ModalReturnItem;
