import { LogRepository } from "@domainRepositories/LogRepository";
import UseCase from "@domainUseCases/UseCase";
import { Log } from "@models/Log";
import { Route } from "@models/Route";

type Input = Route["id"];

type Output = Promise<Log[]>;

export class GetRouteLogsUseCase implements UseCase<Input, Output> {
  constructor(private readonly logRepository: LogRepository) {}

  execute(routeId: Input) {
    return this.logRepository.getRouteLogs(routeId);
  }
}
