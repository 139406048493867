import DatabaseReportRepository from "@domainRepositories/DatabaseReportRepository";
import { UserStore } from "@domainStores/UserStore";
import UseCase from "@domainUseCases/UseCase";
import { userRoles } from "@models/User";

type Input = void;

type Output = void;

export class WatchReturnReports implements UseCase<Input, Output> {
  constructor(
    private readonly userStore: UserStore,
    private readonly databaseReportRepository: DatabaseReportRepository
  ) {}

  execute() {
    const user = this.userStore.user;

    if (user.role === userRoles.operator) {
      this.databaseReportRepository.watchOperatorReturnReports();
    }

    return;
  }
}
