//Icons map
import defIcon from "@assets/markers/def34.png";
import idosoIcon from "@assets/markers/idoso34.png";
import auxDoenca from "@assets/markers/auxDoenca34.png";
import auxBrasil from "@assets/markers/auxBrasil34.png";
import pensaoPorMorte from "@assets/markers/pensaoPorMorte34.png";
import auxDoencaAcidente from "@assets/markers/auxDoencaAcidente34.png";
import auxAcidente from "@assets/markers/auxAcidente34.png";
import money34 from "@assets/markers/money34.gif";

//Variables
export const speciesFilterIcons = [
  {
    specie: "LOAS DEF",
    icon: defIcon,
    title: "LOAS DEFICIENTE",
  },
  {
    specie: "LOAS IDOSO",
    icon: idosoIcon,
    title: "LOAS IDOSO",
  },
  {
    specie: "AUX. DOENÇA",
    icon: auxDoenca,
    title: "AUX. DOENÇA",
  },
  {
    specie: "AUX. BRASIL",
    icon: auxBrasil,
    title: "AUX. BRASIL",
  },
  {
    specie: "PENSÃO POR MORTE",
    icon: pensaoPorMorte,
    title: "PENSÃO POR MORTE",
  },
  {
    specie: "AUX. DOENÇA POR ACIDENTE DO TRABALHO",
    icon: auxDoencaAcidente,
    title: "AUX. DOENÇA POR ACIDENTE DO TRABALHO",
  },
  {
    specie: "AUX. ACIDENTE",
    icon: auxAcidente,
    title: "AUX. ACIDENTE",
  },
  {
    specie: "ESPECIAL",
    icon: money34,
    title: "ESPECIAL",
  },
];
