import DatabaseReportRepository from "@domainRepositories/DatabaseReportRepository";
import UseCase from "@domainUseCases/UseCase";

type WatchMonthSignedContractsInput = void;

type WatchMonthSignedContractsOutput = void;

export class WatchMonthSignedContractsUseCase
  implements UseCase<WatchMonthSignedContractsInput, WatchMonthSignedContractsOutput>
{
  constructor(private readonly databaseReportRepository: DatabaseReportRepository) {}

  execute() {
    return this.databaseReportRepository.watchMonthSignedReports();
  }
}
