import ClientRepository from "@domainRepositories/ClientRepository";
import showToast from "@helpers/showToast";
import { HttpClient } from "@infra/api/HttpClient";
import { Client, clientStatus } from "@models/Client";

export class HttpClientRepository implements ClientRepository {
  private readonly baseUrl = `${process.env.REACT_APP_API_URL}/clients`;

  constructor(private readonly httpClient: HttpClient) {}

  async get(clientId: Client["id"]) {
    return this.httpClient
      .request({
        method: "GET",
        url: `${this.baseUrl}/${clientId}`,
      })
      .then((response) => {
        return response.data;
      });
  }

  async save(clientId: Client["id"], data: Partial<Client>) {
    return this.httpClient
      .request({
        method: "POST",
        url: `${this.baseUrl}/${clientId}`,
        body: JSON.stringify(data),
      })
      .then((response) => {
        showToast(response.message, "success");
        return response.data;
      });
  }

  async updateStatus(clientId: Client["id"]) {
    return this.httpClient
      .request({
        method: "POST",
        url: `${this.baseUrl}/${clientId}/status`,
        body: JSON.stringify({ status: clientStatus.CLOSED }),
      })
      .then((response) => {
        showToast(response.message, "success");
        return response.data;
      });
  }

  async toggleSpecialIcon(clientId: Client["id"]) {
    return this.httpClient
      .request({
        method: "POST",
        url: `${this.baseUrl}/${clientId}/special_icon`,
      })
      .then((response) => {
        showToast(response.message, "success");
        return response.data;
      });
  }
}
