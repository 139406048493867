import { Address } from "./Client";
import { MapClient } from "./MapClient";

export class RoutePoint {
  id: MapClient["id"];
  cpf: MapClient["cpf"];
  name: MapClient["name"];
  birthDate: MapClient["birthDate"];
  specie: MapClient["specie"];

  lat: MapClient["lat"];
  lng: MapClient["lng"];

  currentAddress: Address;

  specialIcon: MapClient["specialIcon"];

  iconUrl: MapClient["iconUrl"];

  mapId: MapClient["id"];

  visited: boolean;

  constructor(mapClient: MapClient, visited: boolean = false) {
    this.id = mapClient.id;
    this.cpf = mapClient.cpf;
    this.name = mapClient.name;
    this.birthDate = mapClient.birthDate;
    this.specie = mapClient.specie;
    this.lat = mapClient.lat;
    this.lng = mapClient.lng;
    this.currentAddress = mapClient.currentAddress;
    this.specialIcon = mapClient.specialIcon;
    this.iconUrl = mapClient.iconUrl;
    this.mapId = mapClient.mapId;
    this.visited = visited;
  }
}
