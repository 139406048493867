import RouteRepository from "@domainRepositories/RouteRepository";
import UseCase from "@domainUseCases/UseCase";
import { Route } from "@models/Route";

type Input = Route["id"];

type Output = Promise<Route>;

export class CancelRouteUseCase implements UseCase<Input, Output> {
  constructor(private readonly routeRepository: RouteRepository) {}

  execute(routeId: Input) {
    return this.routeRepository.cancel(routeId);
  }
}
