import React, { useEffect, useState } from "react";

import { Container, Header } from "./styles";

//Custom components
import ProfileButton from "@components/ProfileButton/ProfileButton";
import OperatorsCard from "./components/OperatorsCard/OperatorsCard";
import DataTable from "@components/DataTable/DataTable";

//Utils
import tableColumnsReturns from "@resources/tableColumnsReturns.json";
import { arrayHelper } from "@helpers/array";
import { filtersHelper } from "@helpers/filters";
import ReturnsCards from "./components/ReturnsCards/ReturnCards";
import useUser from "@hooks/useUser";
import useReturns from "@hooks/useReturns";
import { Client } from "@models/Client";
import { Return } from "@models/Return";
import { reportResultOptions } from "@models/Report";
import { User } from "@models/User";
import { searchHelper } from "@helpers/search";

export type CardsData = {
  completedReturns: Client[];
  pendingReturns: Client[];
  inRouteReturns: Client[];
  expiredReturns: Client[];
  signedAfterReturn: Client[];
  total: Client[];
};

export type OperatorsData = Partial<User> & CardsData;

function ReturnsPage() {
  const { user, isAdmin } = useUser();

  const { returns } = useReturns();

  //Cards
  const [operatorsData, setOperatorsData] = useState<OperatorsData[]>([]);
  const [cardsData, setCardsData] = useState<CardsData>({
    completedReturns: [],
    pendingReturns: [],
    inRouteReturns: [],
    expiredReturns: [],
    signedAfterReturn: [],
    total: [],
  });

  //filters and search
  const [filters, setFilters] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (returns) {
      processReportsCards(returns);
    }
  }, [returns]);

  useEffect(() => {
    if (cardsData) {
      processOperatorsCard(cardsData);
    }
  }, [cardsData]);

  const processReportsCards = (returns: Return) => {
    const adminUids = arrayHelper.reduceToSimple(user.users.admins, "id");

    if (isAdmin) {
      adminUids.push(user.id);
    }

    const completedReturns = returns.completed.filter(
      (x) => !adminUids.includes(x.returnOperatorId)
    );
    const pendingReturns = returns.pending
      .concat(returns.premium)
      .filter((x) => !adminUids.includes(x.returnOperatorId));
    const expiredReturns = returns.expired.filter((x) => !adminUids.includes(x.returnOperatorId));
    const signedAfterReturn = returns.completed
      .filter(
        (x) =>
          x.lastReportResult === reportResultOptions.SIGNED ||
          x.lastReportResult === reportResultOptions.RECOMMENDATION_SIGNED
      )
      .filter((x) => !adminUids.includes(x.returnOperatorId));
    const total = arrayHelper.ordenateArrayDesc(
      completedReturns.concat(pendingReturns, expiredReturns, signedAfterReturn),
      "lastReportDate"
    );

    const inRouteReturns = total.filter((x) => x.currentRouteId);

    setCardsData({
      completedReturns: arrayHelper.ordenateArrayDesc(completedReturns, "lastReportDate"),
      pendingReturns: arrayHelper.ordenateArrayDesc(pendingReturns, "lastReportDate"),
      inRouteReturns: arrayHelper.ordenateArrayDesc(inRouteReturns, "lastReportDate"),
      expiredReturns: arrayHelper.ordenateArrayDesc(expiredReturns, "lastReportDate"),
      signedAfterReturn: arrayHelper.ordenateArrayDesc(signedAfterReturn, "lastReportDate"),
      total: total,
    });
  };

  const processOperatorsCard = (data: CardsData) => {
    const array = [];
    const arrayUsers = user.users.operators.filter((x) => x.disabled != true);

    array.push({
      userName: "Geral",
      img64: null,
      completedReturns: data.completedReturns,
      pendingReturns: data.pendingReturns,
      inRouteReturns: data.inRouteReturns,
      expiredReturns: data.expiredReturns,
      signedAfterReturn: data.signedAfterReturn,
      total: data.total,
    });

    for (const user of arrayUsers) {
      const findRealized = data.completedReturns.filter((x) => x.returnOperatorId === user.id);
      const findPending = data.pendingReturns.filter((x) => x.returnOperatorId === user.id);
      const findInRoute = data.inRouteReturns.filter((x) => x.returnOperatorId === user.id);
      const findExpired = data.expiredReturns.filter((x) => x.returnOperatorId === user.id);
      const findClosedByReturn = data.signedAfterReturn.filter(
        (x) => x.returnOperatorId === user.id
      );

      array.push({
        ...user,
        completedReturns: arrayHelper.ordenateArrayDesc(findRealized, "lastReportDate"),
        pendingReturns: arrayHelper.ordenateArrayDesc(findPending, "lastReportDate"),
        inRouteReturns: arrayHelper.ordenateArrayDesc(findInRoute, "lastReportDate"),
        expiredReturns: arrayHelper.ordenateArrayDesc(findExpired, "lastReportDate"),
        signedAfterReturn: arrayHelper.ordenateArrayDesc(findClosedByReturn, "lastReportDate"),
        total: arrayHelper.ordenateArrayDesc(
          findRealized.concat(findPending, findInRoute, findExpired, findClosedByReturn),
          "lastReportDate"
        ),
      });
    }

    setOperatorsData(array);
  };

  const filterByTable = () => {
    if (cardsData?.total) {
      return filtersHelper.filterDataTable(cardsData?.total, filters);
    } else {
      return [];
    }
  };

  const clearFilters = () => {
    setFilters([]);
    setSearch("");
  };

  const baseTableData = filterByTable();

  const backupTableData = cardsData ? cardsData?.total : [];

  const tableData = search
    ? searchHelper.searchTable(search, tableColumnsReturns, backupTableData)
    : baseTableData;

  return (
    <Container>
      <Header>
        <h1>Central de Retornos</h1>
        <ProfileButton arrowColor="var(grey2)" />
      </Header>

      <ReturnsCards cardsData={cardsData} />

      <OperatorsCard operatorsData={operatorsData} />

      <DataTable
        title="Clientes com retorno"
        tableColumns={tableColumnsReturns}
        tableRows={tableData}
        tableRowsBackup={backupTableData}
        filterTypes={tableColumnsReturns}
        columns={7}
        filtersHighlights={[
          {
            field: "lastReportDate",
            headerName: "Data",
          },
          {
            field: "returnOperatorName",
            headerName: "Operador",
          },
          {
            field: "lastReportResult",
            headerName: "Resultado",
          },
        ]}
        perPage={15}
        dateField="lastReportDate"
        search={search}
        setSearch={setSearch}
        filters={filters}
        onChangeFilters={(newFiltersArray) => {
          setFilters(newFiltersArray);
        }}
        onClearFilters={() => clearFilters()}
        onClick={() => {}}
      />
    </Container>
  );
}

export default ReturnsPage;
