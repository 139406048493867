import { create } from "zustand";
import { MapStore } from "@domainStores/MapStore";

type InitialState = Pick<MapStore, "maps">;

const initialState: InitialState = {
  maps: [],
};

const mapStore = create<MapStore>((set) => ({
  ...initialState,
  updateMaps: (maps) =>
    set((state) => {
      return {
        ...state,
        maps: maps,
      };
    }),
  clear: () =>
    set((state) => {
      return {
        ...state,
        ...initialState,
      };
    }),
}));

export default mapStore;
