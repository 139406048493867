import React, { useState } from "react";

import { Container, Header, ListContainer, TableFooter } from "./styles";

//Icons
import { AiOutlineCloseCircle } from "react-icons/ai";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

//Custom components
// import DownloadButton from "../DownloadButton";
import ModalReturnItem from "@components/Modal/ModalReturnItem/ModalReturnItem";
import Loading from "@components/Loading/Loading";

//Utils
import Modal from "react-modal";
import ModalAssignReturn from "@components/Modal/ModalAssignReturn/ModelAssignReturn";
import { filtersHelper } from "@helpers/filters";
import { ReturnModalData } from "@pages/Returns/components/ReturnsCards/ReturnCards";
import { Client } from "@models/Client";
import ModalDetailsAdmin from "../ModalDetailsAdmin/ModalDetailsAdmin";
import ModalConfirm from "../ModalConfirm/ModalConfirm";
import { AssignReturnUseCase, ReleaseReturnUseCase } from "@dataUseCases/index";
import { HttpReturnRepository } from "@dataRepositories/Http/HttpReturnRepository";
import { api } from "@infra/api";
import { SelectOption } from "@models/SelectOption";
import DataHeaderFilter from "@components/Filters/DataHeaderFilter/DataHeaderFilter";

type Props = {
  data: ReturnModalData;
  onClose: () => void;
};

const perPage = 15;

function ModalReturnCard({ data, onClose }: Props) {
  const [loading, setLoading] = useState(false);

  //filters
  const [filters, setFilters] = useState([]);

  const [modalData, setModalData] = useState<Client[]>(data.array);
  const [rangeOfList, setRangeOfList] = useState({ init: 0, finish: perPage });

  const [currentClient, setCurrentClient] = useState<Client>();

  //Modal
  const [modalReleaseMap, setModalReleaseMap] = useState(false);
  const [modalAssignReturn, setModalAssignReturn] = useState(false);
  const [modalFullData, setModalFullData] = useState(false);

  const releaseToMap = async () => {
    setLoading(true);

    const useCase = new ReleaseReturnUseCase(new HttpReturnRepository(api));

    useCase
      .execute(currentClient.id)
      .then((client) => {
        updateModalData(client.id);
        setCurrentClient(null);
        setModalReleaseMap(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const assignToOperator = async (operator: SelectOption) => {
    const useCase = new AssignReturnUseCase(new HttpReturnRepository(api));

    useCase.execute({ clientId: currentClient.id, operatorId: operator.value }).then((client) => {
      updateModalData(client.id);
      setCurrentClient(null);
      setModalAssignReturn(false);
    });
  };

  const updateModalData = (clientId: Client["id"]) => {
    setModalData(modalData.filter((x) => x.id !== clientId));
  };

  const filterList = () => {
    return filtersHelper.filterDataTable(modalData, filters);
  };

  const clearFilters = () => {
    setFilters([]);
  };

  if (!modalData) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  const tableData = filters.length > 0 ? filterList() : modalData;

  return (
    <Container>
      <Header>
        <h3>{data.title}</h3>

        <div>
          {data.filterTypes && (
            <DataHeaderFilter
              dateField="lastReportDate"
              filters={filters}
              filterTypes={data.filterTypes}
              tableRowsBackup={data.array}
              defaultTitle=""
              onChangeFilters={(newFiltersArray) => {
                setFilters(newFiltersArray);
              }}
              onClearFilters={() => clearFilters()}
            />
          )}
          {/* <DownloadButton tableColumns={data.tableColumns} tableRows={tableData} /> */}
          <AiOutlineCloseCircle
            style={{ marginLeft: 10 }}
            onClick={onClose}
            strokeWidth={0.5}
            cursor="pointer"
            size={22}
          />
        </div>
      </Header>
      <ListContainer>
        {tableData &&
          tableData.slice(rangeOfList.init, rangeOfList.finish).map((item, index) => (
            <ModalReturnItem
              key={index}
              data={item}
              type={data.type}
              columns={data.columns}
              onViewFullData={() => {
                setCurrentClient(item);
                setModalFullData(true);
              }}
              onReleaseMap={() => {
                setCurrentClient(item);
                setModalReleaseMap(true);
              }}
              onAssignReturn={() => {
                setCurrentClient(item);
                setModalAssignReturn(true);
              }}
            />
          ))}
      </ListContainer>
      <TableFooter>
        <h3>
          {rangeOfList.init} - {rangeOfList.finish}
        </h3>
        <div>
          <MdKeyboardArrowLeft
            size={20}
            cursor="pointer"
            onClick={() => {
              if (rangeOfList.finish == tableData.length) {
                const value = rangeOfList.finish - rangeOfList.init + perPage;

                const newFinish = rangeOfList.init;

                setRangeOfList({ init: rangeOfList.finish - value, finish: newFinish });
              } else {
                if (rangeOfList.init - perPage >= 0) {
                  setRangeOfList({
                    init: rangeOfList.init - perPage,
                    finish: rangeOfList.finish - perPage,
                  });
                }
              }
            }}
          />
          <MdKeyboardArrowRight
            size={20}
            cursor="pointer"
            onClick={() => {
              if (tableData.length > perPage) {
                if (rangeOfList.finish + perPage <= tableData.length) {
                  setRangeOfList({
                    init: rangeOfList.finish,
                    finish: rangeOfList.finish + perPage,
                  });
                } else {
                  if (rangeOfList.finish != tableData.length) {
                    const value = rangeOfList.finish + (tableData.length - rangeOfList.finish);

                    setRangeOfList({ init: rangeOfList.finish, finish: value });
                  }
                }
              }
            }}
          />
        </div>
        <h3>{tableData ? tableData.length : 0} clientes</h3>
      </TableFooter>

      <Modal
        isOpen={modalFullData}
        onRequestClose={() => setModalFullData(false)}
        overlayClassName="modal-overlay"
        className="modal-content-auto-width"
        contentLabel="Example Modal"
      >
        <ModalDetailsAdmin data={currentClient} onClose={() => setModalFullData(false)} />
      </Modal>

      <Modal
        isOpen={modalReleaseMap}
        onRequestClose={() => setModalReleaseMap(false)}
        overlayClassName="modal-overlay"
        className="modal-content-auto-width"
        contentLabel="Example Modal"
      >
        <ModalConfirm
          text={`Deseja devolver o cliente ${currentClient?.name} ao mapa?`}
          option="Devolver ao mapa"
          loading={loading}
          onCancel={() => setModalReleaseMap(false)}
          onConfirm={releaseToMap}
        />
      </Modal>

      <Modal
        isOpen={modalAssignReturn}
        onRequestClose={() => setModalAssignReturn(false)}
        overlayClassName="modal-overlay"
        className="modal-content-auto-width"
        contentLabel="Example Modal"
      >
        <ModalAssignReturn
          client={currentClient}
          onCancel={() => setModalAssignReturn(false)}
          onConfirm={assignToOperator}
        />
      </Modal>
    </Container>
  );
}

export default ModalReturnCard;
