import React from "react";

//Styles
import {
  Container,
  RoutePointItemLeft,
  RoutePointItemInfo,
  PointDot,
  IconContainer,
} from "./styles";

//Icons
import PointIcon from "@components/Icons/PointIcon/PointIcon";
import { FiTrash2 } from "react-icons/fi";

//utils
import { addressHelper } from "../../helpers/address";
import { RoutePoint } from "@models/RoutePoint";

type Props = {
  index: number;
  point: RoutePoint;
  inEdit?: boolean;
  onRemove: (clientId: string) => void;
};
const RouteClientPoint = ({ index, point, inEdit, onRemove }: Props) => {
  return (
    <Container>
      <RoutePointItemLeft>
        <PointIcon
          index={index}
          size={18}
          backgroundColor={point.visited ? "var(--success)" : "var(--red)"}
        />

        <PointDot />
        <PointDot />
        <PointDot />
        <PointDot />
        <PointDot />
        <PointDot />
      </RoutePointItemLeft>
      <RoutePointItemInfo>
        <h4>{point.name}</h4>
        <p>{addressHelper.formatCurrentAddress(point.currentAddress)}</p>
      </RoutePointItemInfo>
      {inEdit && !point.visited && (
        <IconContainer>
          <FiTrash2
            onClick={() => onRemove(point.id)}
            color="var(--red)"
            size={20}
            style={{ strokeWidth: 1.5, cursor: "pointer" }}
          />
        </IconContainer>
      )}
    </Container>
  );
};

export default RouteClientPoint;
