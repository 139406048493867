import { AiOutlineCloseSquare } from "react-icons/ai";

export const alertErrorStyle = {
  style: {
    background: "var(--red)",
    color: "white",
  },
  progressStyle: {
    background: "white",
  },
  icon: AiOutlineCloseSquare,
};
