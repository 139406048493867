import { ReturnRepository } from "@domainRepositories/ReturnRepository";
import UseCase from "@domainUseCases/UseCase";
import { Client } from "@models/Client";

type Input = Client["id"];

type Output = Promise<Client>;

export class ReleaseReturnUseCase implements UseCase<Input, Output> {
  constructor(private readonly returnRepository: ReturnRepository) {}

  execute(clientId: Input) {
    return this.returnRepository.release(clientId);
  }
}
