import styled from "styled-components";

export const Container = styled.div`
  box-sizing: border-box;
  display: flex;

  width: 99%;
  height: 44px;
  align-items: center;

  margin: 5px 0;

  border: 1px solid var(--grey1);
  border-radius: 100px;
`;
