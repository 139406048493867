import MapRepository from "@domainRepositories/MapRepository";
import UseCase from "@domainUseCases/UseCase";
import { Map } from "@models/Map";

type Input = Partial<Map>;

type Output = Promise<Map>;

export class CreateMapUseCase implements UseCase<Input, Output> {
  constructor(private readonly mapRepository: MapRepository) {}

  execute(data: Input) {
    return this.mapRepository.create(data);
  }
}
