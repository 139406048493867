import DatabaseRouteRepository from "@domainRepositories/DatabaseRouteRepository";
import { UserStore } from "@domainStores/UserStore";
import UseCase from "@domainUseCases/UseCase";
import { userRoles } from "@models/User";
import { Timestamp } from "firebase/firestore";

type Input = {
  startDate: Timestamp;
  endDate: Timestamp;
};

type Output = void;

export class WatchRoutesOfDay implements UseCase<Input, Output> {
  constructor(
    private readonly userStore: UserStore,
    private readonly databaseRouteRepository: DatabaseRouteRepository
  ) {}

  execute({ startDate, endDate }: Input) {
    const user = this.userStore.user;

    if (user.role === userRoles.root || user.role === userRoles.admin) {
      return this.databaseRouteRepository.watchRoutesByDateAdmin(startDate, endDate);
    }

    if (user.role === userRoles.manager) {
      return this.databaseRouteRepository.watchRoutesByDateManager(
        startDate,
        endDate,
        user.operatorsIds
      );
    }

    return;
  }
}
