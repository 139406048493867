import { Filter } from "@models/Filters";
import { Timestamp } from "firebase/firestore";

export const datetimeHelper = {
  getDate,
  getFullDate,
  getHours,
  getMonthIntervalMs,
  getMonthsIntervalMs,
  getDate00,
  getTimeFromFilter,
  getTimestampFromFilter,
  getMillisecondsForYearBackwards,
};

function getFullDate(timestamp: Timestamp | "") {
  if (!timestamp) {
    return "";
  }

  const dateNow = new Date(timestamp.seconds * 1000);

  const day = dateNow.getDate() < 10 ? `0${dateNow.getDate()}` : dateNow.getDate();
  const month = dateNow.getMonth() + 1 < 10 ? `0${dateNow.getMonth() + 1}` : dateNow.getMonth() + 1;
  const year = dateNow.getFullYear();

  const hours = dateNow.getHours() < 10 ? `0${dateNow.getHours()}` : dateNow.getHours();
  const minutes = dateNow.getMinutes() < 10 ? `0${dateNow.getMinutes()}` : dateNow.getMinutes();
  const seconds = dateNow.getSeconds() < 10 ? `0${dateNow.getSeconds()}` : dateNow.getSeconds();

  const fullDate = `${day}/${month}/${year} - ${hours}:${minutes}:${seconds}`;

  return fullDate;
}

function getDate(timestamp: Timestamp | "") {
  if (!timestamp) {
    return "";
  }
  const dateNow = new Date(timestamp.seconds * 1000);

  const day = dateNow.getDate() < 10 ? `0${dateNow.getDate()}` : dateNow.getDate();
  const month = dateNow.getMonth() + 1 < 10 ? `0${dateNow.getMonth() + 1}` : dateNow.getMonth() + 1;
  const year = dateNow.getFullYear();

  const fullDate = `${day}/${month}/${year}`;

  return fullDate;
}

function getHours(time) {
  const dateNow = new Date(time);

  const hours = dateNow.getHours() < 10 ? `0${dateNow.getHours()}` : dateNow.getHours();
  const minutes =
    dateNow.getMinutes() + 1 < 10 ? `0${dateNow.getMinutes() + 1}` : dateNow.getMinutes() + 1;

  const fullDate = `${hours}:${minutes}`;

  return fullDate;
}

function getMonthIntervalMs(time) {
  const dateNow = new Date(time);

  const month = dateNow.getMonth();
  const year = dateNow.getFullYear();

  const startTime = new Date(year, month, 1).getTime();
  const endTime = new Date(year, month + 1, 1).getTime(); //get first day of next month

  return {
    startTime: startTime,
    endTime: endTime - 1000, //subtract 1 second to get 23h59m of yesterday
  };
}

function getMonthsIntervalMs(time: number, months: number) {
  const array = [];

  const date = new Date(time);

  const currentMonth = date.getMonth();
  const currentYear = date.getFullYear();

  const startMonth = months;
  const endMonth = 0;

  for (let month = startMonth; month > endMonth; month = month - 1) {
    const realYear = months - month > currentMonth ? currentYear - 1 : currentYear;
    const realMonth = realYear !== currentYear ? 12 - month : month;

    const date = new Date(realYear, realMonth, 1);

    const interval = getMonthIntervalMs(date.getTime());

    array.push(interval);
  }

  return array;
}

function getDate00(time) {
  const date = new Date(time);

  const time00 = new Date(date.getFullYear(), date.getMonth(), date.getDate());

  return time00;
}

function getTimeFromFilter(dateFilterValue: Filter["dateValue"]["selectedStart"]) {
  return new Date(
    `${dateFilterValue.month}/${dateFilterValue.day}/${dateFilterValue.year}`
  ).getTime();
}

function getTimestampFromFilter(dateFilterValue: Filter["dateValue"]["selectedStart"]) {
  return Timestamp.fromDate(
    new Date(`${dateFilterValue.month}/${dateFilterValue.day}/${dateFilterValue.year}`)
  );
}

function getMillisecondsForYearBackwards() {
  const now = new Date();
  const startYearAgo = new Date(now.getFullYear() - 1, now.getMonth() + 1, 0);

  const monthsInMilliseconds = [];

  for (let i = 0; i <= 12; i++) {
    const currentMonth = new Date(startYearAgo.getFullYear(), startYearAgo.getMonth() + i, 1);

    if (currentMonth > now) break;

    const startTime = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth(),
      1,
      0,
      0,
      0
    ).getTime();

    const endTime = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() + 1,
      0,
      23,
      59,
      59
    ).getTime();

    monthsInMilliseconds.push({
      year: currentMonth.getFullYear(),
      month: currentMonth.getMonth() + 1,
      startTime,
      endTime,
    });
  }

  return monthsInMilliseconds;
}
