import { MapClientStore } from "@domainStores/MapClientStore";
import { create } from "zustand";

type InitialState = Pick<MapClientStore, "mapClients">;

const initialState: InitialState = {
  mapClients: [],
};

const mapClientStore = create<MapClientStore>((set) => ({
  ...initialState,
  updateMapClients: (newMapClients) =>
    set((state) => {
      return {
        ...state,
        mapClients: newMapClients,
      };
    }),
  clear: () =>
    set((state) => {
      return {
        ...state,
        ...initialState,
      };
    }),
}));

export default mapClientStore;
