import { FirebaseMapRepository } from "@dataRepositories/Firebase/FirebaseMapRepository";
import { WatchMapsUseCase } from "@dataUseCases/index";
import mapStore from "@stores/map";
import userStore from "@stores/user";
import { useEffect } from "react";
import { useStore } from "zustand";

export default function useMaps() {
  const { maps } = useStore(mapStore);

  const getMaps = async () => {
    const useCase = new WatchMapsUseCase(
      userStore.getState(),
      new FirebaseMapRepository(mapStore.getState())
    );

    return useCase.execute();
  };

  useEffect(() => {
    if (maps.length === 0) {
      getMaps();
    }
  }, []);

  return {
    maps,
  };
}
