import RouteRepository from "@domainRepositories/RouteRepository";
import showToast from "@helpers/showToast";
import { HttpClient } from "@infra/api/HttpClient";
import { Route } from "@models/Route";

export class HttpRouteRepository implements RouteRepository {
  private readonly baseUrl = `${process.env.REACT_APP_API_URL}/routes`;

  constructor(private readonly httpClient: HttpClient) {}

  async getDaysWithRoutes(startDate: number, endDate: number) {
    return this.httpClient
      .request({
        url: `${this.baseUrl}/days/?startDate=${startDate}&endDate=${endDate}`,
        method: "GET",
      })
      .then((response) => response.data);
  }

  async create(data: Partial<Route>) {
    return this.httpClient
      .request({
        url: `${this.baseUrl}`,
        method: "POST",
        body: JSON.stringify(data),
      })
      .then((response) => {
        showToast(response.message, "success");
        return response.data;
      });
  }

  async update(routeId: Route["id"], data: Partial<Route>) {
    return this.httpClient
      .request({
        url: `${this.baseUrl}/${routeId}`,
        method: "PUT",
        body: JSON.stringify(data),
      })
      .then((response) => {
        showToast(response.message, "success");
        return response.data;
      });
  }

  async cancel(routeId: Route["id"]) {
    return this.httpClient
      .request({
        url: `${this.baseUrl}/${routeId}`,
        method: "DELETE",
      })
      .then((response) => {
        showToast(response.message, "success");
        return response.data;
      });
  }
}
