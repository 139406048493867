import MapRepository from "@domainRepositories/MapRepository";
import UseCase from "@domainUseCases/UseCase";
import { Map } from "@models/Map";

type Input = {
  mapId: Map["id"];
  data: Partial<Map>;
};

type Output = Promise<Map>;

export class UpdateMapUseCase implements UseCase<Input, Output> {
  constructor(private readonly mapRepository: MapRepository) {}

  execute({ mapId, data }: Input) {
    return this.mapRepository.update(mapId, data);
  }
}
