import React from "react";
import { Navigate } from "react-router-dom";
import useAuthentication from "@hooks/useAuthentication";
import PageLoading from "../components/PageLoading/PageLoading";

type TPrivateRouteProps = {
  children: JSX.Element;
  redirectTo: string;
};

export const PrivateRoute = ({ children, redirectTo }: TPrivateRouteProps) => {
  const { user, loading } = useAuthentication();

  if (loading) {
    return <PageLoading />;
  }

  return user ? children : <Navigate to={redirectTo} replace />;
};
