import { ReportRepository } from "@domainRepositories/ReportRepository";
import UseCase from "@domainUseCases/UseCase";
import { Report } from "@models/Report";

type Input = Partial<Report>;

type Output = Promise<Report>;

export class CreateReportUseCase implements UseCase<Input, Output> {
  constructor(private readonly reportRepository: ReportRepository) {}

  execute(report: Input) {
    return this.reportRepository.create(report);
  }
}
