import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
  max-width: 850px;

  padding: 20px 20px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 550px;
  overflow-y: scroll;
  padding: 0 10px 0px 0px;
`;

export const ImageStreetView = styled.img`
  width: 100%;
  height: 144px;

  margin-bottom: 10px;
  border-radius: 10px;

  cursor: pointer;
`;

export const ClientHeader = styled.div`
  display: grid;
  grid-template-columns: 1.8fr 1.25fr 0.75fr;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;

  gap: 10px;

  h3 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    padding-bottom: 5px;

    /* 2 */

    color: #808b9f;
  }
`;

export const ClientHeaderLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const ImageEspecie = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 52px;
  width: 52px;

  border-radius: 100px;
  background-color: var(--grey1);
`;

export const ClientNameContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  h4 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;

    /* 1 */

    color: #4a5e83;
  }

  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    /* 2 */

    color: #808b9f;
  }
`;

export const Divider = styled.div`
  position: relative;
  width: 100%;
  height: 0px;

  margin: 20px 0;

  /* 3 */

  border: 0.5px dashed #b4c1d5;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  gap: 10px;
  margin-bottom: 10px;

  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    max-width: 80%;
    /* 2 */

    color: var(--grey2);
  }
`;

export const InfoHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  margin: 10px 0;
  padding-right: 10px;

  color: #4a5e83;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;

    gap: 10px;
  }

  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    /* 1 */

    color: #4a5e83;
  }
`;

export const RowContent = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  row-gap: 10px;
  column-gap: 10px;
`;

export const RowItem = styled.div`
  display: flex;
  flex-direction: column;

  gap: 10px;

  h3 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    /* 1 */

    color: #4a5e83;
  }

  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    /* 2 */

    color: #808b9f;
  }
`;

export const AddressItem = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  gap: 10px;
`;

export const AddressHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 44px;

  gap: 15px;

  justify-content: space-between;
  align-items: center;

  color: var(--blue4);

  div {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    min-width: 200px;

    user-select: none;
    cursor: pointer;
  }

  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    /* 1 */

    color: #4a5e83;
  }
`;

export const AddressText = styled.div`
  display: grid;
  grid-template:
    "header header" 1fr
    "content content" 1fr / 1fr 1fr 1fr;

  & div:first-child {
    grid-area: header;
  }

  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    /* 2 */

    color: #808b9f;
  }
`;

export const AddressForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 5px;

  &:first-child {
    grid-column: 1;
    width: 100%;
  }
`;

export const AnalysisText = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  /* 1 */

  color: #4a5e83;
`;

export const EditButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 10px;
  justify-content: space-between;
`;

export const CancelButton = styled.div`
  cursor: pointer;
  user-select: none;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  /* 2 */

  color: #808b9f;
`;

export const SaveButton = styled.div`
  background: #de6161;
  box-shadow: 0px 7px 10px rgba(228, 73, 73, 0.25);
  border-radius: 100px;

  cursor: pointer;
  user-select: none;

  padding: 7px 40px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  color: #ffffff;
`;

export const ActionsContent = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 20px 0;

  gap: 20px;

  align-items: center;
  justify-content: right;
`;

export const ActionButton = styled.div`
  display: flex;

  align-items: center;
  gap: 10px;

  cursor: pointer;
  user-select: none;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  /* 2 */

  color: ${(props) => props.color};
`;

export const Value = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  h3 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: var(--blue4);
  }

  h4 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;

    color: var(--white);
  }

  div {
    padding: 8px 8px;
    background: var(--red);
    border-radius: 20px;
  }
`;
