import { Client } from "@models/Client";
import { OperatorsCardData } from "@pages/Dashboard/DashboardPage";
import { Return } from "@models/Return";
import { User } from "@models/User";
import { reportResultOptions } from "@models/Report";
import { arrayHelper } from "./array";
import { DaysWithRoutes } from "@models/DaysWithRoutes";

export type CardsData = {
  signed: Client[];
  winnerSigned: {
    operatorId: string;
    operatorName: string;
    qtd: number;
    array: Client[];
  };
  attendClients: Client[];
  winnerVisits: {
    operatorId: string;
    operatorName: string;
    qtd: number;
    array: Client[];
  };
};

export const dashboardHelper = {
  getCardsData,
  geOperatorsCardData,
  getAttendOfMonth,
};

function getCardsData(user: User, attendClients: Client[]): CardsData {
  const adminUids = arrayHelper.reduceToSimple(user.users.admins, "id").concat([user.id]);

  attendClients = attendClients.filter(
    (client) =>
      !adminUids.includes(client.operatorId) &&
      client.lastReportResult != reportResultOptions.DIVERSE_LOCATION
  );

  const signed = calculateSignedContracts(attendClients);
  const winnerSigned = calculateRanking(signed, attendClients);
  const winnerVisits = calculateWinnerVisits(attendClients, attendClients);

  return {
    signed: signed,
    winnerSigned: winnerSigned,
    attendClients: attendClients,
    winnerVisits: winnerVisits,
  };
}

function calculateSignedContracts(attendClients: Client[]): Client[] {
  return attendClients.filter(
    (x) =>
      x.lastReportResult === reportResultOptions.SIGNED ||
      x.lastReportResult === reportResultOptions.RECOMMENDATION_SIGNED
  );
}

function calculateRanking(
  signedReportsClients: Client[],
  cardsData: Client[]
): CardsData["winnerSigned"] {
  const operators = [];
  const rankingArray = [];

  for (const client of cardsData) {
    const result = operators.find((x) => x === client.operatorName);

    if (!result) {
      operators.push({
        operatorName: client.operatorName,
        operatorId: client.operatorId,
      });
    }
  }

  if (operators.length > 0) {
    for (const operator of operators) {
      const result = signedReportsClients.filter((x) => x.operatorId === operator.operatorId);

      rankingArray.push({
        operatorName: operator.operatorName,
        operatorId: operator.operatorId,
        qtd: result.length,
        array: result,
      });
    }
  }

  if (rankingArray.length > 0) {
    return rankingArray.reduce(function (prev, current) {
      return prev.qtd > current.qtd ? prev : current;
    });
  }

  return {
    operatorName: "",
    operatorId: "",
    qtd: 0,
    array: [],
  };
}

function calculateWinnerVisits(
  attendClients: Client[],
  cardsData: Client[]
): CardsData["winnerVisits"] {
  const operators = [];
  const rankingArray = [];

  for (const client of cardsData) {
    const result = operators.find((x) => x === client.operatorName);

    if (!result) {
      operators.push({
        operatorName: client.operatorName,
        operatorId: client.operatorId,
      });
    }
  }

  if (operators.length > 0) {
    for (const operator of operators) {
      const result = attendClients.filter((x) => x.operatorId === operator.operatorId);

      rankingArray.push({
        operatorName: operator.operatorName,
        operatorId: operator.operatorId,
        qtd: result.length,
        array: result,
      });
    }
  }

  if (rankingArray.length > 0) {
    return rankingArray.reduce(function (prev, current) {
      return prev.qtd > current.qtd ? prev : current;
    });
  }

  return {
    operatorName: "",
    operatorId: "",
    qtd: 0,
    array: [],
  };
}

function geOperatorsCardData(
  user: User,
  attendClients: Client[],
  returns: Return,
  daysWithRoutes: DaysWithRoutes
): OperatorsCardData[] {
  const array: OperatorsCardData[] = [];

  const adminUids = arrayHelper.reduceToSimple(user.users.admins, "id").concat([user.id]);

  attendClients = attendClients.filter(
    (client) =>
      !adminUids.includes(client.operatorId) &&
      client.lastReportResult != reportResultOptions.DIVERSE_LOCATION
  );

  array.push({
    name: "Geral",
    id: "geral",
    profileImageUrl: null,
    daysWithRoutes: daysWithRoutes && daysWithRoutes.total ? daysWithRoutes.total : 0,
    attendClients: attendClients,
    returns: {
      total: returns.completed.concat(returns.expired, returns.pending),
      completed: returns.completed,
      expired: returns.expired,
      pending: returns.pending,
    },
  });

  const arrayUsers = user.users.operators
    ? user.users.operators.filter((x) => x.disabled != true)
    : [];

  for (const user of arrayUsers) {
    const findAttendClients = attendClients.filter((x) => x.operatorId === user.id);
    const findRealizedReturns = returns.completed.filter((x) => x.operatorId === user.id);
    const findExpiredReturns = returns.expired.filter((x) => x.operatorId === user.id);
    const findPendingReturns = returns.pending.filter((x) => x.operatorId === user.id);

    array.push({
      name: user.name,
      id: user.id,
      profileImageUrl: user.profileImageUrl,
      daysWithRoutes: daysWithRoutes && daysWithRoutes[user.id] ? daysWithRoutes[user.id] : 0,
      attendClients: findAttendClients,
      returns: {
        total: findRealizedReturns.concat(findPendingReturns, findExpiredReturns),
        completed: findRealizedReturns,
        expired: findExpiredReturns,
        pending: findPendingReturns,
      },
    });
  }

  return array;
}

function getAttendOfMonth(attendClients: Client[]) {
  const dateNow = new Date();
  const currentMonth = dateNow.getMonth();

  const result = attendClients.filter(
    (x) =>
      x.lastReportDate &&
      new Date(x.lastReportDate.seconds * 1000).getMonth() === currentMonth &&
      new Date(x.lastReportDate.seconds * 1000).getFullYear() === dateNow.getFullYear()
  );

  return result;
}
