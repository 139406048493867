import DatabaseAttendClientRepository from "@domainRepositories/DatabaseAttendClientRepository";
import { UserStore } from "@domainStores/UserStore";
import UseCase from "@domainUseCases/UseCase";
import { Paging } from "@models/Paging";
import { userRoles } from "@models/User";

type Input = {
  paging: Paging;
};

type Output = void;

export class WatchTableAttendClientsUseCase implements UseCase<Input, Output> {
  constructor(
    private readonly userStore: UserStore,
    private readonly databaseAttendClientRepository: DatabaseAttendClientRepository
  ) {}
  execute({ paging }: Input) {
    const user = this.userStore.user;

    if (user.role === userRoles.root || user.role === userRoles.admin) {
      return this.databaseAttendClientRepository.watchAttendClientsAdmin(paging);
    }

    if (user.role === userRoles.manager) {
      return this.databaseAttendClientRepository.watchAttendClientsManager(
        paging,
        user.operatorsIds
      );
    }

    return;
  }
}
