import { LogRepository } from "@domainRepositories/LogRepository";
import UseCase from "@domainUseCases/UseCase";
import { Client } from "@models/Client";
import { Log } from "@models/Log";

type Input = Client["id"];

type Output = Promise<Log[]>;

export class GetClientLogsUseCase implements UseCase<Input, Output> {
  constructor(private readonly logRepository: LogRepository) {}

  async execute(clientId: Input) {
    return this.logRepository.getClientLogs(clientId);
  }
}
