import React, { useState, useEffect } from "react";

import {
  ModalLogsContent,
  LogsContainer,
  Container,
  LogItem,
  Circle,
  Line,
  Header,
  ClearSearch,
} from "../ModalLogs/styles";

import {
  CancelButton,
  SearchBar,
  SearchButton,
  SearchContainer,
} from "@components/DataTable/styles";

//Icons
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FiSearch } from "react-icons/fi";

//Custom components
import Loading from "@components/Loading/Loading";
import CustomNoRowsOverlay from "@components/NoRowsOverlay/NoRowsOverlay";

//MUI
import Menu from "@mui/material/Menu";
import { searchHelper } from "@helpers/search";
import { filtersHelper } from "@helpers/filters";
import LogsFilter from "@components/Filters/LogsFilter/LogsFilter";
import { User } from "@models/User";
import { GetUserLogsUseCase } from "@dataUseCases/index";
import { HttpLogRepository } from "@dataRepositories/Http/HttpLogRepository";
import { api } from "@infra/api";
import { Log } from "@models/Log";
import { logsHelper } from "@helpers/logs";

type Props = {
  userId: User["id"];
  userName: User["name"];
  onClose: () => void;
};

const ModalLogsUser = ({ userName, userId, onClose }: Props) => {
  const [userLogs, setUserLogs] = useState<Log[]>();

  //filters
  const [filters, setFilters] = useState([]);

  const [search, setSearch] = useState("");
  const [searchBar, setSearchBar] = useState(null);
  const openSearch = Boolean(searchBar);

  useEffect(() => {
    const useCase = new GetUserLogsUseCase(new HttpLogRepository(api));

    useCase.execute(userId).then((logs) => {
      setUserLogs(logs);
    });
  }, []);

  useEffect(() => {
    if (!search) {
      setSearch("");
    }
  }, [search]);

  const processLogs = (logDocs: Log[]) => {
    return logsHelper.mapLogs(logDocs);
  };

  if (!userLogs) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  if (userLogs?.length === 0) {
    return (
      <Container>
        <CustomNoRowsOverlay />
      </Container>
    );
  }

  const searchLogs = (backupTableData) => {
    const arrayLogs = [];

    for (const log of backupTableData) {
      if (searchHelper.searchText(log, search)) {
        arrayLogs.push(log);
      }
    }

    return arrayLogs;
  };

  const filterByTable = () => {
    const filteredDocs = filtersHelper.filterDataTable(userLogs, filters);

    return processLogs(filteredDocs);
  };

  const clearFilters = () => {
    setFilters([]);
  };

  const baseTableData = filters.length > 0 ? filterByTable() : processLogs(userLogs);

  const backupTableData = processLogs(userLogs);

  const tableData = search ? searchLogs(filterByTable()) : baseTableData;

  return (
    <Container>
      <Header>
        <div>
          <h3>{`Logs do usuário ${userName}`}</h3>
          <FiSearch
            onClick={(event) => {
              setSearchBar(event.currentTarget);
            }}
            size={20}
            cursor="pointer"
            color="var(--grey2)"
          />
          <ClearSearch>
            {search && (
              <>
                {search}
                <AiOutlineCloseCircle
                  cursor="pointer"
                  onClick={() => {
                    setSearch("");
                  }}
                />
              </>
            )}
          </ClearSearch>
        </div>
        <div>
          <LogsFilter
            defaultTitle=""
            filtersHighlights={[]}
            filters={filters}
            filterTypes={[
              { headerName: "Data", field: "time" },
              { headerName: "Ação", field: "log" },
            ]}
            customFilterOptions={[
              { label: "Entrou no sistema", value: "acessou" },
              { label: "Saiu do sistema", value: "saiu" },
              { label: "Visualizou as informações", value: "visualizou" },
              { label: "Atendimentos", value: "atendeu" },
              { label: "Erros", value: "erro" },
            ]}
            tableRowsBackup={backupTableData}
            onChangeFilters={(newFiltersArray) => setFilters(newFiltersArray)}
            onClearFilters={() => clearFilters()}
          />
          <AiOutlineCloseCircle
            style={{ marginLeft: 10 }}
            onClick={() => onClose()}
            strokeWidth={0.5}
            cursor="pointer"
            size={22}
          />
        </div>
      </Header>
      <hr />
      <ModalLogsContent>
        <LogsContainer>
          {tableData.map((item, index) => (
            <LogItem key={index}>
              <div>
                <Circle />
                <Line />
              </div>
              <h3>{item}</h3>
            </LogItem>
          ))}
        </LogsContainer>
      </ModalLogsContent>

      <Menu
        id="basic-menu"
        anchorEl={searchBar}
        open={openSearch}
        onClose={() => {
          setSearchBar(null);
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        PaperProps={{
          style: {
            width: 600,
            height: 40,
            maxHeight: 40,
            borderRadius: 20,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            overflow: "hidden",
          },
        }}
      >
        <SearchContainer
          onSubmit={(e) => {
            e.preventDefault();
            searchLogs(backupTableData);
            setSearchBar(null);
          }}
        >
          <FiSearch size={18} cursor="pointer" color="#626F87" strokeWidth="3" />
          <p>&#8288;</p>
          <SearchBar>
            <input
              name="search"
              onChange={(event) => setSearch(event.target.value)}
              value={search}
              placeholder="Buscar..."
              autoFocus={true}
            />
          </SearchBar>
          <CancelButton
            onClick={() => {
              setSearch("");
              setSearchBar(null);
            }}
          >
            Cancelar
          </CancelButton>
          <SearchButton
            onClick={() => {
              searchLogs(backupTableData);
              setSearchBar(null);
            }}
          >
            Buscar
          </SearchButton>
        </SearchContainer>
      </Menu>
    </Container>
  );
};

export default ModalLogsUser;
