import { LogRepository } from "@domainRepositories/LogRepository";
import UseCase from "@domainUseCases/UseCase";
import { Log } from "@models/Log";
import { Map } from "@models/Map";

type Input = Map["id"];

type Output = Promise<Log[]>;

export class GetMapLogsUseCase implements UseCase<Input, Output> {
  constructor(private readonly logRepository: LogRepository) {}

  async execute(mapId: Input) {
    return this.logRepository.getMapLogs(mapId);
  }
}
