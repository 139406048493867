import React, { useEffect, useState, useCallback } from "react";

import {
  Container,
  RouteBar,
  MapContainer,
  LogoContainer,
  RouteBarHeader,
  HeaderTitle,
  NewRouteButton,
  HeaderLeft,
  RouteBarContent,
  NewRouteContainer,
  FieldText,
  TextInput,
  TextSection,
  RoutePointsContainer,
  Divider,
  ActionsContent,
  CancelButton,
  SaveButton,
  CustomInfoWindow,
  AddRouteButton,
  InfoWindowButtonsContainer,
  InfoWindowTextContainer,
  MapDivRight,
  ReturnMapButton,
  SavedRoutesContainer,
  ReturnMapDiv,
  ReturnMapDivHeader,
  DividerReturn,
  ReturnsContainer,
  ReturnCard,
  DividerReturnItem,
  ReturnsContent,
  NoRoutesContainer,
  NoRouteButton,
  HelpButton,
  MarkersLegend,
  LegendItem,
  RouteBarButton,
  InfoWindowOpenDetailsButton,
  InfoWindowCloseButton,
  InfoWindowMobileButtonsContainer,
} from "./styles";

//Google Maps API
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  DirectionsRenderer,
  InfoWindow,
  StreetViewPanorama,
  GoogleMapProps,
} from "@react-google-maps/api";

//Utils
import Switch from "@mui/material/Switch";
import Modal from "react-modal";

//Icons
import { MdAddCircleOutline, MdOutlineAdd, MdOutlineRemove } from "react-icons/md";
import { BsArrowLeft } from "react-icons/bs";
import { BiHelpCircle } from "react-icons/bi";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { TbRouteSquare } from "react-icons/tb";

import { markerIcons } from "@resources/markerIcons";

//Images
import logo from "@assets/central.png";
import emptyRoutes from "@assets/emptyRoutes.svg";

//Custom Components
import OperatorRouteCard from "@components/OperatorRouteCard/OperatorRouteCard";
import Loading from "@components/Loading/Loading";
import ModalConfirm from "@components/Modal/ModalConfirm/ModalConfirm";
import ClientDetailsOperator from "./components/ClientDetailsOperator/ClientDetailsOperator";
import ModalDetailsOperator from "@components/Modal/ModalDetailsOperator/ModalDetailsOperator";
import RouteClientPoint from "@components/RouteClientPoint/RouteClientPoint";
import ProfileButton from "@components/ProfileButton/ProfileButton";
import PointIcon from "@components/Icons/PointIcon/PointIcon";
import ModalReport from "@components/Modal/ModalReport/ModalReport";
import ModalReturnsOperator from "@components/Modal/ModalReturnsOperator/ModalReturnsOperator";
import ModalHeader from "@components/Modal/ModalHeader/ModalHeader";

//utils
import { datetimeHelper } from "@helpers/datetime";
import { markersHelper } from "@helpers/markers";
import ModalNoMap from "@components/Modal/ModalNoMap/ModalNoMap";
import { arrayHelper } from "@helpers/array";
import ClipLoader from "react-spinners/ClipLoader";
import useRoutes from "@hooks/useRoutes";
import useMapClients from "@hooks/useMapClients";
import PageLoading from "@components/PageLoading/PageLoading";
import { useRef } from "react";
import { screenSizeValues } from "@resources/breakpoints";
import { BottomSheet } from "react-spring-bottom-sheet";

import "react-spring-bottom-sheet/dist/style.css";
import useUser from "@hooks/useUser";
import useReturns from "@hooks/useReturns";
import { MapClient } from "@models/MapClient";
import { Client, clientReturnStatus } from "@models/Client";
import { Route } from "@models/Route";
import { useReturnReports } from "@hooks/useReturnReports";
import { Report } from "@models/Report";
import { CancelRouteUseCase, CreateRouteUseCase, UpdateRouteUseCase } from "@dataUseCases/index";
import { HttpRouteRepository } from "@dataRepositories/index";
import { api } from "@infra/api";
import { RoutePoint } from "@models/RoutePoint";

//Variables
const letras = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

type MapState = {
  center: { lat: number; lng: number };
  zoom: number;
};

const OperatorMap = () => {
  const { user } = useUser();

  const { mapClients } = useMapClients();
  const { returns } = useReturns();
  const { operatorRoutes } = useRoutes();
  const { operatorReturnReports } = useReturnReports();

  const [loadingData, setLoadingData] = useState(true);

  //Modal
  const [modalData, setModalData] = useState({ text: "", option: "" });
  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalDetails, setModalDetails] = useState(false);
  const [modalReturns, setModalReturns] = useState(false);
  const [modalReport, setModalReport] = useState(false);
  const [modalNoMap, setModalNoMap] = useState(false);
  const [modalSave, setModalSave] = useState(false);
  const [modalSaveData, setModalSaveData] = useState({ text: "", option: "" });

  //Route creation
  const [routeForm, setRouteForm] = useState("");
  const [routeInEdit, setRouteInEdit] = useState<Route>();
  const [routeToDelete, setRouteToDelete] = useState<Route>();
  const [routeName, setRouteName] = useState("");
  const [points, setPoints] = useState<RoutePoint[]>([]);

  //Client
  const [clients, setClients] = useState<MapClient[]>();
  const [currentClient, setCurrentClient] = useState<MapClient | Client | RoutePoint>();

  //Map
  const streetViewRef = useRef<StreetViewPanorama>(null);
  const [showInfoWindow, setShowInfoWindow] = useState({ status: false, client: null });
  const [streetView, setStreetView] = useState(false);
  const [markers, setMarkers] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [onlyReturns, setOnlyReturns] = useState(false);
  const [returnExpanded, setReturnExpanded] = useState(false);
  const [mappedReturns, setMappedReturns] =
    useState<Array<Client & Pick<Report, "description" | "returnSchedule">>>();
  const [legend, setLegend] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const [routeBarIsOpen, setRouteBarIsOpen] = useState(false);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [mapState, setMapState] = useState<MapState>({
    center: { lat: -8.06036, lng: -34.921713 },
    zoom: 10,
  });
  const mapRef = useRef<GoogleMap>(null);

  // Atualiza a largura ao redimensionar
  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    // Remove o listener ao desmontar o componente
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  useEffect(() => {
    (async () => {
      setLoadingData(true);
      if (user.mapsIds.length == 0) {
        setLoadingData(false);
        setModalNoMap(true);
      } else {
        if (mapClients && returns && operatorReturnReports) {
          setOnlyReturns(false);

          const array: Array<Client & Pick<Report, "description" | "returnSchedule">> = [];

          const openReturns = returns.pending.concat(returns.premium).concat(returns.expired);

          for (const openReturn of openReturns) {
            const report = operatorReturnReports.find((x) => x.id === openReturn.lastReportId);

            array.push({
              ...openReturn,
              description: report.description,
              returnSchedule: report.returnSchedule,
            });
          }

          setMappedReturns(array);

          setClients(mapClients);

          await calculateMapCenter(mapClients);

          setMarkers(true);

          setLoadingData(false);
          setModalNoMap(false);
        }
      }
    })();
  }, [user, mapClients, returns]);

  const calculateMapCenter = async (clients: MapClient[]) => {
    const arrayLat = [];
    const arrayLng = [];
    const valueBase = 9.89715082;

    for (const client of clients) {
      if (client.cityMap && client.lat && client.lng) {
        arrayLat.push(client.lat);
        arrayLng.push(client.lng);
      }
    }

    if (arrayLat.length > 1 && arrayLng.length > 1) {
      const centerLat = (getMaxOfArray(arrayLat) + getMinOfArray(arrayLat)) / 2;
      const centerLng = (getMaxOfArray(arrayLng) + getMinOfArray(arrayLng)) / 2;

      const dif = getMaxOfArray(arrayLat) - getMinOfArray(arrayLat);

      setMapState({ center: { lat: centerLat, lng: centerLng }, zoom: (valueBase / dif) * dif });
    } else {
      const defaultCoords = { lat: -8.113334, lng: -35.05953 };

      setMapState({ center: defaultCoords, zoom: valueBase });
    }
  };

  function getMaxOfArray(numArray) {
    return Math.max.apply(null, numArray);
  }

  function getMinOfArray(numArray) {
    return Math.min.apply(null, numArray);
  }

  const onMapIdle = useCallback((map: GoogleMapProps) => {
    if (map) {
      const newCenter = map.center;
      const newZoom = map.zoom;

      setMapState({
        zoom: newZoom,
        center: {
          lat: typeof newCenter.lat === "number" ? newCenter.lat : newCenter.lat(),
          lng: typeof newCenter.lng === "number" ? newCenter.lng : newCenter.lng(),
        },
      });
    }
  }, []);

  const handleChangeOnlyReturns = (event) => {
    if (event.target.checked) {
      setMarkers(false);
      setClients([]);
      setClients(
        mapClients.filter(
          (x) =>
            x.returnStatus !== clientReturnStatus.COMPLETED &&
            x.returnStatus !== clientReturnStatus.NO_RETURN
        )
      );
      setMarkers(true);
      setOnlyReturns(event.target.checked);
    } else {
      setClients(mapClients);
      setOnlyReturns(event.target.checked);
    }
  };

  const getToEdit = async (route: Route) => {
    setRouteName(route.name);
    setPoints(route.points);
    setRouteForm("Editar");
    setMarkers(true);
  };

  const cancelRouteForm = (resetClients?: boolean) => {
    setDirectionsResponse(null);
    setPoints([]);
    setRouteName("");
    setRouteForm("");
    setRouteInEdit(null);
    setOnlyReturns(false);
    setModalConfirm(false);
    if (resetClients) {
      setClients(mapClients);
    }
    setSaveLoading(false);
  };

  const handleInputChange = (event) => {
    setRouteName(event.target.value);
  };

  const removeFromRoute = async (item: RoutePoint) => {
    const newPoints = points.filter((x) => x.id !== item.id);

    setPoints(newPoints);
    setDirectionsResponse(null);
    setViewDetails(false);
    setShowInfoWindow({ status: false, client: null });
  };

  const addOnRoute = (item: MapClient) => {
    setStreetView(false);
    setViewDetails(false);
    setDirectionsResponse(null);

    const routePoint = new RoutePoint(item);

    setPoints([...points, routePoint]);
  };

  async function traceRoute(points: RoutePoint[]) {
    const formatedOrigin = {
      lat: points[0].lat,
      lng: points[0].lng,
    };

    const formatedDestination = {
      lat: points.slice(-1)[0].lat,
      lng: points.slice(-1)[0].lng,
    };

    const waypoints = points.slice(1, -1);
    const formatedWaypoints = [];

    for (const p in waypoints) {
      formatedWaypoints.push({
        location: { lat: waypoints[p].lat, lng: waypoints[p].lng },
      });
    }

    const google = window.google;
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: formatedOrigin,
      destination: formatedDestination,
      waypoints: formatedWaypoints,
      travelMode: google.maps.TravelMode.DRIVING,
    });

    setDirectionsResponse(results);
  }

  const createRoute = async () => {
    const dateNow = new Date();

    const useCase = new CreateRouteUseCase(new HttpRouteRepository(api));

    useCase
      .execute({
        name: routeName
          ? routeName
          : `Rota ${dateNow.toLocaleDateString()} - ${dateNow.toLocaleTimeString()}`,
        points: points,
      })
      .then(() => cancelRouteForm(false))
      .finally(() => setSaveLoading(false));
  };

  const updateRoute = async () => {
    if (routeInEdit) {
      const useCase = new UpdateRouteUseCase(new HttpRouteRepository(api));

      useCase
        .execute({
          routeId: routeInEdit.id,
          data: {
            name: routeInEdit.name,
            points: points,
          },
        })
        .then(() => cancelRouteForm(false))
        .finally(() => setSaveLoading(false));
    }
  };

  const cancelRoute = async (item: Route) => {
    const useCase = new CancelRouteUseCase(new HttpRouteRepository(api));

    useCase
      .execute(item.id)
      .then(() => {
        setPoints([]);
        setDirectionsResponse(null);

        setModalConfirm(false);
        setModalData(null);
        setRouteToDelete(null);
      })
      .finally(() => setSaveLoading(false));
  };

  window.addEventListener("beforeunload", function (event) {
    event.preventDefault();

    event.returnValue = "Mensagem de aviso";
    return "Mensagem de aviso";
  });

  if (loadingData) {
    return <PageLoading />;
  }

  const renderRouteBarHeader = () => {
    return (
      <RouteBarHeader>
        <HeaderLeft>
          <LogoContainer>
            <img style={{ width: 60 }} src={logo} alt="Login Logo" />
          </LogoContainer>

          <HeaderTitle>
            {routeForm && (
              <BsArrowLeft
                style={{ cursor: "pointer" }}
                size={20}
                onClick={() => {
                  setModalData({
                    text: "Tem certeza que deseja cancelar a edição/criação da rota?",
                    option: "Sim",
                  });
                  setModalConfirm(true);
                }}
              />
            )}
            <p>{routeForm ? `${routeForm} rota` : "Rotas"}</p>
          </HeaderTitle>
        </HeaderLeft>
        {!routeForm && (
          <NewRouteButton
            onClick={() => {
              setDirectionsResponse(null);
              setPoints([]);
              setRouteForm("Nova");
            }}
          >
            <MdAddCircleOutline />
            <p>Nova rota</p>
          </NewRouteButton>
        )}
      </RouteBarHeader>
    );
  };

  const renderRouteBarContent = () => {
    return (
      <RouteBarContent>
        {routeForm ? (
          <NewRouteContainer>
            <FieldText>Nome</FieldText>
            <TextInput>
              <input
                name="routeName"
                value={routeName}
                onChange={(event) => handleInputChange(event)}
                placeholder="Nome da rota"
                onBlur={() => {
                  if (screenWidth <= screenSizeValues.md) {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }
                }}
              />
            </TextInput>

            <TextSection>Roteiro</TextSection>

            <RoutePointsContainer>
              {points.length != 0 ? (
                points.map((item, index) => (
                  <RouteClientPoint
                    index={index}
                    key={index}
                    point={item}
                    onRemove={() => removeFromRoute(item)}
                  />
                ))
              ) : (
                <p>Clique nos ícones presentes no mapa para adicionar os clientes</p>
              )}
            </RoutePointsContainer>
            <Divider />
            <ActionsContent>
              <CancelButton
                onClick={() => {
                  setModalData({
                    text: "Tem certeza que deseja cancelar a edição/criação da rota?",
                    option: "Sim",
                  });
                  setModalConfirm(true);
                }}
              >
                <p>Cancelar</p>
              </CancelButton>
              {saveLoading ? (
                <SaveButton>
                  <ClipLoader
                    size={20}
                    color={"var(--white)"}
                    loading={true}
                    speedMultiplier={1.5}
                  />
                </SaveButton>
              ) : (
                <SaveButton
                  disabled={points.length === 0}
                  onClick={async () => {
                    if (points.length > 0) {
                      setModalSaveData({
                        text: "Tem certeza que deseja SALVAR a rota?",
                        option: "Sim",
                      });
                      setModalSave(true);
                    }
                  }}
                >
                  <p>Salvar</p>
                </SaveButton>
              )}
            </ActionsContent>
          </NewRouteContainer>
        ) : (
          <SavedRoutesContainer>
            {operatorRoutes && operatorRoutes.length != 0 ? (
              operatorRoutes.map((item, index) => (
                <OperatorRouteCard
                  route={item}
                  key={index}
                  onEdit={() => {
                    getToEdit(item);
                    setRouteInEdit(item);
                  }}
                  onCancel={async () => {
                    setRouteToDelete(item);
                    setModalData({
                      text: `Tem certeza que deseja cancelar a rota ${item.name}`,
                      option: "Sim",
                    });
                    setModalConfirm(true);
                  }}
                  onTrace={async (expanded) => {
                    if (expanded && item.points.length != 0) {
                      await traceRoute(item.points);
                      setPoints(item.points);
                    } else {
                      setDirectionsResponse(null);
                      setPoints([]);
                    }
                  }}
                />
              ))
            ) : (
              <NoRoutesContainer>
                <img src={emptyRoutes} />
                <h3>Nenhuma rota cadastrada.</h3>
                <NoRouteButton
                  onClick={() => {
                    setDirectionsResponse(null);
                    setPoints([]);
                    setRouteForm("Nova");
                  }}
                >
                  <MdOutlineAdd />
                  <p>Criar rota</p>
                </NoRouteButton>
              </NoRoutesContainer>
            )}
          </SavedRoutesContainer>
        )}
      </RouteBarContent>
    );
  };

  return (
    <Container>
      <BottomSheet
        open={routeBarIsOpen}
        skipInitialTransition
        defaultSnap={({ maxHeight }) => maxHeight / 3}
        snapPoints={({ maxHeight }) => [maxHeight - maxHeight / 10, maxHeight / 3, maxHeight * 0.6]}
        blocking={false}
        scrollLocking={false}
        header={renderRouteBarHeader()}
        onDismiss={() => {
          setRouteBarIsOpen(false);
        }}
      >
        {renderRouteBarContent()}
      </BottomSheet>
      <RouteBar streetView={streetView} routeBarIsOpen={false}>
        {renderRouteBarHeader()}
        {renderRouteBarContent()}
      </RouteBar>
      {isLoaded && loadingData === false && mapState ? (
        <MapContainer>
          <GoogleMap
            ref={mapRef}
            mapContainerStyle={{ width: "100%", height: "100%" }}
            center={mapState.center}
            zoom={mapState.zoom}
            onIdle={(props?: GoogleMapProps) => onMapIdle(props)}
            clickableIcons={false}
            options={{
              disableDefaultUI: true,
              gestureHandling: "greedy",
            }}
            onClick={() => {
              if (screenWidth <= screenSizeValues.md) {
                setShowInfoWindow({ status: false, client: null });
                setCurrentClient(null);
              }
            }}
          >
            <RouteBarButton routeBarIsOpen={routeBarIsOpen} onClick={() => setRouteBarIsOpen(true)}>
              <TbRouteSquare size={32} />
            </RouteBarButton>
            {routeForm != "" && (
              <ReturnMapDiv>
                <ReturnMapDivHeader>
                  <Switch
                    checked={onlyReturns}
                    onChange={handleChangeOnlyReturns}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <p onClick={() => setReturnExpanded(!returnExpanded)}>Mostrar somente retornos</p>
                  {returnExpanded ? (
                    <IoIosArrowUp cursor="pointer" onClick={() => setReturnExpanded(false)} />
                  ) : (
                    <IoIosArrowDown cursor="pointer" onClick={() => setReturnExpanded(true)} />
                  )}
                </ReturnMapDivHeader>
                {returnExpanded && (
                  <ReturnsContainer>
                    <DividerReturn />

                    <ReturnsContent>
                      <h3>Próximos retornos</h3>
                      {mappedReturns &&
                        mappedReturns.map((item, index) => (
                          <div key={index}>
                            <ReturnCard
                              onClick={async () => {
                                setShowInfoWindow({ status: true, client: item.cpf });
                                setCurrentClient(item);
                                setViewDetails(true);
                              }}
                            >
                              <div className="header">
                                <h3>{item.name}</h3>
                                <p>
                                  {item.returnSchedule &&
                                    datetimeHelper.getDate(item.returnSchedule)}
                                </p>
                              </div>
                              <div className="description">{item.description}</div>
                            </ReturnCard>
                            {index != mappedReturns.length && <DividerReturnItem />}
                          </div>
                        ))}
                    </ReturnsContent>
                  </ReturnsContainer>
                )}
              </ReturnMapDiv>
            )}
            <MapDivRight>
              {routeForm === "" && (
                <ReturnMapButton onClick={() => setModalReturns(true)}>
                  <p>Retornos</p>
                  <PointIcon
                    text={mappedReturns ? String(mappedReturns.length) : "0"}
                    size={24}
                    fontSize={"12"}
                    fontWeight="300"
                  />
                </ReturnMapButton>
              )}
              <ProfileButton arrowColor="white" />
            </MapDivRight>
            {!legend && (
              <HelpButton onClick={() => setLegend(true)}>
                <BiHelpCircle size={40} cursor="pointer" />
              </HelpButton>
            )}
            {legend && (
              <MarkersLegend>
                <ModalHeader title="Legenda" onClose={() => setLegend(false)} />
                <div>
                  {markerIcons &&
                    markerIcons.map((item, index) => (
                      <LegendItem key={index}>
                        <img src={item.icon} alt="img img" />
                        <h1>{item.title}</h1>
                      </LegendItem>
                    ))}
                </div>
              </MarkersLegend>
            )}
            {markers === true && clients ? (
              clients.map((client) =>
                client.lat != null &&
                client.lng != null &&
                !arrayHelper.includeOnArrayObject(points, "cpf", client.cpf) ? (
                  <Marker
                    key={client.cpf}
                    position={{ lat: client.lat, lng: client.lng }}
                    onClick={async () => {
                      setShowInfoWindow({ status: true, client: client.cpf });
                      setCurrentClient(client);

                      if (screenWidth >= screenSizeValues.md) {
                        setViewDetails(true);
                      }
                    }}
                    options={{
                      icon: markersHelper.getMarkerIcons(client),
                      optimized: true,
                      animation: client.specialIcon ? window.google.maps.Animation.BOUNCE : null,
                    }}
                  >
                    {showInfoWindow.status === true && showInfoWindow.client === client.cpf && (
                      <InfoWindow
                        position={{ lat: currentClient.lat, lng: currentClient.lng }}
                        onCloseClick={() => {
                          setShowInfoWindow({ status: false, client: null });
                          setCurrentClient(null);
                        }}
                      >
                        <CustomInfoWindow>
                          <InfoWindowTextContainer>
                            <h4>{currentClient.name}</h4>
                          </InfoWindowTextContainer>
                          {routeForm != "" && (
                            <InfoWindowButtonsContainer>
                              <AddRouteButton onClick={() => addOnRoute(client)}>
                                <MdOutlineAdd />
                                <p>Adicionar à rota</p>
                              </AddRouteButton>
                            </InfoWindowButtonsContainer>
                          )}
                          <InfoWindowMobileButtonsContainer>
                            <InfoWindowCloseButton
                              onClick={() => {
                                setShowInfoWindow({ status: false, client: null });
                                setCurrentClient(null);
                              }}
                            >
                              <p>Fechar</p>
                            </InfoWindowCloseButton>
                            <InfoWindowOpenDetailsButton
                              onClick={async () => {
                                setViewDetails(true);
                              }}
                            >
                              <p>Ver</p>
                            </InfoWindowOpenDetailsButton>
                          </InfoWindowMobileButtonsContainer>
                        </CustomInfoWindow>
                      </InfoWindow>
                    )}
                  </Marker>
                ) : null
              )
            ) : (
              <></>
            )}
            {points.length != 0 ? (
              points.map((point, index) => (
                <Marker
                  key={point.cpf}
                  position={{ lat: point.lat, lng: point.lng }}
                  onClick={async () => {
                    setShowInfoWindow({ status: true, client: point.cpf });
                    setCurrentClient(point);

                    if (screenWidth >= screenSizeValues.md) {
                      setViewDetails(true);
                    }
                  }}
                  zIndex={1000}
                  options={
                    directionsResponse === null
                      ? {
                          label: {
                            text: letras[index],
                            color: "white",
                            fontWeight: "bold",
                          },
                        }
                      : {
                          label: {
                            text: letras[index],
                            color: "white",
                            fontWeight: "bold",
                          },
                        }
                  }
                >
                  {showInfoWindow.status === true && showInfoWindow.client === point.cpf && (
                    <InfoWindow
                      position={{ lat: currentClient.lat, lng: currentClient.lng }}
                      onCloseClick={() => {
                        setShowInfoWindow({ status: false, client: null });
                        setCurrentClient(null);
                      }}
                    >
                      <CustomInfoWindow>
                        <InfoWindowTextContainer>
                          <h4>{currentClient.name}</h4>
                          {/* <p>{address.formatEnd(currentClient)}</p> */}
                        </InfoWindowTextContainer>
                        {routeForm != "" && (
                          <InfoWindowButtonsContainer>
                            <AddRouteButton onClick={() => removeFromRoute(point)}>
                              <MdOutlineRemove />
                              <p>Remover da rota</p>
                            </AddRouteButton>
                          </InfoWindowButtonsContainer>
                        )}
                        <InfoWindowMobileButtonsContainer>
                          <InfoWindowCloseButton
                            onClick={() => {
                              setShowInfoWindow({ status: false, client: null });
                              setCurrentClient(null);
                            }}
                          >
                            <p>Fechar</p>
                          </InfoWindowCloseButton>
                          <InfoWindowOpenDetailsButton
                            onClick={async () => {
                              setViewDetails(true);
                            }}
                          >
                            <p>Ver</p>
                          </InfoWindowOpenDetailsButton>
                        </InfoWindowMobileButtonsContainer>
                      </CustomInfoWindow>
                    </InfoWindow>
                  )}
                </Marker>
              ))
            ) : (
              <></>
            )}
            <StreetViewPanorama
              ref={streetViewRef}
              options={{
                position: { lat: currentClient?.lat, lng: currentClient?.lng },
                visible: streetView,
                enableCloseButton: true,
              }}
              onVisibleChanged={() => {
                if (streetViewRef?.current?.state?.streetViewPanorama?.getVisible() === false) {
                  setStreetView(false);
                }
              }}
            />
            {directionsResponse && (
              <DirectionsRenderer
                options={{ suppressMarkers: true }}
                directions={directionsResponse}
              />
            )}
          </GoogleMap>
        </MapContainer>
      ) : (
        <Container>
          <Loading />
        </Container>
      )}
      {currentClient && viewDetails && (
        <ClientDetailsOperator
          clientId={currentClient?.id}
          points={points}
          onClose={() => setViewDetails(false)}
          streetView={streetView}
          onStreetView={async () => {
            // const logMessage = `${currentClient.nome} teve seu street view visualizado pelo operador ${userData.userName}`;
            // const logMessageUser = `${userData.userName} visualizou o street view do cliente ${currentClient.nome} `;

            // await addClientLogs(currentClient.cpf, logMessage);
            // await addUserLogs(userData.uid, logMessageUser);
            setStreetView(!streetView);
          }}
          onGenerateReport={() => {
            setModalReport(true);
          }}
          onViewFullData={() => {
            setModalDetails(true);
          }}
        />
      )}
      <Modal
        isOpen={modalReturns}
        onRequestClose={() => setModalReturns(false)}
        overlayClassName="modal-overlay"
        className="modal-content-auto-width"
        contentLabel="Example Modal"
      >
        <ModalReturnsOperator
          returns={mappedReturns}
          onClose={() => setModalReturns(false)}
          onClickView={(item) => {
            setCurrentClient(item);
            setModalDetails(true);
          }}
        />
      </Modal>
      <Modal
        isOpen={modalDetails}
        onRequestClose={() => setModalDetails(false)}
        overlayClassName="modal-overlay"
        className="modal-content-auto-width"
        contentLabel="Example Modal"
      >
        <ModalDetailsOperator
          clientId={currentClient?.id}
          onClose={() => setModalDetails(false)}
          onStreetView={() => {
            setModalDetails(false);
            setModalReturns(false);
            setStreetView(!streetView);

            setViewDetails(true);
            setShowInfoWindow({ status: true, client: currentClient.cpf });
          }}
        />
      </Modal>
      <Modal
        isOpen={modalReport}
        onRequestClose={() => setModalReport(false)}
        overlayClassName="modal-overlay"
        className="modal-content-auto"
        contentLabel="Example Modal"
        style={{
          overlay: {
            overflowY: "scroll",
          },
        }}
      >
        <ModalReport
          clientId={currentClient?.id}
          onClose={() => setModalReport(false)}
          onSave={() => {
            setModalReport(false);
            setStreetView(false);
            setShowInfoWindow({ status: true, client: null });
            setCurrentClient(null);
            setViewDetails(false);
          }}
          screenWidth={screenWidth}
        />
      </Modal>
      <Modal
        isOpen={modalConfirm}
        onRequestClose={() => setModalConfirm(false)}
        overlayClassName="modal-overlay"
        className="modal-content-alert"
        contentLabel="Example Modal"
      >
        <ModalConfirm
          text={modalData?.text}
          option={modalData?.option}
          onCancel={() => {
            setModalConfirm(false);
          }}
          onConfirm={async () => {
            cancelRouteForm(true);

            if (routeToDelete) {
              await cancelRoute(routeToDelete);
            }
          }}
        />
      </Modal>
      <Modal
        isOpen={modalSave}
        onRequestClose={() => setModalSave(false)}
        overlayClassName="modal-overlay"
        className="modal-content-alert"
        contentLabel="Example Modal"
      >
        <ModalConfirm
          text={modalSaveData?.text}
          option={modalSaveData?.option}
          onCancel={() => {
            setModalSave(false);
          }}
          onConfirm={async () => {
            setSaveLoading(true);
            setModalSave(false);
            setModalSaveData(null);
            await traceRoute(points);

            if (routeForm == "Nova") {
              await createRoute();
            }
            if (routeForm == "Editar") {
              await updateRoute();
            }
          }}
        />
      </Modal>
      <Modal
        isOpen={modalNoMap}
        onRequestClose={() => {}}
        overlayClassName="modal-overlay"
        className="modal-content-auto"
        contentLabel="Example Modal"
      >
        <ModalNoMap />
      </Modal>
    </Container>
  );
};

export default OperatorMap;
