import React, { useEffect, useState } from "react";

import {
  DetailsContainer,
  Header,
  ClientHeader,
  ClientNameContent,
  Container,
  ImageContainer,
  ImageStreetView,
  InfoHeader,
  PointDivider,
  FullData,
  GenerateReportButton,
  ImageEspecie,
  Content,
} from "./styles";

import { AiOutlineCloseCircle } from "react-icons/ai";
import { FiMapPin } from "react-icons/fi";
import { TbFileInvoice } from "react-icons/tb";
import Loading from "@components/Loading/Loading";
import axios from "axios";

//utils
import { markersHelper } from "@helpers/markers";
import { addressHelper } from "@helpers/address";
import ClientBadges from "@components/ClientBadges/ClientBadges";
import { Client } from "@models/Client";
import { RoutePoint } from "@models/RoutePoint";
import { GetClientUseCase } from "@dataUseCases/index";
import { HttpClientRepository } from "@dataRepositories/index";
import { api } from "@infra/api";

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

type Props = {
  clientId: string;
  points: RoutePoint[];
  onClose: () => void;
  streetView: boolean;
  onStreetView: () => void;
  onGenerateReport: () => void;
  onViewFullData: () => void;
};

const ClientDetailsOperator = ({
  clientId,
  points,
  onClose,
  streetView,
  onStreetView,
  onGenerateReport,
  onViewFullData,
}: Props) => {
  const [loadingData, setLoadingData] = useState(true);

  const [client, setClient] = useState<Client>();

  const [imageStreetView, setImageStreetView] = useState<Blob>();

  const [reportDisable, setReportDisable] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setLoadingData(true);

      if (points.length > 0) {
        const inRoute = points.find((x) => x.id === clientId);

        setReportDisable(inRoute ? true : false);
      }

      const useCase = new GetClientUseCase(new HttpClientRepository(api));

      useCase
        .execute(clientId)
        .then(async (clientData) => {
          if (clientData.lat && clientData.lng) {
            await axios
              .get(
                `https://maps.googleapis.com/maps/api/streetview?size=400x400&location=${
                  clientData.lat
                },${clientData.lng}&fov=80&heading=70&pitch=0&key=${apiKey}`,
                { responseType: "blob" }
              )
              .then((response) => {
                setImageStreetView(response.data);
              });
          }

          setClient(clientData);
          setLoadingData(false);
        })
        .catch(() => {
          onClose();
        });
    })();
  }, [clientId]);

  if (loadingData) {
    return (
      <Container>
        <Loading background="white" />
      </Container>
    );
  }

  return (
    <Container streetView={streetView}>
      <Header>
        <AiOutlineCloseCircle size={25} style={{ cursor: "pointer" }} onClick={onClose} />
      </Header>

      <Content>
        <ImageContainer>
          <ImageStreetView
            src={URL.createObjectURL(imageStreetView)}
            onClick={() => {
              onStreetView();
            }}
          />
        </ImageContainer>

        <ClientHeader>
          <ImageEspecie>
            <img
              src={markersHelper.getSpecieIcon(client.specie)}
              style={{ width: 34, height: 34, objectFit: "cover" }}
            />
          </ImageEspecie>
          <ClientNameContent>
            <h4>{client.name}</h4>
            <p>{client.specie}</p>
          </ClientNameContent>
        </ClientHeader>

        <PointDivider />

        <DetailsContainer>
          <InfoHeader>
            <FiMapPin size={22} color="var(--red)" />
            <p>Endereço</p>
          </InfoHeader>
          <p>{addressHelper.formatAddress(client)}</p>
        </DetailsContainer>

        <DetailsContainer>
          <InfoHeader>
            <TbFileInvoice size={26} color="var(--red)" style={{ strokeWidth: 1.8 }} />
            <p>Dados</p>
          </InfoHeader>
          <p>Idade: {client.age}</p>
          <ClientBadges client={client} isModalDetails={false} />
        </DetailsContainer>

        <FullData onClick={onViewFullData}>Ver dados completos</FullData>

        {!reportDisable && (
          <GenerateReportButton onClick={onGenerateReport}>Localidade diversa</GenerateReportButton>
        )}
      </Content>
    </Container>
  );
};

export default ClientDetailsOperator;
