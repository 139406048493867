import SearchClientRepository, {
  SearchClientResponse,
} from "@domainRepositories/SearchClientRepository";
import UseCase from "@domainUseCases/UseCase";
import { Filter } from "@models/Filters";

type Input = {
  query: string;
  filters: Filter[];
  page: number;
};

type Output = Promise<SearchClientResponse>;

export class SearchTableAttendClientsUseCase implements UseCase<Input, Output> {
  constructor(private readonly searchClientRepository: SearchClientRepository) {}

  async execute({ query, filters, page }: Input) {
    let textFilters = ``;
    const facetFilters = [];

    for (const filter of filters) {
      if (filter.type === "date") {
        if (filter.dateValue) {
          let stringFilter = textFilters.length > 0 ? " AND " : "";

          const selectedStart = filter.dateValue.selectedStart;
          const selectedEnd = filter.dateValue.selectedEnd;

          const startTime = new Date(
            Number(selectedStart.year),
            Number(selectedStart.month) - 1,
            Number(selectedStart.day),
            0,
            0,
            0
          ).getTime();

          const endTime = new Date(
            Number(selectedEnd.year),
            Number(selectedEnd.month) - 1,
            Number(selectedEnd.day),
            23,
            59,
            59
          ).getTime();

          stringFilter = stringFilter + `${filter.field}:${startTime} TO ${endTime}` + "";

          textFilters = textFilters + stringFilter;
        }
      } else {
        if (filter.values?.length > 0) {
          let stringFilter = textFilters.length > 0 ? " AND (" : "(";

          for (const index in filter.values) {
            if (parseInt(index) === 0) {
              stringFilter = stringFilter + `${filter.field}:"${filter.values[index].value}"`;
            } else {
              stringFilter =
                stringFilter + " OR " + `${filter.field}:"${filter.values[index].value}"`;
            }
          }
          stringFilter = stringFilter + ")";

          textFilters = textFilters + stringFilter;
        }
      }
    }

    return this.searchClientRepository.searchClients(query, textFilters, facetFilters, page);
  }
}
