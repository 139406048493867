import React from "react";

import { Container } from "./styles";

import DotLoader from "react-spinners/ClipLoader";

export type LoadingProps = {
  background?: string;
  size?: number;
};

const Loading = ({ background, size }: LoadingProps) => {
  return (
    <Container background={background}>
      <DotLoader
        size={size ? size : 60}
        color={"var(--red)"}
        loading={true}
        speedMultiplier={1.5}
      />
    </Container>
  );
};

export default Loading;
