import { Client, clientReturnStatus } from "@models/Client";
import { QuerySnapshot } from "firebase/firestore";

export const fireabseAdapter = {
  mapFirebaseDoc,
  mapReturnClients,
};

function mapFirebaseDoc<T>(querySnapshot: QuerySnapshot) {
  const data = querySnapshot.docs;

  return data.map((value) => {
    return { ...value.data(), id: value.id } as T;
  });
}

function mapReturnClients(returnClients: Client[]) {
  const completed = [];
  const expired = [];
  const premium = [];
  const pending = [];

  for (const client of returnClients) {
    switch (client.returnStatus) {
      case clientReturnStatus.COMPLETED:
        completed.push(client);
        break;
      case clientReturnStatus.EXPIRED:
        expired.push(client);
        break;
      case clientReturnStatus.PREMIUM:
        premium.push(client);
        break;
      case clientReturnStatus.PENDING:
        pending.push(client);
        break;
      default:
        break;
    }
  }

  return {
    completed: completed,
    expired: expired,
    premium: premium,
    pending: pending,
  };
}
