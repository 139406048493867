import ClientRepository from "@domainRepositories/ClientRepository";
import UseCase from "@domainUseCases/UseCase";
import { Client } from "@models/Client";

type Input = Client["id"];

type Output = void;

export class ToggleSpecialIconUseCase implements UseCase<Input, Output> {
  constructor(private readonly clientRepository: ClientRepository) {}

  execute(clientId: Input) {
    return this.clientRepository.toggleSpecialIcon(clientId);
  }
}
