import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 369px;

  padding: 20px 0;

  background: var(--white);
  border-radius: 20px;
`;

export const SavedRouteHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const SavedRouteName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-overflow: clip;

    color: var(--blue3);
  }
`;

export const SavedRouteDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  justify-content: center;
  gap: 15px;
`;

export const SavedRouteInfo = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
  gap: 10px;

  color: var(--red);
  fill: var(--red);

  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;

    color: var(--blue3);
  }
`;

type SavedPointsContainerProps = { inEdit: boolean };
export const SavedPointsContainer = styled.div<SavedPointsContainerProps>`
  display: flex;
  flex-direction: column;

  overflow-y: auto;
  max-height: ${(props) => (props.inEdit ? "55vh" : "45vh")};
  margin-top: 10px;
`;

export const ExpandedButton = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding-top: 20px;

  gap: 10px;
  color: var(--blue3);
  fill: var(--blue3);

  cursor: pointer;

  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;

    color: var(--blue3);
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;

  gap: 10px;

  color: var(--blue3);
  fill: var(--blue3);
`;
