import DatabaseMapClientRepository from "@domainRepositories/DatabaseMapClientRepository";
import { UserStore } from "@domainStores/UserStore";
import UseCase from "@domainUseCases/UseCase";
import { userRoles } from "@models/User";

type Input = void;

type Output = void;

export class WatchMapClientsUseCase implements UseCase<Input, Output> {
  constructor(
    private readonly userStore: UserStore,
    private readonly databaseMapClientRepository: DatabaseMapClientRepository
  ) {}
  execute() {
    const user = this.userStore.user;

    if (user.role === userRoles.root || user.role === userRoles.admin) {
      return this.databaseMapClientRepository.watchMapClientsAdmin();
    }

    if (user.role === userRoles.manager) {
      const mapsIds = user.mapsArray.map((x) => x.value);

      return this.databaseMapClientRepository.watchMapClientsManager(mapsIds);
    }

    if (user.role === userRoles.operator) {
      const mapsIds = user.mapsIds.map((x) => x.value);

      return this.databaseMapClientRepository.watchMapClientsOperator(user.id, mapsIds);
    }

    return;
  }
}
