import { LogRepository } from "@domainRepositories/LogRepository";
import { HttpClient } from "@infra/api/HttpClient";
import { Client } from "@models/Client";
import { Map } from "@models/Map";
import { Route } from "@models/Route";
import { User } from "@models/User";

export class HttpLogRepository implements LogRepository {
  private readonly baseUrl = `${process.env.REACT_APP_API_URL}/logs`;

  constructor(private readonly httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async getClientLogs(clientId: Client["id"]) {
    return this.httpClient
      .request({
        method: "GET",
        url: `${this.baseUrl}/clients/${clientId}`,
      })
      .then((response) => response.data);
  }

  async getMapLogs(mapId: Map["id"]) {
    return this.httpClient
      .request({
        method: "GET",
        url: `${this.baseUrl}/maps/${mapId}`,
      })
      .then((response) => response.data);
  }

  async getRouteLogs(routeId: Route["id"]) {
    return this.httpClient
      .request({
        method: "GET",
        url: `${this.baseUrl}/routes/${routeId}`,
      })
      .then((response) => response.data);
  }

  async getUserLogs(userId: User["id"]) {
    return this.httpClient
      .request({
        method: "GET",
        url: `${this.baseUrl}/users/${userId}`,
      })
      .then((response) => response.data);
  }
}
