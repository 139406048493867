import React, { useState } from "react";
import {
  ActionsContent,
  CancelButton,
  Divider,
  FieldText,
  Container,
  SaveButton,
  TextInput,
} from "./styles";

//Custom Components
import ModalHeader from "@components/Modal/ModalHeader/ModalHeader";
import ModalConfirm from "@components/Modal/ModalConfirm/ModalConfirm";

//Utils
import Modal from "react-modal";
import DotLoader from "react-spinners/ClipLoader";
import Select from "react-select";
import useUser from "@hooks/useUser";
import { reportResultSelectOptions } from "@constants/reportResultSelectOptions";
import { SelectOption } from "@models/SelectOption";
import { Report, ReportResult, reportResultOptions } from "@models/Report";
import { CreateReportUseCase, GetClientUseCase } from "@dataUseCases/index";
import { HttpClientRepository, HttpReportRepository } from "@dataRepositories/index";
import { api } from "@infra/api";
import { screenSizeValues } from "@resources/breakpoints";

type Props = {
  clientId: string;
  screenWidth?: number;
  onClose: () => void;
  onSave: () => void;
};

const ModalReport = ({ clientId, screenWidth = screenSizeValues.md, onClose, onSave }: Props) => {
  const { isAdmin, isManager, isOperator, user } = useUser();

  let selectOptions = reportResultSelectOptions;

  if (isAdmin) {
    const hiddenOptions: ReportResult[] = [
      reportResultOptions.RECOMMENDATION_SIGNED,
      reportResultOptions.PENDENCY,
      reportResultOptions.RETURN,
    ];

    selectOptions = reportResultSelectOptions.filter((x) => !hiddenOptions.includes(x.value));
  }

  if (isManager) {
    selectOptions = reportResultSelectOptions.filter((x) => ![].includes(x.value));
  }

  if (isOperator) {
    const enabledOptions: ReportResult[] = [reportResultOptions.DIVERSE_LOCATION];

    selectOptions = reportResultSelectOptions.filter((x) => enabledOptions.includes(x.value));
  }

  const [selectedStatus, setSelectedStatus] = useState<Report["result"]>("Contrato fechado");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalData, setModalData] = useState<{ text: string; option: string }>({
    text: "",
    option: "",
  });

  const [operatorsOptions, setOperatorsOptions] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState<SelectOption | null>();

  const getOperatorsOptions = () => {
    const array = [];

    if (user.users && user.users?.operators) {
      for (const operator of user.users.operators) {
        array.push({
          label: operator.name,
          value: operator.id,
        });
      }
    }

    setOperatorsOptions(array);
  };

  const handleSave = async () => {
    setLoading(true);

    const clientUseCase = new GetClientUseCase(new HttpClientRepository(api));

    const clientData = await clientUseCase.execute(clientId);

    const report: Partial<Report> = {
      operatorId: selectedOperator ? selectedOperator.value : user.id,
      operatorName: selectedOperator ? selectedOperator.label : user.name,
      clientId: clientData.id,
      clientCpf: clientData.cpf,
      clientName: clientData.name,
      clientSpecie: clientData.specie,
      clientAddress: clientData.addresses[clientData.validAddress],
      clientValidAddress: clientData.validAddress,

      result: selectedStatus,
      description: description,
      routeId: "off-route",
      returnSchedule: "",
    };

    const useCase = new CreateReportUseCase(new HttpReportRepository(api));

    useCase
      .execute(report)
      .then(() => {
        onSave();
        setModalConfirm(false);
      })
      .finally(() => setLoading(false));
  };

  const customStyle = {
    container: (base) => ({
      ...base,
      borderRadius: 40,
      color: "var(--blue4)",
    }),
    control: (base) => ({
      ...base,
      padding: 3,
      borderRadius: 40,
      color: "var(--blue4)",
    }),
    multiValue: (base) => ({
      ...base,
      borderRadius: 40,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "var(--blue4)",
    }),
  };

  const onChangeFilter = async (newValue) => {
    setSelectedStatus(newValue.value);
  };

  return (
    <Container>
      <ModalHeader title="Gerar atendimento" onClose={onClose} />
      <br />
      <FieldText>Resultado</FieldText>
      <Select
        key="result"
        isClearable={false}
        isSearchable={screenWidth >= screenSizeValues.md ? true : false}
        defaultValue={{ label: selectedStatus, value: selectedStatus }}
        closeMenuOnSelect={true}
        options={selectOptions}
        isMulti={false}
        styles={customStyle}
        autoFocus={screenWidth >= screenSizeValues.md ? true : false}
        openMenuOnFocus={true}
        onMenuClose={() => {
          if (screenWidth <= screenSizeValues.md) {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }
        }}
        onChange={(item) => onChangeFilter(item)}
      />
      <br />
      <FieldText>Descrição</FieldText>
      <TextInput>
        <input
          name="description"
          onChange={(event) => setDescription(event.target.value)}
          placeholder="Opcional"
          onBlur={() => {
            if (screenWidth <= screenSizeValues.md) {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }
          }}
        />
      </TextInput>

      {isAdmin && (
        <>
          <Divider />

          <FieldText>Operador</FieldText>
          <Select
            key="operator"
            isClearable={false}
            isSearchable={true}
            defaultValue={selectedOperator ? selectedOperator : null}
            closeMenuOnSelect={true}
            options={operatorsOptions}
            styles={customStyle}
            openMenuOnFocus={true}
            onMenuOpen={() => getOperatorsOptions()}
            onChange={(item) => setSelectedOperator(item)}
          />
        </>
      )}

      <Divider />

      {loading ? (
        <>
          <DotLoader size={20} color={"var(--red)"} loading={loading} speedMultiplier={1.5} />
        </>
      ) : (
        <ActionsContent>
          <CancelButton onClick={onClose}>
            <p>Cancelar</p>
          </CancelButton>
          <SaveButton
            onClick={() => {
              setModalData({
                text: "Tem certeza que deseja salvar o atendimento?",
                option: "Sim",
              });
              setModalConfirm(true);
            }}
          >
            <p>Salvar</p>
          </SaveButton>
        </ActionsContent>
      )}
      <Modal
        isOpen={modalConfirm}
        onRequestClose={() => setModalConfirm(false)}
        overlayClassName="modal-overlay"
        className="modal-content-alert"
        contentLabel="Example Modal"
      >
        <ModalConfirm
          text={modalData.text}
          option={modalData.option}
          loading={loading}
          onCancel={() => {
            setModalConfirm(false);
          }}
          onConfirm={handleSave}
        />
      </Modal>
    </Container>
  );
};

export default ModalReport;
