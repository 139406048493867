import React, { useRef, useState } from "react";

import {
  Container,
  FieldText,
  TextInput,
  ActionButtons,
  CancelButton,
  SaveButton,
  UserPhoto,
  UserPhotoContainer,
} from "./styles";

//Icons
import { AiOutlineUser } from "react-icons/ai";

//Custom components
import ModalHeader from "@components/Modal/ModalHeader/ModalHeader";
import Divider from "@components/Divider/Divider";

//MUI
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

//Utils
import Select from "react-select";
import Loading from "@components/Loading/Loading";
import { toast } from "react-toastify";
import { alertErrorStyle } from "@resources/alertErrorStyle";
import { User } from "@models/User";
import { userRolesOptions } from "@constants/userRolesOptions";
import { ImageFile } from "@models/ImageFile";
import { avatarHelper } from "@helpers/avatar";

type Props = {
  data: User;
  title: string;
  loading: boolean;
  onClose: () => void;
  onSave: (newUser: User, imageFile: ImageFile) => void;
};

function ModalFormUser({ data, title, loading, onClose, onSave }: Props) {
  const imageInput = useRef(null);

  const [newUser, setNewUser] = useState<User>(data);
  const [userImage, setUserImage] = useState<ImageFile>();

  const handleInputChange = (field, event) => {
    setNewUser({
      ...newUser,
      [field]: event.target.value,
    });
  };

  const blobToBase64 = (blob: Blob): Promise<string> => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);

    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
    });
  };

  const handleUserFunction = (selectedOption) => {
    setNewUser({
      ...newUser,
      role: selectedOption.value,
    });
  };

  const customStyle = {
    container: (base) => ({
      ...base,
      borderRadius: 40,
      marginBottom: 5,
    }),
    control: (base) => ({
      ...base,
      padding: 3,
      borderRadius: 40,
    }),
    multiValue: (base) => ({
      ...base,
      borderRadius: 40,
      backgroundColor: "#ECF2FB",
    }),
  };

  if (loading) {
    <Container>
      <Loading />
    </Container>;
  }

  return (
    <Container>
      <ModalHeader title={title} onClose={onClose} />

      <UserPhotoContainer>
        <UserPhoto>
          {userImage ? (
            <img
              src={avatarHelper.getAvatarByBase64(userImage.image64)}
              style={{ height: "100%", width: "100%", borderRadius: 100 }}
              alt="img img"
            />
          ) : newUser && newUser.profileImageUrl ? (
            <img
              src={newUser.profileImageUrl}
              style={{ height: "100%", width: "100%", borderRadius: 100 }}
              alt="img img"
            />
          ) : (
            <AiOutlineUser size={20} />
          )}
        </UserPhoto>
        <input
          type="file"
          accept=".png, .jpg, .jpeg"
          id="imageFile"
          ref={imageInput}
          style={{ display: "none" }}
          onChange={async (e) => {
            e.preventDefault();

            const file = e.target.files[0];
            if (!file) return;

            try {
              const base64 = await blobToBase64(file);

              // Extraindo o tipo da imagem
              const mimeType = base64.match(/^data:(.*);base64,/)[1];
              const image64 = base64.split(",")[1];
              const size = file.size;

              setUserImage({
                image64,
                mimetype: mimeType,
                size,
              });
            } catch (error) {
              toast(`Erro ao inserir imagem: ${error}`, alertErrorStyle);
            }
          }}
        />
        <h4 onClick={() => imageInput.current.click()}>Alterar imagem</h4>
      </UserPhotoContainer>

      <FieldText>Nome</FieldText>
      <TextInput>
        <input
          name="name"
          onChange={(event) => handleInputChange("name", event)}
          placeholder="Nome do usuário"
          defaultValue={newUser && newUser.name}
        />
      </TextInput>

      <FieldText>Tipo de usuário:</FieldText>
      <Select
        id="select-function"
        options={userRolesOptions}
        value={userRolesOptions.find((x) => x.value == newUser?.role)}
        styles={customStyle}
        onChange={(selectedOption) => {
          handleUserFunction(selectedOption);
        }}
      />
      <FieldText>Status</FieldText>
      <FormGroup>
        <FormControlLabel
          control={<Switch checked={!newUser?.disabled} />}
          onChange={() => setNewUser({ ...newUser, disabled: !newUser?.disabled })}
          label={!newUser?.disabled ? "Ativo" : "Inativo"}
          style={{
            color: "var(--blue4)",
          }}
        />
      </FormGroup>

      <FieldText>Login</FieldText>
      <TextInput>
        <input
          name="login"
          onChange={(event) => handleInputChange("login", event)}
          placeholder="Login do usuário"
          defaultValue={newUser && newUser.login}
        />
      </TextInput>

      <FieldText>Email</FieldText>
      <TextInput>
        <input
          name="email"
          onChange={(event) => handleInputChange("email", event)}
          placeholder="E-mail do usuário"
          defaultValue={newUser && newUser.email}
          disabled={data ? true : false}
        />
      </TextInput>

      <FieldText>Senha</FieldText>
      <TextInput>
        <input
          name="password"
          onChange={(event) => handleInputChange("password", event)}
          placeholder="Nova senha do usuário"
          defaultValue={newUser && newUser.password}
        />
      </TextInput>

      <br />
      <Divider width="100%" />
      <ActionButtons>
        {loading ? (
          <Loading />
        ) : (
          <>
            <CancelButton
              onClick={() => {
                onClose();
              }}
            >
              <p>Cancelar</p>
            </CancelButton>
            <SaveButton
              onClick={async () => {
                await onSave(newUser, userImage);
              }}
            >
              <p>Salvar</p>
            </SaveButton>
          </>
        )}
      </ActionButtons>
    </Container>
  );
}

export default ModalFormUser;
