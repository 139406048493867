import DatabaseRouteRepository from "@domainRepositories/DatabaseRouteRepository";
import { UserStore } from "@domainStores/UserStore";
import UseCase from "@domainUseCases/UseCase";
import { userRoles } from "@models/User";

type Input = void;

type Output = void;

export class WatchRoutesUseCase implements UseCase<Input, Output> {
  constructor(
    private readonly userStore: UserStore,
    private readonly databaseRouteRepository: DatabaseRouteRepository
  ) {}

  execute() {
    const user = this.userStore.user;

    if (user.role === userRoles.root || user.role === userRoles.admin) {
      return this.databaseRouteRepository.watchRoutesAdmin();
    }

    if (user.role === userRoles.manager) {
      return this.databaseRouteRepository.watchRoutesManager(user.operatorsIds);
    }

    if (user.role === userRoles.operator) {
      return this.databaseRouteRepository.watchRoutesOperator(user.id);
    }

    return;
  }
}
