import React, { useState, useMemo, useEffect } from "react";

import {
  Container,
  RouteBar,
  MapContainer,
  Title,
  FieldText,
  TextInput,
  ActionButtons,
  CancelButton,
  SaveButton,
  LogsButton,
  CloseButton,
} from "./styles";

//Google Maps API
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  DirectionsRenderer,
  StreetViewPanorama,
} from "@react-google-maps/api";

//Icons
import greenMarker from "@assets/greenMarker.png";
import Loading from "@components/Loading/Loading";
import Divider from "@components/Divider/Divider";
import clockIcon from "@assets/clockIcon2.svg";
import { AiOutlineCloseCircle } from "react-icons/ai";

//Custom components
import ModalClientDetails from "@components/Modal/ModalClientDetails/ModalClientDetails";
import ModalDetailsAdmin from "@components/Modal/ModalDetailsAdmin/ModalDetailsAdmin";

//Utils
import Modal from "react-modal";
import ModalConfirm from "@components/Modal/ModalConfirm/ModalConfirm";
import ModalLogsRoutes from "@components/Modal/ModalLogs/ModalLogsRoute";
import { Route } from "@models/Route";
import useUser from "@hooks/useUser";
import RouteCard from "@components/RouteCard/RouteCard";
import { CancelRouteUseCase, UpdateRouteUseCase } from "@dataUseCases/index";
import { HttpRouteRepository } from "@dataRepositories/index";
import { api } from "@infra/api";
import { RoutePoint } from "@models/RoutePoint";

const letras = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

type Props = {
  routeData: Route;
  onClose: () => void;
};

function ModalViewRoute({ routeData, onClose }: Props) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const { isAdmin } = useUser();

  //Modal
  const [modalFullData, setModalFullData] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalData, setModalData] = useState<{ text: string; option: string }>({
    text: "",
    option: "",
  });
  const [modalLogs, setModalLogs] = useState(false);

  //Route edit
  const [edit, setEdit] = useState(false);
  const [newData, setNewData] = useState<Route>(routeData);

  //Map
  const [loadingData, setLoadingData] = useState(true);
  const [mapRef, setMapRef] = useState(null);
  const [lastCenter, setLastCenter] = useState({ lat: -8.113334, lng: -35.05953 });
  const center = useMemo(() => ({ lat: -8.113334, lng: -35.05953 }), []);

  //Marker
  const [currentClient, setCurrentClient] = useState<RoutePoint>();
  const [currentClientIndex, setCurrentClientIndex] = useState(0);
  const [streetView, setStreetView] = useState(false);
  const [directionsResponse, setDirectionsResponse] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      setLoadingData(false);
      traceRoute(routeData.points);
    }, 300);
  }, [routeData]);

  const traceRoute = async (points: RoutePoint[]) => {
    if (points.length > 0) {
      const formatedOrigin = {
        lat: points[0].lat,
        lng: points[0].lng,
      };

      const formatedDestination = {
        lat: points.slice(-1)[0].lat,
        lng: points.slice(-1)[0].lng,
      };

      const waypoints = points.slice(1, -1);
      const formatedWaypoints = [];

      for (const p in waypoints) {
        formatedWaypoints.push({
          location: { lat: waypoints[p].lat, lng: waypoints[p].lng },
        });
      }

      const google = window.google;
      const directionsService = new google.maps.DirectionsService();
      const results = await directionsService.route({
        origin: formatedOrigin,
        destination: formatedDestination,
        waypoints: formatedWaypoints,
        travelMode: google.maps.TravelMode.DRIVING,
      });

      return setDirectionsResponse(results);
    }
  };

  const handleInputRouteName = (event) => {
    setNewData({
      ...newData,
      name: event.target.value,
    });
  };

  const removePoint = (clientId: RoutePoint["id"]) => {
    const newPoints = newData.points.filter((x) => x.id !== clientId);

    setNewData({
      ...newData,
      points: newPoints,
    });
  };

  const saveRoute = () => {
    const useCase = new UpdateRouteUseCase(new HttpRouteRepository(api));

    useCase
      .execute({
        routeId: routeData.id,
        data: { name: newData.name, points: newData.points },
      })
      .then((route) => {
        setEdit(false);
        setNewData(route);
        traceRoute(route.points);
      });
  };

  const cancelRoute = async () => {
    const useCase = new CancelRouteUseCase(new HttpRouteRepository(api));

    useCase.execute(routeData.id).then((route) => {
      setNewData(route);
      setModalConfirm(false);
      onClose();
    });
  };

  if (loadingData) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  return (
    <Container>
      <RouteBar>
        {!edit ? (
          <>
            <Title>Detalhe da rota</Title>
            <RouteCard
              route={newData}
              onCancel={() => {
                setModalData({
                  text: `Tem certeza que deseja cancelar a rota ${routeData.name}?`,
                  option: "Sim",
                });
                setModalConfirm(true);
              }}
              onEdit={() => {
                setEdit(true);
              }}
            />
            <Divider width="100%" />
            {isAdmin && (
              <LogsButton onClick={() => setModalLogs(true)}>
                <img src={clockIcon} style={{ height: 20, width: 20 }} alt="icon icon" />
                Ver log da rota
              </LogsButton>
            )}
          </>
        ) : (
          <>
            <Title>Editar rota</Title>
            <FieldText>Nome da rota</FieldText>
            <TextInput>
              <input
                name="routeName"
                onChange={(event) => handleInputRouteName(event)}
                placeholder="Nome da rota"
                defaultValue={newData.name}
              />
            </TextInput>
            <RouteCard
              route={newData}
              onCancel={() => {
                setModalData({
                  text: `Tem certeza que deseja cancelar a rota ${routeData.name}?`,
                  option: "Sim",
                });
                setModalConfirm(true);
              }}
              onEdit={() => {
                setEdit(true);
              }}
              inEdit={edit}
              onRemove={removePoint}
            />
            <Divider width="100%" />
            <ActionButtons>
              <CancelButton
                onClick={() => {
                  setNewData(routeData);
                  setEdit(false);
                }}
              >
                <p>Cancelar</p>
              </CancelButton>
              <SaveButton onClick={saveRoute}>
                <p>Salvar</p>
              </SaveButton>
            </ActionButtons>
          </>
        )}
      </RouteBar>
      {isLoaded && loadingData === false && center && (
        <MapContainer>
          <GoogleMap
            onLoad={(map) => {
              setMapRef(map);
            }}
            mapContainerStyle={{
              width: "100%",
              height: "100%",
              borderTopRightRadius: 40,
              borderBottomRightRadius: 40,
            }}
            center={lastCenter ? lastCenter : center}
            zoom={11}
            clickableIcons={false}
            options={{
              disableDefaultUI: true,
            }}
          >
            <CloseButton onClick={onClose}>
              <AiOutlineCloseCircle size={30} />
            </CloseButton>

            {currentClient && (
              <ModalClientDetails
                index={currentClientIndex}
                data={currentClient}
                onClose={() => {
                  setCurrentClient(null);
                  setCurrentClientIndex(0);
                }}
                onStreetView={() => {
                  if (streetView == true) {
                    setStreetView(false);
                    setTimeout(async () => {
                      setStreetView(true);
                    }, 500);
                  } else {
                    setStreetView(!streetView);
                  }
                }}
                onViewFullData={() => {
                  setModalFullData(true);
                }}
              />
            )}

            {newData && newData.points.length > 0 ? (
              newData.points.map((cliente, index) =>
                cliente.lat != null && cliente.lng != null ? (
                  <Marker
                    key={cliente.cpf}
                    position={{ lat: cliente.lat, lng: cliente.lng }}
                    onClick={async () => {
                      setLastCenter(mapRef.center);
                      setCurrentClient(cliente);
                      setCurrentClientIndex(index);
                    }}
                    zIndex={5000}
                    options={{
                      label: {
                        text: letras[index],
                        color: cliente.visited ? "transparent" : "white",
                        fontWeight: "bold",
                      },
                      optimized: true,
                      icon: cliente.visited ? greenMarker : null,
                    }}
                  />
                ) : null
              )
            ) : (
              <></>
            )}
            {streetView === true && currentClient && (
              <StreetViewPanorama
                options={{
                  position: { lat: currentClient.lat, lng: currentClient.lng },
                  visible: streetView,
                }}
                onCloseclick={() => alert("fechou")}
              />
            )}
            {directionsResponse && (
              <DirectionsRenderer
                options={{ suppressMarkers: true }}
                directions={directionsResponse}
              />
            )}
          </GoogleMap>
        </MapContainer>
      )}
      <Modal
        isOpen={modalFullData}
        onRequestClose={() => setModalFullData(false)}
        overlayClassName="modal-overlay"
        className="modal-content-auto-width"
        contentLabel="Example Modal"
      >
        <ModalDetailsAdmin
          data={currentClient}
          onClose={() => setModalFullData(false)}
          //   onStreetView={() => {
          //     setModalFullData(false);
          //     if (streetView == true) {
          //       setStreetView(false);
          //       setTimeout(async () => {
          //         setStreetView(true);
          //       }, 500);
          //     } else {
          //       setStreetView(!streetView);
          //     }
          //   }}
        />
      </Modal>

      <Modal
        isOpen={modalConfirm}
        onRequestClose={() => setModalConfirm(false)}
        overlayClassName="modal-overlay"
        className="modal-content-alert"
        contentLabel="Example Modal"
      >
        <ModalConfirm
          text={modalData.text}
          option={modalData.option}
          onCancel={() => {
            setModalConfirm(false);
          }}
          onConfirm={cancelRoute}
        />
      </Modal>

      <Modal
        isOpen={modalLogs}
        onRequestClose={() => setModalLogs(false)}
        overlayClassName="modal-overlay"
        className="modal-content-auto-width"
        contentLabel="Example Modal"
      >
        <ModalLogsRoutes
          routeId={routeData.id}
          routeName={routeData.name}
          onClose={() => setModalLogs(false)}
        />
      </Modal>
    </Container>
  );
}

export default ModalViewRoute;
