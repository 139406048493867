import React, { useState } from "react";

//Styles
import {
  ActionsContainer,
  Container,
  ExpandedButton,
  MapDetails,
  MapHeader,
  MapName,
  CitiesContainer,
  CityItem,
} from "./styles";

//Custom components
import ModalLogsMap from "@components/Modal/ModalLogs/ModalLogsMap";

//Icons
import { TbPencil } from "react-icons/tb";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { FiTrash2 } from "react-icons/fi";
import ElipseIcon from "@components/Icons/ElipseIcon/ElipseIcon";
import logIconGrey from "@assets/logIconGrey.svg";

//Utils
import Modal from "react-modal";
import { markerIcons } from "@resources/markerIcons";
import useUser from "@hooks/useUser";
import { MapObject } from "@pages/Map/MapPage";

const exceptionIcons = ["BIRTHDAY", "RETORNO", "RETORNO PREMIUM"];

type Props = {
  map: MapObject;
  selected: boolean;
  onSelect: () => void;
  onEdit: () => void;
  onDelete: () => void;
};

const MapCard = ({ map, onEdit, onSelect, onDelete, selected }: Props) => {
  const { isAdmin } = useUser();

  const [expanded, setExpanded] = useState(false);

  const [modalLogs, setModalLogs] = useState(false);

  const calculateSpecie = (specie) => {
    const result = map.clients.filter((x) => x.specie === specie);

    return result.length;
  };

  return (
    <Container selected={selected} onClick={onSelect}>
      <MapHeader>
        <MapName>
          <p>{map.name}</p>
          <ElipseIcon
            text={String(map.clients.length)}
            size={17}
            background="var(--blue3)"
            fontWeight="400"
            fontSize={12}
          />
        </MapName>
        {map.name != "Geral" && isAdmin && (
          <ActionsContainer>
            <img
              src={logIconGrey}
              alt="img img"
              style={{ cursor: "pointer" }}
              onClick={() => setModalLogs(true)}
            />
            <TbPencil onClick={onEdit} size={22} style={{ strokeWidth: 1.5, cursor: "pointer" }} />
            <FiTrash2
              onClick={onDelete}
              color="var(--red)"
              size={20}
              style={{ strokeWidth: 1.5, cursor: "pointer" }}
            />
          </ActionsContainer>
        )}
      </MapHeader>
      <MapDetails>
        {markerIcons &&
          markerIcons.map((item, index) => {
            const findException = exceptionIcons.find((x) => x === item.specie);
            const value = calculateSpecie(item.specie);

            if (!findException && value > 0) {
              return (
                <div key={index}>
                  <img src={item.icon} alt="img img" style={{ height: 14, width: 14 }} />
                  <h4>{value}</h4>
                </div>
              );
            }
          })}
      </MapDetails>
      {expanded && (
        <>
          <CitiesContainer>
            {map.cities.length != 0 &&
              map.cities.map((item, index) => <CityItem key={index}>{item.label}</CityItem>)}
          </CitiesContainer>
        </>
      )}
      <ExpandedButton
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        <p>{expanded ? "Recolher" : "Expandir"}</p>
        {expanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
      </ExpandedButton>

      <Modal
        isOpen={modalLogs}
        onRequestClose={() => {
          setModalLogs(false);
        }}
        overlayClassName="modal-overlay"
        className="modal-content-auto-width"
        contentLabel="Example Modal"
      >
        <ModalLogsMap
          mapId={map.id}
          mapName={map.name}
          onClose={() => {
            setModalLogs(false);
          }}
        />
      </Modal>
    </Container>
  );
};

export default MapCard;
