import UserRepository from "@domainRepositories/UserRepository";
import UseCase from "@domainUseCases/UseCase";
import { User } from "@models/User";

type Input = User["id"];

type Output = Promise<void>;

export class DeleteUserUseCase implements UseCase<Input, Output> {
  constructor(private readonly userRepository: UserRepository) {}

  execute(userId: Input) {
    return this.userRepository.delete(userId);
  }
}
