import { FirebaseReportRepository } from "@dataRepositories/index";
import { WatchReturnReports } from "@dataUseCases/index";
import reportStore from "@stores/report";
import userStore from "@stores/user";
import { useEffect } from "react";
import { useStore } from "zustand";

export function useReturnReports() {
  const { operatorReturnReports } = useStore(reportStore);

  useEffect(() => {
    if (operatorReturnReports.length === 0) {
      getReturns();
    }
  }, []);

  const getReturns = () => {
    const useCase = new WatchReturnReports(
      userStore.getState(),
      new FirebaseReportRepository(reportStore.getState())
    );

    useCase.execute();
  };

  return {
    operatorReturnReports,
  };
}
