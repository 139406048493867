import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const RoutePointItemLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 2px;
  width: 12%;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  height: 100%;

  width: 8%;

  color: #626f87;
  fill: #626f87;
`;

export const RoutePointItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  gap: 5px;
  width: 80%;
  height: 100%;

  h4 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    /* 1 */

    color: var(--blue3);
  }

  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */
    max-lines: 2;
    text-overflow: clip;
    /* 1 */

    color: var(--blue3);
  }
`;

export const PointDot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6px;
  width: 0.9px;

  border-radius: 2px;
  background: var(--red);

  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 9px;

    /* 5 */

    color: var(--white);
  }
`;
