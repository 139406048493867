import React, { useState } from "react";

import { CardsContainer } from "./styles";

import { Card } from "@components/Card/Card";

import checkedIcon from "@assets/checkedIcon.svg";
import clockIcon from "@assets/clockIcon.svg";
import inRouteIcon from "@assets/inRouteIcon.svg";
import expiredIcon from "@assets/expiredIcon.svg";
import contractIcon from "@assets/contractIcon.svg";

import Modal from "react-modal";

//Utils
import tableColumnsReturns from "@resources/tableColumnsReturns.json";
import ModalReturnCard from "@components/Modal/ModalReturnCard/ModalReturnCard";
import { CardsData } from "@pages/Returns/ReturnsPage";
import { Client } from "@models/Client";

export type ReturnModalData = {
  array: Client[];
  title: string;
  type: "completed" | "pending" | "inRoute" | "expired" | "signed";
  columns: number;
  filterTypes: { headerName: string; field: string }[];
  tableColumns: typeof tableColumnsReturns;
  visible: boolean;
};

const defaultModalData: ReturnModalData = {
  array: [],
  title: "",
  type: null,
  columns: 5,
  filterTypes: null,
  tableColumns: tableColumnsReturns,
  visible: false,
};

type Props = {
  cardsData: CardsData;
};

function ReturnsCards({ cardsData }: Props) {
  const [modalData, setModalData] = useState(defaultModalData);

  const closeModal = () => {
    setModalData(defaultModalData);
  };

  return (
    <>
      <CardsContainer>
        <Card.Root
          loading={!cardsData}
          onClick={() => {
            setModalData({
              array: cardsData.completedReturns,
              title: "Retornos realizados",
              type: "completed",
              columns: 6,
              filterTypes: [
                { headerName: "Data", field: "lastReportDate" },
                { headerName: "Operador", field: "returnOperatorName" },
                { headerName: "Resultado", field: "lastReportResult" },
              ],
              tableColumns: tableColumnsReturns,
              visible: true,
            });
          }}
        >
          <Card.Title color="var(--success)" title="Retornos realizados" imgSrc={checkedIcon} />
          <Card.Divider />
          <Card.Content
            color="var(--success)"
            value={cardsData.completedReturns?.length}
            description=""
          />
        </Card.Root>

        <Card.Root
          loading={!cardsData}
          onClick={() => {
            setModalData({
              array: cardsData.pendingReturns,
              title: "Retornos a realizar",
              type: "pending",
              columns: 5,
              filterTypes: [{ headerName: "Operador", field: "returnOperatorName" }],
              tableColumns: tableColumnsReturns,
              visible: true,
            });
          }}
        >
          <Card.Title color="var(--yellow)" title="Retornos a realizar" imgSrc={clockIcon} />
          <Card.Divider />
          <Card.Content
            color="var(--yellow)"
            value={cardsData.pendingReturns?.length}
            description=""
          />
        </Card.Root>

        <Card.Root
          loading={!cardsData}
          onClick={() => {
            setModalData({
              array: cardsData.inRouteReturns,
              title: "Retornos em rota",
              type: "inRoute",
              columns: 6,
              filterTypes: [
                { headerName: "Status", field: "routeStatus" },
                { headerName: "Operador", field: "returnOperatorName" },
              ],
              tableColumns: tableColumnsReturns,
              visible: true,
            });
          }}
        >
          <Card.Title color="var(--blue)" title="Retornos em rota" imgSrc={inRouteIcon} />
          <Card.Divider />
          <Card.Content
            color="var(--blue)"
            value={cardsData.inRouteReturns?.length}
            description=""
          />
        </Card.Root>

        <Card.Root
          loading={!cardsData}
          onClick={() => {
            setModalData({
              array: cardsData.expiredReturns,
              title: "Retornos expirados",
              type: "expired",
              columns: 5,
              filterTypes: [{ headerName: "Operador", field: "returnOperatorName" }],
              tableColumns: tableColumnsReturns,
              visible: true,
            });
          }}
        >
          <Card.Title color="var(--red)" title="Retornos expirados" imgSrc={expiredIcon} />
          <Card.Divider />
          <Card.Content
            color="var(--red)"
            value={cardsData.expiredReturns?.length}
            description=""
          />
        </Card.Root>

        <Card.Root
          loading={!cardsData}
          onClick={() => {
            setModalData({
              array: cardsData.signedAfterReturn,
              title: "Contratos fechados após retorno",
              type: "signed",
              columns: 5,
              filterTypes: null,
              tableColumns: tableColumnsReturns,
              visible: true,
            });
          }}
        >
          <Card.Title
            color="var(--success)"
            title="Contratos fechados após retorno"
            imgSrc={contractIcon}
          />
          <Card.Divider />
          <Card.Content
            color="var(--success)"
            value={cardsData.signedAfterReturn?.length}
            description=""
          />
        </Card.Root>
      </CardsContainer>

      <Modal
        isOpen={modalData.visible}
        onRequestClose={() => closeModal()}
        overlayClassName="modal-overlay"
        className="modal-content-auto-width"
        contentLabel="Example Modal"
      >
        <ModalReturnCard data={modalData} onClose={() => closeModal()} />
      </Modal>
    </>
  );
}

export default ReturnsCards;
