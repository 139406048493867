import styled from "styled-components";
import { PageLoadingProps } from "./PageLoading";

export const Container = styled.div<PageLoadingProps>`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;

  align-items: center;
  justify-content: center;
  align-self: center;
  background-color: ${(props: PageLoadingProps) =>
    props.background ? props.background : "transparent"};
`;
