import { DatabaseMapRepository } from "@domainRepositories/DatabaseMapRepository";
import { UserStore } from "@domainStores/UserStore";
import UseCase from "@domainUseCases/UseCase";
import { userRoles } from "@models/User";

type Input = void;

type Output = void;

export class WatchMapsUseCase implements UseCase<Input, Output> {
  constructor(
    private readonly userStore: UserStore,
    private readonly databaseMapRepository: DatabaseMapRepository
  ) {}

  execute() {
    const user = this.userStore.user;

    if (user.role === userRoles.root || user.role === userRoles.admin) {
      return this.databaseMapRepository.watchMapsAdmin();
    }

    if (user.role === userRoles.manager) {
      const mapsIds = user.mapsArray.map((x) => x.value);
      return this.databaseMapRepository.watchMapsManager(mapsIds);
    }
  }
}
