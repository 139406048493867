import styled from "styled-components";

export const ReportsContent = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  justify-content: center;
  /* align-items: center; */
`;

export const ReportItem = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  gap: 10px;
`;

export const ReportHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  /* height: 44px; */

  gap: 15px;

  justify-content: flex-start;
  align-items: center;

  color: var(--blue4);

  .left {
    display: flex;
    flex-direction: row;

    align-items: center;
    gap: 10px;
  }

  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    /* 1 */

    color: #4a5e83;
  }

  span {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;

    /* 1 */

    color: #4a5e83;
  }
`;

export const ResultText = styled.div`
  box-sizing: border-box;
  display: flex;

  /* height: 34px; */
  padding: 5px 15px;
  align-items: center;

  background: transparent;
  border-radius: 100px;
`;

export const ReportDescription = styled.div`
  width: 100%;

  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    /* 1 */

    color: #4a5e83;
  }
`;
