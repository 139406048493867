import React from "react";

import { Container } from "./styles";

export type DividerProps = {
  width: string;
};

export default function Divider({ width }: DividerProps) {
  return <Container width={width} />;
}
