import { Client } from "@models/Client";
import { speciesColors } from "../constants/speciesColors";

export const speciesHelper = {
  getSpecieTag,
};

function getSpecieTag(specie: Client["specie"]) {
  if (specie) {
    const find = speciesColors.find((x) => x.specie === specie);

    if (find && find?.tagColor && find?.textColor) {
      return {
        tagColor: find.tagColor,
        textColor: find.textColor,
      };
    }
  }

  return {
    tagColor: "#808B9F",
    textColor: "#FFFFFF",
  };
}
