import styled from "styled-components";
import { breakpoints } from "@resources/breakpoints";

type Props = { streetView?: boolean };
export const Container = styled.div<Props>`
  position: absolute;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 300px;
  height: 100dvh;

  top: 0px;
  right: 0;
  padding: 40px 20px 10px 20px;
  gap: 10px;

  color: var(--blue4);
  /* fill: var(--blue4); */

  background: #ffffff;
  /* shadowsidebar */

  box-shadow: 0px -10px 10px rgba(0, 0, 0, 0.25);
  z-index: 5000;
  @media ${breakpoints.sm} {
    display: ${(props) => (props.streetView ? "none" : "flex")};
    width: 250px;
  }
`;

export const Content = styled.div`
  overflow-y: auto;
  padding-bottom: 20px;
`;

export const Header = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 160px;

  cursor: pointer;

  border-radius: 10px;
`;

export const ImageStreetView = styled.img`
  width: 100%;
  height: 160px;

  /* background: url(image.png); */
  border-radius: 10px;
`;

export const ClientHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  max-width: 100%;

  gap: 10px;

  margin-top: 8px;
`;

export const Value = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: right;
  align-items: center;

  gap: 10px;

  h3 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: var(--blue4);
  }

  h4 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;

    color: var(--white);
  }

  div {
    padding: 10px 10px;
    background: var(--red);
    border-radius: 20px;
  }
`;

export const ImageEspecie = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  min-width: 60px;

  border-radius: 100px;
  background-color: #ecf2fb;
`;

export const ClientNameContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  h4 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    max-width: 100%;

    /* 1 */

    color: #4a5e83;
  }

  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    /* 2 */

    color: #808b9f;
  }
`;

export const PointDivider = styled.div`
  position: relative;
  width: 100%;
  height: 0px;

  margin: 8px 0;

  border: 0.5px dashed #b4c1d5;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  gap: 10px;
  margin-bottom: 10px;

  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    max-width: 80%;
    /* 2 */

    color: var(--grey2);
  }
`;

export const InfoHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: left;
  align-items: center;

  gap: 20px;

  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: var(--blue4);
  }
`;

export const FullData = styled.p`
  margin: 10px 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;

  cursor: pointer;
  /* 1 */

  color: var(--blue4);
`;

export const GenerateReportButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  padding: 12px 58px 12px 58px;

  background: #de6161;
  box-shadow: 0px 7px 10px rgba(228, 73, 73, 0.25);
  border-radius: 100px;

  color: var(--white);

  @media ${breakpoints.md} {
    padding: 10px 38px;
  }

  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    text-align: center;

    /* 5 */

    color: var(--white);
  }
`;
