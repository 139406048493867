import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1.5fr repeat(3, 1fr) repeat(2, 0.75fr);

  padding: 0 15px;
  cursor: pointer;
  user-select: none;

  align-items: center;
  height: 59px;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;

    gap: 10px;
  }

  h4 {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    color: #808b9f;
  }
`;

export const Name = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;

  /* 1 */

  color: #4a5e83;
`;

type SpecieProps = {
  textColor: string;
};
export const Specie = styled.div<SpecieProps>`
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: left;
  background: ${(props) => props.color};
  opacity: 0.8;
  border-radius: 100px;

  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height */

  /* 5 */

  color: ${(props) => props.textColor};
`;

export const TextAction = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  cursor: pointer;
  user-select: none;

  text-decoration-line: underline;

  /* DE6161 */

  color: #de6161;
`;
