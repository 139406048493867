import { Address, Client } from "@models/Client";

export const addressHelper = {
  formatAddress,
  formatByValidAddress,
  formatCurrentAddress,
};

const hasContent = (string: string) => {
  if (!string || string === "" || string === "N/D") {
    return false;
  }

  return true;
};

function formatAddress(client: Client) {
  let string = "";

  const addressNames = Object.keys(client.addresses[client.validAddress]);

  for (const name of addressNames) {
    const value = client.addresses[client.validAddress][name];

    if (value && value != "N/D" && hasContent(value)) {
      if (name === "street") {
        string = value;
      } else {
        if (name === "city" || name === "state") {
          string = string + " - " + value;
        } else {
          string = string + ", " + value;
        }
      }
    }
  }

  if (!string) {
    string = "N/D";
  }

  return string;
}

function formatByValidAddress(client: Client, validAddress: Client["validAddress"]) {
  let string = "";

  const addressNames = Object.keys(client.addresses[validAddress]);

  for (const name of addressNames) {
    const value = client.addresses[validAddress][name];

    if (value && value != "N/D" && hasContent(value)) {
      if (name === "street") {
        string = value;
      } else {
        if (name === "city" || name === "state") {
          string = string + " - " + value;
        } else {
          string = string + ", " + value;
        }
      }
    }
  }

  if (!string) {
    string = "N/D";
  }

  return string;
}

function formatCurrentAddress(currentAddress: Address) {
  let string = "";

  if (currentAddress) {
    const addressNames = Object.keys(currentAddress);

    for (const addressName of addressNames) {
      const value = currentAddress[addressName];

      if (value) {
        if (addressName == "street") {
          string = value;
        } else {
          if (addressName == "city" || addressName == "state") {
            string = string + " - " + value;
          } else {
            string = string + ", " + value;
          }
        }
      }
    }
  }

  return string;
}
