import React, { useEffect, useState } from "react";

import { Container, ProgressBar, ProgressText } from "./styles";
import { Route, routeStatus } from "@models/Route";

const colorsStatus = {
  WAITING: "var(--grey1)",
  STARTED: "var(--orange)",
  PARTIALLY_COMPLETED: "var(--yellow)",
  COMPLETED: "var(--success)",
  CANCELED: "var(--red)",
} as const;

type Props = {
  data: Route;
};

function ProgressIcon({ data }: Props) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let visited = 0;
    for (const point of data.points) {
      if (point.visited) {
        visited++;
      }
    }

    setProgress(visited);
  }, [data]);

  return (
    <Container>
      <ProgressBar
        progress={progress > 0 ? (progress * 100) / data.points.length : 0}
        background={colorsStatus[data.status]}
        barBackground={data.status === routeStatus.CANCELED ? "var(--red)" : "var(--background)"}
      >
        <div></div>
      </ProgressBar>
      <ProgressText>
        {progress}/{data.points.length}
      </ProgressText>
    </Container>
  );
}

export default ProgressIcon;
