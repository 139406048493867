import React, { useState } from "react";

import {
  Container,
  Content,
  ActionsContent,
  CancelButton,
  ConfirmButton,
  ModalConfirmText,
  Divider,
} from "./styles";

import deleteIcon from "@assets/deleteIcon.svg";
import Loading from "@components/Loading/Loading";
import { Map } from "@models/Map";
import { DeleteMapUseCase } from "@dataUseCases/index";
import { HttpMapRepository } from "@dataRepositories/index";
import { api } from "@infra/api";

type Props = {
  mapId: Map["id"];
  mapName: Map["name"];
  onClose: () => void;
};

const ModalDeleteMap = ({ mapId, mapName, onClose }: Props) => {
  const [loading, setLoading] = useState(false);

  const handleConfirm = () => {
    const useCase = new DeleteMapUseCase(new HttpMapRepository(api));

    useCase
      .execute(mapId)
      .then(() => {
        onClose();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container>
      <Content>
        <img src={deleteIcon} style={{ height: 80, width: 80 }} alt="map map" />
        <ModalConfirmText>Tem certeza que deseja excluir o mapa {mapName}?</ModalConfirmText>
      </Content>
      <Divider />
      <ActionsContent>
        {loading ? (
          <Loading />
        ) : (
          <>
            <CancelButton onClick={onClose}>
              <p>Voltar</p>
            </CancelButton>
            <ConfirmButton onClick={handleConfirm}>
              <p>Excluir</p>
            </ConfirmButton>
          </>
        )}
      </ActionsContent>
    </Container>
  );
};

export default ModalDeleteMap;
