import React, { useEffect, useState } from "react";

import { dashboardHelper, CardsData } from "../../helpers/dashboard";
import useCardsReturns from "@hooks/useCardsReturnClients";
import useUser from "@hooks/useUser";
import { Client } from "@models/Client";
import { User } from "@models/User";
import { Container, Header } from "./styles";
import ReportsCards from "./components/ReportsCards/ReportsCards";
import Table from "@components/Table/Table";
import { tableColumns } from "@resources/tableColumns";
import useCardsAttendClients from "@hooks/useCardsAttendClients";
import useTableAttendClients from "@hooks/useTableAttendClients";
import { Filter } from "@models/Filters";
import { Paging } from "@models/Paging";
import HeaderFilter from "@components/Filters/HeaderFilter/HeaderFilter";
import { monthNames } from "@constants/monthNames";
import OperatorsCard from "./components/OperatorsCard/OperatorsCard";
import ProfileButton from "@components/ProfileButton/ProfileButton";
import Charts from "./components/Charts/Charts";
import useDaysWithRoutes from "@hooks/useDaysWithRoutes";

const dateNow = new Date();
const currentMonth = dateNow.getMonth();

const defaultFilterObj = {
  line1: [],
  line2: [],
  line3: [],
};

export type ChartsFilters = {
  line1: Filter[];
  line2: Filter[];
  line3: Filter[];
};

export type OperatorsCardData = {
  name: User["name"];
  id: User["id"];
  profileImageUrl: User["profileImageUrl"];
  daysWithRoutes: number;
  attendClients: Client[];
  returns: {
    total: Client[];
    completed: Client[];
    expired: Client[];
    pending: Client[];
  };
};

const DashboardPage = () => {
  const [headerFilters, setHeaderFilters] = useState<Filter[]>([]);

  const { cardsAttendClients } = useCardsAttendClients({ headerFilters });

  const [selectedLine, setSelectedLine] = useState("line1");
  const [filters, setFilters] = useState<ChartsFilters>(defaultFilterObj);

  const [paging, setPaging] = useState<Paging>({ offset: 0, limit: 25 });

  const { tableAttendClients, tableAttendClientsTotal } = useTableAttendClients({
    filters: filters[selectedLine],
    paging,
  });

  const { dashboardReturns } = useCardsReturns({ headerFilters });
  const { user } = useUser();
  const { daysWithRoutes } = useDaysWithRoutes({ headerFilters });

  //Cards
  const [operatorsCardData, seOperatorsCardData] = useState<OperatorsCardData[]>(null);
  const [cardsData, setCardsData] = useState<CardsData>(null);

  useEffect(() => {
    if (cardsAttendClients) {
      processReportsCards(cardsAttendClients);
      processOperatorsCard(cardsAttendClients);
    }
  }, [cardsAttendClients, headerFilters, daysWithRoutes]);

  const processReportsCards = (cardsAttendClients: Client[]) => {
    const data = dashboardHelper.getCardsData(user, cardsAttendClients);

    setCardsData(data);
  };

  const processOperatorsCard = (cardsAttendClients: Client[]) => {
    const data = dashboardHelper.geOperatorsCardData(
      user,
      cardsAttendClients,
      dashboardReturns,
      daysWithRoutes
    );
    seOperatorsCardData(data);
  };

  return (
    <Container>
      <Header>
        <h1>Dashboard</h1>
        <div>
          <HeaderFilter
            filters={headerFilters}
            filterTypes={[{ headerName: "Data", field: "lastReportDate" }]}
            dateField="lastReportDate"
            onChangeFilters={(newFiltersArray) => {
              setHeaderFilters(newFiltersArray);
            }}
            onClearFilters={() => setHeaderFilters([])}
            defaultTitle={monthNames.full[currentMonth]}
          />
          <ProfileButton arrowColor="var(grey2)" />
        </div>
      </Header>

      <ReportsCards cardsData={cardsData} />

      <OperatorsCard operatorsData={operatorsCardData} />

      <Charts
        filters={filters}
        selectedLine={selectedLine}
        setSelectedLine={(newLine) => setSelectedLine(newLine)}
      />

      <Table
        title="Clientes atendidos"
        tableColumns={tableColumns.dashboard}
        tableRows={tableAttendClients}
        tableRowsTotal={tableAttendClientsTotal}
        filterTypes={tableColumns.dashboard}
        filters={filters[selectedLine]}
        onChangeFilters={(newFiltersArray: Filter[]) => {
          setFilters((prev) => ({
            ...prev,
            [selectedLine]: newFiltersArray,
          }));
        }}
        paging={paging}
        onChangePaging={(paging) => setPaging(paging)}
        columns={7}
        filtersHighlights={[
          {
            field: "lastReportDate",
            headerName: "Data",
          },
          {
            field: "operatorName",
            headerName: "Operador",
          },
          {
            field: "lastReportResult",
            headerName: "Resultado",
          },
        ]}
        dateField="lastReportDate"
      />
    </Container>
  );
};

export default DashboardPage;
