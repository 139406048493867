import justica from "@assets/badges/JUSTICA.png";
import excelente from "@assets/badges/EXCELENTE.png";
import { Client, clientReportStatus, clientStatus } from "@models/Client";

export const badges = {
  getClientBadges,
};

function getClientBadges(client: Client) {
  const array = [];

  if (
    client.status === clientStatus.OPEN &&
    client.reportStatus === clientReportStatus.NOT_ATTEND
  ) {
    array.push({ title: "JUSTIÇA LIVRE", path: justica });
  }

  if (client.specialIcon) {
    array.push({ title: "EXCELENTE", path: excelente });
  }

  return array;
}
