import styled from "styled-components";

export const Container = styled.div`
  box-sizing: border-box;

  /* width: 250.86px; */
  min-height: 142.38px;
  /* margin-top: 97px; */

  display: flex;
  flex-direction: column;

  /* align-items: center; */
  justify-content: center;

  background: #ffffff;

  cursor: pointer;
  user-select: none;

  box-shadow: 0px 4px 20px rgba(35, 54, 63, 0.05);
  border-radius: 20px;
`;

export const Title = styled.div`
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  h1 {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* or 171% */

    /* 2 */

    color: #808b9f;
  }

  .icon {
    color: ${(props) => (props.color ? props.color : "#DE6161")};
    fill: ${(props) => (props.color ? props.color : "#DE6161")};
  }
`;

export const Divider = styled.div`
  border: 1px solid #ecf2fb;
  margin: 0 20px;
`;

export const Content = styled.div`
  height: 97px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;

  .value {
    font-weight: 400;
    font-size: 40px;
    line-height: 24px;
    /* or 60% */

    /* 83DBD6 */

    color: ${(props) => (props.color ? props.color : "#4A5E83")};
  }

  .description {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    /* 83DBD6 */

    color: ${(props) => (props.color ? props.color : "#4A5E83")};
  }

  .operatorName {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* or 171% */

    /* 2 */

    color: #808b9f;
  }
`;
