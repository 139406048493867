import { ReportRepository } from "@domainRepositories/ReportRepository";
import showToast from "@helpers/showToast";
import { HttpClient } from "@infra/api/HttpClient";
import { Report } from "@models/Report";

export class HttpReportRepository implements ReportRepository {
  private readonly baseUrl = `${process.env.REACT_APP_API_URL}/reports`;

  constructor(private readonly httpClient: HttpClient) {}

  async get(reportId: Report["id"]) {
    return this.httpClient
      .request({
        url: `${this.baseUrl}/${reportId}`,
        method: "GET",
      })
      .then((response) => response.data);
  }

  async getClientReports(clientId: string) {
    return this.httpClient
      .request({
        url: `${this.baseUrl}/client/${clientId}`,
        method: "GET",
      })
      .then((response) => response.data);
  }

  async create(report: Partial<Report>): Promise<Report> {
    return this.httpClient
      .request({
        method: "POST",
        url: `${this.baseUrl}`,
        body: JSON.stringify(report),
      })
      .then((response) => {
        showToast(response.message, "success");
        return response.data as Report;
      });
  }

  async save(clientId: string, reports: Partial<Report>[]) {
    return this.httpClient
      .request({
        method: "POST",
        url: `${this.baseUrl}/${clientId}/all`,
        body: JSON.stringify({ reports }),
      })
      .then((response) => {
        showToast(response.message, "success");
        return response.data as Report[];
      });
  }

  async delete(reportId: string) {
    return this.httpClient
      .request({
        method: "DELETE",
        url: `${this.baseUrl}/${reportId}`,
      })
      .then((response) => {
        showToast(response.message, "success");
        return response.data;
      });
  }
}
