import styled from "styled-components";
import { breakpoints } from "@resources/breakpoints";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  padding: 40px 40px;

  gap: 40px;

  @media ${breakpoints.md} {
    padding: 20px 20px;
    gap: 10px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  gap: 20px;

  color: var(--blue4);
`;

export const ModalConfirmHeader = styled.div``;

export const ModalConfirmText = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  max-width: 50%;
  /* or 100% */

  text-align: center;

  color: #4a5e83;

  @media ${breakpoints.md} {
    max-width: 80%;
  }
`;

export const ActionsContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: bottom;
  justify-content: right;
  gap: 20px;
  padding-top: 18px;
`;

export const CancelButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  padding: 10px;

  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;

    color: var(--grey2);
  }
`;

export const ConfirmButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  padding: 12px 58px 12px 58px;

  background: #de6161;
  box-shadow: 0px 7px 10px rgba(228, 73, 73, 0.25);
  border-radius: 100px;

  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    text-align: center;

    /* 5 */

    color: var(--white);
  }
`;
