import SearchClientRepository from "@domainRepositories/SearchClientRepository";
import { AlgoliaIndex } from "@infra/algolia/config";
import { algoliaAdapter, AlgoliaClient } from "./algoliaAdapter";

export class AlgoliaSearchClientRepository implements SearchClientRepository {
  private readonly algoliaIndex: AlgoliaIndex;

  constructor(algoliaIndex: AlgoliaIndex) {
    this.algoliaIndex = algoliaIndex;
  }

  async searchClients(
    search: string,
    textFilters: string,
    facetFilters: string[],
    page: number,
    perPage = 25
  ) {
    return this.algoliaIndex
      .search<AlgoliaClient>(search, {
        filters: textFilters,
        facetFilters: facetFilters,
        page: page,
        hitsPerPage: perPage,
      })
      .then((value) => {
        return {
          clients: algoliaAdapter.mapClients(value.hits),
          total: value.nbHits,
        };
      });
  }
}
