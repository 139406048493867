import React, { useState } from "react";

import { BiBarChart } from "react-icons/bi";
import { TbClipboardCheck, TbRoute } from "react-icons/tb";
import { FiUsers, FiMap } from "react-icons/fi";
import { AiOutlineFileAdd } from "react-icons/ai";
import { HiOutlineDatabase } from "react-icons/hi";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import menuLogIcon from "../../assets/menuLogIcon.svg";

import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import {
  Container,
  Image,
  ImageContainer,
  ButtonsContainer,
  Button,
  Frame,
  Title,
  LogButton,
} from "./styles";
import { IoRemoveCircleOutline } from "react-icons/io5";
import useUser from "@hooks/useUser";

const MenuBar = () => {
  const { isAdmin } = useUser();

  const navigate = useNavigate();
  const location = useLocation();

  const [selected, setSelected] = useState(location.pathname.substring(1));
  const [expanded, setExpanded] = useState(false);

  const handleNavigate = (newValue) => {
    setSelected(newValue);
    navigate("/" + newValue);
  };

  return (
    <Container>
      <ImageContainer
        onClick={() => {
          if (location.pathname.substring(1) == "dashboard") {
            window.location.reload();
          } else {
            handleNavigate("dashboard");
          }
        }}
      >
        <Image />
      </ImageContainer>

      <ButtonsContainer>
        <Button
          selected={selected == "dashboard" ? true : false}
          onClick={() => handleNavigate("dashboard")}
        >
          <Frame>
            <BiBarChart size={18} />
          </Frame>
          <Title>Dashboard</Title>
        </Button>

        <Button
          selected={selected == "adminMapPage" ? true : false}
          onClick={() => handleNavigate("adminMapPage")}
        >
          <Frame>
            <FiMap size={15} />
          </Frame>
          <Title>Mapas</Title>
        </Button>

        <Button
          selected={selected == "adminRoutesPage" ? true : false}
          onClick={() => handleNavigate("adminRoutesPage")}
        >
          <Frame>
            <TbRoute size={18} />
          </Frame>
          <Title>Rotas</Title>
        </Button>

        <Button
          selected={selected == "adminReturnsPage" ? true : false}
          onClick={() => handleNavigate("adminReturnsPage")}
        >
          <Frame>
            <TbClipboardCheck size={18} />
          </Frame>
          <Title>Central de retornos</Title>
        </Button>

        {isAdmin && (
          <Button
            selected={selected == "manageUsers" ? true : false}
            onClick={() => handleNavigate("manageUsers")}
          >
            <Frame>
              <FiUsers size={16} />
            </Frame>
            <Title>Usuários</Title>
          </Button>
        )}

        {/* {isAdmin && (
          <Button
            selected={selected == "upload" ? true : false}
            onClick={() => handleNavigate("upload")}
          >
            <Frame>
              <AiOutlineFileAdd size={16} />
            </Frame>
            <Title>Adicionar clientes</Title>
          </Button>
        )}

        {isAdmin && (
          <Button
            selected={selected == "remove" ? true : false}
            onClick={() => handleNavigate("remove")}
          >
            <Frame>
              <IoRemoveCircleOutline size={16} />
            </Frame>
            <Title>Remover clientes</Title>
          </Button>
        )}

        {isAdmin && (
          <Button
            selected={selected == "databasePage" ? true : false}
            onClick={() => handleNavigate("databasePage")}
          >
            <Frame>
              <HiOutlineDatabase size={16} />
            </Frame>
            <Title>Banco de dados</Title>
          </Button>
        )}

        {isAdmin && (
          <>
            <Button selected={false} onClick={() => setExpanded(!expanded)}>
              <img
                src={menuLogIcon}
                alt="img img"
                style={{ height: 15, width: 15, opacity: 1, marginRight: 10 }}
              />

              <LogButton>
                <Title>Logs</Title>
                {expanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </LogButton>
            </Button>

            {expanded && (
              <Button
                selected={selected == "pjeLogs" ? true : false}
                onClick={() => handleNavigate("pjeLogs")}
              >
                <Frame></Frame>
                <Title>PJE</Title>
              </Button>
            )}

            {expanded && (
              <Button
                selected={selected == "closeRoutesLogs" ? true : false}
                onClick={() => handleNavigate("closeRoutesLogs")}
              >
                <Frame></Frame>
                <Title>Fechamento de rotas</Title>
              </Button>
            )}

            {expanded && (
              <Button
                selected={selected == "systemLogs" ? true : false}
                onClick={() => handleNavigate("systemLogs")}
              >
                <Frame></Frame>
                <Title>Sistema</Title>
              </Button>
            )}
          </>
        )} */}
      </ButtonsContainer>
    </Container>
  );
};

export default MenuBar;
