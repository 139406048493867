import { Log } from "@models/Log";

export const logsHelper = { mapLogs };

function mapLogs(logs: Log[]) {
  let logsArray: string[] = [];

  for (const item of logs) {
    logsArray = logsArray.concat(item.logs.reverse());
  }
  return logsArray;
}
