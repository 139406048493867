import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 400px;
`;

export const FieldText = styled.p`
  height: 24px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 24px;

  margin-bottom: 5px;

  color: #4a5e83;
`;

export const TextInput = styled.div`
  box-sizing: border-box;
  display: flex;

  width: 312px;
  height: 44px;
  align-items: center;

  border: 1px solid var(--grey1);
  border-radius: 100px;
`;

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: right;

  gap: 30px;
  padding: 20px 10px;
`;

export const CancelButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  padding: 10px;

  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;

    color: var(--grey2);
  }
`;

type SaveButtonProps = { disabled: boolean };
export const SaveButton = styled.div<SaveButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  padding: 12px 58px 12px 58px;

  background: ${(props) => (props.disabled ? "var(--grey1)" : " #DE6161")};
  box-shadow: ${(props) =>
    props.disabled ? "0px 7px 10px var(--grey1)" : "0px 7px 10px rgba(228, 73, 73, 0.25)"};
  border-radius: 100px;

  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    text-align: center;

    /* 5 */

    color: var(--white);
  }
`;
