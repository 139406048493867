import React from "react";

import { Container, Options, RouteColumn, RouteName } from "./styles";

//Icons
import { BiMap } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";

//MUI
import { Avatar } from "@mui/material";
import StatusIcon from "@components/Icons/StatusIcon/StatusIcon";
import ProgressIcon from "@components/Icons/ProgressIcon/ProgressIcon";

//utils
import { datetimeHelper } from "@helpers/datetime";
import { avatarHelper } from "@helpers/avatar";
import { Route, routeStatus } from "@models/Route";

type Props = {
  data: Route;
  columns: number;
  isHistory?: boolean;
  onViewRoute: () => void;
};

function RouteItem({ data, columns, isHistory, onViewRoute }: Props) {
  return (
    <Container columns={columns}>
      <RouteColumn>
        <BiMap size={15} color="var(--grey2)" />
        <RouteName>{data.name}</RouteName>
      </RouteColumn>
      <RouteColumn>
        <Avatar
          alt="Avatar"
          src={avatarHelper.getUserAvatar(data.operatorId)}
          sx={{
            width: 20,
            height: 20,
            borderRadius: 100,
            borderColor: "white",
            borderWidth: 10,
          }}
        />
        <h4>{data.operatorName}</h4>
      </RouteColumn>
      {isHistory && <h4>{data.endAt && datetimeHelper.getDate(data.endAt)}</h4>}
      {!isHistory && (
        <RouteColumn>
          <StatusIcon status={data.status} />
        </RouteColumn>
      )}

      {!isHistory && (
        <RouteColumn>
          <h4>{datetimeHelper.getDate(data.createdAt)}</h4>
        </RouteColumn>
      )}

      {data.status !== routeStatus.WAITING &&
        data.status !== routeStatus.CANCELED &&
        !isHistory && (
          <RouteColumn>
            <h4>{datetimeHelper.getFullDate(data.startAt)}</h4>
          </RouteColumn>
        )}

      {data.status !== routeStatus.WAITING && data.status !== routeStatus.STARTED && !isHistory && (
        <RouteColumn>
          <h4>{datetimeHelper.getFullDate(data.endAt)}</h4>
        </RouteColumn>
      )}
      <ProgressIcon data={data} />
      <Options>
        <AiOutlineEye
          onClick={onViewRoute}
          cursor="pointer"
          size={20}
          color="#808B9F"
          fill="#808B9F"
        />
      </Options>
    </Container>
  );
}

export default RouteItem;
