import DatabaseReturnClientRepository from "@domainRepositories/DatabaseReturnClientRepository";
import { UserStore } from "@domainStores/UserStore";
import UseCase from "@domainUseCases/UseCase";
import { userRoles } from "@models/User";

type Input = void;

type Output = void;

export class WatchReturnsUseCase implements UseCase<Input, Output> {
  constructor(
    private readonly userStore: UserStore,
    private readonly databaseReturnClientRepository: DatabaseReturnClientRepository
  ) {}

  execute() {
    const user = this.userStore.user;

    if (user.role === userRoles.root || user.role === userRoles.admin) {
      return this.databaseReturnClientRepository.watchReturnClientsAdmin();
    }

    if (user.role === userRoles.manager) {
      return this.databaseReturnClientRepository.watchReturnClientsManager(user.operatorsIds);
    }

    return;
  }
}
