import React, { useEffect, useState } from "react";

import { Container, CloseButton } from "./styles";

//APIS
import { GoogleMap, useJsApiLoader, StreetViewPanorama } from "@react-google-maps/api";

//Icons
import { AiOutlineCloseCircle } from "react-icons/ai";

//Custom components
import Loading from "@components/Loading/Loading";
import { Client } from "@models/Client";

type Props = {
  client: Client;
  onClose: () => void;
};

function ModalStreetView({ client, onClose }: Props) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoadingData(false);
    }, 2000);
  }, [client]);

  if (loadingData || !isLoaded) {
    return <Loading />;
  }

  return (
    <Container>
      {isLoaded && loadingData == false && (
        <GoogleMap
          mapContainerStyle={{
            width: "100%",
            height: "100%",
            borderRadius: 10,
          }}
          center={{ lat: -8.113334, lng: -35.05953 }}
          zoom={10}
          clickableIcons={false}
          options={{
            disableDefaultUI: true,
          }}
        >
          <StreetViewPanorama
            options={{
              disableDefaultUI: true,
              visible: true,
              position: { lat: client.lat, lng: client.lng },
            }}
          />
          <CloseButton onClick={onClose}>
            <AiOutlineCloseCircle size={35} />
          </CloseButton>
        </GoogleMap>
      )}
    </Container>
  );
}

export default ModalStreetView;
