import React, { useState } from "react";

import { Container, Divider, FooterContent, HeaderContent, LogoutMenu } from "./styles";

//Icons
import { IoIosArrowDown } from "react-icons/io";

//MUI
import { Avatar } from "@mui/material";
import Menu from "@mui/material/Menu";
import { avatarHelper } from "@helpers/avatar";
import { MdExitToApp } from "react-icons/md";
import useUser from "@hooks/useUser";

type Props = {
  arrowColor: string;
};

const ProfileButton = ({ arrowColor }: Props) => {
  const { user, logoutUser } = useUser();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  return (
    <Container>
      <Avatar
        alt="Remy Sharp"
        src={avatarHelper.getUserAvatar(user.id)}
        sx={{ width: 46, height: 46, borderRadius: 2, borderColor: "white", borderWidth: 20 }}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      />
      <IoIosArrowDown
        onClick={(event) => setAnchorEl(event.currentTarget)}
        size={20}
        color={arrowColor}
        fill={arrowColor}
        style={{ cursor: "pointer" }}
      />

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          style: {
            marginTop: 18,
            marginRight: "50%",
            borderRadius: 20,
          },
        }}
      >
        <LogoutMenu>
          <HeaderContent>
            <Avatar
              alt="Remy Sharp"
              src={avatarHelper.getUserAvatar(user.id)}
              sx={{ width: 46, height: 46, borderRadius: 2, borderColor: "white", borderWidth: 20 }}
            />
            <div className="info">
              <h4>{user.name}</h4>
              <h5>{user.email}</h5>
            </div>
          </HeaderContent>
          <Divider />
          <FooterContent onClick={() => logoutUser()}>
            <MdExitToApp size={18} color="#DE6161" fill="#DE6161" />
            <h4>Sair</h4>
          </FooterContent>
        </LogoutMenu>
      </Menu>
    </Container>
  );
};

export default ProfileButton;
