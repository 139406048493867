import React, { useEffect, useState } from "react";

import { Container, Content, Item, Header, ClearText, ToolBar } from "./styles";
import { arrayHelper } from "@helpers/array";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { BsEraser } from "react-icons/bs";
import { speciesFilterIcons } from "@resources/speciesFilterIcons";
import { returnFilterIcons } from "@resources/returnFilterIcons";

type Props = {
  calculate: boolean;
  clear: boolean;
  onChange: (newSpecies: typeof speciesFilterIcons) => void;
};

function SpecieMapFilter({ calculate, clear, onChange }: Props) {
  const [selectedEspecies, setSelectedEspecies] = useState(
    arrayHelper.addSelectedField(speciesFilterIcons.concat(returnFilterIcons), true)
  );
  const [expanded, setExpanded] = useState(false);

  const handleSelect = (item) => {
    const index = selectedEspecies.findIndex((x) => x.specie == item.specie);

    if (index != -1) {
      const newItem = {
        ...item,
        selected: !item.selected,
      };

      const newArray = arrayHelper.insertObjectOnArray(selectedEspecies, index, newItem);

      setSelectedEspecies(newArray);
      onChange(newArray.filter((x) => x.selected == true));
    }
  };

  const clearFilter = () => {
    setSelectedEspecies(
      arrayHelper.addSelectedField(speciesFilterIcons.concat(returnFilterIcons), true)
    );
    onChange(arrayHelper.addSelectedField(speciesFilterIcons.concat(returnFilterIcons), true));
  };

  useEffect(() => {
    if (calculate) {
      setSelectedEspecies(
        arrayHelper.addSelectedField(speciesFilterIcons.concat(returnFilterIcons), true)
      );
    }
  }, [calculate]);

  useEffect(() => {
    setSelectedEspecies(
      arrayHelper.addSelectedField(speciesFilterIcons.concat(returnFilterIcons), true)
    );
  }, [clear]);

  return (
    <Container>
      <Header onClick={() => setExpanded(!expanded)}>
        <h3>Filtro de benefícios</h3>
        {expanded ? <IoIosArrowUp cursor="pointer" /> : <IoIosArrowDown cursor="pointer" />}
      </Header>
      {expanded && (
        <>
          <ToolBar>
            {expanded && <h5>Clique para marcar/desmarcar</h5>}
            <ClearText onClick={() => clearFilter()}>
              Limpar
              <BsEraser style={{ cursor: "pointer", marginLeft: 5, color: "#808B9F" }} />
            </ClearText>
          </ToolBar>
          <Content>
            {selectedEspecies.map((item, index) => (
              <Item
                key={index}
                selected={item.selected}
                title={item.title}
                onClick={() => handleSelect(item)}
              >
                <img src={item.icon} />
              </Item>
            ))}
          </Content>
        </>
      )}
    </Container>
  );
}

export default SpecieMapFilter;
