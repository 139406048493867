import React, { useState, useEffect } from "react";

import { ModalLogsContent, LogsContainer, Container, LogItem, Circle, Line } from "./styles";

import Loading from "@components/Loading/Loading";
import NoRowsOverlay from "@components/NoRowsOverlay/NoRowsOverlay";
import ModalHeader from "@components/Modal/ModalHeader/ModalHeader";
import { Client } from "@models/Client";
import { GetClientLogsUseCase } from "@dataUseCases/index";
import { api } from "@infra/api";
import { HttpLogRepository } from "@dataRepositories/Http/HttpLogRepository";
import { logsHelper } from "@helpers/logs";

type Props = {
  data: Client;
  onClose: () => void;
};

const ModalLogsClient = ({ data, onClose }: Props) => {
  const [clientLogsFormated, setClientLogsFormated] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const useCase = new GetClientLogsUseCase(new HttpLogRepository(api));

      useCase
        .execute(data.id)
        .then((logs) => {
          setClientLogsFormated(logsHelper.mapLogs(logs));
        })
        .finally(() => setLoading(false));
    })();
  }, []);

  return (
    <Container>
      <ModalHeader title={`Logs do cliente ${data.name}`} onClose={onClose} />
      <hr />
      <ModalLogsContent>
        {loading ? (
          <Loading />
        ) : (
          <>
            {clientLogsFormated.length != 0 ? (
              <LogsContainer>
                {clientLogsFormated.map((item, index) => (
                  <LogItem key={index}>
                    <div>
                      <Circle />
                      <Line />
                    </div>
                    <h3>{item}</h3>
                  </LogItem>
                ))}
              </LogsContainer>
            ) : (
              <NoRowsOverlay />
            )}
          </>
        )}
      </ModalLogsContent>
    </Container>
  );
};

export default ModalLogsClient;
