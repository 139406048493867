import styled from "styled-components";

type ContainerProps = { background: string };
export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 2px 10px;
  background: ${(props) => props.background};
  border-radius: 100px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  color: var(--white);
`;
