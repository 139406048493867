import React from "react";

import { Container } from "./styles";

type Props = {
  name: string;
  defaultValue: string;
  onChange: (value: string) => void;
};

const TextArea = ({ name, defaultValue, onChange }: Props) => {
  return (
    <Container>
      <textarea
        name={name}
        onChange={(event) => onChange(event.target.value)}
        defaultValue={defaultValue}
      />
    </Container>
  );
};

export default TextArea;
