import CardContent from "./CardContent";
import CardDivider from "./CardDivider";
import CardRoot from "./CardRoot";
import CardTitle from "./CardTitle";

export const Card = {
  Root: CardRoot,
  Title: CardTitle,
  Divider: CardDivider,
  Content: CardContent,
};
