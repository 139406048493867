import React, { useState } from "react";

import {
  Container,
  Title,
  OperatorsList,
  HeaderList,
  ListContainer,
  OperatorItem,
  OperatorsInfo,
  HeaderInfo,
  InfoItem,
  Circle,
  OperatorsListLoading,
  OperatorsInfoLoading,
} from "./styles";

//Icons
import checkedIcon from "@assets/checkedIcon.svg";
import clockIcon from "@assets/clockIcon.svg";
import expiredIcon from "@assets/expiredIcon.svg";
import expiredIconWhite from "@assets/expiredIconWhite.svg";

import { FiUsers, FiCheckSquare } from "react-icons/fi";
import { TbRoute, TbFileInvoice } from "react-icons/tb";
import { AiOutlineClockCircle } from "react-icons/ai";

//MUI
import { Avatar } from "@mui/material";
import Loading from "@components/Loading/Loading";
import { OperatorsData } from "@pages/Returns/ReturnsPage";
import { avatarHelper } from "@helpers/avatar";

type Props = {
  operatorsData: OperatorsData[];
};

function OperatorsCard({ operatorsData }: Props) {
  const [selected, setSelected] = useState(0);

  const handleSelect = (
    index: number,
    type: "operator" | "completed" | "pending" | "inRoute" | "expired" | "signed"
  ) => {
    if (index === selected) {
      setSelected(0);
    } else {
      if (type === "operator") {
        setSelected(index);
      }
    }
  };

  if (!operatorsData) {
    return (
      <Container>
        <OperatorsListLoading>
          <Loading />
        </OperatorsListLoading>
        <OperatorsInfoLoading>
          <Loading />
        </OperatorsInfoLoading>
      </Container>
    );
  }

  return (
    <Container>
      <OperatorsList>
        <Title>
          <FiUsers />
          <h3>Operadores</h3>
        </Title>
        <HeaderList>
          <h3>Operador</h3>
          <h3>Realizados</h3>
          <h3>Pendentes</h3>
          <h3>Expirados</h3>
        </HeaderList>
        <ListContainer>
          {operatorsData.map((item, index) => {
            if (item.name != "Geral") {
              return (
                <OperatorItem
                  key={index}
                  active={selected == index ? true : false}
                  onClick={() => handleSelect(index, "operator")}
                >
                  <div>
                    <Avatar
                      alt="Avatar"
                      src={avatarHelper.getUserAvatar(item.id)}
                      sx={{
                        width: 30,
                        height: 30,
                        borderRadius: 100,
                        borderColor: "white",
                        borderWidth: 20,
                      }}
                    />
                    <h4>{item.name}</h4>
                  </div>
                  <div>
                    <img src={checkedIcon} alt="icon icon" />
                    <h4>{item.completedReturns.length}</h4>
                  </div>
                  <div>
                    <img src={clockIcon} alt="icon icon" />
                    <h4>{item.pendingReturns.length}</h4>
                  </div>
                  <div>
                    <img src={expiredIcon} alt="icon icon" />
                    <h4>{item.expiredReturns.length}</h4>
                  </div>
                </OperatorItem>
              );
            }
          })}
        </ListContainer>
      </OperatorsList>
      {operatorsData.length > 0 && (
        <OperatorsInfo>
          <HeaderInfo>
            <Avatar
              alt="Avatar"
              src={avatarHelper.getUserAvatar(operatorsData[selected].id)}
              sx={{
                marginTop: -5,
                width: 100,
                height: 100,
                borderRadius: 100,
                borderColor: "white",
                borderWidth: 10,
              }}
            />
            <h3>{operatorsData[selected].name}</h3>
          </HeaderInfo>
          <InfoItem
            color="var(--success)"
            onClick={() => {
              handleSelect(null, "completed");
            }}
          >
            <div>
              <Circle color="var(--success)">
                <FiCheckSquare />
              </Circle>
              <h3>Retornos realizados</h3>
            </div>
            <h1>{operatorsData[selected].completedReturns.length}</h1>
          </InfoItem>
          <InfoItem
            color="var(--yellow)"
            onClick={() => {
              handleSelect(null, "pending");
            }}
          >
            <div>
              <Circle color="var(--yellow)">
                <AiOutlineClockCircle />
              </Circle>
              <h3>Retornos a realizar</h3>
            </div>
            <h1>{operatorsData[selected].pendingReturns.length}</h1>
          </InfoItem>
          <InfoItem
            color="var(--blue)"
            onClick={() => {
              handleSelect(null, "inRoute");
            }}
          >
            <div>
              <Circle color="var(--blue)">
                <TbRoute />
              </Circle>
              <h3>Retornos em rota</h3>
            </div>
            <h1>{operatorsData[selected].inRouteReturns.length}</h1>
          </InfoItem>
          <InfoItem
            color="var(--red)"
            onClick={() => {
              handleSelect(null, "expired");
            }}
          >
            <div>
              <Circle color="var(--red)">
                <img src={expiredIconWhite} alt="icon" />
              </Circle>
              <h3>Retornos expirados</h3>
            </div>
            <h1>{operatorsData[selected].expiredReturns.length}</h1>
          </InfoItem>
          <InfoItem
            color="var(--success)"
            onClick={() => {
              handleSelect(null, "signed");
            }}
          >
            <div>
              <Circle color="var(--success)">
                <TbFileInvoice />
              </Circle>
              <h3>Contratos fechados após retorno</h3>
            </div>
            <h1>{operatorsData[selected].signedAfterReturn.length}</h1>
          </InfoItem>
        </OperatorsInfo>
      )}
    </Container>
  );
}

export default OperatorsCard;
