import React from "react";

import { Container, Text } from "./styles";

type Props = {
  index?: number;
  backgroundColor?: string;
  size: number;
  text?: string | number;
  textColor?: string;
  fontSize?: string;
  fontWeight?: string;
};

const letters = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

const PointIcon = ({
  index,
  text,
  textColor,
  backgroundColor,
  size,
  fontSize,
  fontWeight,
}: Props) => {
  return (
    <Container backgroundColor={backgroundColor} size={size}>
      <Text textColor={textColor} fontSize={fontSize} fontWeight={fontWeight}>
        {text ? String(text) : letters[index]}
      </Text>
    </Container>
  );
};

export default PointIcon;
