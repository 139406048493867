import { FirebaseReturnClientRepository } from "@dataRepositories/index";
import { WatchReturnsUseCase } from "@dataUseCases/index";
import returnClientStore from "@stores/returnClient";
import userStore from "@stores/user";
import { useEffect } from "react";
import { useStore } from "zustand";

export default function useReturns() {
  const { returns } = useStore(returnClientStore);

  const getReturns = () => {
    const useCase = new WatchReturnsUseCase(
      userStore.getState(),
      new FirebaseReturnClientRepository(returnClientStore.getState())
    );

    useCase.execute();
  };

  useEffect(() => {
    if (returns.completed.length === 0) {
      getReturns();
    }
  }, []);

  return {
    returns,
  };
}
