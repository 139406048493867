import React, { useEffect, useState } from "react";

import {
  Container,
  Header,
  RoutesContainer,
  Divider,
  SwitchRoute,
  SwitchButton,
  RouteSection,
  RoutesCard,
  RoutesCardHeader,
  HistoryContainer,
  FilterContainer,
  FilteredDescription,
  DateDescription,
  FilterItem,
  PointDivider,
  HistoryHeader,
  HistoryCard,
  HistoryList,
  DividerLight,
  TableFooter,
} from "./styles";

import Modal from "react-modal";

import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

import routeOrange from "@assets/routeOrange.svg";
import routeSuccess from "@assets/routeSuccess.svg";
import routeYellow from "@assets/routeYellow.svg";
import routeRed from "@assets/routeRed.svg";

import ProfileButton from "@components/ProfileButton/ProfileButton";
import Loading from "@components/Loading/Loading";
import PointIcon from "@components/Icons/PointIcon/PointIcon";
import RouteItem from "@components/TableItem/RouteItem/RouteItem";
import DateFilter from "@components/Filters/DateFilter/DateFilter";
import ModalViewRoute from "@components/Modal/ModalViewRoute/ModalViewRoute";
import { arrayHelper } from "@helpers/array";
import { filtersHelper } from "@helpers/filters";
import useRoutes from "@hooks/useRoutes";
import { Filter } from "@models/Filters";
import { Route, routeStatus } from "@models/Route";
import useMaps from "@hooks/useMaps";
import DataHeaderFilter from "@components/Filters/DataHeaderFilter/DataHeaderFilter";

type RoutesByStatus = {
  WAITING: Route[];
  STARTED: Route[];
  COMPLETED: Route[];
  CANCELED: Route[];
};

type HistoryRoute = Route & { mapName: string };

const perPage = 50;

const RoutesPage = () => {
  const { routesOfDay, allRoutes } = useRoutes();
  const { maps } = useMaps();

  //Switch
  const [switchSelected, setSwitchSelected] = useState(0);

  const [loadingData, setLoadingData] = useState(true);
  const [currentRoute, setCurrentRoute] = useState<Route>();

  //Route of day
  const [routesByStatus, setRoutesByStatus] = useState<RoutesByStatus>();

  //History
  const [historyData, setHistoryData] = useState<HistoryRoute[]>();
  const [rangeOfList, setRangeOfList] = useState({ init: 0, finish: perPage });

  const [filters, setFilters] = useState<Filter[]>([]);

  //Modal
  const [modalViewRoute, setModalViewRoute] = useState(false);

  useEffect(() => {
    (async () => {
      setLoadingData(true);
      if (routesOfDay && allRoutes) {
        const routesHistory: HistoryRoute[] = [];

        setRoutesByStatus({
          WAITING: routesOfDay.filter((x) => x.status === routeStatus.WAITING),
          STARTED: routesOfDay.filter((x) => x.status === routeStatus.STARTED),
          COMPLETED: routesOfDay
            .filter((x) => x.status === routeStatus.COMPLETED)
            .concat(routesOfDay.filter((x) => x.status === routeStatus.PARTIALLY_COMPLETED)),
          CANCELED: routesOfDay.filter((x) => x.status === routeStatus.CANCELED),
        });

        for (const route of allRoutes) {
          let mapName = "";

          for (const point of route.points) {
            if (point.mapId) {
              const result = maps.find((x) => x.id == point.mapId);

              if (result) {
                mapName = result.name;
              }
            }
          }

          routesHistory.push({
            ...route,
            mapName: mapName,
          });
        }

        setHistoryData(routesHistory);
      }
      setLoadingData(false);
    })();
  }, [routesOfDay, allRoutes]);

  const handleChangeDateFilter = (calendarValues, field) => {
    const exists = filters.findIndex((x) => x.field === field);

    if (exists != -1) {
      if (calendarValues?.selectedStart || calendarValues?.selectedEnd) {
        const newObject: Filter = {
          ...filters[exists],
          dateValue: calendarValues,
        };

        const newArray = arrayHelper.replaceItemOfArray(filters, exists, newObject);

        setFilters(newArray);
      } else {
        const newArray = arrayHelper.removeItemOfArray(filters, exists);

        setFilters(newArray);
      }
    } else {
      const newObject: Filter = {
        field: field,
        type: "date",
        values: [],
        dateValue: calendarValues,
      };

      setFilters([...filters, newObject]);
    }
  };

  const filterTable = () => {
    return filtersHelper.filterDataTable(historyData, filters);
  };

  const getFilterDescription = () => {
    const exists = filters.find((x) => x.field === "endAt");

    if (exists) {
      const selectedStart = exists?.dateValue?.selectedStart;
      const selectedEnd = exists?.dateValue?.selectedEnd;

      if (selectedStart && selectedEnd?.day) {
        return `${selectedStart.day}/${selectedStart.month}/${selectedStart.year} - 
                    ${selectedEnd.day}/${selectedEnd.month}/${selectedEnd.year}`;
      }

      if (selectedStart) {
        return `${selectedStart.day}/${selectedStart.month}/${selectedStart.year}`;
      }
    } else {
      return `Desde o início`;
    }
  };

  if (loadingData || !routesOfDay || !historyData) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  const tableRows = filters.length > 0 ? filterTable() : historyData;

  return (
    <Container>
      <Header>
        <h1>Rotas</h1>
        <div>
          {switchSelected == 1 && (
            <DataHeaderFilter
              tableRowsBackup={historyData}
              filters={filters}
              filterTypes={[
                { headerName: "Status", field: "status" },
                { headerName: "Operador", field: "operatorName" },
                { headerName: "Mapa", field: "mapName" },
              ]}
              dateField="endAt"
              onChangeFilters={(newFiltersArray) => {
                setFilters(newFiltersArray);
              }}
              onClearFilters={() => {
                setFilters([]);
              }}
              defaultTitle=""
            />
          )}
          <ProfileButton arrowColor="var(grey2)" />
        </div>
      </Header>
      <SwitchRoute>
        <SwitchButton onClick={() => setSwitchSelected(0)}>
          Rotas de hoje
          {switchSelected == 0 && <div></div>}
        </SwitchButton>
        <SwitchButton onClick={() => setSwitchSelected(1)}>
          Histórico
          {switchSelected == 1 && <div></div>}
        </SwitchButton>
      </SwitchRoute>
      {switchSelected == 0 && (
        <RoutesContainer>
          <>
            <RouteSection>
              Aguardando
              <PointIcon
                size={30}
                fontWeight="500"
                fontSize="14"
                backgroundColor="#4A5E83"
                textColor="white"
                text={String(routesByStatus.WAITING.length)}
              />
            </RouteSection>
            <RoutesCard>
              <RoutesCardHeader>
                <h3>Nome</h3>
                <h3>Operador</h3>
                <h3>Status</h3>
                <h3>Criação</h3>
                <h3>Progresso</h3>
                <h3>Ações</h3>
              </RoutesCardHeader>
              {routesByStatus.WAITING.map((item, index) => (
                <RouteItem
                  key={index}
                  data={item}
                  columns={6}
                  onViewRoute={() => {
                    setCurrentRoute(item);
                    setModalViewRoute(true);
                  }}
                />
              ))}
            </RoutesCard>
          </>

          <>
            <RouteSection>
              Em andamento
              <PointIcon
                size={30}
                fontWeight="500"
                fontSize="14"
                backgroundColor="#4A5E83"
                textColor="white"
                text={String(routesByStatus.STARTED.length)}
              />
            </RouteSection>
            <RoutesCard>
              <RoutesCardHeader columns={7}>
                <h3>Nome</h3>
                <h3>Operador</h3>
                <h3>Status</h3>
                <h3>Criação</h3>
                <h3>Início</h3>
                <h3>Progresso</h3>
                <h3>Ações</h3>
              </RoutesCardHeader>
              {routesByStatus.STARTED.map((item, index) => (
                <RouteItem
                  key={index}
                  data={item}
                  columns={7}
                  onViewRoute={() => {
                    setCurrentRoute(item);
                    setModalViewRoute(true);
                  }}
                />
              ))}
            </RoutesCard>
          </>

          <>
            <RouteSection>
              Encerradas
              <PointIcon
                size={30}
                fontWeight="500"
                fontSize="14"
                backgroundColor="#4A5E83"
                textColor="white"
                text={String(routesByStatus.COMPLETED.length)}
              />
            </RouteSection>
            <RoutesCard>
              <RoutesCardHeader columns={8}>
                <h3>Nome</h3>
                <h3>Operador</h3>
                <h3>Status</h3>
                <h3>Criação</h3>
                <h3>Início</h3>
                <h3>Finalização</h3>
                <h3>Progresso</h3>
                <h3>Ações</h3>
              </RoutesCardHeader>
              {routesByStatus.COMPLETED.map((item, index) => (
                <RouteItem
                  key={index}
                  data={item}
                  columns={8}
                  onViewRoute={() => {
                    setCurrentRoute(item);
                    setModalViewRoute(true);
                  }}
                />
              ))}
            </RoutesCard>
          </>

          <>
            <RouteSection>
              Canceladas
              <PointIcon
                size={30}
                fontWeight="500"
                fontSize="14"
                backgroundColor="#4A5E83"
                textColor="white"
                text={String(routesByStatus.CANCELED.length)}
              />
            </RouteSection>
            <RoutesCard>
              <RoutesCardHeader columns={7}>
                <h3>Nome</h3>
                <h3>Operador</h3>
                <h3>Status</h3>
                <h3>Criação</h3>
                <h3>Cancelamento</h3>
                <h3>Progresso</h3>
                <h3>Ações</h3>
              </RoutesCardHeader>
              {routesByStatus.CANCELED.map((item, index) => (
                <RouteItem
                  key={index}
                  data={item}
                  columns={7}
                  isHistory={false}
                  onViewRoute={() => {
                    setCurrentRoute(item);
                    setModalViewRoute(true);
                  }}
                />
              ))}
            </RoutesCard>
          </>
        </RoutesContainer>
      )}
      {switchSelected == 1 && (
        <HistoryContainer>
          <FilterContainer>
            <DateFilter
              filters={filters}
              dateField="endAt"
              onFilterDate={(values) => handleChangeDateFilter(values, "endAt")}
              clearDateFilter={() => setFilters([])}
              onClose={() => {}}
            />
            <Divider />

            <FilteredDescription>
              <h3>Exibindo:</h3>
              <br />
              <DateDescription>{getFilterDescription()}</DateDescription>

              <br />
              <FilterItem>
                <img src={routeOrange} alt="img img" />
                <div>
                  <h3>Rotas em andamento:</h3>
                  <h4>{tableRows.filter((x) => x.status === routeStatus.STARTED).length}</h4>
                </div>
              </FilterItem>
              <PointDivider />
              <FilterItem>
                <img src={routeSuccess} alt="img img" />
                <div>
                  <h3>Rotas concluídas:</h3>
                  <h4>{tableRows.filter((x) => x.status === routeStatus.COMPLETED).length}</h4>
                </div>
              </FilterItem>
              <PointDivider />
              <FilterItem>
                <img src={routeYellow} alt="img img" />
                <div>
                  <h3>Rotas parcialmente concluídas:</h3>
                  <h4>
                    {tableRows.filter((x) => x.status === routeStatus.PARTIALLY_COMPLETED).length}
                  </h4>
                </div>
              </FilterItem>
              <PointDivider />
              <FilterItem>
                <img src={routeRed} alt="img img" />
                <div>
                  <h3>Rotas canceladas:</h3>
                  <h4>{tableRows.filter((x) => x.status === routeStatus.CANCELED).length}</h4>
                </div>
              </FilterItem>
            </FilteredDescription>
          </FilterContainer>
          <HistoryCard>
            <HistoryHeader>
              <h3>Nome</h3>
              <h3>Operador</h3>
              <h3>Data de conclusão</h3>
              <h3>Progresso</h3>
              <h3>Ações</h3>
            </HistoryHeader>
            <HistoryList>
              {tableRows.slice(rangeOfList.init, rangeOfList.finish).map((item, index) => (
                <div key={item.id}>
                  <RouteItem
                    data={item}
                    columns={5}
                    isHistory={true}
                    onViewRoute={() => {
                      setCurrentRoute(item);
                      setModalViewRoute(true);
                    }}
                  />
                  {index != historyData.length - 1 && <DividerLight />}
                </div>
              ))}
            </HistoryList>
            <TableFooter>
              <h3>
                {rangeOfList.init} - {rangeOfList.finish}
              </h3>
              <div>
                <MdKeyboardArrowLeft
                  size={20}
                  cursor="pointer"
                  onClick={() => {
                    if (rangeOfList.finish == tableRows.length) {
                      const value = rangeOfList.finish - rangeOfList.init + perPage;

                      const newFinish = rangeOfList.init;

                      setRangeOfList({ init: rangeOfList.finish - value, finish: newFinish });
                    } else {
                      if (rangeOfList.init - perPage >= 0) {
                        setRangeOfList({
                          init: rangeOfList.init - perPage,
                          finish: rangeOfList.finish - perPage,
                        });
                      }
                    }
                  }}
                />
                <MdKeyboardArrowRight
                  size={20}
                  cursor="pointer"
                  onClick={() => {
                    if (tableRows.length > perPage) {
                      if (rangeOfList.finish + perPage <= tableRows.length) {
                        setRangeOfList({
                          init: rangeOfList.finish,
                          finish: rangeOfList.finish + perPage,
                        });
                      } else {
                        if (rangeOfList.finish != tableRows.length) {
                          const value =
                            rangeOfList.finish + (tableRows.length - rangeOfList.finish);

                          setRangeOfList({ init: rangeOfList.finish, finish: value });
                        }
                      }
                    }
                  }}
                />
              </div>
              <h3>{tableRows ? tableRows.length : 0} clientes</h3>
            </TableFooter>
          </HistoryCard>
        </HistoryContainer>
      )}
      <Modal
        isOpen={modalViewRoute}
        onRequestClose={() => setModalViewRoute(false)}
        overlayClassName="modal-overlay"
        className="modal-view-route"
        contentLabel="Example Modal"
      >
        <ModalViewRoute routeData={currentRoute} onClose={() => setModalViewRoute(false)} />
      </Modal>
    </Container>
  );
};

export default RoutesPage;
