import React from "react";

import { Container } from "./styles";

import DotLoader from "react-spinners/ClipLoader";

export type PageLoadingProps = {
  background?: string;
};

const PageLoading = ({ background }: PageLoadingProps) => {
  return (
    <Container background={background}>
      <DotLoader size={60} color={"var(--red)"} loading={true} speedMultiplier={1.5} />
    </Container>
  );
};

export default PageLoading;
