import React, { useEffect, useState } from "react";

import {
  CancelButton,
  Container,
  SearchBar,
  SearchButton,
  SearchContainer,
  TableColumns,
  TableHeader,
  TableFooter,
  TableRows,
  TableTypeButton,
  ClearSearch,
} from "./styles";

//Icons
import { TbArrowWaveRightUp } from "react-icons/tb";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FiSearch } from "react-icons/fi";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

//Custom components
import ReturnItem from "../TableItem/ReturnItem/ReturnItem";
// import DownloadButton from "../DownloadButton";

//MUI
import Menu from "@mui/material/Menu";

//Utils
import Modal from "react-modal";
import { Client } from "@models/Client";
import TableFilter from "@components/Filters/TableFilter/TableFilter";
import useTable from "@hooks/useTable";
import { Filter } from "@models/Filters";
import { Paging } from "@models/Paging";
import { tableConstants } from "@constants/table";
import ModalDetailsAdmin from "@components/Modal/ModalDetailsAdmin/ModalDetailsAdmin";

const perPage = tableConstants.perPage;

type TableType = "MAP" | "ADDRESS_NOT_FOUND" | "DIVERSE_LOCATION";

export type TableProps = {
  title: string;
  tableColumns: { headerName: string; field: string }[];
  tableRows: Client[];
  tableRowsTotal: number;
  paging: Paging;
  filterTypes: { headerName: string; field: string }[];
  filtersHighlights: { headerName: string; field: string }[];
  filters: Filter[];
  columns: number;
  tableType?: TableType;
  dateField: keyof Pick<Client, "lastReportDate"> | keyof Pick<Client, "updatedAt">;
  onChangePaging: (paging: Paging) => void;
  onChangeFilters: (newFiltersArray: Filter[]) => void;
  onChangeTableType?: (type: TableType) => void;
};

function Table({
  title,
  tableColumns,
  tableRows,
  tableRowsTotal,
  filters,
  paging,
  filterTypes,
  filtersHighlights,
  columns,
  tableType,
  dateField,
  onChangePaging,
  onChangeFilters,
  onChangeTableType,
}: TableProps) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const {
    search,
    setSearch,
    clearSearch,

    draftSearch,
    setDraftSearch,

    filteredTableData,
  } = useTable({
    filters: filters,
    paging: paging,
  });

  //Modal
  const [modalFullData, setModalFullData] = useState(false);
  const [modalData, setModalData] = useState<Client>(null);

  useEffect(() => {
    if (!draftSearch) {
      clearSearch();
    }
  }, [draftSearch]);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const searchTable = () => {
    onChangePaging({ offset: 0, limit: 25 });
    setSearch(draftSearch);
    handleMenuClose();
  };

  const clearFilters = () => {
    onChangeFilters([]);
    clearSearch();
    onChangePaging({ offset: 0, limit: 25 });
  };

  const tableData = {
    items: filters.length === 0 && !search ? tableRows : filteredTableData.items,
    totalItems: filters.length === 0 && !search ? tableRowsTotal : filteredTableData.totalItems,
  };

  return (
    <Container>
      <TableHeader>
        <div>
          {!tableType && (
            <>
              <TbArrowWaveRightUp size={26} color="var(--red)" />
              <h3>{title}</h3>
            </>
          )}
          {tableType && (
            <>
              <TableTypeButton
                active={tableType === "MAP" ? true : false}
                onClick={() => onChangeTableType("MAP")}
              >
                Clientes no mapa
              </TableTypeButton>
              <TableTypeButton
                active={tableType === "ADDRESS_NOT_FOUND" ? true : false}
                onClick={() => onChangeTableType("ADDRESS_NOT_FOUND")}
              >
                Endereços não encontrados
              </TableTypeButton>
              <TableTypeButton
                active={tableType === "DIVERSE_LOCATION" ? true : false}
                onClick={() => onChangeTableType("DIVERSE_LOCATION")}
              >
                Localidade diversa
              </TableTypeButton>
            </>
          )}
          <FiSearch onClick={handleMenuOpen} size={20} cursor="pointer" color="var(--grey2)" />
          <ClearSearch>
            {draftSearch && (
              <>
                {draftSearch}
                <AiOutlineCloseCircle
                  cursor="pointer"
                  onClick={() => {
                    clearSearch();
                  }}
                />
              </>
            )}
          </ClearSearch>
        </div>
        <div>
          <TableFilter
            filters={filters}
            filterTypes={filterTypes}
            filtersHighlights={filtersHighlights}
            dateField={dateField}
            onChangeFilters={(newFiltersArray) => {
              clearSearch();
              onChangeFilters(newFiltersArray);
            }}
            onClearFilters={() => clearFilters()}
          />
          {/* <DownloadButton tableColumns={tableColumns} tableRows={tableData} /> */}
        </div>
      </TableHeader>
      <TableColumns columns={columns}>
        {tableColumns && tableColumns.map((item, index) => <h3 key={index}>{item.headerName}</h3>)}
      </TableColumns>
      <TableRows>
        {tableData.items.map((item, index) => (
          <ReturnItem
            key={index}
            data={item}
            onClick={() => {
              setModalData(item);
              setModalFullData(true);
            }}
          />
        ))}
      </TableRows>
      <TableFooter>
        <h3>
          {paging.offset} - {paging.offset + perPage}
        </h3>
        <div>
          <MdKeyboardArrowLeft
            size={20}
            cursor="pointer"
            onClick={() => {
              if (paging.offset > 0) {
                if (paging.offset === tableData.items.length) {
                  const value = paging.limit - paging.offset + perPage;
                  const newLimit = paging.offset;
                  onChangePaging({ offset: paging.limit - value, limit: newLimit });
                } else {
                  if (paging.offset - perPage >= 0) {
                    onChangePaging({
                      offset: paging.offset - perPage,
                      limit: paging.limit - perPage,
                    });
                  }
                }
              }
            }}
          />
          <MdKeyboardArrowRight
            size={20}
            cursor="pointer"
            onClick={() => {
              if (paging.limit < tableData.totalItems) {
                if (paging.limit + perPage <= tableData.totalItems) {
                  onChangePaging({
                    offset: paging.limit,
                    limit: paging.limit + perPage,
                  });
                } else {
                  if (paging.limit != tableData.totalItems) {
                    const value = paging.limit + (tableData.totalItems - paging.limit);
                    onChangePaging({ offset: paging.limit, limit: value });
                  }
                }
              }
            }}
          />
        </div>
        <h3>{tableData ? tableData.totalItems : 0} clientes</h3>
      </TableFooter>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        PaperProps={{
          style: {
            width: 600,
            height: 40,
            maxHeight: 40,
            borderRadius: 20,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            overflow: "hidden",
          },
        }}
      >
        <SearchContainer
          onSubmit={(e) => {
            e.preventDefault();
            searchTable();
          }}
        >
          <FiSearch size={18} cursor="pointer" color="#626F87" strokeWidth="3" />
          <p>&#8288;</p>
          <SearchBar>
            <input
              name="draftSearch"
              onChange={(event) => setDraftSearch(event.target.value)}
              value={draftSearch}
              placeholder="Buscar..."
              autoFocus={true}
            />
          </SearchBar>
          <CancelButton onClick={handleMenuClose}>Cancelar</CancelButton>
          <SearchButton onClick={() => searchTable()}>Buscar</SearchButton>
        </SearchContainer>
      </Menu>

      <Modal
        isOpen={modalFullData}
        onRequestClose={() => setModalFullData(false)}
        overlayClassName="modal-overlay"
        className="modal-content-auto-width"
        contentLabel="Example Modal"
      >
        <ModalDetailsAdmin data={modalData} onClose={() => setModalFullData(false)} />
      </Modal>
    </Container>
  );
}

export default Table;
