import React, { useState } from "react";
import {
  AnalysisText,
  CancelButton,
  DetailsContainer,
  EditButtons,
  InfoHeader,
  SaveButton,
} from "../styles";
import { TbHammer, TbPencil } from "react-icons/tb";
import { Client } from "@models/Client";
import TextArea from "@components/Form/TextArea/TextArea";

type Props = {
  client: Client;
  inEdit: boolean;
  isAdmin: boolean;
  handleActiveEdit?: (section: string) => void;
  handleSaveClient?: (newClient: Partial<Client>) => void;
  onClose?: () => void;
};

const AnalysisSection = ({
  client,
  inEdit,
  isAdmin,
  handleActiveEdit,
  handleSaveClient,
  onClose,
}: Props) => {
  const defaultState = { analysis: client.analysis };

  const [newObj, setNewObj] = useState<Partial<Client>>(defaultState);

  const handleEdit = (field: string, value: string) => {
    let string = "";

    const splitText = value.split("\n");

    for (const text of splitText) {
      if (text === "") {
        // string = string + "<br/>"
      } else {
        string = string + text + "<br/>";
      }
    }

    setNewObj({
      ...newObj,
      [field]: string,
    });
  };

  const handleCancel = () => {
    setNewObj(defaultState);
    onClose();
  };

  return (
    <DetailsContainer>
      <InfoHeader>
        <div>
          <TbHammer size={22} color="var(--red)" style={{ transform: "rotate(-90deg)" }} />
          <p>Análise</p>
          {!inEdit && isAdmin && (
            <TbPencil
              onClick={() => handleActiveEdit("analysis")}
              size={22}
              style={{ strokeWidth: 1.5, cursor: "pointer" }}
            />
          )}
        </div>
        {inEdit && (
          <EditButtons>
            <CancelButton onClick={handleCancel}>Cancelar</CancelButton>
            <SaveButton onClick={() => handleSaveClient(newObj)}>Salvar edição</SaveButton>
          </EditButtons>
        )}
      </InfoHeader>
      {inEdit ? (
        <TextArea
          name="analysis"
          onChange={(value) => handleEdit("analysis", value)}
          defaultValue={client.analysis ? newObj.analysis.split("<br/>").join("\n") : ""}
        />
      ) : (
        <>
          {client.analysis &&
            client.analysis.length > 0 &&
            client.analysis
              .split("<br/>")
              .map((item, index) => <AnalysisText key={index}>{item}</AnalysisText>)}
        </>
      )}
    </DetailsContainer>
  );
};

export default AnalysisSection;
