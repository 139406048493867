import React from "react";

import {
  Container,
  Content,
  ActionsContent,
  CancelButton,
  ConfirmButton,
  ModalConfirmText,
} from "./styles";

import { IoWarningOutline } from "react-icons/io5";
import Loading from "@components/Loading/Loading";

type Props = {
  text: string;
  option: string;
  loading?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

const ModalConfirm = ({ text, option, loading, onCancel, onConfirm }: Props) => {
  return (
    <Container>
      <Content>
        <IoWarningOutline size={80} />
        <ModalConfirmText>{text}</ModalConfirmText>
      </Content>
      <ActionsContent>
        {loading ? (
          <Loading />
        ) : (
          <>
            <CancelButton onClick={onCancel}>
              <p>Voltar</p>
            </CancelButton>
            <ConfirmButton onClick={onConfirm}>
              <p>{option}</p>
            </ConfirmButton>
          </>
        )}
      </ActionsContent>
    </Container>
  );
};

export default ModalConfirm;
