import React from "react";
import { badges } from "@helpers/badges";

import { Container, ClientBadgeFake, ClientBadgeItem, ContainerModalDetails } from "./styles";
import { Client } from "@models/Client";

type Props = {
  client: Client;
  isModalDetails: boolean;
};

function ClientBadges({ client, isModalDetails }: Props) {
  if (isModalDetails) {
    return (
      <ContainerModalDetails>
        {badges.getClientBadges(client).map((item, index) => (
          <ClientBadgeItem key={index} src={item.path} title={item.title} />
        ))}
      </ContainerModalDetails>
    );
  } else {
    return (
      <Container>
        {badges.getClientBadges(client).length === 1 ? (
          <>
            <ClientBadgeFake />
            {badges.getClientBadges(client).map((item, index) => (
              <ClientBadgeItem key={index} src={item.path} title={item.title} />
            ))}
            <ClientBadgeFake />
          </>
        ) : (
          <>
            <ClientBadgeFake />
            {badges.getClientBadges(client).map((item, index) => (
              <ClientBadgeItem key={index} src={item.path} title={item.title} />
            ))}
          </>
        )}
      </Container>
    );
  }
}

export default ClientBadges;
