import { AttendClientStore } from "@domainStores/AttendClientStore";
import { create } from "zustand";

type InitialState = Pick<
  AttendClientStore,
  "dashboardCard" | "dashboardTable" | "dashboardTableTotal"
>;

const initialState: InitialState = {
  dashboardCard: [],
  dashboardTable: [],
  dashboardTableTotal: 0,
};

const attendClientsStore = create<AttendClientStore>((set) => ({
  ...initialState,
  updateDashboardCard: (newClients) =>
    set((state) => {
      return {
        ...state,
        dashboardCard: newClients,
      };
    }),
  updateDashboardTable(newClients, total) {
    set((state) => {
      return {
        ...state,
        dashboardTable: newClients,
        dashboardTableTotal: total,
      };
    });
  },
  clear: () => {
    set((state) => {
      return {
        ...state,
        ...initialState,
      };
    });
  },
}));

export default attendClientsStore;
