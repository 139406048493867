import { useEffect } from "react";

import { Timestamp } from "firebase/firestore";

import { useStore } from "zustand";
import returnClientStore from "@stores/returnClient";
import { WatchCardsReturnClientsUseCase } from "@dataUseCases/index";
import userStore from "@stores/user";
import { FirebaseReturnClientRepository } from "@dataRepositories/index";
import { Filter } from "@models/Filters";
import { datetimeHelper } from "@helpers/datetime";

type Props = {
  headerFilters: Filter[];
};

const dateNow = new Date();
const firstDay = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);
const defaultStartDate = Timestamp.fromDate(firstDay);
const defaultEndDate = Timestamp.fromDate(dateNow);

export default function useCardsReturns({ headerFilters }: Props) {
  const { dashboardReturns } = useStore(returnClientStore);

  const getReturnClients = (startDate: Timestamp, endDate: Timestamp) => {
    const useCase = new WatchCardsReturnClientsUseCase(
      userStore.getState(),
      new FirebaseReturnClientRepository(returnClientStore.getState())
    );

    useCase.execute({ startDate, endDate });
  };

  useEffect(() => {
    if (dashboardReturns.completed.length === 0) {
      const headerDateFilter = headerFilters.find((x) => x.type === "date");

      if (headerDateFilter && headerDateFilter?.dateValue) {
        const startDate = datetimeHelper.getTimestampFromFilter(
          headerDateFilter.dateValue.selectedStart
        );
        const endDate = datetimeHelper.getTimestampFromFilter(
          headerDateFilter.dateValue.selectedEnd
        );

        getReturnClients(startDate, endDate);
      } else {
        getReturnClients(defaultStartDate, defaultEndDate);
      }
    }
  }, [headerFilters]);

  return {
    dashboardReturns: dashboardReturns,
  };
}
