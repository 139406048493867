import styled from "styled-components";

export const Container = styled.div`
  /* display: flex; */
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 256px;

  padding: 20px 34px;

  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  height: 200vh;
  width: calc(100% - 256px);

  background: var(--background);
  /* z-index: 5000; */
`;

export const Header = styled.div`
  width: 100%;
  height: 67px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 20px;

  div {
    display: flex;

    align-items: center;

    gap: 20px;
  }

  h1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 38px;
    /* identical to box height */

    /* 1 */

    color: #4a5e83;
  }
`;

export const SwitchRoute = styled.div`
  display: flex;
  flex-direction: row;
  height: 50px;
  gap: 20px;
`;

export const SwitchButton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;

  color: #4a5e83;

  cursor: pointer;
  user-select: none;

  div {
    background: #4a5e83;
    height: 2px;
    width: 100%;
  }
`;

export const RoutesContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */

  gap: 20px;
  overflow-y: auto;
  max-height: 175vh;
`;

export const RouteSection = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
  gap: 20px;

  /* margin: 10px 0; */

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  /* 1 */

  color: #4a5e83;
`;

export const RoutesCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: #ffffff;
  /* shadowcard */

  box-shadow: 0px 4px 20px rgba(35, 54, 63, 0.05);
  border-radius: 20px;

  /* height: 180vh; */
  /* overflow: auto; */
`;

type RoutesCardHeaderProps = { columns?: number };
export const RoutesCardHeader = styled.div<RoutesCardHeaderProps>`
  display: grid;
  grid-template-columns: repeat(3, 1fr) 0.5fr ${(props) =>
      props.columns ? `repeat(${props.columns - 6}, 0.75fr)` : null} 1fr 0.5fr;
  min-height: 60px;
  width: 100%;

  padding: 0 10px;

  align-items: center;
  /* justify-content: center; */

  background: rgba(236, 242, 251, 0.5);
  border-radius: 10px;

  h3 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    margin-left: 10px;
    /* identical to box height */

    /* 2 */

    color: #808b9f;
  }
`;

export const HistoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  /* align-items: center; */

  gap: 20px;
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 20vw;
  /* height: 605px; */

  padding: 20px 10px;

  background: #ffffff;

  box-shadow: 0px 4px 20px rgba(35, 54, 63, 0.05);
  border-radius: 20px;
`;

export const Divider = styled.div`
  display: flex;
  width: 90%;
  height: 0px;

  margin: 10px 0;

  border: 1px solid #b4c1d5;
`;

export const DividerLight = styled.div`
  display: flex;
  width: 99%;
  height: 0px;

  opacity: 0.4;

  border: 1px solid #b4c1d5;
`;

export const DateDescription = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */

  /* 1 */

  color: #4a5e83;
`;

export const FilteredDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;

  width: 100%;
  padding: 20px;

  h3 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;

    /* 1 */

    color: #4a5e83;
  }

  h4 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;

    /* 1 */

    color: #4a5e83;
  }
`;

export const FilterItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 20px 0;

  gap: 10px;

  div {
    display: flex;
    flex-direction: column;
  }
`;

export const PointDivider = styled.div`
  display: flex;
  width: 90%;
  height: 0px;

  border: 1px dashed #b4c1d5;
`;

export const HistoryCard = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  width: 80vw;

  padding: 20px;

  background: #ffffff;

  box-shadow: 0px 4px 20px rgba(35, 54, 63, 0.05);
  border-radius: 20px;
`;

export const HistoryHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr) 0.5fr;
  min-height: 60px;
  width: 100%;

  padding: 0 10px;

  align-items: center;
  /* justify-content: center; */

  background: rgba(236, 242, 251, 0.5);
  border-radius: 10px;

  h3 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    margin-left: 10px;
    /* identical to box height */

    /* 2 */

    color: #808b9f;
  }
`;

export const HistoryList = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 100%;

  overflow-y: scroll;
`;

export const AdminRouteCard = styled.div`
  width: 100%;
  display: wrap;
  justify-content: center;
  align-items: center;
  background: #fbfcfc;
  margin-top: 20px;

  padding: 5px;

  border-radius: 8px;
  border: 0.5px solid #f8f9f9;
  box-shadow: 0 5px 5px 0px rgba(0, 0, 0, 0.2);
`;

export const AdminRouteCardContent = styled.div`
  display: flex;
  flex-direction: column;

  h4 {
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 8px;
  }

  li {
    margin-top: 5px;
    margin-left: 20px;
    font-weight: bold;
  }

  span {
    color: #9a4749;
  }
`;

export const ButtonToggleContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  margin-left: 40px;
  margin-right: 40px;
  /* align-items: center; */
`;

type ButtonToggleProps = { colorText: string; colorButton: string };
export const ButtonToggle = styled.div<ButtonToggleProps>`
  display: flex;
  justify-content: center;
  padding-bottom: 13px;

  button {
    font-size: 15px;
    border: none;
    border-radius: 10px;
    color: ${(props) => props.colorText};
    padding: 20px;

    line-height: 1.2;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 40px;

    background: ${(props) => props.colorButton};
    border: 2px solid #9a4749;
  }

  &:hover .button-toggle {
    cursor: pointer;
  }
`;

export const TextDeleteRoute = styled.h5`
  color: #9a4749;
  margin-top: 5px;
  text-align: center;
  padding: 10px;

  cursor: pointer;
`;

export const TableFooter = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 2px 20px;

  user-select: none;

  gap: 10px;

  color: #808b9f;

  div {
    display: flex;
    flex-direction: row;

    align-items: center;

    gap: 10px;
  }

  h3 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;

    color: #808b9f;
  }
`;
