import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
  StreetViewPanorama,
  GoogleMapProps,
} from "@react-google-maps/api";

//Styled components
import {
  Container,
  TableContainer,
  MapContainer,
  MapBar,
  MapBarHeader,
  NewMapButton,
  MapsContainer,
  MapDivRight,
  AlertDiv,
  AlertDivHeader,
} from "./styles";

import {
  CustomInfoWindow,
  InfoWindowTextContainer,
  HelpButton,
  MarkersLegend,
  LegendItem,
} from "../OperatorMap/styles";

//Icons
import { MdAddCircleOutline } from "react-icons/md";
import { BiHelpCircle } from "react-icons/bi";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import carIcon from "@assets/markers/car34.png";

import { markerIcons } from "@resources/markerIcons";

import Modal from "react-modal";

//MUI
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

//APIS
import * as Geocode from "react-geocode";

//JSON
import tableColumnsMap from "@resources/tableColumnsMap.json";

//Utils
import { alertErrorStyle } from "@resources/alertErrorStyle";
import { toast } from "react-toastify";
import { markersHelper } from "@helpers/markers";
import { arrayHelper } from "@helpers/array";
import useUser from "@hooks/useUser";
import useMaps from "@hooks/useMaps";
import PageLoading from "@components/PageLoading/PageLoading";
import useMapClients from "@hooks/useMapClients";
import ProfileButton from "@components/ProfileButton/ProfileButton";
import { Map } from "@models/Map";
import { clientAddressStatus, clientReturnStatus } from "@models/Client";
import MapCard from "./components/MapCard/MapCard";
import SpecieMapFilter from "./components/SpecieMapFilter/SpecieMapFilter";
import PointIcon from "@components/Icons/PointIcon/PointIcon";
import ModalHeader from "@components/Modal/ModalHeader/ModalHeader";
import OperatorLocation from "./components/OperatorLocation/OperatorLocation";
import useOperatorLocation from "@hooks/useOperatorLocation";
import ClientDetails from "./components/ClientDetails/ClientDetails";
import ModalDetailsAdmin from "@components/Modal/ModalDetailsAdmin/ModalDetailsAdmin";
import ModalFormMap from "@components/Modal/ModalFormMap/ModalFormMap";
import { SelectOption } from "@models/SelectOption";
import ModalDeleteMap from "@components/Modal/ModalDeleteMap/ModalDeleteMap";
import ModalReport from "@components/Modal/ModalReport/ModalReport";
import DataTable, { TableDataType } from "@components/DataTable/DataTable";
import { filtersHelper } from "@helpers/filters";
import { searchHelper } from "@helpers/search";
import { MapClient } from "@models/MapClient";

export type MapObject = {
  id: Map["id"];
  name: string;
  lastMapName?: string;
  cities: Map["cities"];
  clients: MapClient[];
  clientsBackup: MapClient[];
  calculate: boolean;
  visible: boolean;
  dataType: TableDataType;
};

type MapState = {
  center: { lat: number; lng: number };
  zoom: number;
};

type Alerts = {
  data: { quantity: number; title: string; dataType: TableDataType }[];
  total: number;
};

const alertOptions: {
  title: string;
  values: MapClient["addressStatus"][];
  dataType: TableDataType;
}[] = [
  {
    title: "endereços não encontrados",
    values: [clientAddressStatus.NOT_FOUND, clientAddressStatus.NOT_FOUND_OPERATOR],
    dataType: clientAddressStatus.NOT_FOUND,
  },
  {
    title: "localidades diversas",
    values: [clientAddressStatus.DIVERSE_LOCATION],
    dataType: clientAddressStatus.DIVERSE_LOCATION,
  },
];

const MapPage = () => {
  Geocode.setKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
  Geocode.setLanguage("pt-BR");

  const { isAdmin, isManager, user } = useUser();

  const { mapClients } = useMapClients();
  const { maps } = useMaps();

  const { operatorsLocation } = useOperatorLocation();

  const [loadingData, setLoadingData] = useState(true);

  //Clientes and Table
  const [filters, setFilters] = useState([]);
  const [search, setSearch] = useState("");

  //Client manager
  const [viewDetails, setViewDetails] = useState(false);
  const [currentClient, setCurrentClient] = useState<MapClient>();
  const [dataType, setDataType] = useState<TableDataType>(clientAddressStatus.FOUND);

  //Modal
  const [modalFormMap, setModalFormMap] = useState(false);
  const [modalFullData, setModalFullData] = useState(false);
  const [modalReport, setModalReport] = useState(false);

  //Map
  const [allMaps, setAllMaps] = useState<MapObject[]>([]);
  const [selectedMap, setSelectedMap] = useState<MapObject>();
  const [newMap, setNewMap] = useState(false);
  const [modalDeleteMap, setModalDeleteMap] = useState(false);
  const [mapToDelete, setMapToDelete] = useState<Pick<Map, "id" | "name">>();

  //Map
  // const [mapRef, setMapRef] = useState(null);
  const [alerts, setAlerts] = useState<Alerts>({ data: [], total: 0 });

  const [streetView, setStreetView] = useState(false);
  const [expandedAlert, setExpandedAlert] = useState(false);
  const [showInfoWindow, setShowInfoWindow] = useState({ status: false, client: null });
  const [markers, setMarkers] = useState(false);

  const [optionsCities, setOptionsCities] = useState<SelectOption[]>([]);
  const [legend, setLegend] = useState(false);
  const [clearEspeciesFilter, setClearEspeciesFilter] = useState(false);

  const [mapState, setMapState] = useState<MapState>({
    center: { lat: -8.06036, lng: -34.921713 },
    zoom: 10,
  });
  const mapRef = useRef<GoogleMap>(null);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  useEffect(() => {
    (async () => {
      if (mapClients && maps.length > 0) {
        const validCities: SelectOption[] = [];
        const arrayMaps: MapObject[] = [];

        const clientsInMap: MapClient[] = [];
        let allCities: Map["cities"] = [];

        for (const client of mapClients) {
          if (client.addressStatus === clientAddressStatus.FOUND) {
            clientsInMap.push(client);
          }
        }

        if (isAdmin) {
          for (const client of mapClients) {
            const mapDoc = maps.find((x) => x.id === client.mapId);

            if (!mapDoc) {
              const inValid = validCities.find((x) => x.value === client.cityMap);
              if (!inValid) {
                validCities.push({
                  value: client.cityMap,
                  label: client.cityMap,
                });
              }
            }
          }

          getAlerts();

          for (const map of maps) {
            const clientsOfMap = clientsInMap.filter((x) => x.mapId === map.id);

            arrayMaps.push({
              id: map.id,
              name: map.name,
              cities: map.cities,
              clients: clientsOfMap,
              clientsBackup: clientsOfMap,
              calculate: true,
              visible: true,
              dataType: clientAddressStatus.FOUND,
            });

            allCities = allCities.concat(map.cities);
          }

          arrayMaps.unshift({
            id: "0",
            name: "Geral",
            cities: allCities,
            clients: clientsInMap,
            clientsBackup: clientsInMap,
            calculate: true,
            visible: true,
            dataType: clientAddressStatus.FOUND,
          });
        }

        if (isManager && user?.mapsArray) {
          for (const map of maps) {
            const managerMap = user?.mapsArray?.find((x) => x.value === map.id);

            if (managerMap) {
              const clientes = clientsInMap.filter((x) => x.mapId === map.id);

              arrayMaps.push({
                id: "0",
                name: map.name,
                cities: map.cities,
                clients: clientes,
                clientsBackup: clientes,
                calculate: false,
                visible: true,
                dataType: clientAddressStatus.FOUND,
              });
            }
          }
        }

        for (const option of alertOptions) {
          arrayMaps.push({
            id: option.dataType,
            name: option.title,
            cities: [],
            clients: mapClients.filter((x) => option.values.includes(x.addressStatus)),
            clientsBackup: mapClients.filter((x) => option.values.includes(x.addressStatus)),
            calculate: true,
            visible: false,
            dataType: option.dataType,
          });
        }

        if (currentClient) {
          const updatedClient = clientsInMap.find((x) => x.cpf == currentClient.cpf);

          if (updatedClient) {
            setCurrentClient(updatedClient);
          } else {
            setCurrentClient(null);
          }
        }

        if (selectedMap) {
          const map = arrayMaps.find((x) => x.id === selectedMap.id);

          setSelectedMap({
            ...map,
            calculate: false,
          });
        } else {
          setSelectedMap({
            id: "0",
            name: "Geral",
            cities: allCities,
            clients: clientsInMap,
            clientsBackup: clientsInMap,
            calculate: false,
            visible: true,
            dataType: clientAddressStatus.FOUND,
          });
        }

        setOptionsCities(validCities);
        setAllMaps(arrayMaps);
        setMarkers(true);
        setLoadingData(false);
      }
    })();
  }, [mapClients, user, modalFormMap, modalDeleteMap, maps]);

  useEffect(() => {
    if (selectedMap && selectedMap.clients?.length > 0) {
      if (selectedMap.calculate && selectedMap.lastMapName !== selectedMap.name) {
        calculateMapCenter(selectedMap.clients, null, null);
      }
    }

    if (selectedMap && selectedMap.dataType === clientAddressStatus.FOUND) {
      setDataType(clientAddressStatus.FOUND);
    }
  }, [selectedMap]);

  const calculateMapCenter = (
    clients: MapObject["clients"],
    defaultCenter: MapState["center"],
    defaultZoom: MapState["zoom"]
  ) => {
    const arrayLat = [];
    const arrayLng = [];
    let valueBase = 0;

    if (defaultCenter && defaultZoom) {
      return setMapState({ center: defaultCenter, zoom: defaultZoom });
    }

    if (selectedMap.name !== "Geral") {
      valueBase = 10.5215082;
    } else {
      valueBase = 8.39715082;
    }

    for (const client of clients) {
      if (
        client.cityMap != "SP - TREMEMBÉ" &&
        client.cpf != "0" &&
        client.cpf != "11" &&
        client.cpf != "111"
      ) {
        arrayLat.push(client.lat);
        arrayLng.push(client.lng);
      }
    }

    const centerLat = (getMaxOfArray(arrayLat) + getMinOfArray(arrayLat)) / 2;
    const centerLng = (getMaxOfArray(arrayLng) + getMinOfArray(arrayLng)) / 2;

    const dif = getMaxOfArray(arrayLat) - getMinOfArray(arrayLat);

    if (clients.length > 1) {
      setMapState({ center: { lat: centerLat, lng: centerLng }, zoom: (valueBase / dif) * dif });
    } else {
      if (clients.length == 0) {
        const defaultCoords = { lat: -8.06036, lng: -34.921713 };

        setMapState({ center: defaultCoords, zoom: valueBase });
        return toast("Sem clientes no mapa", alertErrorStyle);
      } else {
        setMapState({ center: { lat: clients[0].lat, lng: clients[0].lng }, zoom: valueBase });
      }
    }
  };

  const onMapIdle = useCallback((map: GoogleMapProps) => {
    if (map) {
      const newCenter = map.center;
      const newZoom = map.zoom;

      setMapState({
        zoom: newZoom,
        center: {
          lat: typeof newCenter.lat === "number" ? newCenter.lat : newCenter.lat(),
          lng: typeof newCenter.lng === "number" ? newCenter.lng : newCenter.lng(),
        },
      });
    }
  }, []);

  const handleSelectedMap = (map: MapObject) => {
    if (selectedMap) {
      setSelectedMap((prev) => {
        if (prev.name) {
          return {
            ...map,
            calculate: true,
            lastMapName: prev && prev.name ? prev.name : map.name,
          };
        } else {
          return {
            ...map,
            calculate: true,
          };
        }
      });
    } else {
      setSelectedMap({
        ...map,
        calculate: true,
      });
    }
  };

  function getMaxOfArray(numArray: number[]) {
    return Math.max.apply(null, numArray);
  }

  function getMinOfArray(numArray: number[]) {
    return Math.min.apply(null, numArray);
  }

  const getAlerts = async () => {
    const arrayAlerts: Alerts["data"] = [];

    const arrayAddressNotFound = [];
    const arrayDiverseLocation = [];

    for (const client of mapClients) {
      if (alertOptions[0].values.includes(client.addressStatus)) {
        arrayAddressNotFound.push(client);
      } else {
        if (alertOptions[1].values.includes(client.addressStatus)) {
          arrayDiverseLocation.push(client);
        }
      }
    }

    if (arrayAddressNotFound.length > 0) {
      arrayAlerts.push({
        quantity: arrayAddressNotFound.length,
        title: alertOptions[0].title,
        dataType: alertOptions[0].dataType,
      });
    }

    if (arrayDiverseLocation.length > 0) {
      arrayAlerts.push({
        quantity: arrayDiverseLocation.length,
        title: alertOptions[1].title,
        dataType: alertOptions[1].dataType,
      });
    }

    setAlerts({
      data: arrayAlerts,
      total: arrayAddressNotFound.length + arrayDiverseLocation.length,
    });
  };

  if (loadingData || !isLoaded || !selectedMap) {
    return (
      <Container>
        <PageLoading />
      </Container>
    );
  }

  const filterByTable = () => {
    return filtersHelper.filterDataTable(selectedMap.clients, filters);
  };

  const clearFilters = () => {
    setFilters([]);
    setSearch("");

    setSelectedMap((prev) => ({
      ...prev,
      clients: prev.clientsBackup,
    }));
  };

  const baseTableData = filterByTable();

  const backupTableData = selectedMap.clientsBackup;

  //search use all clients
  const tableData = search
    ? searchHelper.searchTable(search, tableColumnsMap, selectedMap.clientsBackup)
    : baseTableData;

  const visibleMaps = allMaps.filter((x) => x.visible);

  return (
    <>
      <Container>
        {isLoaded && loadingData === false && mapState && selectedMap ? (
          <MapContainer>
            <MapBar>
              <MapBarHeader>
                <h3>Mapas</h3>
                {isAdmin && (
                  <NewMapButton
                    onClick={() => {
                      setNewMap(true);
                      setModalFormMap(true);
                    }}
                  >
                    <MdAddCircleOutline />
                    <p>Novo mapa</p>
                  </NewMapButton>
                )}
              </MapBarHeader>
              <MapsContainer>
                {visibleMaps.length > 0 &&
                  visibleMaps.map((item, index) => (
                    <MapCard
                      key={index}
                      map={item}
                      selected={selectedMap.id == item.id ? true : false}
                      onSelect={() => {
                        handleSelectedMap(item);
                      }}
                      onDelete={() => {
                        setMapToDelete({ id: item.id, name: item.name });
                        setModalDeleteMap(true);
                      }}
                      onEdit={() => {
                        handleSelectedMap(item);
                        setModalFormMap(true);
                      }}
                    />
                  ))}
              </MapsContainer>
            </MapBar>
            <GoogleMap
              ref={mapRef}
              mapContainerStyle={{ width: "100%", height: "100%" }}
              clickableIcons={false}
              center={mapState.center}
              zoom={mapState.zoom}
              onIdle={(props?: GoogleMapProps) => onMapIdle(props)}
              options={{
                disableDefaultUI: true,
              }}
            >
              <MapDivRight>
                <SpecieMapFilter
                  onChange={(selectedEspecies) => {
                    const newObj: MapObject = {
                      ...selectedMap,
                      calculate: false,
                      clients: selectedMap.clientsBackup.filter((x) => {
                        const isEspecial = selectedEspecies.find((x) => x.specie === "ESPECIAL");

                        if (isEspecial) {
                          if (x.specialIcon) {
                            return true;
                          }
                        }

                        if (x.returnStatus === clientReturnStatus.PREMIUM) {
                          if (
                            arrayHelper.isPropertyValue(
                              selectedEspecies,
                              "specie",
                              "premiumReturn"
                            ) &&
                            !x.specialIcon
                          ) {
                            return true;
                          } else {
                            return false;
                          }
                        }

                        if (x.returnStatus !== "COMPLETED") {
                          if (
                            arrayHelper.isPropertyValue(selectedEspecies, "specie", "return") &&
                            !x.specialIcon
                          ) {
                            return true;
                          } else {
                            return false;
                          }
                        }

                        if (
                          arrayHelper.isPropertyValue(selectedEspecies, "specie", x.specie) &&
                          !x.specialIcon
                        ) {
                          return true;
                        }

                        return false;
                      }),
                    };

                    handleSelectedMap(newObj);
                  }}
                  calculate={selectedMap ? selectedMap?.calculate : true}
                  clear={clearEspeciesFilter}
                />
                {isAdmin && (
                  <AlertDiv>
                    <AlertDivHeader onClick={() => setExpandedAlert(!expandedAlert)}>
                      <div>
                        <h3>Alertas</h3>
                        <PointIcon
                          text={String(alerts.total)}
                          backgroundColor="var(--red)"
                          size={30}
                          textColor="var(--white)"
                          fontSize="14"
                          fontWeight="400"
                        />
                      </div>

                      {expandedAlert ? (
                        <IoIosArrowUp cursor="pointer" />
                      ) : (
                        <IoIosArrowDown cursor="pointer" />
                      )}
                    </AlertDivHeader>
                    {expandedAlert &&
                      alerts.data.length > 0 &&
                      alerts.data.map((item, index) => (
                        <Accordion
                          key={index}
                          style={{
                            boxShadow: "none",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`${index}-content"`}
                            id={`${index}-header"`}
                            style={{
                              marginBottom: -10,
                              gap: 10,
                              padding: 0,
                            }}
                          >
                            <Typography
                              sx={{
                                width: "100%",
                                flexShrink: 10,
                                fontSize: 12,
                                fontFamily: "Roboto",
                              }}
                            >
                              {`${item.quantity} ${item.title}`}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            style={{ paddingLeft: 0 }}
                            onClick={() => {
                              const map = allMaps.find((x) => x.name === item.title);

                              if (map) {
                                handleSelectedMap(map);
                                setDataType(map.dataType);
                              }
                            }}
                          >
                            <h4>Corrigir</h4>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                  </AlertDiv>
                )}
                <ProfileButton arrowColor="white" />
              </MapDivRight>
              {!legend && (
                <HelpButton onClick={() => setLegend(true)}>
                  <BiHelpCircle size={40} cursor="pointer" />
                </HelpButton>
              )}
              {legend && (
                <MarkersLegend>
                  <ModalHeader title="Legenda" onClose={() => setLegend(false)} />
                  {markerIcons &&
                    markerIcons.map((item, index) => (
                      <LegendItem key={index}>
                        <img src={item.icon} alt="img img" />
                        <h1>{item.title}</h1>
                      </LegendItem>
                    ))}
                </MarkersLegend>
              )}
              {markers ? (
                selectedMap.clients.map((item) =>
                  item.lat ? (
                    <Marker
                      key={item.cpf}
                      position={{ lat: item.lat, lng: item[`lng`] }}
                      onClick={async () => {
                        setShowInfoWindow({ status: true, client: item.cpf });
                        setCurrentClient(item);
                        setViewDetails(true);
                      }}
                      options={{
                        icon: {
                          url: markersHelper.getMarkerIcons(item),
                          scaledSize: new window.google.maps.Size(34, 34),
                        },
                        optimized: true,
                        animation: item.specialIcon ? window.google.maps.Animation.BOUNCE : null,
                      }}
                    >
                      {showInfoWindow.status === true && showInfoWindow.client === item.cpf && (
                        <InfoWindow
                          position={{ lat: currentClient.lat, lng: currentClient[`lng`] }}
                          onCloseClick={() => setShowInfoWindow({ status: false, client: null })}
                        >
                          <CustomInfoWindow>
                            <InfoWindowTextContainer>
                              <h4>{currentClient.name}</h4>
                            </InfoWindowTextContainer>
                          </CustomInfoWindow>
                        </InfoWindow>
                      )}
                    </Marker>
                  ) : null
                )
              ) : (
                <></>
              )}
              {operatorsLocation &&
                operatorsLocation.map((item, index) => (
                  <Marker
                    position={{ lat: item.lat, lng: item[`lng`] }}
                    options={{
                      icon: carIcon,
                      optimized: true,
                    }}
                    key={index}
                  >
                    <InfoWindow position={{ lat: item.lat, lng: item[`lng`] }}>
                      <OperatorLocation operatorId={item.id} operatorName={item.name} />
                    </InfoWindow>
                  </Marker>
                ))}
              {streetView === true && (
                <StreetViewPanorama
                  options={{
                    position: { lat: currentClient.lat, lng: currentClient.lng },
                    visible: streetView,
                  }}
                />
              )}
              {currentClient && viewDetails && (
                <ClientDetails
                  data={currentClient}
                  onClose={() => {
                    setViewDetails(false);
                    setCurrentClient(null);
                    setShowInfoWindow({ status: false, client: null });
                    setSearch("");
                  }}
                  onStreetView={async () => {
                    if (streetView == true) {
                      setStreetView(false);
                      setTimeout(async () => {
                        setStreetView(true);
                      }, 500);
                    } else {
                      setStreetView(!streetView);
                    }
                  }}
                  onGenerateReport={() => {
                    setModalReport(true);
                  }}
                  onViewFullData={() => {
                    setModalFullData(true);
                  }}
                  onChangeSpecialIcon={() => {
                    const newObj: MapObject = {
                      ...selectedMap,
                      calculate: false,
                      clients: selectedMap.clientsBackup,
                      clientsBackup: selectedMap.clientsBackup,
                    };

                    handleSelectedMap(newObj);
                    setClearEspeciesFilter(!clearEspeciesFilter);
                  }}
                />
              )}
            </GoogleMap>
          </MapContainer>
        ) : (
          <Container></Container>
        )}
        <TableContainer>
          <DataTable
            title="Clientes"
            tableRows={tableData}
            tableRowsBackup={backupTableData}
            tableColumns={tableColumnsMap}
            filterTypes={tableColumnsMap}
            dateField="updatedAt"
            filtersHighlights={[{ field: "cidadeMap", headerName: "Cidade" }]}
            columns={6}
            tableDataType={dataType}
            perPage={12}
            onClick={(client) => {
              setShowInfoWindow({ status: true, client: client.id });
              setCurrentClient(client);
              setViewDetails(true);

              const newObj = {
                ...selectedMap,
                clients: [client],
              };

              handleSelectedMap(newObj);
            }}
            onChangeTableType={(newDataType) => {
              clearFilters();
              setDataType(newDataType);
              const map = allMaps.find((x) => x.dataType === newDataType);

              if (newDataType === clientAddressStatus.FOUND) {
                handleSelectedMap(allMaps[0]);
              } else {
                handleSelectedMap(map);
              }
            }}
            search={search}
            setSearch={setSearch}
            filters={filters}
            onChangeFilters={(newFiltersArray) => {
              setFilters(newFiltersArray);
            }}
            onClearFilters={() => clearFilters()}
          />
        </TableContainer>
      </Container>

      <Modal
        isOpen={modalFullData}
        onRequestClose={() => setModalFullData(false)}
        overlayClassName="modal-overlay"
        className="modal-content-auto-width"
        contentLabel="Example Modal"
      >
        <ModalDetailsAdmin data={currentClient} onClose={() => setModalFullData(false)} />
      </Modal>

      <Modal
        isOpen={modalFormMap}
        onRequestClose={() => {
          setModalFormMap(false);
          setNewMap(false);
        }}
        overlayClassName="modal-overlay"
        className="modal-content-auto-width"
        contentLabel="Example Modal"
      >
        <ModalFormMap
          title={newMap ? "Novo mapa" : "Editar mapa"}
          mapId={newMap ? null : selectedMap.id}
          data={
            newMap
              ? { name: "", cities: [] }
              : { name: selectedMap.name, cities: selectedMap.cities }
          }
          citiesOptions={optionsCities}
          onClose={() => {
            setModalFormMap(false);
            setNewMap(false);
          }}
        />
      </Modal>

      <Modal
        isOpen={modalDeleteMap}
        onRequestClose={() => {
          setModalDeleteMap(false);
        }}
        overlayClassName="modal-overlay"
        className="modal-content-auto-width"
        contentLabel="Example Modal"
      >
        <ModalDeleteMap
          mapId={mapToDelete?.id}
          mapName={mapToDelete?.name}
          onClose={() => {
            setModalDeleteMap(false);
            setMapToDelete(null);
            handleSelectedMap(visibleMaps[0]);
          }}
        />
      </Modal>

      <Modal
        isOpen={modalReport}
        onRequestClose={() => {
          setModalReport(false);
        }}
        overlayClassName="modal-overlay"
        className="modal-content-auto-width"
        contentLabel="Example Modal"
      >
        <ModalReport
          clientId={currentClient?.id}
          onClose={() => setModalReport(false)}
          onSave={() => {
            setViewDetails(false);
            setShowInfoWindow({ status: false, client: null });
            setCurrentClient(null);
            setModalReport(false);
          }}
        />
      </Modal>
    </>
  );
};

export default MapPage;
