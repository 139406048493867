import { create } from "zustand";
import { ReportStore } from "@domainStores/ReportStore";

type InitialState = Pick<ReportStore, "monthReports" | "operatorReturnReports">;

const initialState: InitialState = {
  monthReports: 0,
  operatorReturnReports: [],
};

const reportStore = create<ReportStore>((set) => ({
  ...initialState,
  updateMonthReports: (monthReports) =>
    set((state) => {
      return {
        ...state,
        monthReports: monthReports,
      };
    }),
  updateOperatorReturnReports: (newReports) =>
    set((state) => {
      return {
        ...state,
        operatorReturnReports: newReports,
      };
    }),
  clear: () =>
    set((state) => {
      return {
        ...state,
        ...initialState,
      };
    }),
}));

export default reportStore;
