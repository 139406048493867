import { firebase } from "@infra/firebase/config";
import { clientAddressStatus } from "@models/Client";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { fireabseAdapter } from "./firebaseAdapter";
import { MapClientStore } from "@domainStores/MapClientStore";
import DatabaseMapClientRepository from "@domainRepositories/DatabaseMapClientRepository";
import { MapClient } from "@models/MapClient";

export class FirebaseMapClientRepository implements DatabaseMapClientRepository {
  COLLECTION = "mapClients";

  constructor(private readonly mapClientStore: MapClientStore) {}

  watchMapClientsAdmin() {
    const consulta = query(collection(firebase.db, this.COLLECTION));
    onSnapshot(
      consulta,
      async (querySnapshot) => {
        const mappedDocs = fireabseAdapter.mapFirebaseDoc<MapClient>(querySnapshot);

        this.mapClientStore.updateMapClients(mappedDocs);
      },
      (error) => console.log("watchMapClientsAdmin error", error)
    );
  }

  watchMapClientsManager(mapsIds: string[]) {
    const consulta = query(
      collection(firebase.db, this.COLLECTION),
      where("addressStatus", "==", clientAddressStatus.FOUND),
      where("mapId", "in", mapsIds)
    );
    onSnapshot(
      consulta,
      async (querySnapshot) => {
        const mappedDocs = fireabseAdapter.mapFirebaseDoc<MapClient>(querySnapshot);

        this.mapClientStore.updateMapClients(mappedDocs);
      },
      (error) => console.log("watchMapClientsManager error", error)
    );
  }

  watchMapClientsOperator(userId: string, mapsIds: string[]) {
    const consulta = query(
      collection(firebase.db, this.COLLECTION),
      where("addressStatus", "==", clientAddressStatus.FOUND),
      where("mapId", "in", mapsIds)
    );
    onSnapshot(
      consulta,
      async (querySnapshot) => {
        const mappedDocs = fireabseAdapter.mapFirebaseDoc<MapClient>(querySnapshot);

        const filtered = mappedDocs.filter(
          (x) => x.returnOperatorId === "" || x.returnOperatorId === userId
        );

        this.mapClientStore.updateMapClients(filtered);
      },
      (error) => console.log("watchMapClientsOperator error", error)
    );
  }
}
