import { ReturnRepository } from "@domainRepositories/ReturnRepository";
import UseCase from "@domainUseCases/UseCase";
import { Client } from "@models/Client";

type Input = {
  clientId: Client["id"];
  operatorId: string;
};

type Output = Promise<Client>;

export class AssignReturnUseCase implements UseCase<Input, Output> {
  constructor(private readonly returnRepository: ReturnRepository) {}

  execute({ clientId, operatorId }: Input) {
    return this.returnRepository.assign(clientId, operatorId);
  }
}
