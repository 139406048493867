import React, { useEffect, useState } from "react";
import { CancelButton, DetailsContainer, EditButtons, InfoHeader, SaveButton } from "../styles";
import { TbBriefcase, TbPencil } from "react-icons/tb";
import { ReportDescription, ReportHeader, ReportsContent, ResultText } from "./styles";
import { datetimeHelper } from "@helpers/datetime";
import { Report, ReportResult, reportResultOptions } from "@models/Report";

import Select from "react-select";

import { Accordion } from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FiTrash2 } from "react-icons/fi";
import TextInput from "@components/Form/TextInput/TextInput";
import { arrayHelper } from "@helpers/array";
import { customSelectStyle } from "@constants/customSelectStyle";

const reportResultExceptions: ReportResult[] = [
  reportResultOptions.RECOMMENDATION_SIGNED,
  reportResultOptions.PENDENCY,
];

const validResultOptions: { label: ReportResult; value: ReportResult }[] = Object.values(
  reportResultOptions
)
  .filter((x): x is ReportResult => !reportResultExceptions.includes(x))
  .map((x) => ({ label: x, value: x }));

type Props = {
  clientReports: Report[];
  inEdit: boolean;
  isAdmin: boolean;
  isManager: boolean;
  handleActiveEdit?: (section: string) => void;
  handleSaveReports?: (reports: Report[]) => void;
  handleDeleteReport?: (reportId: Report["id"]) => void;
  onClose?: () => void;
};

const ReportsSection = ({
  inEdit,
  isAdmin,
  isManager,
  clientReports,
  handleActiveEdit,
  handleSaveReports,
  handleDeleteReport,
  onClose,
}: Props) => {
  const [newReports, setNewReports] = useState<Report[]>(clientReports);

  const handleCancel = () => {
    setNewReports(clientReports);
    onClose();
  };

  const handleEditReportResult = async (
    selectedOption: { label: ReportResult; value: ReportResult } | null,
    index: number
  ) => {
    if (!selectedOption) return;

    const newData: Report[] = arrayHelper.insertObjectOnArray<Report>(newReports, index, {
      ...newReports[index],
      result: selectedOption.value,
    });

    setNewReports(newData);
  };

  const handleEditReportDescription = async (description: string, index: number) => {
    const newData: Report[] = arrayHelper.insertObjectOnArray<Report>(newReports, index, {
      ...newReports[index],
      description: description,
    });

    setNewReports(newData);
  };

  useEffect(() => {
    setNewReports(clientReports);
  }, [clientReports]);

  return (
    <DetailsContainer>
      <InfoHeader>
        <div>
          <TbBriefcase size={22} color="var(--red)" />
          <p>Atendimentos</p>
          {!inEdit && (isAdmin || isManager) && (
            <TbPencil
              onClick={() => handleActiveEdit("reports")}
              size={22}
              style={{ strokeWidth: 1.5, cursor: "pointer" }}
            />
          )}
        </div>
        {inEdit && (
          <EditButtons>
            <CancelButton onClick={handleCancel}>Cancelar</CancelButton>
            <SaveButton onClick={() => handleSaveReports(newReports)}>Salvar edição</SaveButton>
          </EditButtons>
        )}
      </InfoHeader>
      <ReportsContent>
        {newReports &&
          newReports.map((report, index) => (
            <Accordion
              defaultExpanded
              key={index}
              sx={{
                boxShadow: "none",
                borderBottom: "1px solid var(--grey1)",
                gap: 0,
                margin: 0,
                marginBottom: 0,
                marginTop: 0,
                padding: 0,
                paddingBottom: 1,
              }}
              style={{
                margin: 0,
                marginBottom: 0,
                marginTop: 0,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  backgroundColor: "transparent",
                  flexDirection: "row",
                  display: "flex",
                }}
                color="white"
              >
                <ReportHeader>
                  <p>
                    {`${datetimeHelper.getFullDate(report.createdAt)} - `}
                    <span>{report.operatorName}</span>
                  </p>
                  {inEdit && report.result !== reportResultOptions.RECOMMENDATION_SIGNED ? (
                    <>
                      <Select
                        isClearable={false}
                        isSearchable={true}
                        defaultValue={
                          validResultOptions.find((opt) => opt.value === report.result) ?? {
                            label: report.result,
                            value: report.result,
                          }
                        }
                        closeMenuOnSelect={true}
                        options={validResultOptions}
                        isMulti={false}
                        styles={customSelectStyle}
                        onChange={(item) => handleEditReportResult(item, index)}
                      />
                      <FiTrash2
                        onClick={() => handleDeleteReport(report.id)}
                        color="var(--red)"
                        size={20}
                        style={{ strokeWidth: 1.5, cursor: "pointer" }}
                      />
                    </>
                  ) : (
                    <ResultText>{report.result}</ResultText>
                  )}
                </ReportHeader>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  padding: "5px 15px",
                }}
              >
                <ReportDescription>
                  {inEdit ? (
                    <TextInput
                      name="description"
                      defaultValue={report.description}
                      onChange={(value) => handleEditReportDescription(value, index)}
                    />
                  ) : (
                    <p>{report.description}</p>
                  )}
                </ReportDescription>
              </AccordionDetails>
            </Accordion>
          ))}
      </ReportsContent>
    </DetailsContainer>
  );
};

export default ReportsSection;
