import styled from "styled-components";

export const Container = styled.div`
  /* display: flex; */
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 256px;

  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  height: 200vh;
  width: calc(100% - 256px);

  background: var(--background);
  /* z-index: 5000; */
`;

export const MapContainer = styled.div`
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 3fr;
`;

export const MapBar = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;

  padding: 20px 10px;
  border-top-left-radius: 30px;

  background: var(--background);
  box-shadow: 4px -2px 10px rgba(99, 99, 99, 0.15);
  z-index: 10;
`;

export const MapBarHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  user-select: none;

  padding: 0 23px;

  height: 77px;
  width: 100%;

  h3 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 38px;
    /* identical to box height */

    color: #4d6289;
  }
`;

export const NewMapButton = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;

  color: var(--blue3);
  fill: var(--blue3);

  cursor: pointer;

  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    color: var(--blue3);
  }
`;

export const MapsContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0 20px;

  gap: 15px;

  height: 100%;
  overflow-y: auto;
`;

export const TableContainer = styled.div`
  height: 100vh;
  width: 100%;

  padding: 20px;
`;

export const MapDivRight = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  box-sizing: border-box;
  border: 1px solid transparent;
  gap: 20px;
  background: "transparent";
  /* width: 300px; */
  /* height: 200px; */
  padding: 12px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.3);
  position: absolute;
  right: 2%;
  margin-top: 10px;
`;

export const MapAddressDiv = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border: 1px solid transparent;
  background-color: rgba(255, 255, 255, 1);
  width: 70%;
  padding: 15px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 68%;
  left: 10px;
  margin-top: 10px;
`;

export const MapAddressHeader = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  margin-bottom: 10px;

  h3 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;

    color: #4d6289;
  }

  h4 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    /* identical to box height, or 200% */

    text-decoration-line: underline;

    cursor: pointer;
    user-select: none;

    /* DE6161 */

    color: #de6161;
  }

  div {
    display: flex;

    align-items: center;
    gap: 10px;
  }
`;

export const AlertDiv = styled.div`
  display: flex;
  flex-direction: column;

  min-width: 260px;

  background: var(--white);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  padding: 15px 20px 10px 20px;

  border-radius: 20px;
  /* box-sizing: border-box;
    border: 1px solid transparent;
    background-color: rgba(255, 255, 255, 255);
    width: 300px;
    padding: 12px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.3);
    position: absolute;
    margin-left: 10px;
    margin-top: 8%; */

  h4 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */

    text-decoration-line: underline;

    padding: 5px 0;

    /* DE6161 */

    cursor: pointer;
    user-select: none;

    color: #de6161;
  }
`;

export const AlertDivHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 10px;
  /* height: 60px; */

  cursor: pointer;
  user-select: none;

  color: #4d6289;

  h3 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    color: #4d6289;
  }

  div {
    display: flex;
    align-items: center;

    gap: 10px;
  }
`;
