import { useState } from "react";
import { firebase } from "@infra/firebase/config";
import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { FirebaseError } from "firebase/app";
import { User, userRoles } from "@models/User";
import { useNavigate } from "react-router-dom";
import showToast from "../helpers/showToast";
import { useStore } from "zustand";
import { ClearStoresUseCase, GetUserUseCase } from "@dataUseCases/index";
import { HttpUserRepository } from "@dataRepositories/index";
import { api } from "@infra/api";

import attendClientsStore from "@stores/attendClient";
import mapClientStore from "@stores/mapClient";
import mapStore from "@stores/map";
import reportStore from "@stores/report";
import returnClientStore from "@stores/returnClient";
import routeStore from "@stores/route";
import userStore from "@stores/user";

type CacheLogin = { email: string; login: string };

type Cache = {
  logins: CacheLogin[];
};

export default function useUser() {
  const navigate = useNavigate();

  const { user, updateUser, updateFirebaseUser } = useStore(userStore);

  const [loading, setLoading] = useState(false);

  const isAdmin = user?.role === userRoles.admin || user?.role === userRoles.root ? true : false;
  const isManager = user?.role === userRoles.manager ? true : false;
  const isOperator = user?.role === userRoles.operator ? true : false;

  const checkUserLogin = () => {
    onAuthStateChanged(firebase.auth, async (user) => {
      if (user) {
        updateFirebaseUser(user);
        await getUserData(user.uid);
      } else {
        updateUser(null);
      }
    });
  };

  const loginUser = async (credential: string, password: string) => {
    setLoading(true);

    let email = null;

    if (credential.match(/@/)) {
      email = credential;
    } else {
      const cachedEmail = await getEmailByCachedLogin(credential);

      if (cachedEmail) {
        email = cachedEmail;
      }
    }

    if (email) {
      await firebaseLogin(email, password)
        .then(async (userCredential) => {
          const user = userCredential.user;

          updateFirebaseUser(user);

          await getUserData(user.uid);
        })
        .catch((error: FirebaseError) => {
          if (error.code === "auth/user-disabled") {
            showToast(`Conta desativada`, "error");
          } else {
            showToast(`Usuário ou senha incorretos!`, "error");
          }
        });
    } else {
      showToast("Por favor use o email", "error");
    }

    setLoading(false);
    return;
  };

  const firebaseLogin = async (email: string, password: string) => {
    return signInWithEmailAndPassword(firebase.auth, email, password).then(
      (userCredential) => userCredential
    );
  };

  const getUserData = async (userId: string) => {
    return fetchUser(userId).then((userData) => {
      addDataIntoCache(userData.email, userData.login);

      redirectUser(userData.role);
    });
  };

  const fetchUser = async (userId: string) => {
    const useCase = new GetUserUseCase(new HttpUserRepository(api));

    return useCase
      .execute({ id: userId })
      .then((userData) => {
        updateUser(userData);

        return userData;
      })
      .catch(() => {
        updateUser(null);
        return null;
      });
  };

  const redirectUser = (role: User["role"]) => {
    if (role === userRoles.operator) {
      navigate("/map");
    } else {
      navigate("/dashboard");
    }
  };

  const logoutUser = async () => {
    updateUser(null);
    const useCase = new ClearStoresUseCase(
      attendClientsStore.getState(),
      mapClientStore.getState(),
      mapStore.getState(),
      reportStore.getState(),
      returnClientStore.getState(),
      routeStore.getState(),
      userStore.getState()
    );

    useCase.execute();
    await signOut(firebase.auth);
  };

  const addDataIntoCache = (email: string, login: string) => {
    let savedData: Cache = { logins: [] };
    try {
      const logins = window.localStorage.getItem("logins");

      if (logins) {
        savedData = JSON.parse(logins);

        const result = savedData.logins.find((x) => x.email === email);

        if (result) {
          if (result.login !== login) {
            const array = savedData.logins;

            const newArray = array.filter((x) => x.email !== email);

            newArray.push({
              email: email,
              login: login,
            });

            const data = {
              logins: newArray,
            };

            window.localStorage.setItem("logins", JSON.stringify(data));
          }
        } else {
          const array = savedData.logins;

          array.push({
            email: email,
            login: login,
          });

          const data = {
            logins: array,
          };

          window.localStorage.setItem("logins", JSON.stringify(data));
        }
      }
    } catch {
      const data = {
        logins: [
          {
            email: email,
            login: login,
          },
        ],
      };

      window.localStorage.setItem("logins", JSON.stringify(data));
    }
  };

  const getEmailByCachedLogin = async (login: string) => {
    try {
      const logins = window.localStorage.getItem("logins");

      if (logins) {
        const savedData: Cache = JSON.parse(logins);

        const result = savedData.logins.find((x) => x.login === login);

        if (result) {
          return result.email;
        }
      }

      return null;
    } catch {
      return null;
    }
  };

  return {
    loading,
    user,
    isAdmin,
    isManager,
    isOperator,
    fetchUser,
    loginUser,
    logoutUser,
    checkUserLogin,
  };
}
