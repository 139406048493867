import { Timestamp } from "firebase/firestore";
import { Location } from "./Location";
import { RoutePoint } from "./RoutePoint";

export const routeStatus = {
  WAITING: "WAITING",
  STARTED: "STARTED",
  PARTIALLY_COMPLETED: "PARTIALLY_COMPLETED",
  COMPLETED: "COMPLETED",
  CANCELED: "CANCELED",
} as const;

export const routeStatusNames = {
  WAITING: "Aguardando",
  STARTED: "Em andamento",
  PARTIALLY_COMPLETED: "Parcialmente concluída",
  COMPLETED: "Concluída",
  CANCELED: "Cancelada",
} as const;

export interface Route {
  id: string;
  name: string;
  status: keyof typeof routeStatus;
  points: RoutePoint[];
  operatorId: string;
  operatorName: string;
  operatorPosition: Location & { updatedAt: Timestamp };
  createdAt: Timestamp;
  updatedAt: Timestamp;
  startAt: Timestamp | "";
  endAt: Timestamp | "";
}
