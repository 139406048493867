import { firebase } from "@infra/firebase/config";
import { Client, clientReportStatus } from "@models/Client";
import { Paging } from "@models/Paging";
import {
  collection,
  getCountFromServer,
  limit,
  onSnapshot,
  orderBy,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import { fireabseAdapter } from "./firebaseAdapter";
import { AttendClientStore } from "@domainStores/AttendClientStore";
import DatabaseAttendClientRepository from "@domainRepositories/DatabaseAttendClientRepository";

export class FirebaseAttendClientRepository implements DatabaseAttendClientRepository {
  constructor(private readonly attendClientStore: AttendClientStore) {}

  watchAttendClientsByDateAdmin(startDate: Timestamp, endDate: Timestamp) {
    const consulta = query(
      collection(firebase.db, "clients"),
      where("lastReportDate", ">=", startDate),
      where("lastReportDate", "<=", endDate),
      where("reportStatus", "==", clientReportStatus.ATTEND),
      orderBy("lastReportDate", "desc")
    );

    onSnapshot(
      consulta,
      async (querySnapshot) => {
        const mappedDocs = fireabseAdapter.mapFirebaseDoc<Client>(querySnapshot);
        this.attendClientStore.updateDashboardCard(mappedDocs);
      },
      (error) => console.log("watchAttendClientsByDateAdmin error", error)
    );
  }

  watchAttendClientsByDateManager(
    startDate: Timestamp,
    endDate: Timestamp,
    operatorsIds: string[]
  ) {
    const consulta = query(
      collection(firebase.db, "clients"),
      orderBy("lastReportDate", "desc"),
      where("lastReportDate", ">=", startDate),
      where("lastReportDate", "<=", endDate),
      where("reportStatus", "==", clientReportStatus.ATTEND),
      where("operatorId", "in", operatorsIds)
    );

    onSnapshot(
      consulta,
      async (querySnapshot) => {
        const mappedDocs = fireabseAdapter.mapFirebaseDoc<Client>(querySnapshot);
        this.attendClientStore.updateDashboardCard(mappedDocs);
      },
      (error) => console.log("watchAttendClientsByDateManager error", error)
    );
  }

  watchAttendClientsAdmin(paging: Paging) {
    const consulta = query(
      collection(firebase.db, "clients"),
      orderBy("lastReportDate", "desc"),
      where("reportStatus", "==", clientReportStatus.ATTEND),
      limit(paging.limit)
    );

    onSnapshot(
      consulta,
      async (querySnapshot) => {
        const mappedDocs = fireabseAdapter.mapFirebaseDoc<Client>(querySnapshot);

        const allDocsCount = await getCountFromServer(
          query(
            collection(firebase.db, "clients"),
            where("reportStatus", "==", clientReportStatus.ATTEND)
          )
        );
        this.attendClientStore.updateDashboardTable(mappedDocs, allDocsCount.data().count);
      },
      (error) => console.log("watchAttendClientsAdmin error", error)
    );
  }

  watchAttendClientsManager(paging: Paging, operatorsIds: string[]) {
    const consulta = query(
      collection(firebase.db, "clients"),
      orderBy("lastReportDate", "desc"),
      where("reportStatus", "==", clientReportStatus.ATTEND),
      where("operatorId", "in", operatorsIds),
      limit(paging.limit)
    );

    onSnapshot(
      consulta,
      async (querySnapshot) => {
        const mappedDocs = fireabseAdapter.mapFirebaseDoc<Client>(querySnapshot);

        const allDocsCount = await getCountFromServer(
          query(
            collection(firebase.db, "clients"),
            where("reportStatus", "==", clientReportStatus.ATTEND),
            where("operatorId", "in", operatorsIds)
          )
        );
        this.attendClientStore.updateDashboardTable(mappedDocs, allDocsCount.data().count);
      },
      (error) => console.log("watchAttendClientsManager error", error)
    );
  }
}
