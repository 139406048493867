import { Filter } from "@models/Filters";
import { arrayHelper } from "./array";
import { datetimeHelper } from "./datetime";
import { Timestamp } from "firebase/firestore";

export const filtersHelper = {
  filterDataTable,
  filterCalendar,
  getFilterOptions,
  getDefaultValues,
};

function filterDataTable<T>(array: T[], filters: Filter[]) {
  let baseData = array;

  for (const filter of filters) {
    if (filter.field === "log" || filter.field === "logOperator") {
      baseData = filterLogs(baseData, filter);
    } else {
      if (filter.type === "string") {
        const values = arrayHelper.reduceToSimple(filter.values, "value");

        baseData = baseData.filter((x) => values.includes(x[filter.field]));
      }

      if (filter.type === "date" && filter.dateValue) {
        baseData = filterCalendar<T>(baseData, filter);
      }
    }
  }

  return baseData;
}

function filterCalendar<T>(data: T[], filter: Filter) {
  const { field } = filter;
  const { selectedStart, selectedEnd } = filter.dateValue;

  const startTime = new Date(
    `${selectedStart.month}/${selectedStart.day}/${selectedStart.year}`
  ).getTime();
  const endTime = selectedEnd.day
    ? new Date(`${selectedEnd.month}/${selectedEnd.day}/${selectedEnd.year}`).getTime()
    : startTime + 86400 * 1000;

  const arrayValues = [];
  const newArray = [];

  for (let i = startTime; i <= endTime; i = i + 86400 * 1000) {
    const date = new Date(i);

    arrayValues.push(datetimeHelper.getDate(Timestamp.fromDate(date)));
  }

  for (const date of arrayValues) {
    for (const item of data) {
      if (item[field]) {
        if (datetimeHelper.getDate(item[field]) === date) {
          newArray.push(item);
        }
      }
    }
  }

  return newArray;
}

function filterLogs(data, filter) {
  const newArrayDocs = [];

  for (const log of data) {
    if (log.logs) {
      const newLogs = [];

      for (const text of log.logs) {
        for (const item of filter.values) {
          if (
            text
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toUpperCase()
              .includes(
                item.value
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toUpperCase()
              ) ||
            item.value
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toUpperCase()
              .includes(
                text
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toUpperCase()
              )
          ) {
            newLogs.push(text);
          }
        }
      }

      newArrayDocs.push({
        ...log,
        logs: newLogs,
      });
    }
  }

  return newArrayDocs;
}

function getFilterOptions(array, field, filters) {
  let baseData = array;

  for (const filter of filters) {
    if (filter.type === "string" && filter.field != field) {
      const values = arrayHelper.reduceToSimple(filter.values, "value");

      if (values.length > 0) {
        baseData = baseData.filter((x) => values.includes(x[filter.field]));
      }
    }

    if (filter.type === "date" && filter.values) {
      baseData = filterCalendar(baseData, filter);
    }
  }

  const options = [];

  for (const item of baseData) {
    const exists = options.find((x) => x.value === item[field]);

    if (!exists) {
      options.push({ label: item[field], value: item[field] });
    }
  }

  return options;
}

function getDefaultValues(field, filters) {
  if (!filters) {
    return;
  }
  const find = filters.find((x) => x.field === field);

  if (find) {
    return find.values;
  }

  return null;
}
