import { Client } from "@models/Client";
import { Timestamp } from "firebase/firestore";

export type AlgoliaClient = Client & {
  lastReportDate: string;
  createdAt: string;
  updatedAt: string;
};

export const algoliaAdapter = {
  mapClients,
};

function mapClients(clients: AlgoliaClient[]) {
  return clients.map(
    (item) =>
      new Client({
        id: item.id,
        cpf: item.cpf,
        name: item.name,
        email: item.email,
        motherName: item.motherName,

        age: item.age,
        birthDate: item.birthDate,
        birth65Time: item.birth65Time,

        cityMap: item.cityMap,
        mapId: item.mapId,
        lat: item.lat,
        lng: item.lat,

        isSelected: item.isSelected,
        status: item.status,
        specie: item.specie,
        specialIcon: item.specialIcon,

        validAddress: item.validAddress,
        addressStatus: item.addressStatus,
        addresses: item.addresses,

        phones: item.phones,
        telephone: item.telephone,

        processes: item.processes,
        analysis: item.analysis,
        rejection: item.rejection,

        returnStatus: item.returnStatus,
        returnExpireDate: item.returnExpireDate,
        returnOperatorId: item.returnOperatorId,
        returnOperatorName: item.returnOperatorName,
        hasReturn: item.hasReturn,

        operatorId: item.operatorId,
        operatorName: item.operatorName,

        reportStatus: item.reportStatus,
        lastReportId: item.lastReportId,
        lastReportResult: item.lastReportResult,
        lastReportDate: Timestamp.fromMillis(Number(item.lastReportDate)),
        currentRouteId: item.currentRouteId,

        createdAt: Timestamp.fromMillis(Number(item.createdAt)),
        updatedAt: Timestamp.fromMillis(Number(item.updatedAt)),
      })
  );
}
