import { firebase } from "@infra/firebase/config";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { fireabseAdapter } from "./firebaseAdapter";
import { MapStore } from "@domainStores/MapStore";
import { DatabaseMapRepository } from "@domainRepositories/DatabaseMapRepository";
import { Map } from "@models/Map";

export class FirebaseMapRepository implements DatabaseMapRepository {
  COLLECTION = "maps";

  constructor(private readonly mapStore: MapStore) {}

  watchMapsAdmin() {
    const consulta = query(collection(firebase.db, this.COLLECTION));
    onSnapshot(
      consulta,
      async (querySnapshot) => {
        const mappedDocs = fireabseAdapter.mapFirebaseDoc<Map>(querySnapshot);

        this.mapStore.updateMaps(mappedDocs);
      },
      (error) => console.log("watchMapsAdmin error", error)
    );
  }

  watchMapsManager(mapsIds: string[]) {
    const consulta = query(collection(firebase.db, this.COLLECTION), where("id", "in", mapsIds));
    onSnapshot(
      consulta,
      async (querySnapshot) => {
        const mappedDocs = fireabseAdapter.mapFirebaseDoc<Map>(querySnapshot);

        this.mapStore.updateMaps(mappedDocs);
      },
      (error) => console.log("watchMapsManager error", error)
    );
  }
}
