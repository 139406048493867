export const customSelectStyle = {
  container: (base) => ({
    ...base,
    borderRadius: 40,
  }),
  control: (base) => ({
    ...base,
    padding: 3,
    borderRadius: 40,
  }),
  multiValue: (base) => ({
    ...base,
    borderRadius: 40,
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: 240,
  }),
  menu: () => ({
    background: "transparent",
    marginTop: 10,
  }),
};
