import React, { useState } from "react";
import {
  AddressForm,
  AddressHeader,
  AddressItem,
  AddressText,
  CancelButton,
  DetailsContainer,
  EditButtons,
  InfoHeader,
  SaveButton,
} from "../styles";
import { FiMapPin } from "react-icons/fi";
import { Client } from "@models/Client";
import { TbPencil } from "react-icons/tb";
import { RadioButtonChecked, RadioButtonUncheckedOutlined } from "@mui/icons-material";
import { addressHelper } from "@helpers/address";
import TextInput from "@components/Form/TextInput/TextInput";
import { addressesNames } from "@constants/addressesNames";

type Props = {
  client: Client;
  inEdit: boolean;
  isAdmin: boolean;
  handleActiveEdit?: (section: string) => void;
  handleSaveClient?: (newClient: Partial<Client>) => void;
  onClose?: () => void;
};

const AddressSection = ({
  client,
  inEdit,
  isAdmin,
  handleActiveEdit,
  handleSaveClient,
  onClose,
}: Props) => {
  const defaultState = { addresses: client.addresses, validAddress: client.validAddress };

  const [newObj, setNewObj] = useState<Partial<Client>>(defaultState);

  const handleEditAddress = (
    type: keyof Client["addresses"],
    field: keyof Client["addresses"]["S1"],
    value: string
  ) => {
    const newData = {
      ...newObj,
      addresses: {
        ...newObj.addresses,
        [type]: {
          ...newObj.addresses[type],
          [field]: value,
        },
      },
    };

    setNewObj(newData);
  };

  const handleChangeValidEnd = (newValidAddress: keyof Client["addresses"]) => {
    setNewObj({
      ...newObj,
      validAddress: newValidAddress,
    });
  };

  const handleCancel = () => {
    setNewObj(defaultState);
    onClose();
  };

  return (
    <DetailsContainer>
      <InfoHeader>
        <div>
          <FiMapPin size={22} color="var(--red)" />
          <p>Endereço</p>
          {!inEdit && isAdmin && (
            <TbPencil
              onClick={() => handleActiveEdit("data")}
              size={22}
              style={{ strokeWidth: 1.5, cursor: "pointer" }}
            />
          )}
        </div>
        {inEdit && (
          <EditButtons>
            <CancelButton onClick={handleCancel}>Cancelar</CancelButton>
            <SaveButton onClick={async () => handleSaveClient(newObj)}>Salvar edição</SaveButton>
          </EditButtons>
        )}
      </InfoHeader>
      {Object.keys(client.addresses).map((item: keyof Client["addresses"], index) => {
        const clientAddress = addressHelper.formatByValidAddress(client, item);

        if (clientAddress != "N/D") {
          return (
            <AddressItem key={index}>
              <AddressHeader>
                {inEdit ? (
                  <div onClick={() => handleChangeValidEnd(item)}>
                    {newObj.validAddress === item ? (
                      <RadioButtonChecked fontSize="medium" />
                    ) : (
                      <RadioButtonUncheckedOutlined fontSize="medium" />
                    )}
                    <p>
                      Endereço {item}
                      {item === client.validAddress && " (atual)"}
                    </p>
                  </div>
                ) : (
                  <p>
                    Endereço {item}
                    {item === client.validAddress && " (atual)"}
                  </p>
                )}
              </AddressHeader>
              {inEdit ? (
                <AddressText>
                  {Object.keys(client.addresses[client.validAddress]).map(
                    (addressName: keyof Client["addresses"]["S1"], index) => (
                      <AddressForm key={index}>
                        <p>{addressesNames[addressName]}</p>
                        <TextInput
                          name={addressName}
                          defaultValue={client.addresses[item][addressName]}
                          onChange={(value) => handleEditAddress(item, addressName, value)}
                          placeholder={`${addressName}`}
                          style={{
                            gridArea: index === 0 ? "header" : "content",
                          }}
                        />
                      </AddressForm>
                    )
                  )}
                </AddressText>
              ) : (
                <p>{clientAddress}</p>
              )}
            </AddressItem>
          );
        }
      })}
    </DetailsContainer>
  );
};

export default AddressSection;
