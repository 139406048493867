import { ReturnRepository } from "@domainRepositories/ReturnRepository";
import showToast from "@helpers/showToast";
import { HttpClient } from "@infra/api/HttpClient";
import { Client } from "@models/Client";

export class HttpReturnRepository implements ReturnRepository {
  private readonly baseUrl = `${process.env.REACT_APP_API_URL}/clients`;

  constructor(private readonly httpClient: HttpClient) {}

  async release(clientId: Client["id"]) {
    return this.httpClient
      .request({
        url: `${this.baseUrl}/${clientId}/returns/release`,
        method: "POST",
        body: JSON.stringify({ clientId }),
      })
      .then((response) => {
        showToast(response.message, "success");
        return response.data;
      });
  }

  async assign(clientId: Client["id"], operatorId: string) {
    return this.httpClient
      .request({
        url: `${this.baseUrl}/${clientId}/returns/assign`,
        method: "POST",
        body: JSON.stringify({ operatorId }),
      })
      .then((response) => {
        showToast(response.message, "success");
        return response.data;
      });
  }
}
