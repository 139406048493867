import React, { useState, useEffect } from "react";

import {
  ModalLogsContent,
  LogsContainer,
  LogItem,
  Circle,
  Line,
  Container,
} from "../ModalLogs/styles";

import Loading from "@components/Loading/Loading";
import CustomNoRowsOverlay from "@components/NoRowsOverlay/NoRowsOverlay";
import ModalHeader from "@components/Modal/ModalHeader/ModalHeader";
import { GetRouteLogsUseCase } from "@dataUseCases/index";
import { HttpLogRepository } from "@dataRepositories/Http/HttpLogRepository";
import { api } from "@infra/api";
import { Route } from "@models/Route";
import { logsHelper } from "@helpers/logs";

type Props = {
  routeId: Route["id"];
  routeName: Route["name"];
  onClose: () => void;
};

const ModalLogsRoute = ({ routeName, routeId, onClose }: Props) => {
  const [routeLogsFormated, setRouteLogsFormated] = useState<string[]>();

  const [loadingLogs, setLoadingLogs] = useState(true);

  useEffect(() => {
    (async () => {
      const useCase = new GetRouteLogsUseCase(new HttpLogRepository(api));

      useCase
        .execute(routeId)
        .then((logs) => {
          setRouteLogsFormated(logsHelper.mapLogs(logs));
        })
        .finally(() => setLoadingLogs(false));
    })();
  }, [routeId]);

  return (
    <Container>
      <ModalHeader title={`Logs da rota ${routeName}`} onClose={onClose} />
      <hr />
      <ModalLogsContent>
        {loadingLogs == true ? (
          <Loading />
        ) : (
          <>
            {routeLogsFormated.length != 0 ? (
              <LogsContainer>
                {routeLogsFormated.map((item, index) => (
                  <LogItem key={index}>
                    <div>
                      <Circle />
                      <Line />
                    </div>
                    <h3>{item}</h3>
                  </LogItem>
                ))}
              </LogsContainer>
            ) : (
              <CustomNoRowsOverlay />
            )}
          </>
        )}
      </ModalLogsContent>
    </Container>
  );
};

export default ModalLogsRoute;
