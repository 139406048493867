import { AttendClientStore } from "@domainStores/AttendClientStore";
import { MapClientStore } from "@domainStores/MapClientStore";
import { MapStore } from "@domainStores/MapStore";
import { ReportStore } from "@domainStores/ReportStore";
import { ReturnClientStore } from "@domainStores/ReturnClientStore";
import { RouteStore } from "@domainStores/RouteStore";
import { UserStore } from "@domainStores/UserStore";
import UseCase from "@domainUseCases/UseCase";

type Input = void;

type Output = void;

export class ClearStoresUseCase implements UseCase<Input, Output> {
  constructor(
    private readonly attendiClientStore: AttendClientStore,
    private readonly mapClientStore: MapClientStore,
    private readonly mapStore: MapStore,
    private readonly reportStore: ReportStore,
    private readonly returnClientStore: ReturnClientStore,
    private readonly routeStore: RouteStore,
    private readonly userStore: UserStore
  ) {}

  execute() {
    this.attendiClientStore.clear();
    this.mapClientStore.clear();
    this.mapStore.clear();
    this.reportStore.clear();
    this.returnClientStore.clear();
    this.routeStore.clear();
    this.userStore.clear();

    return;
  }
}
