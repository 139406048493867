//Icons map
import retorno from "@assets/markers/retorno34.png";
import retornoPremium from "@assets/markers/retornoPremium34.png";

//Variables
export const returnFilterIcons = [
  {
    specie: "return",
    icon: retorno,
    title: "RETORNO",
  },
  {
    specie: "premiumReturn",
    icon: retornoPremium,
    title: "RETORNO PREMIUM",
  },
];
