import { AlgoliaSearchClientRepository } from "@dataRepositories/index";
import { SearchTableAttendClientsUseCase } from "@dataUseCases/index";
import { algoliaIndex } from "@infra/algolia/config";
import { Client } from "@models/Client";
import { Filter } from "@models/Filters";
import { useState } from "react";

type AlgoliaResult = {
  clients: Client[];
  total: number;
};

export default function useAlgolia() {
  const [filteredResult, setFilteredResult] = useState<AlgoliaResult>({ clients: [], total: 0 });
  const [searchedResult, setsearchedResult] = useState<AlgoliaResult>({ clients: [], total: 0 });

  const algoliaFilter = async (filters: Filter[], page: number) => {
    const useCase = new SearchTableAttendClientsUseCase(
      new AlgoliaSearchClientRepository(algoliaIndex)
    );

    useCase.execute({ query: "", filters, page }).then((response) => {
      setFilteredResult({
        clients: response.clients,
        total: response.total,
      });
    });
  };

  const algoliaSearch = async (search: string, page: number) => {
    const useCase = new SearchTableAttendClientsUseCase(
      new AlgoliaSearchClientRepository(algoliaIndex)
    );

    useCase.execute({ query: search, filters: [], page }).then((response) => {
      setsearchedResult({
        clients: response.clients,
        total: response.total,
      });
    });
  };

  return {
    algoliaFilter,
    algoliaSearch,
    filteredResult,
    searchedResult,
  };
}
