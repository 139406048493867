import React, { useState } from "react";

import {
  Container,
  Title,
  OperatorsList,
  HeaderList,
  ListContainer,
  OperatorItem,
  OperatorsInfo,
  HeaderInfo,
  InfoItem,
  Circle,
  InfoItemHeader,
  InfoItemDescription,
  OperatorsListLoading,
  OperatorsInfoLoading,
} from "./styles";

import { FiUsers, FiRefreshCcw } from "react-icons/fi";
import { TbRoute } from "react-icons/tb";

//MUI
import { Avatar } from "@mui/material";
import { avatarHelper } from "@helpers/avatar";

import { OperatorsCardData } from "@pages/Dashboard/DashboardPage";
import { reportResultOptions } from "@models/Report";
import Loading from "@components/Loading/Loading";

type Props = {
  operatorsData: OperatorsCardData[];
};

function OperatorsCard({ operatorsData }: Props) {
  const [selected, setSelected] = useState(0);

  const handleSelect = (index: number, type: "operator" | "visits" | "returns") => {
    if (index === selected) {
      setSelected(0);
    } else {
      if (type === "operator") {
        setSelected(index);
      }
    }
  };

  const getReportsType = (data: OperatorsCardData) => {
    const array = [];

    const resultOptions = Object.values(reportResultOptions);

    for (const option of resultOptions) {
      const reports = data.attendClients.filter((x) => x.lastReportResult === option);

      if (reports.length > 0) {
        array.push({
          field: option,
          qtd: reports.length,
        });
      }
    }
    return array;
  };

  if (!operatorsData) {
    return (
      <Container>
        <OperatorsListLoading>
          <Loading />
        </OperatorsListLoading>
        <OperatorsInfoLoading>
          <Loading />
        </OperatorsInfoLoading>
      </Container>
    );
  }

  return (
    <Container>
      <OperatorsList>
        <Title>
          <FiUsers />
          <h3>Operadores</h3>
        </Title>
        <HeaderList>
          <h3>Operador</h3>
          <h3>Dias com rotas</h3>
          <h3>Clientes visitados</h3>
          <h3>Retornos</h3>
        </HeaderList>
        <ListContainer>
          {operatorsData.map((item, index) => {
            if (item.name != "Geral") {
              return (
                <OperatorItem
                  key={index}
                  active={selected === index ? true : false}
                  onClick={() => handleSelect(index, "operator")}
                >
                  <div>
                    <Avatar
                      alt="Avatar"
                      src={avatarHelper.getUserAvatar(item.id)}
                      sx={{
                        width: 30,
                        height: 30,
                        borderRadius: 100,
                        borderColor: "white",
                        borderWidth: 20,
                      }}
                    />
                    <h4>{item.name}</h4>
                  </div>
                  <div>
                    <h4>{item.daysWithRoutes}</h4>
                  </div>
                  <div>
                    <h4>{item.attendClients.length}</h4>
                  </div>
                  <div>
                    <h4>{item.returns.total.length}</h4>
                  </div>
                </OperatorItem>
              );
            }
          })}
        </ListContainer>
      </OperatorsList>
      {operatorsData.length > 0 && (
        <OperatorsInfo>
          <HeaderInfo>
            <Avatar
              alt="Avatar"
              src={avatarHelper.getUserAvatar(operatorsData[selected].id)}
              sx={{
                marginTop: -5,
                width: 100,
                height: 100,
                borderRadius: 100,
                borderColor: "white",
                borderWidth: 10,
              }}
            />
            <h3>{operatorsData[selected].name}</h3>
          </HeaderInfo>
          <InfoItem>
            <InfoItemHeader color="var(--success)">
              <div>
                <Circle color="var(--success)">
                  <TbRoute />
                </Circle>
                <h3>Dias com rotas</h3>
              </div>
              <h1>{operatorsData[selected].daysWithRoutes}</h1>
            </InfoItemHeader>
          </InfoItem>
          <InfoItem onClick={() => handleSelect(null, "visits")}>
            <InfoItemHeader color="var(--yellow)">
              <div>
                <Circle color="var(--yellow)">
                  <FiUsers />
                </Circle>
                <h3>Clientes visitados</h3>
              </div>
              <h1>{operatorsData[selected].attendClients.length}</h1>
            </InfoItemHeader>
            <InfoItemDescription>
              {getReportsType(operatorsData[selected]).map((item, index) => {
                if (index === getReportsType(operatorsData[selected]).length - 1) {
                  return (
                    <h3 key={index}>
                      {item.field}: <span>{item.qtd}</span>
                    </h3>
                  );
                } else {
                  return (
                    <h3 key={index}>
                      {item.field}: <span>{item.qtd}</span> /
                    </h3>
                  );
                }
              })}
            </InfoItemDescription>
          </InfoItem>
          <InfoItem onClick={() => handleSelect(null, "returns")}>
            <InfoItemHeader color="var(--blue)">
              <div>
                <Circle color="var(--blue)">
                  <FiRefreshCcw />
                </Circle>
                <h3>Retornos</h3>
              </div>
              <h1>{operatorsData[selected].returns.total.length}</h1>
            </InfoItemHeader>
            <InfoItemDescription>
              <h3>
                Realizados: <span>{operatorsData[selected].returns.completed.length}</span> /
                Pendentes: <span>{operatorsData[selected].returns.pending.length}</span> /
                Expirados: <span>{operatorsData[selected].returns.expired.length}</span>
              </h3>
            </InfoItemDescription>
          </InfoItem>
        </OperatorsInfo>
      )}
    </Container>
  );
}

export default OperatorsCard;
