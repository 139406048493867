import React, { useEffect, useState } from "react";

import {
  ClientHeader,
  ClientNameContent,
  Container,
  DetailsContainer,
  Divider,
  ImageEspecie,
  ImageStreetView,
  Content,
  ActionsContent,
  ActionButton,
  ClientHeaderLeft,
} from "./styles";

//Utils
import Modal from "react-modal";

//utils
import { ArticleOutlined } from "@mui/icons-material";

import { Client, clientStatus } from "@models/Client";
import useUser from "@hooks/useUser";
import Loading from "@components/Loading/Loading";
import { markersHelper } from "@helpers/markers";
import { datetimeHelper } from "@helpers/datetime";
import DataSection from "./DataSection/DataSection";
import AddressSection from "./AddressSection/AddressSection";
import ContactSection from "./ContactSection/ContactSection";
import RejectionSection from "./RejectionSection/RejectionSection";
import ProcessesSection from "./ProcessesSection/ProcessesSection";
import AnalysisSection from "./AnalysisSection/AnalysisSection";
import ReportsSection from "./ReportsSection/ReportsSection";
import axios from "axios";
import ModalHeader from "../ModalHeader/ModalHeader";
import ClientBadges from "@components/ClientBadges/ClientBadges";
import {
  DeleteReportUseCase,
  GetClientReportsUseCase,
  GetClientUseCase,
  UpdateClientReportsUseCase,
  UpdateClientUseCase,
} from "@dataUseCases/index";
import { HttpClientRepository, HttpReportRepository } from "@dataRepositories/index";
import { api } from "@infra/api";
import ModalDetailsHistory from "../ModalDetailsHistory/ModalDetailsHistory";
import ModalLogsClient from "../ModalLogs/ModalLogsClient";
import ModalStreetView from "../ModalStreetView/ModalStreetView";
import { Report } from "@models/Report";
import ModalRemoveClient from "../ModalRemoveClient/ModalRemoveClient";
import { FiTrash2 } from "react-icons/fi";
import logIconGrey from "@assets/logIconGrey.svg";
import { MapClient } from "@models/MapClient";
import { RoutePoint } from "@models/RoutePoint";

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

type Props = {
  data: Client | MapClient | RoutePoint;
  defaultEdit?: boolean;
  onClose: () => void;
};

function ModalDetailsAdmin({ data, defaultEdit = false, onClose }: Props) {
  const { isAdmin, isManager } = useUser();

  const [loadingData, setLoadingData] = useState(true);
  const [client, setClient] = useState<Client>(null);
  const [clientReports, setClientReports] = useState<Report[]>([]);

  const [imageStreetView, setImageStreetView] = useState<Blob>();

  const [sectionEdit, setSectionEdit] = useState(-1);

  const [modalLogs, setModalLogs] = useState(false);
  const [modalStreetView, setModalStreetView] = useState(false);
  const [modalRemoveClient, setModalRemoveClient] = useState(false);
  const [modalHistory, setModalHistory] = useState(false);

  useEffect(() => {
    if (defaultEdit) {
      setSectionEdit(1);
    }

    fetchClientData();
  }, [data]);

  useEffect(() => {
    (async () => {
      const useCase = new GetClientReportsUseCase(new HttpReportRepository(api));

      useCase.execute({ clientId: data.id }).then((reports) => {
        setClientReports(reports);
      });
    })();
  }, []);

  const fetchClientData = () => {
    setLoadingData(true);

    const useCase = new GetClientUseCase(new HttpClientRepository(api));

    useCase
      .execute(data.id)
      .then(async (clientData) => {
        if (clientData.lat && clientData.lng) {
          await axios
            .get(
              `https://maps.googleapis.com/maps/api/streetview?size=724x144&location=${
                clientData.lat
              },${clientData.lng}&fov=80&heading=70&pitch=0&key=${apiKey}`,
              { responseType: "blob" }
            )
            .then((response) => {
              setImageStreetView(response.data);
            });
        }

        setClient(clientData);
        setLoadingData(false);
      })
      .catch(() => {
        onClose();
      });
  };

  const handleActiveEdit = (sectionId: number) => {
    if (sectionEdit === sectionId) {
      handleCloseSection();
    } else {
      setSectionEdit(sectionId);
    }
  };

  const getChangedClientData = (
    newClient: Partial<Client>
  ): {
    changedData: Omit<Partial<Client>, "lastReportDate" | "createdAt" | "updatedAt">;
    changedFields: string[];
    notChanged: object;
  } => {
    const changedData: Partial<Client> = {};

    const newClientKeys = Object.keys(newClient);

    const changedFields = [];

    for (const key of newClientKeys) {
      if (client[key] !== newClient[key]) {
        changedFields.push(key);
        changedData[key] = newClient[key];
      }
    }

    const { lastReportDate, createdAt, updatedAt, ...filteredData } = changedData;

    return {
      changedData: filteredData,
      changedFields,
      notChanged: { lastReportDate, createdAt, updatedAt },
    };
  };

  const saveClient = async (newClient: Partial<Client>) => {
    const { changedData } = getChangedClientData(newClient);

    const useCase = new UpdateClientUseCase(new HttpClientRepository(api));

    useCase.execute({ clientId: client.id, data: changedData }).then((client) => {
      setClient(client);
      handleCloseSection();
    });
  };

  const getChangedReports = (newReports: Report[]) => {
    const changedReports: Pick<Report, "id" | "result" | "description">[] = [];

    for (const newReport of newReports) {
      const oldReport = clientReports.find((x) => x.id === newReport.id);
      let hasChanged = false;

      if (oldReport) {
        const newReportKeys = Object.keys(newReport);

        for (const key of newReportKeys) {
          if (oldReport[key] !== newReport[key]) {
            hasChanged = true;
          }
        }
      } else {
        hasChanged = true;
      }

      if (hasChanged) {
        changedReports.push({
          id: newReport.id,
          description: newReport.description,
          result: newReport.result,
        });
      }
    }

    return { changedReports };
  };

  const saveClientReports = async (newReports: Report[]) => {
    const { changedReports } = getChangedReports(newReports);

    if (changedReports.length > 0) {
      const useCase = new UpdateClientReportsUseCase(new HttpReportRepository(api));

      useCase
        .execute({
          clientId: client.id,
          reports: changedReports,
        })
        .then((reports) => {
          setClientReports(reports);
          handleCloseSection();
          fetchClientData();
        });
    }
  };

  const deleteReport = async (reportId: Report["id"]) => {
    const useCase = new DeleteReportUseCase(new HttpReportRepository(api));

    useCase.execute(reportId).then((reports) => {
      setClientReports(reports);
      handleCloseSection();
      fetchClientData();
    });
  };

  const handleCloseSection = () => {
    setSectionEdit(-1);
  };

  if (loadingData || !client) {
    return (
      <Container>
        <Loading background="transparent" />
      </Container>
    );
  }

  return (
    <Container>
      <ModalHeader title="Detalhes do cliente" onClose={onClose} />
      <Content>
        <ImageStreetView
          src={imageStreetView ? URL.createObjectURL(imageStreetView) : null}
          onClick={() => setModalStreetView(true)}
        />

        <ClientHeader>
          <ClientHeaderLeft>
            <ImageEspecie>
              <img
                src={markersHelper.getSpecieIcon(client.specie)}
                style={{ width: 30, height: 30, objectFit: "cover" }}
              />
            </ImageEspecie>
            <ClientNameContent>
              <h4>{client.name}</h4>
              <p>{client.specie}</p>
            </ClientNameContent>
          </ClientHeaderLeft>
          <div>
            <ClientBadges client={client} isModalDetails />
          </div>
          <h3>Data de inclusão: {datetimeHelper.getDate(client.createdAt)}</h3>
        </ClientHeader>

        <Divider />

        <DataSection
          client={client}
          inEdit={sectionEdit === 0}
          isAdmin={isAdmin}
          handleActiveEdit={() => handleActiveEdit(0)}
          handleSaveClient={saveClient}
          onClose={handleCloseSection}
        />

        <Divider />

        <AddressSection
          client={client}
          inEdit={sectionEdit === 1}
          isAdmin={isAdmin}
          handleActiveEdit={() => handleActiveEdit(1)}
          handleSaveClient={saveClient}
          onClose={handleCloseSection}
        />

        <Divider />

        <ContactSection
          client={client}
          inEdit={sectionEdit === 2}
          isAdmin={isAdmin}
          handleActiveEdit={() => handleActiveEdit(2)}
          handleSaveClient={saveClient}
          onClose={handleCloseSection}
        />

        <Divider />

        <RejectionSection
          client={client}
          inEdit={sectionEdit === 3}
          isAdmin={isAdmin}
          handleActiveEdit={() => handleActiveEdit(3)}
          handleSaveClient={saveClient}
          onClose={handleCloseSection}
        />

        <Divider />

        <ProcessesSection
          client={client}
          inEdit={sectionEdit === 4}
          isAdmin={isAdmin}
          handleActiveEdit={() => handleActiveEdit(4)}
          handleSaveClient={saveClient}
          onClose={handleCloseSection}
        />

        <Divider />

        <AnalysisSection
          client={client}
          inEdit={sectionEdit === 5}
          isAdmin={isAdmin}
          handleActiveEdit={() => handleActiveEdit(5)}
          handleSaveClient={saveClient}
          onClose={handleCloseSection}
        />

        <Divider />

        <ReportsSection
          clientReports={clientReports}
          inEdit={sectionEdit === 6}
          isAdmin={isAdmin}
          isManager={isManager}
          handleActiveEdit={() => handleActiveEdit(6)}
          handleSaveReports={saveClientReports}
          handleDeleteReport={deleteReport}
          onClose={handleCloseSection}
        />

        <DetailsContainer>
          <ActionsContent>
            <ActionButton color="var(--grey2)" onClick={() => setModalHistory(true)}>
              <ArticleOutlined />
              Histórico do cliente
            </ActionButton>

            {client.cpf && isAdmin && (
              <ActionButton color="var(--grey2)" onClick={() => setModalLogs(true)}>
                <img src={logIconGrey} alt="icon icon" />
                Ver log do cliente
              </ActionButton>
            )}

            {isAdmin && client.status === clientStatus.OPEN && (
              <ActionButton color="var(--red)" onClick={() => setModalRemoveClient(true)}>
                <FiTrash2 />
                Excluir do mapa
              </ActionButton>
            )}
          </ActionsContent>
        </DetailsContainer>
      </Content>

      <Modal
        isOpen={modalLogs}
        onRequestClose={() => setModalLogs(false)}
        overlayClassName="modal-overlay"
        className="modal-content-auto-width"
        contentLabel="Example Modal"
      >
        <ModalLogsClient data={client} onClose={() => setModalLogs(false)} />
      </Modal>

      <Modal
        isOpen={modalHistory}
        onRequestClose={() => setModalHistory(false)}
        overlayClassName="modal-overlay"
        className="modal-content-auto-width"
        contentLabel="Example Modal"
      >
        <ModalDetailsHistory data={client} onClose={() => setModalHistory(false)} />
      </Modal>

      <Modal
        isOpen={modalStreetView}
        onRequestClose={() => setModalStreetView(false)}
        overlayClassName="modal-overlay"
        className="modal-content-auto-width"
        contentLabel="Example Modal"
      >
        <ModalStreetView client={client} onClose={() => setModalStreetView(false)} />
      </Modal>

      <Modal
        isOpen={modalRemoveClient}
        onRequestClose={() => {
          setModalRemoveClient(false);
        }}
        overlayClassName="modal-overlay"
        className="modal-content-auto-width"
        contentLabel="Example Modal"
      >
        <ModalRemoveClient
          client={client}
          onSuccess={() => {
            setModalRemoveClient(false);
            onClose();
          }}
          onCancel={() => {
            setModalRemoveClient(false);
          }}
        />
      </Modal>
    </Container>
  );
}

export default ModalDetailsAdmin;
