import { useEffect, useState } from "react";
import { UserLocation } from "@models/UserLocation";
import { useStore } from "zustand";
import routeStore from "@stores/route";
import { routeStatus } from "@models/Route";
import { WatchRoutesOfDay } from "@dataUseCases/index";
import userStore from "@stores/user";
import { FirebaseRouteRepository } from "@dataRepositories/Firebase/FirebaseRouteRepository";
import { Timestamp } from "firebase/firestore";

export default function useOperatorLocation() {
  const { routesOfDay } = useStore(routeStore);

  const [operatorsLocation, setOperatorsLocation] = useState<UserLocation[]>([]);

  useEffect(() => {
    if (routesOfDay) {
      const dateNow = new Date();
      const array = [];

      for (const route of routesOfDay.filter((x) => x.status === routeStatus.STARTED)) {
        if (route.operatorPosition) {
          const routeTime = new Date(route.operatorPosition.updatedAt.toMillis()).getTime();
          const diff = dateNow.getTime() - routeTime;

          if (diff <= 2 * (3600 * 1000)) {
            const userLocation: UserLocation = {
              id: route.operatorId,
              name: route.operatorName,

              lat: route.operatorPosition?.lat,
              lng: route.operatorPosition.lng,
              updatedAt: route.operatorPosition.updatedAt,
            };

            array.push(userLocation);
          }
        }
      }

      setOperatorsLocation(array);
    }
  }, [routesOfDay]);

  const getRoutesOfDay = async () => {
    const dateNow = new Date();
    const startDate = Timestamp.fromDate(dateNow);

    const endDate = Timestamp.fromMillis(dateNow.getTime() - 2 * (86400 * 1000));

    const useCase = new WatchRoutesOfDay(
      userStore.getState(),
      new FirebaseRouteRepository(routeStore.getState())
    );

    useCase.execute({ startDate, endDate });
  };

  useEffect(() => {
    if (routesOfDay.length === 0) {
      getRoutesOfDay();
    }
  }, []);

  return {
    operatorsLocation: operatorsLocation,
    loading: !operatorsLocation ? true : false,
  };
}
