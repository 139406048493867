import styled from "styled-components";

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 2fr);
  grid-column-gap: 20px;
  /* height: 400px; */
  /* max-height: 200px; */
  /* margin: 20px 0px; */
`;
