import React, { useState } from "react";

import { Container, ButtonFilter, TextClearFilters, ConfirmButton, SelectContent } from "./styles";

//icons
import { IoMdArrowDropdown } from "react-icons/io";
import { BsEraser } from "react-icons/bs";

//Utils
import Select from "react-select";

//Mui components
import Menu from "@mui/material/Menu";
import DateFilter from "@components/Filters/DateFilter/DateFilter";
import { filtersHelper } from "@helpers/filters";
import { arrayHelper } from "@helpers/array";
import { Filter } from "@models/Filters";
import { Route, routeStatusNames } from "@models/Route";
import { Client } from "@models/Client";

type Props = {
  defaultTitle?: string;
  filters: Filter[];
  filterTypes: { headerName: string; field: string }[];
  tableRowsBackup: Route[] | Client[];
  dateField: keyof Pick<Route, "endAt"> | keyof Pick<Client, "lastReportDate">;
  onChangeFilters: (filters: Filter[]) => void;
  onClearFilters: () => void;
};

const DataHeaderFilter = ({
  filters,
  filterTypes,
  tableRowsBackup,
  dateField,
  defaultTitle,
  onChangeFilters,
  onClearFilters,
}: Props) => {
  const [selectedField, setSelectedField] = useState("");

  const [options, setOptions] = useState([]);

  const [filterCalendar, setFilterCalendar] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const clearAllFilters = () => {
    onClearFilters();
  };

  const handleMenuClose = () => {
    setSelectedField("");
    setAnchorEl(null);
  };

  const handleMenuOpen = (event, headerName) => {
    if (headerName == "Data") {
      setFilterCalendar(true);
    } else {
      setFilterCalendar(false);
    }

    setSelectedField(event.currentTarget.id);
    setAnchorEl(event.currentTarget);
  };

  const handleChangeFilters = async (newValueArray, field) => {
    const exists = filters.findIndex((x) => x.field === field);

    if (exists != -1) {
      if (newValueArray.length > 0) {
        const newObject: Filter = {
          ...filters[exists],
          values: newValueArray,
        };

        const newArray = arrayHelper.replaceItemOfArray(filters, exists, newObject);

        onChangeFilters(newArray);
      } else {
        const newArray = arrayHelper.removeItemOfArray(filters, exists);

        onChangeFilters(newArray);
      }
    } else {
      const newObject: Filter = {
        field: field,
        type: "string",
        values: newValueArray,
      };

      onChangeFilters([...filters, newObject]);
    }
  };

  const handleChangeDateFilter = (calendarValues, field) => {
    const exists = filters.findIndex((x) => x.field === field);

    if (exists != -1) {
      if (calendarValues?.selectedStart || calendarValues?.selectedEnd) {
        const newObject = {
          ...filters[exists],
          dateValue: calendarValues,
        };

        const newArray = arrayHelper.replaceItemOfArray(filters, exists, newObject);

        onChangeFilters(newArray);
      } else {
        const newArray = arrayHelper.removeItemOfArray(filters, exists);

        onChangeFilters(newArray);
      }
    } else {
      const newObject: Filter = {
        field: field,
        type: "date",
        values: [],
        dateValue: calendarValues,
      };

      onChangeFilters([...filters, newObject]);
    }
  };

  const buttonHandle = (label, field) => {
    const exists = filters.find((x) => x.field === field);
    if (exists) {
      if (exists.type === "string") {
        if (exists.values?.length > 1) {
          return `${exists.values[0].label} +${exists.values.length - 1}`;
        } else {
          return `${exists.values[0].label}`;
        }
      }

      if (exists.type === "date") {
        const selectedStart = exists?.dateValue?.selectedStart;
        const selectedEnd = exists?.dateValue?.selectedEnd;

        if (selectedStart && selectedEnd?.day) {
          return `${selectedStart.day}/${selectedStart.month}/${selectedStart.year} - 
                         ${selectedEnd.day}/${selectedEnd.month}/${selectedEnd.year}`;
        }

        if (selectedStart) {
          return `${selectedStart.day}/${selectedStart.month}/${selectedStart.year}`;
        }
      }
    } else {
      if (field.toLowerCase().includes("date") && defaultTitle) {
        return defaultTitle;
      }
      return `${label}`;
    }
  };

  const getOptions = (selectedField) => {
    const options = filtersHelper.getFilterOptions(tableRowsBackup, selectedField, filters);

    let mappedOptions = options;

    if (selectedField === "status") {
      mappedOptions = options.map((item) => ({
        label: routeStatusNames[item.label],
        value: item.value,
      }));
    }

    setOptions(mappedOptions);
  };

  const customStyle = {
    container: (base) => ({
      ...base,
      borderRadius: 40,
    }),
    control: (base) => ({
      ...base,
      padding: 3,
      borderRadius: 40,
    }),
    multiValue: (base) => ({
      ...base,
      borderRadius: 40,
    }),
    menuList: (base) => ({
      ...base,
      height: 260,
    }),
  };

  return (
    <Container>
      {filterTypes.map((item, index) => (
        <ButtonFilter
          key={index}
          id={item.field}
          onClick={(event) => handleMenuOpen(event, item.headerName)}
        >
          <h4>{buttonHandle(item.headerName, item.field)}</h4>
          <IoMdArrowDropdown
            style={{ cursor: "pointer" }}
            size={18}
            color="#808B9F"
            fill="#808B9F"
          />
        </ButtonFilter>
      ))}

      <TextClearFilters onClick={() => clearAllFilters()}>
        <BsEraser style={{ cursor: "pointer", marginRight: -5 }} />
        Limpar
      </TextClearFilters>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        style={{ zIndex: 100000 }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        PaperProps={{
          style: {
            width: 400,
            height: 400,
            padding: 10,
            borderRadius: 15,
          },
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {filterCalendar ? (
          <DateFilter
            filters={filters}
            dateField={dateField}
            onFilterDate={(values) => handleChangeDateFilter(values, dateField)}
            clearDateFilter={() => handleChangeDateFilter(null, dateField)}
            onClose={handleMenuClose}
          />
        ) : (
          <SelectContent>
            <Select
              isClearable={false}
              isSearchable={true}
              defaultValue={
                selectedField && filters && filtersHelper.getDefaultValues(selectedField, filters)
              }
              closeMenuOnSelect={false}
              options={options}
              isMulti={true}
              styles={customStyle}
              autoFocus={true}
              openMenuOnFocus={true}
              onMenuOpen={() => getOptions(selectedField)}
              onChange={(item) => handleChangeFilters(item, selectedField)}
            />
            <ConfirmButton onClick={() => handleMenuClose()}>
              <h3>OK</h3>
            </ConfirmButton>
          </SelectContent>
        )}
      </Menu>
    </Container>
  );
};

export default DataHeaderFilter;
