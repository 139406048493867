import React, { useEffect, useState } from "react";
import ModalHeader from "@components/Modal/ModalHeader/ModalHeader";

import {
  Container,
  CardsContainer,
  ReturnCard,
  ReturnCardHeader,
  Divider,
  ReturnCardNumber,
  ReturnsList,
  ReturnsListHeader,
  ReturnsListContent,
  ListItem,
  ListClientName,
  ListEspecie,
  ListDate,
  ListOperator,
  ListOptions,
  DividerHeader,
  Content,
} from "./styles";

import pendingIcon from "@assets/pending.png";
import inRouteIcon from "@assets/inRoute.png";
import expiredIcon from "@assets/expired.png";
import { AiOutlineEye } from "react-icons/ai";

import Avatar from "@mui/material/Avatar";
import Loading from "@components/Loading/Loading";
import { avatarHelper } from "@helpers/avatar";
import { filtersHelper } from "@helpers/filters";
import DataHeaderFilter from "@components/Filters/DataHeaderFilter/DataHeaderFilter";
import { Client, clientReturnStatus } from "@models/Client";
import useUser from "@hooks/useUser";
import useRoutes from "@hooks/useRoutes";
import { speciesColors } from "@constants/speciesColors";
import { datetimeHelper } from "@helpers/datetime";
import { Report } from "@models/Report";

type Props = {
  returns: Array<Client & Pick<Report, "description" | "returnSchedule">>;
  onClose: () => void;
  onClickView: (client: Client) => void;
};

type CardsData = {
  pending: Props["returns"];
  inRoute: Props["returns"];
  expired: Props["returns"];
  all: Props["returns"];
};

type ReturnType = "pending" | "inRoute" | "expired" | "all";

function ModalReturnsOperator({ returns, onClose, onClickView }: Props) {
  const { user } = useUser();
  const { routesOfDay } = useRoutes();

  const [filters, setFilters] = useState([]);
  const [seletedType, setSelectedType] = useState<ReturnType>("all");

  const [cardsData, setCardsData] = useState<CardsData>({
    pending: [],
    inRoute: [],
    expired: [],
    all: [],
  });

  useEffect(() => {
    if (returns && routesOfDay) {
      processReturns();
    }
  }, [returns, routesOfDay]);

  const processReturns = () => {
    const allReturns = returns;

    const arrayPending = returns.filter((x) => x.returnStatus === clientReturnStatus.EXPIRED);
    const arrayExpired = returns.filter((x) => x.returnStatus === clientReturnStatus.EXPIRED);
    const arrayInRoute = allReturns.filter((x) => x.currentRouteId);

    setCardsData({
      pending: arrayPending,
      inRoute: arrayInRoute,
      expired: arrayExpired,
      all: allReturns,
    });
  };

  const filterByTable = (baseTableData: Props["returns"]) => {
    return filtersHelper.filterDataTable(baseTableData, filters);
  };

  const clearFilters = () => {
    setFilters([]);
  };

  if (!cardsData) {
    return (
      <Container>
        <Loading background="transparent" />
      </Container>
    );
  }

  const baseTableData = cardsData[seletedType];

  const tableData = filters.length > 0 ? filterByTable(baseTableData) : baseTableData;

  const backupTableData = cardsData.all;

  return (
    <Container>
      <ModalHeader title="Retornos" onClose={onClose} />
      <Content>
        <CardsContainer>
          <ReturnCard
            onClick={() => {
              if (seletedType === "pending") {
                setSelectedType("all");
              } else {
                setSelectedType("pending");
              }
            }}
          >
            <ReturnCardHeader>
              <img src={pendingIcon} />
              <h3>Retornos pendentes</h3>
            </ReturnCardHeader>
            <DividerHeader />
            <ReturnCardNumber color="#F5C87B">{cardsData.pending.length}</ReturnCardNumber>
          </ReturnCard>

          <ReturnCard
            onClick={() => {
              if (seletedType === "inRoute") {
                setSelectedType("all");
              } else {
                setSelectedType("inRoute");
              }
            }}
          >
            <ReturnCardHeader>
              <img src={inRouteIcon} />
              <h3>Retornos em rota</h3>
            </ReturnCardHeader>
            <DividerHeader />
            <ReturnCardNumber color="#9BBEFF">{cardsData.inRoute.length}</ReturnCardNumber>
          </ReturnCard>

          <ReturnCard
            onClick={() => {
              if (seletedType === "expired") {
                setSelectedType("all");
              } else {
                setSelectedType("expired");
              }
            }}
          >
            <ReturnCardHeader>
              <img src={expiredIcon} />
              <h3>Retornos expirados</h3>
            </ReturnCardHeader>
            <DividerHeader />
            <ReturnCardNumber color="#DE6161">{cardsData.expired.length}</ReturnCardNumber>
          </ReturnCard>
        </CardsContainer>
        <ReturnsList>
          <ReturnsListHeader>
            <h3>Seus retornos</h3>
            <div>
              <DataHeaderFilter
                dateField="lastReportDate"
                filters={filters}
                filterTypes={[
                  { headerName: "Data", field: "lastReportDate" },
                  { headerName: "Status", field: "returnStatus" },
                ]}
                tableRowsBackup={backupTableData}
                onChangeFilters={(newFiltersArray) => {
                  setFilters(newFiltersArray);
                }}
                onClearFilters={() => clearFilters()}
              />
            </div>
          </ReturnsListHeader>
          <ReturnsListContent>
            {tableData.map((item, index) => {
              let specieColor = "#DE6161";

              const find = speciesColors.find((x) => x.specie === item.specie);

              specieColor = find ? find.tagColor : specieColor;

              return (
                <>
                  <ListItem key={index}>
                    <ListClientName>{item.name}</ListClientName>
                    <ListEspecie color={specieColor}>
                      <h4>{item.specie}</h4>
                    </ListEspecie>
                    <ListDate>
                      {item.returnSchedule
                        ? datetimeHelper.getFullDate(item.returnSchedule)
                        : "Sem data e hora"}
                    </ListDate>
                    <ListOperator>
                      <Avatar
                        src={avatarHelper.getUserAvatar(user.id)}
                        sx={{ marginRight: 1, height: 24, width: 24 }}
                      />
                      <h4>{item.returnOperatorName}</h4>
                    </ListOperator>
                    <ListOptions>
                      <AiOutlineEye
                        onClick={() => onClickView(item)}
                        cursor="pointer"
                        size={20}
                        color="#808B9F"
                        fill="#808B9F"
                      />
                    </ListOptions>
                  </ListItem>
                  <Divider />
                </>
              );
            })}
          </ReturnsListContent>
        </ReturnsList>
      </Content>
    </Container>
  );
}

export default ModalReturnsOperator;
