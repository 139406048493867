import React from "react";

import { Container } from "./styles";
import { Route, routeStatusNames } from "@models/Route";

const colorsStatus = {
  WAITING: "var(--grey1)",
  STARTED: "var(--orange)",
  PARTIALLY_COMPLETED: "var(--yellow)",
  COMPLETED: "var(--success)",
  CANCELED: "var(--red)",
} as const;

type Props = { status: Route["status"] };
const StatusIcon = ({ status }: Props) => {
  return <Container background={colorsStatus[status]}>{routeStatusNames[status]}</Container>;
};

export default StatusIcon;
