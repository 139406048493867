import React, { useEffect, useState } from "react";

import {
  DetailsContainer,
  Header,
  ClientHeader,
  ClientNameContent,
  Container,
  ImageContainer,
  ImageStreetView,
  InfoHeader,
  PointDivider,
  FullData,
  GenerateReportButton,
  ImageEspecie,
  SpecialIcon,
} from "./styles";

import { AiOutlineCloseCircle } from "react-icons/ai";
import { FiMapPin } from "react-icons/fi";
import { TbFileInvoice } from "react-icons/tb";
import Loading from "@components/Loading/Loading";
import axios from "axios";

//utils
import { addressHelper } from "@helpers/address";
import { markersHelper } from "@helpers/markers";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import money34 from "@assets/markers/money34.gif";
import ClientBadges from "@components/ClientBadges/ClientBadges";
import { Client } from "@models/Client";
import useUser from "@hooks/useUser";
import useSpecialIcon from "@hooks/useSpecialIcon";
import { MapClient } from "@models/MapClient";
import { GetClientUseCase } from "@dataUseCases/index";
import { HttpClientRepository } from "@dataRepositories/index";
import { api } from "@infra/api";

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

type Props = {
  data: Client | MapClient;
  onClose: () => void;
  onStreetView: () => void;
  onGenerateReport: () => void;
  onViewFullData: () => void;
  onChangeSpecialIcon: () => void;
};

const ClientDetails = ({
  data,
  onClose,
  onStreetView,
  onGenerateReport,
  onViewFullData,
  onChangeSpecialIcon,
}: Props) => {
  const { isAdmin } = useUser();

  const { toggleSpecialIcon } = useSpecialIcon();

  const [loadingData, setLoadingData] = useState(true);

  const [imageStreetView, setImageStreetView] = useState<Blob>();

  const [client, setClient] = useState<Client>(null);

  useEffect(() => {
    (async () => {
      setLoadingData(true);

      const useCase = new GetClientUseCase(new HttpClientRepository(api));

      useCase
        .execute(data.id)
        .then(async (clientData) => {
          if (clientData.lat && clientData.lng) {
            await axios
              .get(
                `https://maps.googleapis.com/maps/api/streetview?size=400x400&location=${
                  data.lat
                },${data.lng}&fov=80&heading=70&pitch=0&key=${apiKey}`,
                { responseType: "blob" }
              )
              .then((response) => {
                setImageStreetView(response.data);
              });
          }

          setClient(clientData);
          setLoadingData(false);
        })
        .catch(() => {
          onClose();
        });
    })();
  }, [data]);

  const handleSpecialIcon = () => {
    toggleSpecialIcon(data.id);
    onChangeSpecialIcon();
  };

  if (loadingData || !client) {
    return (
      <Container>
        <Loading background="white" />
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <AiOutlineCloseCircle size={25} style={{ cursor: "pointer" }} onClick={onClose} />
      </Header>

      <ImageContainer>
        <ImageStreetView
          src={URL.createObjectURL(imageStreetView)}
          onClick={() => {
            onStreetView();
          }}
        />
      </ImageContainer>

      <ClientHeader>
        <ImageEspecie>
          <img
            src={markersHelper.getSpecieIcon(client.specie)}
            style={{ width: 34, height: 34, objectFit: "cover" }}
          />
        </ImageEspecie>
        <ClientNameContent>
          <h4>{client.name}</h4>
          <p>{client.specie}</p>
        </ClientNameContent>
      </ClientHeader>

      <SpecialIcon onClick={handleSpecialIcon}>
        {client.specialIcon ? (
          <MdCheckBox size={20} style={{ borderRadius: 20 }} />
        ) : (
          <MdCheckBoxOutlineBlank size={20} />
        )}
        <h2>{`${client.specialIcon ? "Remover" : "Adicionar"} ícone especial`}</h2>
        <img src={money34} />
      </SpecialIcon>

      <PointDivider />

      <DetailsContainer>
        <InfoHeader>
          <FiMapPin size={22} color="var(--red)" />
          <p>Endereço</p>
        </InfoHeader>
        <p>{addressHelper.formatAddress(client)}</p>
      </DetailsContainer>

      <DetailsContainer>
        <InfoHeader>
          <TbFileInvoice size={26} color="var(--red)" style={{ strokeWidth: 1.8 }} />
          <p>Dados</p>
        </InfoHeader>
        <p>Idade: {data.age}</p>
        <ClientBadges client={client} isModalDetails={false} />
      </DetailsContainer>

      <FullData onClick={onViewFullData}>Ver dados completos</FullData>

      {isAdmin && (
        <GenerateReportButton onClick={onGenerateReport}>Gerar atendimento</GenerateReportButton>
      )}
    </Container>
  );
};

export default ClientDetails;
