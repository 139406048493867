import { ReturnClientStore } from "@domainStores/ReturnClientStore";
import { Client } from "@models/Client";
import { create } from "zustand";

export type Returns = {
  completed: Client[];
  expired: Client[];
  pending: Client[];
  premium: Client[];
};

type InitialState = Pick<ReturnClientStore, "dashboardReturns" | "returns">;

const initialState: InitialState = {
  dashboardReturns: {
    completed: [],
    expired: [],
    pending: [],
    premium: [],
  },
  returns: {
    completed: [],
    expired: [],
    pending: [],
    premium: [],
  },
};

const returnClientStore = create<ReturnClientStore>((set) => ({
  ...initialState,
  updateDashboardReturns: (newReturns) =>
    set((state) => {
      return {
        ...state,
        dashboardReturns: newReturns,
      };
    }),
  updateReturns: (newReturns) =>
    set((state) => {
      return {
        ...state,
        returns: newReturns,
      };
    }),
  clear: () =>
    set((state) => {
      return {
        ...state,
        ...initialState,
      };
    }),
}));

export default returnClientStore;
