import { Timestamp } from "firebase/firestore";
import { SelectOption } from "./SelectOption";
import { MapSpecie } from "./MapSpecie";

export const userRoles = {
  root: "root",
  admin: "admin",
  manager: "manager",
  operator: "operator",
} as const;
export type UserRole = keyof typeof userRoles;

export interface User {
  id: string;
  name: string;
  login: string;
  email: string;
  password: string;
  profileImageUrl: string;

  disabled: boolean;
  testUser: boolean;

  role: UserRole;

  // Based in role
  mapsIds: SelectOption[]; // Operator
  species: MapSpecie; // Operator

  mapsArray: SelectOption[]; // Manager
  operatorsIds: string[]; // Manager
  notificationTokens: string[]; // Root, Admin and Manager

  // Root and Admin
  users?: {
    rootId: string;
    allUsers: User[];
    admins: User[];
    managers: User[];
    operators: User[];
  };

  createdAt: Timestamp;
  updatedAt: Timestamp;
}
