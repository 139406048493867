import MapRepository from "@domainRepositories/MapRepository";
import UseCase from "@domainUseCases/UseCase";
import { Map } from "@models/Map";

type Input = Map["id"];

type Output = Promise<void>;

export class DeleteMapUseCase implements UseCase<Input, Output> {
  constructor(private readonly mapRepository: MapRepository) {}

  execute(mapId: Input) {
    return this.mapRepository.delete(mapId);
  }
}
