import React from "react";

import { Title } from "./styles";

type CardTitleProps = {
  color: string;
  imgSrc: string;
  title: string;
};

function CardTitle({ color, imgSrc, title }: CardTitleProps) {
  return (
    <Title color={color}>
      <img src={imgSrc} alt="icon icon" />
      <h1>{title}</h1>
    </Title>
  );
}

export default CardTitle;
