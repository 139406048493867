import { FirebaseReportRepository } from "@dataRepositories/index";
import { WatchMonthSignedContractsUseCase } from "@dataUseCases/index";
import showToast from "@helpers/showToast";
import reportStore from "@stores/report";
import { useEffect } from "react";
import { useStore } from "zustand";

let lastMonthReports = null;

export default function NewContractToast() {
  const monthReports = useStore(reportStore);

  useEffect(() => {
    const watchMonthSignedReportsUseCase = new WatchMonthSignedContractsUseCase(
      new FirebaseReportRepository(reportStore.getState())
    );

    watchMonthSignedReportsUseCase.execute();
  }, []);

  useEffect(() => {
    if (lastMonthReports) {
      if (monthReports > lastMonthReports) {
        showToast("Novo contrato fechado", "success");
      }
    } else {
      lastMonthReports = monthReports;
    }
  }, [monthReports]);

  return null;
}
