import React, { useState } from "react";

import { Container, Name, TextAction, Specie } from "./styles";

//Custom components
import ModalRemoveClient from "@components/Modal/ModalRemoveClient/ModalRemoveClient";

//Icons
import cpfIcon from "@assets/cpfIcon.svg";
import { BiMap } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";
import { TbPencil } from "react-icons/tb";
import { FiTrash2 } from "react-icons/fi";

//Utils
import Modal from "react-modal";

//logs
import { speciesHelper } from "@helpers/species";
import { clientAddressStatus } from "@models/Client";
import useUser from "@hooks/useUser";
import { MapClient } from "@models/MapClient";

type MapItemProps = {
  data: MapClient;
  onClick: () => void;
  onView: () => void;
  onEdit: () => void;
  onChangeAddress: () => void;
};

function MapItem({ data, onClick, onView, onEdit, onChangeAddress }: MapItemProps) {
  const { isAdmin } = useUser();

  const [modalRemoveClient, setModalRemoveClient] = useState(false);

  const getEndNotFoundType = () => {
    if (data.addressStatus === clientAddressStatus.NOT_FOUND) {
      return "(S)";
    }

    if (data.addressStatus === clientAddressStatus.NOT_FOUND_OPERATOR) {
      return "(O)";
    }

    return;
  };

  return (
    <Container onClick={onClick}>
      <Name>
        {data.name} {data.addressStatus != clientAddressStatus.FOUND && getEndNotFoundType()}
      </Name>
      <div>
        <img src={cpfIcon} alt="cpf" />
        <h4>{data.cpf}</h4>
      </div>
      <div>
        <h4>{data.age}</h4>
      </div>
      <Specie
        color={speciesHelper.getSpecieTag(data.specie).tagColor}
        textColor={speciesHelper.getSpecieTag(data.specie).textColor}
      >
        {data.specie}
      </Specie>
      <div>
        <BiMap size={15} color="var(--grey2)" />
        <h4>{data.cityMap}</h4>
      </div>

      {isAdmin ? (
        <>
          {data.addressStatus === clientAddressStatus.FOUND ? (
            <div>
              <AiOutlineEye
                onClick={onView}
                cursor="pointer"
                size={18}
                color="#808B9F"
                fill="#808B9F"
              />
              {isAdmin && (
                <>
                  <TbPencil
                    onClick={onEdit}
                    size={18}
                    style={{ strokeWidth: 1.5, cursor: "pointer" }}
                    color="#808B9F"
                  />
                  <FiTrash2
                    onClick={() => setModalRemoveClient(true)}
                    size={16}
                    style={{ strokeWidth: 1.5, cursor: "pointer" }}
                    color="#808B9F"
                  />
                </>
              )}
            </div>
          ) : (
            <TextAction onClick={onChangeAddress}>
              {data.addressStatus === clientAddressStatus.DIVERSE_LOCATION
                ? "Alterar endereço no mapa"
                : "Escolher outro endereço"}
            </TextAction>
          )}
        </>
      ) : (
        <div>
          <AiOutlineEye
            onClick={onView}
            cursor="pointer"
            size={18}
            color="#808B9F"
            fill="#808B9F"
          />
        </div>
      )}

      <Modal
        isOpen={modalRemoveClient}
        onRequestClose={() => {
          setModalRemoveClient(false);
        }}
        overlayClassName="modal-overlay"
        className="modal-content-auto-width"
        contentLabel="Example Modal"
      >
        <ModalRemoveClient
          client={data}
          onSuccess={() => {
            setModalRemoveClient(false);
          }}
          onCancel={() => {
            setModalRemoveClient(false);
          }}
        />
      </Modal>
    </Container>
  );
}

export default MapItem;
