import DatabaseAttendClientRepository from "@domainRepositories/DatabaseAttendClientRepository";
import { UserStore } from "@domainStores/UserStore";
import UseCase from "@domainUseCases/UseCase";
import { userRoles } from "@models/User";
import { Timestamp } from "firebase/firestore";

type Input = {
  startDate: Timestamp;
  endDate: Timestamp;
};

type Output = void;

export class WatchCardsAttendClientsUseCase implements UseCase<Input, Output> {
  constructor(
    private readonly userStore: UserStore,
    private readonly databaseAttendClientRepository: DatabaseAttendClientRepository
  ) {}

  execute({ startDate, endDate }: Input) {
    const user = this.userStore.user;

    if (user.role === userRoles.root || user.role === userRoles.admin) {
      return this.databaseAttendClientRepository.watchAttendClientsByDateAdmin(startDate, endDate);
    }

    if (user.role === userRoles.manager) {
      return this.databaseAttendClientRepository.watchAttendClientsByDateManager(
        startDate,
        endDate,
        user.operatorsIds
      );
    }

    return;
  }
}
