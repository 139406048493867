import ClientRepository from "@domainRepositories/ClientRepository";
import UseCase from "@domainUseCases/UseCase";
import { Client } from "@models/Client";

type Input = Client["id"];

type Output = Promise<Client>;

export class GetClientUseCase implements UseCase<Input, Output> {
  constructor(private readonly clientRepository: ClientRepository) {}

  async execute(clientId: Input) {
    return this.clientRepository.get(clientId);
  }
}
