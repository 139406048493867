import ClientRepository from "@domainRepositories/ClientRepository";
import UseCase from "@domainUseCases/UseCase";
import { Client } from "@models/Client";

type Input = Client["id"];

type Output = Promise<void>;

export class RemoveClientFromMapUseCase implements UseCase<Input, Output> {
  constructor(private readonly clientRepository: ClientRepository) {
    this.clientRepository = clientRepository;
  }

  async execute(clientId: Input) {
    return this.clientRepository.updateStatus(clientId);
  }
}
