import { firebase } from "@infra/firebase/config";
import {
  collection,
  getCountFromServer,
  onSnapshot,
  orderBy,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import { fireabseAdapter } from "./firebaseAdapter";
import DatabaseRouteRepository from "@domainRepositories/DatabaseRouteRepository";
import { RouteStore } from "@domainStores/RouteStore";
import { Route } from "@models/Route";

export class FirebaseRouteRepository implements DatabaseRouteRepository {
  constructor(private readonly routeStore: RouteStore) {}

  watchRoutesByDateAdmin(startDate: Timestamp, endDate: Timestamp) {
    const consulta = query(
      collection(firebase.db, "routes"),
      orderBy("createdAt", "desc"),
      where("createdAt", ">=", startDate),
      where("createdAt", "<=", endDate)
    );

    onSnapshot(
      consulta,
      async (querySnapshot) => {
        const mappedDocs = fireabseAdapter.mapFirebaseDoc<Route>(querySnapshot);

        this.routeStore.updateRoutesOfDay(mappedDocs);
      },
      (error) => console.log("watchRoutesByDateAdmin error", error)
    );
  }

  watchRoutesByDateManager(startDate: Timestamp, endDate: Timestamp, operatorsIds: string[]) {
    const consulta = query(
      collection(firebase.db, "routes"),
      orderBy("createdAt", "desc"),
      where("createdAt", ">=", startDate),
      where("createdAt", "<=", endDate),
      where("operatorId", "in", operatorsIds)
    );

    onSnapshot(
      consulta,
      async (querySnapshot) => {
        const mappedDocs = fireabseAdapter.mapFirebaseDoc<Route>(querySnapshot);
        this.routeStore.updateRoutesOfDay(mappedDocs);
      },
      (error) => console.log("watchRoutesByDateManager error", error)
    );
  }

  watchRoutesAdmin() {
    const consulta = query(collection(firebase.db, "routes"), orderBy("createdAt", "desc"));

    onSnapshot(
      consulta,
      async (querySnapshot) => {
        const mappedDocs = fireabseAdapter.mapFirebaseDoc<Route>(querySnapshot);

        const allDocsCount = await getCountFromServer(query(collection(firebase.db, "routes")));
        this.routeStore.updateAllRoutes(mappedDocs, allDocsCount.data().count);
      },
      (error) => console.log("watchRoutesAdmin error", error)
    );
  }

  watchRoutesManager(operatorsIds: string[]) {
    const consulta = query(
      collection(firebase.db, "routes"),
      orderBy("createdAt", "desc"),
      where("operatorId", "in", operatorsIds)
    );

    onSnapshot(
      consulta,
      async (querySnapshot) => {
        const mappedDocs = fireabseAdapter.mapFirebaseDoc<Route>(querySnapshot);

        const allDocsCount = await getCountFromServer(
          query(collection(firebase.db, "routes"), where("operatorId", "in", operatorsIds))
        );
        this.routeStore.updateAllRoutes(mappedDocs, allDocsCount.data().count);
      },
      (error) => console.log("watchRoutesManager error", error)
    );
  }

  watchRoutesOperator(operatorId: string) {
    const consulta = query(
      collection(firebase.db, "routes"),
      orderBy("createdAt", "desc"),
      where("operatorId", "==", operatorId)
    );

    onSnapshot(
      consulta,
      async (querySnapshot) => {
        const mappedDocs = fireabseAdapter.mapFirebaseDoc<Route>(querySnapshot);

        this.routeStore.updateOperatorRoutes(mappedDocs);
      },
      (error) => console.log("watchRoutesOperator error", error)
    );
  }
}
