import React, { useState } from "react";
import { CancelButton, DetailsContainer, EditButtons, InfoHeader, SaveButton } from "../styles";
import { TbHammer, TbPencil } from "react-icons/tb";
import { Client } from "@models/Client";
import TextInput from "@components/Form/TextInput/TextInput";

type Props = {
  client: Client;
  inEdit: boolean;
  isAdmin: boolean;
  handleActiveEdit?: (section: string) => void;
  handleSaveClient?: (newClient: Partial<Client>) => void;
  onClose?: () => void;
};

const ProcessesSection = ({
  client,
  inEdit,
  isAdmin,
  handleActiveEdit,
  handleSaveClient,
  onClose,
}: Props) => {
  const defaultState: Partial<Client> = { processes: client.processes };
  const [newObj, setNewObj] = useState<Partial<Client>>(defaultState);

  const handleEdit = (field: string, value: string) => {
    setNewObj({
      ...newObj,
      [field]: value,
    });
  };

  const handleCancel = () => {
    setNewObj(defaultState);
    onClose();
  };

  return (
    <DetailsContainer>
      <InfoHeader>
        <div>
          <TbHammer size={22} color="var(--red)" style={{ transform: "rotate(-90deg)" }} />
          <p>Processos arquivados</p>
          {!inEdit && isAdmin && (
            <TbPencil
              onClick={() => handleActiveEdit("process")}
              size={22}
              style={{ strokeWidth: 1.5, cursor: "pointer" }}
            />
          )}
        </div>
        {inEdit && (
          <EditButtons>
            <CancelButton onClick={handleCancel}>Cancelar</CancelButton>
            <SaveButton onClick={() => handleSaveClient(newObj)}>Salvar edição</SaveButton>
          </EditButtons>
        )}
      </InfoHeader>
      {inEdit ? (
        <TextInput
          name="processes"
          defaultValue={client.processes}
          onChange={(value) => handleEdit("processes", value)}
        />
      ) : (
        <p>{client.processes}</p>
      )}
    </DetailsContainer>
  );
};

export default ProcessesSection;
