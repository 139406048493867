import RouteRepository from "@domainRepositories/RouteRepository";
import UseCase from "@domainUseCases/UseCase";
import { DaysWithRoutes } from "@models/DaysWithRoutes";
import { Timestamp } from "firebase/firestore";

type GetDaysWithRoutesInput = {
  startDate: Timestamp;
  endDate: Timestamp;
};

type GetDaysWithRoutesOutput = Promise<DaysWithRoutes>;

export class GetDaysWithRoutesUseCase
  implements UseCase<GetDaysWithRoutesInput, GetDaysWithRoutesOutput>
{
  constructor(private readonly routeRepository: RouteRepository) {}

  execute(input: GetDaysWithRoutesInput) {
    return this.routeRepository.getDaysWithRoutes(
      input.startDate.seconds * 1000,
      input.endDate.seconds * 1000
    );
  }
}
