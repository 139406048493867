import React, { useEffect, useState } from "react";

import {
  DetailsContainer,
  Header,
  ClientHeader,
  ClientNameContent,
  Container,
  ImageContainer,
  ImageStreetView,
  InfoHeader,
  PointDivider,
  FullData,
  ImageEspecie,
} from "./styles";

import { AiOutlineCloseCircle } from "react-icons/ai";
import { FiMapPin } from "react-icons/fi";
import { TbFileInvoice } from "react-icons/tb";
import Loading from "@components/Loading/Loading";
import axios from "axios";
import PointIcon from "@components/Icons/PointIcon/PointIcon";

//utils
import { addressHelper } from "@helpers/address";
import { markersHelper } from "@helpers/markers";
import { RoutePoint } from "@models/RoutePoint";
import { Client } from "@models/Client";
import { GetClientUseCase } from "@dataUseCases/index";
import { HttpClientRepository } from "@dataRepositories/index";
import { api } from "@infra/api";

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

type Props = {
  index: number;
  data: RoutePoint;
  onClose: () => void;
  onStreetView: () => void;
  onViewFullData: () => void;
};

const ModalClientDetails = ({ index, data, onClose, onStreetView, onViewFullData }: Props) => {
  const [loadingData, setLoadingData] = useState(true);

  const [imageStreetView, setImageStreetView] = useState<Blob>();

  const [client, setClient] = useState<Client>(null);

  useEffect(() => {
    (async () => {
      setLoadingData(true);

      const useCase = new GetClientUseCase(new HttpClientRepository(api));

      useCase
        .execute(data.id)
        .then(async (clientData) => {
          if (clientData.lat && clientData.lng) {
            await axios
              .get(
                `https://maps.googleapis.com/maps/api/streetview?size=400x400&location=${
                  data.lat
                },${data.lng}&fov=80&heading=70&pitch=0&key=${apiKey}`,
                { responseType: "blob" }
              )
              .then((response) => {
                setImageStreetView(response.data);
              });
          }

          setClient(clientData);
          setLoadingData(false);
        })
        .catch(() => {
          onClose();
        });
    })();
  }, [data]);

  if (loadingData || !client) {
    return (
      <Container>
        <Loading background="white" />
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <AiOutlineCloseCircle size={25} style={{ cursor: "pointer" }} onClick={onClose} />
      </Header>

      <PointIcon
        size={30}
        textColor="var(--white)"
        fontWeight="400"
        fontSize="12"
        backgroundColor={data.visited ? "var(--success)" : "var(--red)"}
        index={index}
      />
      <br />
      <ImageContainer>
        <ImageStreetView src={URL.createObjectURL(imageStreetView)} onClick={onStreetView} />
      </ImageContainer>
      <br />
      <ClientHeader>
        <ImageEspecie>
          <img
            src={markersHelper.getSpecieIcon(client.specie)}
            style={{ width: 34, height: 34, objectFit: "cover" }}
          />
        </ImageEspecie>
        <ClientNameContent>
          <h4>{data.name}</h4>
          <p>{client.specie}</p>
        </ClientNameContent>
      </ClientHeader>
      <br />
      <PointDivider />
      <br />
      <DetailsContainer>
        <InfoHeader>
          <FiMapPin size={22} color="var(--red)" />
          <p>Endereço</p>
        </InfoHeader>
        <p>{addressHelper.formatCurrentAddress(data.currentAddress)}</p>
      </DetailsContainer>

      <DetailsContainer>
        <InfoHeader>
          <TbFileInvoice size={26} color="var(--red)" style={{ strokeWidth: 1.8 }} />
          <p>Dados</p>
        </InfoHeader>
        <p>Data de nascimento: {data.birthDate}</p>
        <p>Idade: {client.age}</p>
        <p>Nome da mãe: {client.motherName}</p>
        {client.email != "N/D" && <p>E-mail: {client.email}</p>}
      </DetailsContainer>

      <FullData onClick={onViewFullData}>Ver dados completos</FullData>
    </Container>
  );
};

export default ModalClientDetails;
