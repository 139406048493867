import React, { useEffect, useState } from "react";

import {
  ClientHeader,
  ClientNameContent,
  RowContent,
  RowItem,
  Container,
  DetailsContainer,
  Divider,
  ImageEspecie,
  ImageStreetView,
  InfoHeader,
  ReportDescription,
  ReportHeader,
  ReportItem,
  ReportsContent,
  Content,
  ResultText,
  AnaliseText,
  ClientHeaderLeft,
} from "./styles";

//Icons
import { FiMapPin } from "react-icons/fi";
import { TbPhoneCall, TbHammer, TbBriefcase, TbFileInvoice, TbFileSearch } from "react-icons/tb";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

//Custom components
import Loading from "@components/Loading/Loading";
import ModalHeader from "@components/Modal/ModalHeader/ModalHeader";
import axios from "axios";

//utils
import { markersHelper } from "@helpers/markers";
import { addressHelper } from "@helpers/address";
import { datetimeHelper } from "@helpers/datetime";
import ClientBadges from "@components/ClientBadges/ClientBadges";
import { Client } from "@models/Client";
import { GetClientReportsUseCase, GetClientUseCase } from "@dataUseCases/index";
import { HttpClientRepository, HttpReportRepository } from "@dataRepositories/index";
import { api } from "@infra/api";
import { Report } from "@models/Report";

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

type Props = {
  clientId: string;
  onClose: () => void;
  onStreetView: () => void;
};

function ModalDetailsOperator({ clientId, onClose, onStreetView }: Props) {
  const [loadingData, setLoadingData] = useState(true);
  const [client, setClient] = useState<Client>();
  const [clientReports, setClientReports] = useState<Report[]>();
  const [imageStreetView, setImageStreetView] = useState<Blob>();
  const [expanded, setExpanded] = useState(null);
  const [defaultReportExpanded, setDefaultReportExpanded] = useState(true);

  useEffect(() => {
    fetchClientData();
  }, [clientId]);

  useEffect(() => {
    (async () => {
      const useCase = new GetClientReportsUseCase(new HttpReportRepository(api));

      useCase.execute({ clientId: clientId }).then((reports) => {
        setClientReports(reports);
      });
    })();
  }, []);

  const fetchClientData = () => {
    setLoadingData(true);

    const useCase = new GetClientUseCase(new HttpClientRepository(api));

    useCase
      .execute(clientId)
      .then(async (client) => {
        await axios
          .get(
            `https://maps.googleapis.com/maps/api/streetview?size=724x144&location=${client.lat},${
              client.lng
            }&fov=80&heading=70&pitch=0&key=${apiKey}`,
            { responseType: "blob" }
          )
          .then((response) => {
            setImageStreetView(response.data);
          });
        setClient(client);
      })
      .catch(() => {
        onClose();
      })
      .finally(() => setLoadingData(false));
  };

  if (loadingData || !client) {
    return <Loading background="transparent" />;
  }

  return (
    <Container>
      <ModalHeader title="Detalhes do cliente" onClose={onClose} />
      <Content>
        <ImageStreetView
          src={URL.createObjectURL(imageStreetView)}
          onClick={() => onStreetView()}
        />

        <ClientHeader>
          <ClientHeaderLeft>
            <ImageEspecie>
              <img
                src={markersHelper.getSpecieIcon(client.specie)}
                style={{ width: 30, height: 30, objectFit: "cover" }}
              />
            </ImageEspecie>
            <ClientNameContent>
              <h4>{client.name}</h4>
              <p>{client.specie}</p>
            </ClientNameContent>
          </ClientHeaderLeft>
          <ClientBadges client={client} isModalDetails />
        </ClientHeader>

        <Divider />

        <DetailsContainer>
          <InfoHeader>
            <TbFileInvoice size={24} color="var(--red)" style={{ strokeWidth: 1.8 }} />
            <p>Dados</p>
          </InfoHeader>
          <RowContent>
            {client.birthDate && (
              <RowItem>
                <h3>Data de nascimento</h3>
                <p>{client.birthDate}</p>
              </RowItem>
            )}

            {client.age && (
              <RowItem>
                <h3>Idade</h3>
                <p>{client.age}</p>
              </RowItem>
            )}

            {client.cpf && (
              <RowItem>
                <h3>CPF</h3>
                <p>{client.cpf}</p>
              </RowItem>
            )}

            {client.motherName && (
              <RowItem>
                <h3>Nome da mãe</h3>
                <p>{client.motherName}</p>
              </RowItem>
            )}
          </RowContent>
        </DetailsContainer>

        <Divider />

        <DetailsContainer>
          <InfoHeader>
            <FiMapPin size={22} color="var(--red)" />
            <p>Endereço</p>
          </InfoHeader>
          <p>{addressHelper.formatAddress(client)}</p>
        </DetailsContainer>

        <Divider />

        <DetailsContainer>
          <InfoHeader>
            <TbPhoneCall size={22} color="var(--red)" />
            <p>Contato</p>
          </InfoHeader>
          <RowContent>
            {client.telephone.primary && client.telephone.primary != "N/D" && (
              <RowItem>
                <h3>Telefone principal</h3>
                <p>{client.telephone.primary}</p>
              </RowItem>
            )}

            {client.telephone.secondary && client.telephone.secondary != "N/D" && (
              <RowItem>
                <h3>Telefone secundário</h3>
                <p>{client.telephone.secondary}</p>
              </RowItem>
            )}

            {client.phones.phone && client.phones.phone != "N/D" && (
              <RowItem>
                <h3>Celular</h3>
                <p>{client.phones.phone}</p>
              </RowItem>
            )}

            {client.telephone[0] && client.telephone[0] != "N/D" && (
              <RowItem>
                <h3>Telefone 0</h3>
                <p>{client.telephone[0]}</p>
              </RowItem>
            )}

            {client.telephone[1] && client.telephone[1] != "N/D" && (
              <RowItem>
                <h3>Telefone 1</h3>
                <p>{client.telephone[1]}</p>
              </RowItem>
            )}

            {client.telephone[2] && client.telephone[2] != "N/D" && (
              <RowItem>
                <h3>Telefone 2</h3>
                <p>{client.telephone[2]}</p>
              </RowItem>
            )}

            {client.phones[0] && client.phones[0] != "N/D" && (
              <RowItem>
                <h3>Celular 0</h3>
                <p>{client.phones[0]}</p>
              </RowItem>
            )}

            {client.phones[1] && client.phones[1] != "N/D" && (
              <RowItem>
                <h3>Celular 1</h3>
                <p>{client.phones[1]}</p>
              </RowItem>
            )}

            {client.phones[2] && client.phones[2] != "N/D" && (
              <RowItem>
                <h3>Celular 2</h3>
                <p>{client.phones[2]}</p>
              </RowItem>
            )}
          </RowContent>
        </DetailsContainer>

        <Divider />

        <DetailsContainer>
          <InfoHeader>
            <TbHammer size={22} color="var(--red)" style={{ transform: "rotate(-90deg)" }} />
            <p>Processos arquivados</p>
          </InfoHeader>
          <p>{client.processes}</p>
        </DetailsContainer>

        <Divider />

        <DetailsContainer>
          <InfoHeader>
            <TbFileSearch size={22} color="var(--red)" />
            <p>Análise</p>
          </InfoHeader>
          {client.analysis &&
            client.analysis.length > 0 &&
            client.analysis
              .split("<br/>")
              .map((item, index) => <AnaliseText key={index}>{item}</AnaliseText>)}
        </DetailsContainer>

        <Divider />

        <DetailsContainer>
          <InfoHeader>
            <TbBriefcase size={22} color="var(--red)" />
            <p>Atendimentos</p>
          </InfoHeader>
          <ReportsContent>
            {clientReports &&
              clientReports.map((item, index) => (
                <ReportItem key={index}>
                  <ReportHeader>
                    <div className="left">
                      <p>
                        {`${datetimeHelper.getFullDate(item.createdAt)} - `}
                        <span>{item.operatorName}</span>
                      </p>
                      <ResultText>{item.result}</ResultText>
                    </div>

                    {defaultReportExpanded || expanded == index ? (
                      <IoIosArrowUp
                        cursor="pointer"
                        onClick={() => {
                          setExpanded(null);
                          setDefaultReportExpanded(false);
                        }}
                      />
                    ) : (
                      <IoIosArrowDown cursor="pointer" onClick={() => setExpanded(index)} />
                    )}
                  </ReportHeader>
                  {defaultReportExpanded || expanded == index ? (
                    <ReportDescription>
                      <p>{item.description}</p>
                    </ReportDescription>
                  ) : (
                    <></>
                  )}
                </ReportItem>
              ))}
          </ReportsContent>
        </DetailsContainer>
      </Content>
    </Container>
  );
}

export default ModalDetailsOperator;
