import styled from "styled-components";
import { breakpoints } from "@resources/breakpoints";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: #f5f5f5;

  border-radius: 20px;

  @media ${breakpoints.md} {
    height: 90dvh;
    width: 90dvw;
  }
`;

export const Content = styled.div`
  @media ${breakpoints.md} {
    overflow-y: none;
    overflow-x: hidden;
  }
`;

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  gap: 20px;

  cursor: pointer;
  /* margin-top: 20px; */

  @media ${breakpoints.md} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${breakpoints.sm} {
    grid-template-columns: 1fr;
  }
`;

export const ReturnCard = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  padding: 20px;

  gap: 20px;

  background-color: rgba(236, 242, 251, 0.5);

  box-sizing: border-box;

  border: 1px solid #b4c1d5;
  border-radius: 20px;
`;

export const ReturnCardHeader = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;

  justify-content: space-evenly;
  align-items: center;

  h3 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* or 171% */

    text-align: center;

    /* 2 */

    color: #808b9f;
  }
`;

export const DividerHeader = styled.div`
  width: 100%;
  border: 1px solid #ecf2f1;
`;

export const Divider = styled.div`
  width: 99%;
  height: 0.025rem;
  border: 0.025rem solid #cad5e6;
`;

export const ReturnCardNumber = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 24px;
  /* or 60% */

  text-align: center;

  /* FFD793 */

  color: ${(props) => props.color};
`;

export const ReturnsList = styled.div`
  min-height: 300px;
  max-height: 400px;

  background: rgba(236, 242, 251, 0.5);

  padding: 10px;
  margin-top: 20px;
  /* 3 */

  border: 1px solid #b4c1d5;
  border-radius: 15px;
`;

export const ReturnsListHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 10%;

  @media ${breakpoints.md} {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }

  h3 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */

    /* 1 */

    color: #4a5e83;
  }
`;

export const ReturnsListContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-top: 20px;
`;

export const ListItem = styled.div`
  display: grid;
  flex-direction: row;
  grid-template-columns: 2fr repeat(3, 0.75fr) 0.15fr;
  justify-content: center;
  align-items: center;
  height: 50px;
  padding: 10px 5px;
  gap: 5px;

  @media ${breakpoints.md} {
    height: 4rem;
  }
`;

export const ListClientName = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;

  text-align: left;
  /* identical to box height, or 171% */

  /* 1 */

  color: #4a5e83;

  @media ${breakpoints.md} {
    font-size: 0.9rem;
  }

  @media ${breakpoints.sm} {
    font-size: 0.7rem;
  }
`;

export const ListEspecie = styled.div`
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  justify-self: center;
  background: ${(props) => props.color};
  opacity: 0.8;
  border-radius: 40px;

  @media ${breakpoints.md} {
    padding: 2px 8px;
  }

  h4 {
    font-size: 10px;
    font-weight: 500;
    color: white;
    text-align: center;

    @media ${breakpoints.md} {
      font-size: 0.6rem;
    }

    @media ${breakpoints.sm} {
      font-size: 0.5rem;
    }
  }
`;

export const ListDate = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  text-align: center;
  /* identical to box height, or 171% */

  /* 2 */

  color: #808b9f;

  @media ${breakpoints.md} {
    font-size: 0.8rem;
  }

  @media ${breakpoints.sm} {
    font-size: 0.6rem;
  }
`;

export const ListOperator = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 5px;

  h4 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    /* identical to box height, or 171% */

    /* 1 */

    color: #4a5e83;

    @media ${breakpoints.sm} {
      display: none;
    }
  }
`;

export const ListOptions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
`;
