import { AlgoliaSearchClientRepository } from "@dataRepositories/index";
import { GetChartClientsUseCase } from "@dataUseCases/index";
import { datetimeHelper } from "@helpers/datetime";
import { algoliaIndex } from "@infra/algolia/config";
import { Filter } from "@models/Filters";

export default function useChart() {
  const getChartClients = async (filters: Filter[]) => {
    const validFilters = filters.filter((x) => x.type !== "date");

    const interval = datetimeHelper.getMillisecondsForYearBackwards();

    const useCase = new GetChartClientsUseCase(new AlgoliaSearchClientRepository(algoliaIndex));

    return useCase.execute({ filters: validFilters, monthsInterval: interval });
  };
  return {
    getChartClients,
  };
}
