import UserRepository from "@domainRepositories/UserRepository";
import showToast from "@helpers/showToast";
import { HttpClient } from "@infra/api/HttpClient";
import { ImageFile } from "@models/ImageFile";
import { User } from "@models/User";

export class HttpUserRepository implements UserRepository {
  private readonly baseUrl = `${process.env.REACT_APP_API_URL}/users`;

  constructor(private readonly httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async get(id: string) {
    return this.httpClient
      .request({
        url: `${this.baseUrl}/${id}`,
        method: "GET",
      })
      .then((response) => response.data);
  }

  async create(data: Partial<User>, userImage: ImageFile) {
    let dataToPost: Partial<User> & { image?: ImageFile } = data;

    if (userImage) {
      dataToPost = {
        ...dataToPost,
        image: userImage,
      };
    }

    return this.httpClient
      .request({
        url: `${this.baseUrl}`,
        method: "POST",
        body: JSON.stringify(dataToPost),
      })
      .then((response) => {
        showToast(response.message, "success");
        return response.data;
      });
  }

  async update(id: User["id"], data: Partial<User>, userImage: ImageFile) {
    let dataToPost: Partial<User> & { image?: ImageFile } = data;

    if (userImage) {
      dataToPost = {
        ...dataToPost,
        image: userImage,
      };
    }

    return this.httpClient
      .request({
        url: `${this.baseUrl}/${id}`,
        method: "PUT",
        body: JSON.stringify(dataToPost),
      })
      .then((response) => {
        showToast(response.message, "success");
        return response.data;
      });
  }

  async delete(id: User["id"]) {
    return this.httpClient
      .request({
        url: `${this.baseUrl}/${id}`,
        method: "DELETE",
      })
      .then((response) => {
        showToast(response.message, "success");
        return;
      });
  }
}
