import { Report } from "@models/Report";

export const reportResultSelectOptions: { label: Report["result"]; value: Report["result"] }[] = [
  {
    label: "Contrato fechado",
    value: "Contrato fechado",
  },
  {
    label: "Contrato fechado por indicação",
    value: "Contrato fechado por indicação",
  },
  {
    label: "Endereço não encontrado",
    value: "Endereço não encontrado",
  },
  {
    label: "Já tem advogado",
    value: "Já tem advogado",
  },
  {
    label: "Litispendência",
    value: "Litispendência",
  },
  {
    label: "Localidade diversa",
    value: "Localidade diversa",
  },
  {
    label: "Não tem direito",
    value: "Não tem direito",
  },
  {
    label: "Não tem interesse",
    value: "Não tem interesse",
  },
  {
    label: "Retornar",
    value: "Retornar",
  },
];
