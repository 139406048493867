import RouteRepository from "@domainRepositories/RouteRepository";
import UseCase from "@domainUseCases/UseCase";
import { Route } from "@models/Route";

type Input = {
  routeId: Route["id"];
  data: Partial<Route>;
};

type Output = Promise<Route>;

export class UpdateRouteUseCase implements UseCase<Input, Output> {
  constructor(private readonly routeRepository: RouteRepository) {}

  execute({ routeId, data }: Input) {
    return this.routeRepository.update(routeId, data);
  }
}
