import { Timestamp } from "firebase/firestore";
import { Report } from "./Report";
import { Route } from "./Route";

type ClientSpecie = {
  "LOAS DEF": string;
  "LOAS IDOSO": string;
  "AUX. DOENÇA": string;
  "AUX. BRASIL": string;
  "PENSÃO POR MORTE": string;
  "AUX. DOENÇA POR ACIDENTE DO TRABALHO": string;
  "AUX. ACIDENTE": string;
};

export const clientSpecieOptions = {
  DISABILITY: "LOAS DEF",
  ELDERLY: "LOAS IDOSO",
  DISEASE: "AUX. DOENÇA",
  BRAZIL: "AUX. BRASIL",
  DEATH_PENSION: "PENSÃO POR MORTE",
  DISEASE_WORK_ACCIDENT: "AUX. DOENÇA POR ACIDENTE DO TRABALHO",
  ACCIDENT: "AUX. ACIDENTE",
} as const;

export type Address = {
  street: string;
  number: string;
  district: string;
  state: string;
  city: string;
  zipCode: string;
  complement: string;
};

export type ClientAddress = {
  S1: Address;
  S2: Address;
  O1: Address;
  O2: Address;
};

export const clientStatus = {
  OPEN: "OPEN",
  CLOSED: "CLOSED",
} as const;

export const clientReportStatus = {
  ATTEND: "ATTEND",
  NOT_ATTEND: "NOT_ATTEND",
} as const;

export const clientReturnStatus = {
  NO_RETURN: "NO_RETURN",
  PENDING: "PENDING",
  PREMIUM: "PREMIUM",
  EXPIRED: "EXPIRED",
  COMPLETED: "COMPLETED",
} as const;

export const clientAddressStatus = {
  FOUND: "FOUND",
  NOT_FOUND: "NOT_FOUND",
  NOT_FOUND_OPERATOR: "NOT_FOUND_OPERATOR",
  DIVERSE_LOCATION: "DIVERSE_LOCATION",
} as const;

export class Client {
  id: string;
  cpf: string;
  name: string;
  email: string;
  motherName: string;

  age: string;
  birthDate: string;
  birth65Time: string;

  cityMap: string;
  mapId: string;
  lat: number | null;
  lng: number | null;

  isSelected: boolean;
  status: keyof typeof clientStatus;
  specie: keyof ClientSpecie;
  specialIcon: boolean;

  validAddress: keyof ClientAddress;
  addressStatus: keyof typeof clientAddressStatus;
  addresses: ClientAddress;

  phones: {
    "0": string;
    "1": string;
    "2": string;
    phone: string;
  };
  telephone: {
    "0": string;
    "1": string;
    "2": string;
    primary: string;
    secondary: string;
  };

  processes: string;
  analysis: string;
  rejection: {
    date: string;
    reason: string;
    nb: string;
    derDate: string;
  };

  hasReturn: boolean;
  returnStatus: keyof typeof clientReturnStatus;
  returnExpireDate: Timestamp | "";
  returnOperatorId: string;
  returnOperatorName: string;

  operatorId: string;
  operatorName: string;

  reportStatus: keyof typeof clientReportStatus;
  lastReportId: Report["id"];
  lastReportResult: Report["result"] | "";
  lastReportDate: Report["createdAt"] | "";
  currentRouteId: Route["id"];

  createdAt: Timestamp;
  updatedAt: Timestamp;

  constructor(clientInput: Client) {
    return clientInput;
  }
}
