import React from "react";
import useAuthentication from "@hooks/useAuthentication";
import PageLoading from "../components/PageLoading/PageLoading";
import { Navigate } from "react-router-dom";

type TAdminRouteProps = {
  children: JSX.Element;
  redirectTo: string;
};

export const AdminRoute = ({ children, redirectTo }: TAdminRouteProps): JSX.Element => {
  const { user, loading, isAdmin, isManager } = useAuthentication();

  if (loading) {
    return <PageLoading />;
  }

  return user && (isAdmin || isManager) ? children : <Navigate to={redirectTo} replace />;
};
