import React from "react";

//Styles
import {
  Container,
  RoutePointItemLeft,
  RoutePointItemInfo,
  PointDot,
  IconContainer,
} from "./styles";

//Icons
import PointIcon from "@components/Icons/PointIcon/PointIcon";

//utils
import { addressHelper } from "@helpers/address";
import { markersHelper } from "@helpers/markers";
import { RoutePoint } from "@models/RoutePoint";

type Props = {
  index: number;
  client: RoutePoint;
};

const OperatorRoutePoint = ({ index, client }: Props) => {
  return (
    <Container>
      <RoutePointItemLeft>
        <PointIcon index={index} size={18} />

        <PointDot />
        <PointDot />
        <PointDot />
        <PointDot />
        <PointDot />
        <PointDot />
      </RoutePointItemLeft>
      <IconContainer>
        <img style={{ width: 15 }} src={markersHelper.getSpecieIcon(client.specie)} alt="icon" />
      </IconContainer>
      <RoutePointItemInfo>
        <h4>{client.name}</h4>
        <p>{addressHelper.formatCurrentAddress(client.currentAddress)}</p>
      </RoutePointItemInfo>
    </Container>
  );
};

export default OperatorRoutePoint;
