import React, { useEffect, useState } from "react";
import {
  Container,
  UserCard,
  UserName,
  UsersCardsContainer,
  Header,
  UserCardHeader,
  UserCardContent,
  FieldText,
  UserCardText,
  NewUserButton,
  SwitchType,
  SwitchButton,
  EspeciesContent,
  EspeciesSelectorItem,
  EspeciesItem,
  EspeciesSelector,
  SaveEspeciesButton,
  EspeciesHeader,
  AdminCardContainer,
} from "./styles";

//Firebase and context

//Icons
import { TbPencil } from "react-icons/tb";
import { FiTrash2 } from "react-icons/fi";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { IoIosArrowDown, IoIosArrowUp, IoMdAdd } from "react-icons/io";
import logIconGrey from "@assets/logIconGrey.svg";

//Custom components
import ModalLogsUser from "@components/Modal/ModalLogs/ModalLogsUser";
import ProfileButton from "@components/ProfileButton/ProfileButton";
import ModalFormUser from "@components/Modal/ModalFormUser/ModalFormUser";
import ModalConfirm from "@components/Modal/ModalConfirm/ModalConfirm";
import PageLoading from "@components/PageLoading/PageLoading";

//MUI
import Avatar from "@mui/material/Avatar";

//Utils
import { toast } from "react-toastify";
import Select from "react-select";
import Modal from "react-modal";
import { User, userRoles } from "@models/User";
import { userRolesOptions } from "@constants/userRolesOptions";
import { alertErrorStyle } from "@resources/alertErrorStyle";
import { speciesFilterIcons } from "@resources/speciesFilterIcons";
import { arrayHelper } from "@helpers/array";
import _ from "lodash";
import { ClipLoader } from "react-spinners";
import useUser from "@hooks/useUser";
import useMaps from "@hooks/useMaps";
import { SelectOption } from "@models/SelectOption";
import { datetimeHelper } from "@helpers/datetime";
import { CreateUserUseCase, DeleteUserUseCase, UpdateUserUseCase } from "@dataUseCases/index";
import { HttpUserRepository } from "@dataRepositories/index";
import { api } from "@infra/api";
import { MapSpecie } from "@models/MapSpecie";
import { ImageFile } from "@models/ImageFile";

const UsersPage = () => {
  const { user, fetchUser } = useUser();
  const { maps } = useMaps();

  const [loadingData, setLoadingData] = useState(false);
  const [especiesSaveLoading, setEspeciesSaveLoading] = useState(false);

  //User manager
  const [selectedUser, setSelectedUser] = useState<User>();
  const [usersArray, setUsersArray] = useState<User[]>([]);
  const [mapOptions, setMapOptions] = useState<SelectOption[]>([]);
  const [operatorsOptions, setOperatorsOptions] = useState([]);
  const [showPasswordUser, setShowPasswordUser] = useState("");
  const [selectedType, setSelectedType] = useState<User["role"]>(userRoles.operator);

  //Modal
  const [modalData, setModalData] = useState({ text: "", option: "" });
  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalEditUser, setModalEditUser] = useState(false);
  const [modalLogs, setModalLogs] = useState(false);
  const [speciesExpanded, setSpeciesExpanded] = useState("");

  useEffect(() => {
    setLoadingData(true);

    if (user.users && maps) {
      const arrayOptions = [];

      if (maps) {
        for (const map of maps) {
          arrayOptions.push({
            label: map.name,
            value: map.id,
          });
        }
      }

      const operators = user.users.operators;
      const admins = user.users.admins;
      const managers = user.users.managers;

      setUsersArray(operators.concat(admins).concat(managers));

      const mappedOperatorOptions: SelectOption[] = operators.map((x) => ({
        label: x.name,
        value: x.id,
      }));
      setOperatorsOptions(mappedOperatorOptions);

      setMapOptions(arrayOptions);
      setLoadingData(false);
    }
  }, [maps, user]);

  const filteredUsers =
    selectedType != null ? usersArray.filter((x) => x.role == selectedType) : usersArray;

  const tryCreateUser = async (newUser: User, userImage: ImageFile) => {
    if (!newUser.email || !newUser.password || !newUser.name || !newUser.role) {
      setModalLoading(false);
      return toast(`Preencha os campos Nome, Email, Senha e Tipo para continuar`, alertErrorStyle);
    }

    const useCase = new CreateUserUseCase(new HttpUserRepository(api));

    useCase
      .execute({
        data: {
          name: newUser.name,
          email: newUser.email,
          login: newUser.login ? newUser.login : "",
          password: newUser.password,
          role: newUser.role,
        },
        userImage: userImage ? userImage : null,
      })
      .then(() => {
        fetchUser(user.id);

        setSelectedUser(null);
        setModalEditUser(false);
        window.location.reload();
      })
      .finally(() => {
        setModalLoading(false);
      });
  };

  const trySaveUser = async (newUser: User, userImage: ImageFile) => {
    const useCase = new UpdateUserUseCase(new HttpUserRepository(api));

    useCase
      .execute({
        userId: newUser.id,
        data: {
          name: newUser.name,
          email: newUser.email,
          login: newUser.login ? newUser.login : "",
          password: newUser.password,
          role: newUser.role,
          disabled: newUser.disabled,
        },
        userImage: userImage ? userImage : null,
      })
      .then(() => {
        fetchUser(user.id);

        setSelectedUser(null);
        setModalEditUser(false);
        window.location.reload();
      })
      .finally(() => {
        setModalLoading(false);
      });
  };

  const tryDeleteUser = async () => {
    setModalLoading(true);

    const useCase = new DeleteUserUseCase(new HttpUserRepository(api));

    useCase
      .execute(selectedUser.id)
      .then(() => {
        fetchUser(user.id);

        setSelectedUser(null);
        setModalEditUser(false);
        setModalConfirm(false);
        window.location.reload();
      })
      .finally(() => {
        setModalLoading(false);
      });
  };

  const changeOperators = async (operatorsArray, selectedUser: User) => {
    const newOperatorsIds = [];
    const newMapArray = [];

    for (const item of operatorsArray) {
      newOperatorsIds.push(item.value);
      const data = usersArray.find((x) => x.id == item.value);

      if (data) {
        for (const itemMapId of data.mapsIds) {
          const result = newMapArray.find((x) => x == itemMapId.value);

          if (!result) {
            newMapArray.push(itemMapId);
          }
        }
      }
    }

    const useCase = new UpdateUserUseCase(new HttpUserRepository(api));

    useCase
      .execute({
        userId: selectedUser.id,
        data: {
          operatorsIds: newOperatorsIds,
          mapsArray: newMapArray,
        },
      })
      .then(() => {
        fetchUser(user.id);
      });
  };

  const changeMap = async (mapsArray, selectedUser: User) => {
    const mapNames = [];
    let species: MapSpecie = {};

    for (const map of mapsArray) {
      mapNames.push(map.label);
    }

    if (selectedUser.species) {
      const keys = Object.keys(selectedUser.species);

      for (const mapName of mapNames) {
        const exists = keys.find((x) => x === mapName);

        if (exists) {
          species = {
            ...species,
            [mapName]: selectedUser.species[mapName],
          };
        } else {
          species = {
            ...species,
            [mapName]: arrayHelper.reduceToSimple(speciesFilterIcons, "specie"),
          };
        }
      }
    } else {
      for (const mapName of mapNames) {
        species = {
          ...species,
          [mapName]: arrayHelper.reduceToSimple(speciesFilterIcons, "specie"),
        };
      }
    }

    const useCase = new UpdateUserUseCase(new HttpUserRepository(api));

    useCase
      .execute({
        userId: selectedUser.id,
        data: {
          mapsIds: mapsArray,
          species: species,
        },
      })
      .then(() => {
        fetchUser(user.id);
      });
  };

  const getEspeciesByMaps = (operator: User) => {
    const array: { mapName: string; speciesArray: string[] }[] = [];

    if (operator.species) {
      const keys = Object.keys(operator.species);

      for (const mapName of keys) {
        array.push({
          mapName: mapName,
          speciesArray: operator.species[mapName],
        });
      }
    }

    return array;
  };

  const changeEspecie = (specie: string, mapName: string, operator: User) => {
    let newArray = [];

    const index = operator.species[mapName].findIndex((x) => x == specie);

    if (index != -1) {
      newArray = arrayHelper.removeItemOfArray(operator.species[mapName], index);
    } else {
      newArray = operator.species[mapName].concat([specie]);
    }

    const operatorIndex = usersArray.findIndex((x) => x.id == operator.id);

    const array1 = usersArray.slice(0, operatorIndex);
    const array2 = usersArray.slice(operatorIndex + 1, usersArray.length);

    if (operatorIndex != -1) {
      array1.push({
        ...operator,
        species: {
          ...operator.species,
          [mapName]: newArray,
        },
      });

      setUsersArray(array1.concat(array2));
    }
  };

  const saveChangedEspecies = async (operatorData: User) => {
    setEspeciesSaveLoading(true);

    const useCase = new UpdateUserUseCase(new HttpUserRepository(api));

    useCase
      .execute({
        userId: operatorData.id,
        data: {
          species: operatorData.species,
        },
      })
      .then(() => {
        fetchUser(user.id);
      })
      .finally(() => {
        setEspeciesSaveLoading(false);
      });
  };

  const handleSpeciesExpanded = (operator) => {
    if (speciesExpanded == operator.id) {
      setSpeciesExpanded("");
    } else {
      setSpeciesExpanded(operator.id);
    }
  };

  const verifyEspeciesChanges = (operator: User) => {
    const result = user.users.allUsers.find((x) => x.id == operator.id);

    if (result) {
      if (result.species && operator.species) {
        return !_.isEqual(result.species, operator.species);
      }

      if (!result.species) {
        return !_.isEqual(result.species, operator.species);
      }
    }
  };

  const getOperatorsDefaultValues = (operatorsIds: string[]) => {
    const operatorsOptions = [];

    for (const operator of user.users.operators) {
      if (operatorsIds.includes(operator.id)) {
        operatorsOptions.push({
          label: operator.name,
          value: operator.id,
        });
      }
    }

    return operatorsOptions;
  };

  const customStyle = {
    container: (base) => ({
      ...base,
      borderRadius: 40,
      marginBottom: 10,
    }),
    control: (base) => ({
      ...base,
      padding: 3,
      borderRadius: 40,
    }),
    multiValue: (base) => ({
      ...base,
      borderRadius: 40,
      backgroundColor: "#ECF2FB",
    }),
  };

  if (loadingData) {
    return (
      <Container>
        <PageLoading />
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <h1>Usuários</h1>
        <div>
          <NewUserButton
            onClick={() => {
              setSelectedUser(null);
              setModalEditUser(true);
            }}
          >
            <IoMdAdd />
            <h3>Novo usuário</h3>
          </NewUserButton>
          <ProfileButton arrowColor="var(grey2)" />
        </div>
      </Header>

      <AdminCardContainer>
        <UserCard>
          <UserCardHeader>
            <div>
              <Avatar
                alt="Remy Sharp"
                src={user.profileImageUrl}
                sx={{ width: 58, height: 58, cursor: "pointer" }}
                onClick={() => {
                  setSelectedUser(user);
                }}
              />
              <UserName>
                <h3>{user.name}</h3>
              </UserName>
            </div>
          </UserCardHeader>
        </UserCard>
      </AdminCardContainer>

      <br />
      <br />

      <SwitchType>
        <SwitchButton onClick={() => setSelectedType(userRoles.operator)}>
          Operadores
          {selectedType == userRoles.operator && <div></div>}
        </SwitchButton>
        <SwitchButton onClick={() => setSelectedType(userRoles.manager)}>
          Gerentes
          {selectedType == userRoles.manager && <div></div>}
        </SwitchButton>
        <SwitchButton onClick={() => setSelectedType(userRoles.admin)}>
          Administradores
          {selectedType == userRoles.admin && <div></div>}
        </SwitchButton>
        <SwitchButton onClick={() => setSelectedType(null)}>
          Todos
          {selectedType == null && <div></div>}
        </SwitchButton>
      </SwitchType>

      <UsersCardsContainer>
        {filteredUsers &&
          filteredUsers.map((item, index) => (
            <UserCard key={index}>
              <UserCardHeader>
                <div>
                  <Avatar
                    alt="Remy Sharp"
                    src={item.profileImageUrl}
                    sx={{ width: 58, height: 58, cursor: "pointer" }}
                    onClick={() => {
                      setSelectedUser(item);
                      setModalEditUser(true);
                    }}
                  />
                  <UserName>
                    <h3>{item.name}</h3>
                    <h4>{userRolesOptions.find((x) => x.value == item.role).label}</h4>
                  </UserName>
                </div>
                <div>
                  <TbPencil
                    size={22}
                    style={{ strokeWidth: 1.5, cursor: "pointer" }}
                    onClick={() => {
                      setSelectedUser(item);
                      setModalEditUser(true);
                    }}
                  />
                  <FiTrash2
                    color="var(--red)"
                    size={20}
                    style={{ strokeWidth: 1.5, cursor: "pointer" }}
                    onClick={() => {
                      setSelectedUser(item);
                      setModalData({
                        text: `Tem certeza que deseja excluir ${item.name}?`,
                        option: "Excluir",
                      });
                      setModalConfirm(true);
                    }}
                  />
                </div>
              </UserCardHeader>
              <UserCardContent>
                {item.role == userRoles.manager && (
                  <>
                    <FieldText>Seleção de operadores:</FieldText>
                    <Select
                      id="select-manager"
                      key={`select-operator-${item.id}`}
                      options={operatorsOptions}
                      isMulti
                      defaultValue={getOperatorsDefaultValues(item.operatorsIds)}
                      styles={customStyle}
                      onChange={(selectedOption) => {
                        changeOperators(selectedOption, item);
                      }}
                    />
                  </>
                )}
                {item.role == userRoles.operator && (
                  <>
                    <FieldText>Seleção de mapa:</FieldText>
                    <Select
                      id="select-map"
                      key={`select-map-${item.id}`}
                      options={mapOptions}
                      isMulti
                      defaultValue={item.mapsIds}
                      styles={customStyle}
                      onChange={(selectedOption) => {
                        changeMap(selectedOption, item);
                      }}
                    />

                    <EspeciesHeader onClick={() => handleSpeciesExpanded(item)}>
                      <FieldText>Seleção de benefícios:</FieldText>
                      {speciesExpanded == item.id ? (
                        <IoIosArrowUp cursor="pointer" />
                      ) : (
                        <IoIosArrowDown cursor="pointer" />
                      )}
                    </EspeciesHeader>

                    {speciesExpanded == item.id && (
                      <EspeciesContent>
                        {getEspeciesByMaps(item).length == 0 && <h4>Nenhum mapa selecionado</h4>}
                        {getEspeciesByMaps(item).map((itemSpecie, index) => (
                          <EspeciesItem key={index}>
                            <h3>{itemSpecie.mapName}</h3>
                            <EspeciesSelector>
                              {speciesFilterIcons.map((specie, index) => (
                                <EspeciesSelectorItem
                                  key={index}
                                  title={specie.title}
                                  selected={arrayHelper.findInArray(
                                    itemSpecie.speciesArray,
                                    specie.specie
                                  )}
                                  onClick={() =>
                                    changeEspecie(specie.specie, itemSpecie.mapName, item)
                                  }
                                >
                                  <img src={specie.icon} />
                                </EspeciesSelectorItem>
                              ))}
                            </EspeciesSelector>
                          </EspeciesItem>
                        ))}
                        {verifyEspeciesChanges(item) && (
                          <SaveEspeciesButton onClick={() => saveChangedEspecies(item)}>
                            {especiesSaveLoading ? (
                              <ClipLoader
                                color="var(--white)"
                                size={30}
                                loading={true}
                                speedMultiplier={1.5}
                              />
                            ) : (
                              <h3>Salvar benefícios</h3>
                            )}
                          </SaveEspeciesButton>
                        )}
                      </EspeciesContent>
                    )}
                  </>
                )}
                <UserCardText>
                  <div>
                    <span>E-mail:</span>
                    <h4>{item.email}</h4>
                  </div>
                </UserCardText>
                <UserCardText>
                  <div>
                    <span>Login:</span>
                    <h4>{item.login ? item.login : "Não definido"}</h4>
                  </div>
                </UserCardText>
                <UserCardText>
                  <div>
                    <span>Senha:</span>
                    <h4>
                      {item.password
                        ? showPasswordUser === item.id
                          ? item.password
                          : "******"
                        : "Não definida"}
                    </h4>
                    {showPasswordUser === item.id ? (
                      <AiOutlineEyeInvisible
                        cursor="pointer"
                        onClick={() => setShowPasswordUser("")}
                      />
                    ) : (
                      <AiOutlineEye cursor="pointer" onClick={() => setShowPasswordUser(item.id)} />
                    )}
                  </div>
                </UserCardText>
                <UserCardText>
                  <div>
                    <span>Criado em:</span>
                    <h4>{datetimeHelper.getFullDate(item.createdAt)}</h4>
                  </div>
                  <img
                    src={logIconGrey}
                    alt="icon icon"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSelectedUser(item);
                      setModalLogs(true);
                    }}
                  />
                </UserCardText>
              </UserCardContent>
            </UserCard>
          ))}
      </UsersCardsContainer>

      <Modal
        isOpen={modalEditUser}
        onRequestClose={() => setModalEditUser(false)}
        overlayClassName="modal-overlay"
        className="modal-content-auto-width"
        contentLabel="Example Modal"
      >
        <ModalFormUser
          data={selectedUser}
          title={selectedUser ? "Editar usuário" : "Novo usuário"}
          onClose={() => setModalEditUser(false)}
          loading={modalLoading}
          onSave={async (newUser, imageFile) => {
            setModalLoading(true);
            setTimeout(async () => {
              if (selectedUser) {
                await trySaveUser(newUser, imageFile);
              } else {
                await tryCreateUser(newUser, imageFile);
              }
            }, 2000);
          }}
        />
      </Modal>

      <Modal
        isOpen={modalConfirm}
        onRequestClose={() => {
          setModalData({ text: "", option: "" });
          setModalConfirm(false);
        }}
        overlayClassName="modal-overlay"
        className="modal-content-auto-width"
        contentLabel="Example Modal"
      >
        <ModalConfirm
          text={modalData?.text}
          option={modalData?.option}
          loading={modalLoading}
          onCancel={() => {
            setModalData({ text: "", option: "" });
            setModalConfirm(false);
          }}
          onConfirm={async () => {
            await tryDeleteUser();
          }}
        />
      </Modal>

      <Modal
        isOpen={modalLogs}
        onRequestClose={() => {
          setModalLogs(false);
        }}
        overlayClassName="modal-overlay"
        className="modal-content-auto-width"
        contentLabel="Example Modal"
      >
        <ModalLogsUser
          userName={selectedUser?.name}
          userId={selectedUser?.id}
          onClose={() => {
            setModalLogs(false);
          }}
        />
      </Modal>
    </Container>
  );
};

export default UsersPage;
