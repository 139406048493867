import React, { useState } from "react";

import { Container, FieldText, TextInput, ActionButtons, CancelButton, SaveButton } from "./styles";

//Custom components
import ModalHeader from "@components/Modal/ModalHeader/ModalHeader";
import CustomLoading from "@components/Loading/Loading";
import Divider from "@components/Divider/Divider";

//Util
import Select from "react-select";
import { Map } from "@models/Map";
import { SelectOption } from "@models/SelectOption";
import { CreateMapUseCase, UpdateMapUseCase } from "@dataUseCases/index";
import { HttpMapRepository } from "@dataRepositories/index";
import { api } from "@infra/api";

type Props = {
  title: string;
  mapId: Map["id"] | null;
  data: Pick<Map, "name" | "cities">;
  citiesOptions: SelectOption[];
  onClose: () => void;
};

function ModalFormMap({ title, mapId, data, citiesOptions, onClose }: Props) {
  const [newMap, setNewMap] = useState<Pick<Map, "name" | "cities">>(data);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (event) => {
    setNewMap({
      ...newMap,
      name: event.target.value,
    });
  };

  const handleInputCities = (items) => {
    setNewMap({
      ...newMap,
      cities: items,
    });
  };

  const handleSave = () => {
    if (newMap.name && newMap.cities.length > 0) {
      setLoading(true);

      if (mapId) {
        const useCase = new UpdateMapUseCase(new HttpMapRepository(api));

        useCase
          .execute({ mapId, data: newMap })
          .then(() => {
            onClose();
          })
          .finally(() => setLoading(false));
      } else {
        const useCase = new CreateMapUseCase(new HttpMapRepository(api));

        useCase
          .execute(newMap)
          .then(() => {
            onClose();
          })
          .finally(() => setLoading(false));
      }
    }
  };

  const customStyle = {
    container: (base) => ({
      ...base,
      borderRadius: 40,
    }),
    control: (base) => ({
      ...base,
      padding: 10,
      borderRadius: 40,
    }),
    multiValue: (base) => ({
      ...base,
      borderRadius: 40,
      backgroundColor: "#ECF2FB",
    }),
  };

  if (loading || !newMap) {
    <Container>
      <CustomLoading />
    </Container>;
  }

  return (
    <Container>
      <ModalHeader title={title} onClose={onClose} />

      <FieldText>Nome</FieldText>
      <TextInput>
        <input
          name="mapName"
          onChange={(event) => handleInputChange(event)}
          placeholder="Nome do mapa"
          defaultValue={newMap ? newMap.name : null}
        />
      </TextInput>
      <br />
      <FieldText>Cidades</FieldText>
      <Select
        isClearable={false}
        value={newMap ? newMap.cities : null}
        isSearchable={true}
        closeMenuOnSelect={false}
        options={citiesOptions}
        isMulti={true}
        styles={customStyle}
        autoFocus={true}
        openMenuOnFocus={true}
        onChange={(item) => {
          handleInputCities(item);
        }}
      />
      <br />
      <Divider width="100%" />
      <ActionButtons>
        {loading ? (
          <CustomLoading />
        ) : (
          <>
            <CancelButton
              onClick={() => {
                onClose();
              }}
            >
              <p>Cancelar</p>
            </CancelButton>
            <SaveButton disabled={!newMap.name || newMap.cities.length === 0} onClick={handleSave}>
              <p>Salvar</p>
            </SaveButton>
          </>
        )}
      </ActionButtons>
    </Container>
  );
}

export default ModalFormMap;
