import UserRepository from "@domainRepositories/UserRepository";
import UseCase from "@domainUseCases/UseCase";
import { ImageFile } from "@models/ImageFile";
import { User } from "@models/User";

type Input = {
  data: Partial<User>;
  userImage?: ImageFile;
};

type Output = Promise<User>;

export class CreateUserUseCase implements UseCase<Input, Output> {
  constructor(private readonly userRepository: UserRepository) {}

  execute({ data, userImage }: Input) {
    return this.userRepository.create(data, userImage);
  }
}
