import { FirebaseRouteRepository } from "@dataRepositories/Firebase/FirebaseRouteRepository";
import { WatchRoutesOfDay, WatchRoutesUseCase } from "@dataUseCases/index";
import routeStore from "@stores/route";
import userStore from "@stores/user";
import { Timestamp } from "firebase/firestore";
import { useEffect } from "react";
import { useStore } from "zustand";

export default function useRoutes() {
  const { routesOfDay, allRoutes, allRoutesTotal, operatorRoutes } = useStore(routeStore);

  const getRoutesOfDay = async () => {
    const dateNow = new Date();
    const startDate = Timestamp.fromMillis(dateNow.getTime() - 2 * (86400 * 1000));

    const endDate = Timestamp.fromDate(dateNow);

    const useCase = new WatchRoutesOfDay(
      userStore.getState(),
      new FirebaseRouteRepository(routeStore.getState())
    );

    useCase.execute({ startDate, endDate });
  };

  const getRoutes = () => {
    const useCase = new WatchRoutesUseCase(
      userStore.getState(),
      new FirebaseRouteRepository(routeStore.getState())
    );

    useCase.execute();
  };

  useEffect(() => {
    if (routesOfDay.length === 0) {
      getRoutesOfDay();
    }

    if (allRoutes.length === 0) {
      getRoutes();
    }
  }, []);

  return {
    routesOfDay,
    allRoutes: allRoutes,
    allRoutesTotal: allRoutesTotal,
    operatorRoutes: operatorRoutes,
  };
}
