import { ReportRepository } from "@domainRepositories/ReportRepository";
import UseCase from "@domainUseCases/UseCase";
import { Report } from "@models/Report";

type Input = {
  clientId: string;
};

type Output = Promise<Report[]>;

export class GetClientReportsUseCase implements UseCase<Input, Output> {
  constructor(private readonly reportRepository: ReportRepository) {}

  execute({ clientId }: Input) {
    return this.reportRepository.getClientReports(clientId);
  }
}
