import ClientRepository from "@domainRepositories/ClientRepository";
import UseCase from "@domainUseCases/UseCase";
import { Client } from "@models/Client";

type Input = {
  clientId: Client["id"];
  data: Partial<Client>;
};

type Output = Promise<Client>;

export class UpdateClientUseCase implements UseCase<Input, Output> {
  constructor(private readonly clientRepository: ClientRepository) {}

  async execute({ clientId, data }: Input) {
    return this.clientRepository.save(clientId, data);
  }
}
