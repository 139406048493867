import { RouteStore } from "@domainStores/RouteStore";
import { create } from "zustand";

type InitialState = Pick<
  RouteStore,
  "routesOfDay" | "allRoutes" | "allRoutesTotal" | "operatorRoutes"
>;

const initialState: InitialState = {
  routesOfDay: [],
  allRoutes: [],
  allRoutesTotal: 0,
  operatorRoutes: [],
};

const routeStore = create<RouteStore>((set) => ({
  ...initialState,
  updateRoutesOfDay: (newRoutes) =>
    set((state) => {
      return {
        ...state,
        routesOfDay: newRoutes,
      };
    }),
  updateAllRoutes(newRoutes, total) {
    set((state) => {
      return {
        ...state,
        allRoutes: newRoutes,
        allRoutesTotal: total,
      };
    });
  },
  updateOperatorRoutes(newRoutes) {
    set((state) => {
      return {
        ...state,
        operatorRoutes: newRoutes,
      };
    });
  },
  clear: () => {
    set((state) => {
      return {
        ...state,
        ...initialState,
      };
    });
  },
}));

export default routeStore;
