import { HttpRouteRepository } from "@dataRepositories/index";
import { GetDaysWithRoutesUseCase } from "@dataUseCases/index";
import { datetimeHelper } from "@helpers/datetime";
import { api } from "@infra/api";
import { DaysWithRoutes } from "@models/DaysWithRoutes";
import { Filter } from "@models/Filters";
import { Timestamp } from "firebase/firestore";
import { useEffect, useState } from "react";

type Props = {
  headerFilters: Filter[];
};

const dateNow = new Date();
const firstDay = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);
const defaultStartDate = Timestamp.fromDate(firstDay);
const defaultEndDate = Timestamp.fromDate(dateNow);

export default function useDaysWithRoutes({ headerFilters }: Props) {
  const [daysWithRoutes, setDaysWithRoutes] = useState<DaysWithRoutes>();

  const getDaysWithRoutes = (startDate: Timestamp, endDate: Timestamp) => {
    const getDaysWithRoutesUseCase = new GetDaysWithRoutesUseCase(new HttpRouteRepository(api));

    getDaysWithRoutesUseCase.execute({ startDate, endDate }).then((daysWithRoutes) => {
      setDaysWithRoutes(daysWithRoutes);
    });
  };

  useEffect(() => {
    const headerDateFilter = headerFilters.find((x) => x.type === "date");

    if (headerDateFilter && headerDateFilter?.dateValue) {
      const startDate = datetimeHelper.getTimestampFromFilter(
        headerDateFilter.dateValue.selectedStart
      );
      const endDate = datetimeHelper.getTimestampFromFilter(headerDateFilter.dateValue.selectedEnd);

      getDaysWithRoutes(startDate, endDate);
    } else {
      getDaysWithRoutes(defaultStartDate, defaultEndDate);
    }
  }, [headerFilters]);

  return { daysWithRoutes };
}
