import UserRepository from "@domainRepositories/UserRepository";
import UseCase from "@domainUseCases/UseCase";
import { ImageFile } from "@models/ImageFile";
import { User } from "@models/User";

type Input = {
  userId: User["id"];
  data: Partial<User>;
  userImage?: ImageFile;
};

type Output = Promise<User>;

export class UpdateUserUseCase implements UseCase<Input, Output> {
  constructor(private readonly userRepository: UserRepository) {}

  execute({ userId, data, userImage }: Input) {
    return this.userRepository.update(userId, data, userImage);
  }
}
