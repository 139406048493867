import React from "react";

import { Container } from "./styles";

type Props = {
  name: string;
  defaultValue: string;
  placeholder?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  onChange: (value: string) => void;
};

const TextInput = ({
  name,
  defaultValue,
  placeholder,
  disabled = false,
  style,
  onChange,
}: Props) => {
  return (
    <Container>
      <input
        name={name}
        onChange={(event) => onChange(event.target.value)}
        defaultValue={defaultValue}
        placeholder={placeholder}
        disabled={disabled}
        style={style}
      />
    </Container>
  );
};

export default TextInput;
