import React from "react";

//Styles
import {
  ActionsContainer,
  Container,
  SavedPointsContainer,
  SavedRouteDetails,
  SavedRouteHeader,
  SavedRouteInfo,
  SavedRouteName,
} from "./styles";

//Icons
import { BiUser } from "react-icons/bi";
import { AiOutlineClockCircle } from "react-icons/ai";
import { TbCalendarMinus, TbFile, TbPencil } from "react-icons/tb";
import { FiTrash2 } from "react-icons/fi";
import ElipseIcon from "@components/Icons/ElipseIcon/ElipseIcon";
import Divider from "@components/Divider/Divider";
import StatusIcon from "@components/Icons/StatusIcon/StatusIcon";
import RouteClientPoint from "@components/RouteClientPoint/RouteClientPoint";
import { datetimeHelper } from "../../helpers/datetime";
import { Route, routeStatus } from "@models/Route";
import useUser from "@hooks/useUser";
import { RoutePoint } from "@models/RoutePoint";

type Props = {
  route: Route;
  onEdit: () => void;
  onRemove?: (clientId: RoutePoint["id"]) => void;
  onCancel: () => void;
  inEdit?: boolean;
};

const RouteCard = ({ route, onEdit, onRemove, onCancel, inEdit }: Props) => {
  const { isAdmin } = useUser();

  return (
    <Container>
      {!inEdit && (
        <>
          <SavedRouteHeader>
            <SavedRouteName>
              <p>{route.name}</p>
              <ElipseIcon
                text={route.points.length}
                size={17}
                background="var(--blue3)"
                fontWeight="400"
                fontSize={12}
              />
            </SavedRouteName>
            {route.status == routeStatus.WAITING && isAdmin && (
              <ActionsContainer>
                <TbPencil
                  onClick={onEdit}
                  size={22}
                  style={{ strokeWidth: 1.5, cursor: "pointer" }}
                />
                <FiTrash2
                  onClick={onCancel}
                  color="var(--red)"
                  size={20}
                  style={{ strokeWidth: 1.5, cursor: "pointer" }}
                />
              </ActionsContainer>
            )}
          </SavedRouteHeader>
          <SavedRouteDetails>
            <SavedRouteInfo>
              <BiUser />
              <p>Operador: {route.operatorName}</p>
            </SavedRouteInfo>
            <SavedRouteInfo>
              <TbFile />
              <p>ID: {route.id}</p>
            </SavedRouteInfo>
            <SavedRouteInfo>
              <AiOutlineClockCircle />
              <p>Status:</p>
              <StatusIcon status={route.status} />
            </SavedRouteInfo>
            <SavedRouteInfo>
              <TbCalendarMinus />
              <p>Data de criação: {datetimeHelper.getFullDate(route.createdAt)}</p>
            </SavedRouteInfo>
            <SavedRouteInfo>
              <TbCalendarMinus />
              <p>Início: {route.startAt ? datetimeHelper.getFullDate(route.startAt) : "---"}</p>
            </SavedRouteInfo>
            <SavedRouteInfo>
              <TbCalendarMinus />
              <p>Finalização: {route.endAt ? datetimeHelper.getFullDate(route.endAt) : "---"}</p>
            </SavedRouteInfo>
          </SavedRouteDetails>
        </>
      )}
      <Divider width="100%" />
      <SavedPointsContainer inEdit={inEdit}>
        {route.points.length != 0 &&
          route.points.map((item, index) => (
            <RouteClientPoint
              key={index}
              index={index}
              point={item}
              inEdit={inEdit}
              onRemove={(clientId) => onRemove(clientId)}
            />
          ))}
      </SavedPointsContainer>
    </Container>
  );
};

export default RouteCard;
