import { ReportRepository } from "@domainRepositories/ReportRepository";
import UseCase from "@domainUseCases/UseCase";
import { Report } from "@models/Report";

type Input = Report["id"];

type Output = Promise<Report[]>;

export class DeleteReportUseCase implements UseCase<Input, Output> {
  constructor(private readonly reportRepository: ReportRepository) {
    this.reportRepository = reportRepository;
  }

  async execute(reportId: Input) {
    return this.reportRepository.delete(reportId);
  }
}
