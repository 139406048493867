import React, { useEffect, useState } from "react";

import {
  Container,
  Header,
  WeekHeader,
  Divider,
  DaysContainer,
  DayItem,
  Selected,
  DayItemSelected,
  TextClearFilters,
  RowButtons,
  ConfirmButton,
} from "./styles";

import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { Filter } from "@models/Filters";
import { Client } from "@models/Client";
import Loading from "@components/Loading/Loading";
import { monthNames } from "@constants/monthNames";
import { Route } from "@models/Route";

//utils
const dateNow = new Date();

const weekDays = ["D", "S", "T", "Q", "Q", "S", "S"];

const empty = { day: "", month: "", year: "" };

type Props = {
  filters: Filter[];
  dateField: keyof Client | keyof Route;
  onFilterDate: ({ selectedStart, selectedEnd }: Filter["dateValue"]) => void;
  clearDateFilter: () => void;
  onClose: () => void;
};

function DateFilter({ filters, dateField, onFilterDate, clearDateFilter, onClose }: Props) {
  const [days, setDays] = useState<string[]>([]);

  const exists = filters.find((x) => x.field === dateField);

  const [currentMonth, setCurrentMonth] = useState(
    exists ? parseInt(exists.dateValue?.selectedStart.month) : dateNow.getMonth() + 1
  );
  const [currentYear, setCurrentYear] = useState(
    exists ? parseInt(exists.dateValue?.selectedStart.year) : dateNow.getFullYear()
  );

  const [selectedStart, setSelectedStart] = useState(
    exists ? exists.dateValue?.selectedStart : empty
  );
  const [selectedEnd, setSelectedEnd] = useState(exists ? exists.dateValue?.selectedEnd : empty);

  useEffect(() => {
    processDays(currentMonth, currentYear);
  }, [currentMonth, currentYear]);

  const processDays = async (month, year) => {
    const days = [];
    let count = 0;
    const dayStart = new Date(`${month}/1/${year}`).getDay();
    const dayEnd = new Date(year, month, 0).getDate();

    for (let i = 1; i < dayEnd + dayStart + 1; i++) {
      if (i >= dayStart + 1 && count <= dayEnd - 1) {
        days.push(count + 1);
        count++;
      } else {
        days.push("");
      }
    }

    setDays(days);
  };

  const handleSave = () => {
    if (JSON.stringify(empty) !== JSON.stringify(selectedStart)) {
      if (JSON.stringify(selectedEnd) !== JSON.stringify(empty)) {
        onFilterDate({ selectedStart: selectedStart, selectedEnd: selectedEnd });
      } else {
        onFilterDate({ selectedStart: selectedStart, selectedEnd: selectedEnd });
      }
    } else {
      const exists = filters.find((x) => x.field === dateField);

      if (!exists) {
        clearDateFilter();
      }
    }

    onClose();
  };

  const verifyCurrentDay = (item) => {
    if (
      dateNow.getDate() === item &&
      dateNow.getMonth() + 1 === currentMonth &&
      dateNow.getFullYear() === dateNow.getFullYear()
    ) {
      return true;
    } else {
      return false;
    }
  };

  const verifySelectable = (item): boolean => {
    if (currentYear < dateNow.getFullYear()) {
      return true;
    }

    if (currentMonth <= dateNow.getMonth() + 1 && currentYear <= dateNow.getFullYear()) {
      if (dateNow.getMonth() + 1 === currentMonth) {
        if (item <= dateNow.getDate()) {
          return true;
        }
      } else {
        return true;
      }
    }

    return false;
  };

  if (!days) {
    <Container>
      <Loading />
    </Container>;
  }

  return (
    <Container>
      <Header>
        <BsArrowLeft
          size={24}
          cursor="pointer"
          onClick={() => {
            if (currentMonth - 1 <= 0) {
              setCurrentYear(currentYear - 1);
              setCurrentMonth(12);
            } else {
              setCurrentMonth(currentMonth - 1);
            }
          }}
        />
        <h3>{`${monthNames.full[currentMonth - 1]} ${currentYear}`}</h3>
        <BsArrowRight
          size={24}
          cursor="pointer"
          onClick={() => {
            if (currentMonth + 1 > 12) {
              setCurrentYear(currentYear + 1);
              setCurrentMonth(1);
            } else {
              setCurrentMonth(currentMonth + 1);
            }
          }}
        />
      </Header>
      <WeekHeader>
        {weekDays.map((item, index) => (
          <h4 key={index}>{item}</h4>
        ))}
      </WeekHeader>
      <Divider />
      <DaysContainer>
        {days &&
          days.map((item, index) => {
            const itemDate: Filter["dateValue"]["selectedStart"] = {
              day: item,
              month: String(currentMonth),
              year: String(currentYear),
            };

            const itemTime = new Date(
              `${itemDate.month}/${itemDate.day}/${itemDate.year}`
            ).getTime();
            const selectedStartTime = new Date(
              `${selectedStart.month}/${selectedStart.day}/${selectedStart.year}`
            ).getTime();
            const selectedEndTime = new Date(
              `${selectedEnd.month}/${selectedEnd.day}/${selectedEnd.year}`
            ).getTime();

            const itemDateValues = Object.values(itemDate);
            const selectedStartValues = Object.values(selectedStart);
            const selectedEndValues = Object.values(selectedEnd);

            const emptyValues = Object.values(empty);

            if (
              JSON.stringify(itemDateValues) === JSON.stringify(selectedStartValues) ||
              JSON.stringify(itemDateValues) === JSON.stringify(selectedEndValues)
            ) {
              let type: "start" | "end" = "start";
              let background = "white";

              if (JSON.stringify(itemDateValues) === JSON.stringify(selectedStartValues)) {
                type = "start";
              }
              if (JSON.stringify(itemDateValues) === JSON.stringify(selectedEndValues)) {
                type = "end";
              }

              if (JSON.stringify(selectedStartValues) !== JSON.stringify(emptyValues)) {
                if (JSON.stringify(selectedEndValues) !== JSON.stringify(emptyValues)) {
                  background = "rgba(222, 97, 97, 0.2)";
                }
              }

              return (
                <DayItemSelected
                  type={type}
                  background={background}
                  onClick={() => {
                    if (JSON.stringify(itemDateValues) === JSON.stringify(selectedStartValues)) {
                      if (JSON.stringify(itemDateValues) !== JSON.stringify(emptyValues)) {
                        setSelectedStart(selectedEnd);
                      } else {
                        setSelectedStart(empty);
                      }
                    }

                    if (JSON.stringify(itemDateValues) === JSON.stringify(selectedEndValues)) {
                      setSelectedEnd(empty);
                    }
                  }}
                  key={index}
                >
                  <Selected>{item}</Selected>
                </DayItemSelected>
              );
            } else {
              let background = "white";

              if (itemTime > selectedStartTime && itemTime < selectedEndTime) {
                background = "rgba(222, 97, 97, 0.2)";
              }

              return (
                <DayItem
                  current={verifyCurrentDay(item)}
                  selectable={verifySelectable(item)}
                  background={background}
                  onClick={() => {
                    if (verifySelectable(item)) {
                      if (JSON.stringify(selectedStartValues) === JSON.stringify(emptyValues)) {
                        setSelectedStart(itemDate);
                      } else {
                        setSelectedEnd(itemDate);
                      }
                    }
                  }}
                  key={index}
                >
                  {item}
                </DayItem>
              );
            }
          })}
      </DaysContainer>
      <RowButtons>
        <TextClearFilters
          onClick={() => {
            clearDateFilter();
            setSelectedStart(empty);
            setSelectedEnd(empty);
          }}
        >
          Limpar
        </TextClearFilters>
        <ConfirmButton onClick={() => handleSave()}>
          <h3>OK</h3>
        </ConfirmButton>
      </RowButtons>
    </Container>
  );
}

export default DateFilter;
