import React from "react";

import { Container, Content, ActionsContent, ConfirmButton, ModalText } from "./styles";

import { IoWarningOutline } from "react-icons/io5";

import { ClearStoresUseCase } from "@dataUseCases/index";
import attendClientsStore from "@stores/attendClient";
import mapClientStore from "@stores/mapClient";
import mapStore from "@stores/map";
import reportStore from "@stores/report";
import returnClientStore from "@stores/returnClient";
import routeStore from "@stores/route";
import userStore from "@stores/user";
import useUser from "@hooks/useUser";

const ModalNoMap = () => {
  const { logoutUser } = useUser();

  const clearAllStores = () => {
    const useCase = new ClearStoresUseCase(
      attendClientsStore.getState(),
      mapClientStore.getState(),
      mapStore.getState(),
      reportStore.getState(),
      returnClientStore.getState(),
      routeStore.getState(),
      userStore.getState()
    );

    useCase.execute();
  };

  const logout = async () => {
    clearAllStores();
    logoutUser();
  };

  return (
    <Container>
      <Content>
        <IoWarningOutline size={80} />
        <ModalText>
          Você não possui acesso ao mapa. Entre em contato com o seu gerente ou administrador para
          configurar sua conta.
        </ModalText>
      </Content>
      <ActionsContent>
        <ConfirmButton onClick={() => logout()}>
          <p>Sair</p>
        </ConfirmButton>
      </ActionsContent>
    </Container>
  );
};

export default ModalNoMap;
