import React, { useState } from "react";
import {
  CancelButton,
  DetailsContainer,
  EditButtons,
  InfoHeader,
  RowContent,
  RowItem,
  SaveButton,
} from "../styles";
import { TbPencil, TbPhoneCall } from "react-icons/tb";
import { Client } from "@models/Client";
import TextInput from "@components/Form/TextInput/TextInput";

type Props = {
  client: Client;
  inEdit: boolean;
  isAdmin: boolean;
  handleActiveEdit?: (section: string) => void;
  handleSaveClient?: (newClient: Partial<Client>) => void;
  onClose?: () => void;
};

type SubSection = {
  name: string;
  field: keyof Pick<Client, "phones" | "telephone">;
  subField: keyof Client["phones"] | keyof Client["telephone"];
  disabled: boolean;
};

const subSections: SubSection[] = [
  {
    name: "Telefone principal",
    field: "telephone",
    subField: "primary",
    disabled: false,
  },
  {
    name: "Telefone secundário",
    field: "telephone",
    subField: "secondary",
    disabled: false,
  },
  {
    name: "Celular",
    field: "phones",
    subField: "phone",
    disabled: false,
  },
  {
    name: "Telefone 0",
    field: "telephone",
    subField: "0",
    disabled: false,
  },
  {
    name: "Telefone 1",
    field: "telephone",
    subField: "1",
    disabled: false,
  },
  {
    name: "Telefone 2",
    field: "telephone",
    subField: "2",
    disabled: false,
  },
  {
    name: "Celular 0",
    field: "phones",
    subField: "0",
    disabled: false,
  },
  {
    name: "Celular 1",
    field: "phones",
    subField: "1",
    disabled: false,
  },
  {
    name: "Celular 2",
    field: "phones",
    subField: "2",
    disabled: false,
  },
];

const ContactSection = ({
  client,
  inEdit,
  isAdmin,
  handleActiveEdit,
  handleSaveClient,
  onClose,
}: Props) => {
  const defaultState: Partial<Client> = { phones: client.phones, telephone: client.telephone };
  const [newObj, setNewObj] = useState<Partial<Client>>(defaultState);

  const handleEdit = (field: string, subField: string, value: string) => {
    setNewObj({
      ...newObj,
      [field]: {
        ...newObj[field],
        [subField]: value,
      },
    });
  };

  const handleCancel = () => {
    setNewObj(defaultState);
    onClose();
  };

  return (
    <DetailsContainer>
      <InfoHeader>
        <div>
          <TbPhoneCall size={22} color="var(--red)" />
          <p>Contato</p>
          {!inEdit && isAdmin && (
            <TbPencil
              onClick={() => handleActiveEdit("contact")}
              size={22}
              style={{ strokeWidth: 1.5, cursor: "pointer" }}
            />
          )}
        </div>
        {inEdit && (
          <EditButtons>
            <CancelButton onClick={handleCancel}>Cancelar</CancelButton>
            <SaveButton onClick={() => handleSaveClient(newObj)}>Salvar edição</SaveButton>
          </EditButtons>
        )}
      </InfoHeader>
      <RowContent>
        {subSections.map((item, index) => (
          <RowItem key={index}>
            <h3>{item.name}</h3>
            {inEdit ? (
              <TextInput
                name={item.field[item.subField]}
                defaultValue={newObj[item.field][item.subField]}
                onChange={(value) => handleEdit(item.field, item.subField, value)}
              />
            ) : (
              <p>{client[item.field][item.subField]}</p>
            )}
          </RowItem>
        ))}
      </RowContent>
    </DetailsContainer>
  );
};

export default ContactSection;
