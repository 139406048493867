import centralLogo from "../assets/centralLogo.png";
import { User } from "@models/User";
import userStore from "@stores/user";

export const avatarHelper = {
  getUserAvatar,
  getAvatarByBase64,
};

function getUserAvatar(userId: User["id"]) {
  const { user } = userStore.getState();

  const users = user?.users?.allUsers;

  if (users && userId) {
    const user = users.find((x) => x.id === userId);

    if (user && user?.profileImageUrl) {
      return user.profileImageUrl;
    }

    return centralLogo;
  }

  if (userId) {
    return user.profileImageUrl;
  }

  return centralLogo;
}

function getAvatarByBase64(base64) {
  return `data:image;base64,${base64}`;
}
