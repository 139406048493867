import React, { useState } from "react";

//Styles
import {
  ActionsContainer,
  Container,
  ExpandedButton,
  SavedPointsContainer,
  SavedRouteDetails,
  SavedRouteHeader,
  SavedRouteInfo,
  SavedRouteInfoName,
  SavedRouteInfoValue,
  SavedRouteName,
} from "./styles";

//Icons
import { BiUser } from "react-icons/bi";
import { AiOutlineClockCircle } from "react-icons/ai";
import { TbCalendarMinus, TbPencil } from "react-icons/tb";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { FiTrash2 } from "react-icons/fi";
import ElipseIcon from "@components/Icons/ElipseIcon/ElipseIcon";
import Divider from "@components/Divider/Divider";
import StatusIcon from "@components/Icons/StatusIcon/StatusIcon";
import OperatorRoutePoint from "@components/OperatorRoutePoint/OperatorRoutePoint";

//utils
import { datetimeHelper } from "../../helpers/datetime";
import { Route, routeStatus } from "@models/Route";

type Props = {
  route: Route;
  onEdit: () => void;
  onCancel: () => void;
  onTrace: (trace: boolean) => void;
};

const SavedRouteCard = ({ route, onEdit, onCancel, onTrace }: Props) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Container>
      <SavedRouteHeader>
        <SavedRouteName>
          <p>{route.name}</p>
          <ElipseIcon
            text={route.points.length}
            size={17}
            background="var(--blue3)"
            fontWeight="400"
            fontSize={12}
          />
        </SavedRouteName>
        {route.status === routeStatus.WAITING && (
          <ActionsContainer>
            <TbPencil onClick={onEdit} size={22} style={{ strokeWidth: 1.5, cursor: "pointer" }} />
            <FiTrash2
              onClick={onCancel}
              color="var(--red)"
              size={20}
              style={{ strokeWidth: 1.5, cursor: "pointer" }}
            />
          </ActionsContainer>
        )}
      </SavedRouteHeader>
      <SavedRouteDetails>
        <SavedRouteInfo>
          <SavedRouteInfoName>
            <BiUser />
            <p>Operador:</p>
          </SavedRouteInfoName>
          <SavedRouteInfoValue>{route.operatorName}</SavedRouteInfoValue>
        </SavedRouteInfo>
        <SavedRouteInfo>
          <SavedRouteInfoName>
            <AiOutlineClockCircle />
            <p>Status:</p>
          </SavedRouteInfoName>
          <StatusIcon status={route.status} />
        </SavedRouteInfo>
        <SavedRouteInfo>
          <SavedRouteInfoName>
            <TbCalendarMinus />
            <p>Data de criação:</p>
          </SavedRouteInfoName>
          <SavedRouteInfoValue>{datetimeHelper.getFullDate(route.createdAt)}</SavedRouteInfoValue>
        </SavedRouteInfo>
      </SavedRouteDetails>
      {expanded && (
        <>
          <Divider width="100%" />
          <SavedPointsContainer>
            {route.points.length != 0 &&
              route.points.map((item, index) => (
                <>
                  <OperatorRoutePoint index={index} client={item} />
                </>
              ))}
          </SavedPointsContainer>
        </>
      )}
      <ExpandedButton
        onClick={() => {
          onTrace(!expanded);
          setExpanded(!expanded);
        }}
      >
        <p>{expanded ? "Recolher" : "Expandir"}</p>
        {expanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
      </ExpandedButton>
    </Container>
  );
};

export default SavedRouteCard;
