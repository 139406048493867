import { useEffect } from "react";
import { useStore } from "zustand";
import attendclientStore from "@stores/attendClient";
import { WatchTableAttendClientsUseCase } from "@dataUseCases/index";
import { Filter } from "@models/Filters";
import { Paging } from "@models/Paging";
import { FirebaseAttendClientRepository } from "@dataRepositories/index";
import userStore from "@stores/user";

type Props = {
  filters: Filter[];
  paging: Paging;
};

export default function useTableAttendClients({ filters, paging }: Props) {
  const { dashboardTable, dashboardTableTotal } = useStore(attendclientStore);

  const getTableAttendClients = async () => {
    const useCase = new WatchTableAttendClientsUseCase(
      userStore.getState(),
      new FirebaseAttendClientRepository(attendclientStore.getState())
    );

    useCase.execute({ paging });
  };

  useEffect(() => {
    if (dashboardTable.length === 0) {
      getTableAttendClients();
    }
  }, []);

  useEffect(() => {
    if (filters.length === 0) {
      getTableAttendClients();
    }
  }, [paging]);

  return {
    tableAttendClients: dashboardTable.slice(paging.offset, paging.offset + 25),
    tableAttendClientsTotal: dashboardTableTotal,
  };
}
