import React, { useState } from "react";

import { CardsContainer } from "./styles";

import { Card } from "@components/Card/Card";

import checkedIcon from "@assets/checkedIcon.svg";
import listIcon from "@assets/listIcon.svg";
import winnerIcon from "@assets/winnerIcon.svg";
import medalIcon from "@assets/medalIcon.svg";

import Modal from "react-modal";

//Utils
import ModalDashboardCard from "../ModalDashboardCard/ModalDashboardCard";
import { Avatar } from "@mui/material";
import { CardsData } from "@helpers/dashboard";
import { avatarHelper } from "@helpers/avatar";
import { tableColumns } from "@resources/tableColumns";

const defaultModalData = {
  array: [],
  title: "",
  type: null,
  columns: 5,
  filterTypes: null,
  tableColumns: tableColumns.dashboard,
  visible: false,
};

type TReportsCardsProps = {
  cardsData: CardsData;
};

function ReportsCards({ cardsData }: TReportsCardsProps) {
  const [modalData, setModalData] = useState(defaultModalData);

  const closeModal = () => {
    setModalData(defaultModalData);
  };

  return (
    <>
      <CardsContainer>
        <Card.Root
          loading={!cardsData}
          onClick={() => {
            setModalData({
              array: cardsData?.signed,
              title: "Contratos fechados",
              type: null,
              columns: 5,
              filterTypes: null,
              tableColumns: tableColumns.dashboard,
              visible: true,
            });
          }}
        >
          <Card.Title color="var(--success)" title="Contratos fechados" imgSrc={checkedIcon} />
          <Card.Divider />
          <Card.Content
            color="var(--success)"
            value={cardsData?.signed?.length}
            description="contratos"
          />
        </Card.Root>

        <Card.Root
          loading={!cardsData}
          onClick={() => {
            setModalData({
              array: cardsData?.attendClients,
              title: "Clientes visitados",
              type: null,
              columns: 5,
              filterTypes: null,
              tableColumns: tableColumns.dashboard,
              visible: true,
            });
          }}
        >
          <Card.Title color="var(--blue4)" title="Clientes visitados" imgSrc={listIcon} />
          <Card.Divider />
          <Card.Content
            color="var(--blue4)"
            value={cardsData?.attendClients?.length}
            description="clientes"
          />
        </Card.Root>

        <Card.Root
          onClick={() => {
            setModalData({
              array: cardsData?.winnerSigned ? cardsData?.winnerSigned.array : [],
              title: "Ranking do mês",
              type: "contratos",
              columns: 4,
              filterTypes: null,
              tableColumns: tableColumns.dashboard,
              visible: true,
            });
          }}
        >
          <Card.Title color="var(--success)" title="Ranking do mês" imgSrc={winnerIcon} />
          <Card.Divider />
          <Card.Content
            color="var(--success)"
            value={cardsData?.winnerSigned ? cardsData?.winnerSigned?.qtd : "0"}
            description="contratos"
            Avatar={
              cardsData?.winnerSigned &&
              cardsData?.winnerSigned?.qtd > 0 && (
                <>
                  <Avatar
                    alt="Remy Sharp"
                    sx={{ width: 26, height: 26, cursor: "pointer" }}
                    src={avatarHelper.getUserAvatar(cardsData?.winnerSigned.operatorId)}
                  />

                  <h1 className="operatorName">{cardsData?.winnerSigned.operatorName}</h1>
                </>
              )
            }
          />
        </Card.Root>

        <Card.Root
          onClick={() => {
            setModalData({
              array: cardsData?.winnerVisits ? cardsData?.winnerVisits.array : [],
              title: "Campeão de visitas",
              type: "clientes",
              columns: 4,
              filterTypes: null,
              tableColumns: tableColumns.dashboard,
              visible: true,
            });
          }}
        >
          <Card.Title color="var(--blue4)" title="Campeão de visitas" imgSrc={medalIcon} />
          <Card.Divider />
          <Card.Content
            color="var(--blue4)"
            value={cardsData?.winnerVisits ? cardsData?.winnerVisits?.qtd : "0"}
            description="clientes"
            Avatar={
              cardsData?.winnerVisits &&
              cardsData?.winnerVisits?.qtd > 0 && (
                <>
                  <Avatar
                    alt="Remy Sharp"
                    sx={{ width: 26, height: 26, cursor: "pointer" }}
                    src={avatarHelper.getUserAvatar(cardsData?.winnerVisits.operatorId)}
                  />

                  <h1 className="operatorName">{cardsData?.winnerVisits.operatorName}</h1>
                </>
              )
            }
          />
        </Card.Root>
      </CardsContainer>

      <Modal
        isOpen={modalData.visible}
        onRequestClose={() => closeModal()}
        overlayClassName="modal-overlay"
        className="modal-content-auto-width"
        contentLabel="Example Modal"
      >
        <ModalDashboardCard data={modalData} onClose={() => closeModal()} />
      </Modal>
    </>
  );
}

export default ReportsCards;
