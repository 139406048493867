import React, { useState } from "react";
import {
  CancelButton,
  DetailsContainer,
  EditButtons,
  InfoHeader,
  RowContent,
  RowItem,
  SaveButton,
} from "../styles";
import { TbFileInvoice, TbPencil } from "react-icons/tb";
import TextInput from "@components/Form/TextInput/TextInput";
import { Client } from "@models/Client";

type Props = {
  client: Client;
  inEdit: boolean;
  isAdmin: boolean;
  handleActiveEdit?: (section: string) => void;
  handleSaveClient?: (newClient: Partial<Client>) => void;
  onClose?: () => void;
};

type SubSection = {
  name: string;
  field: keyof Pick<Client, "birthDate" | "age" | "cpf" | "motherName">;
  disabled: boolean;
};

const subSections: SubSection[] = [
  {
    name: "Data de nascimento",
    field: "birthDate",
    disabled: false,
  },
  {
    name: "Idade",
    field: "age",
    disabled: false,
  },
  {
    name: "CPF",
    field: "cpf",
    disabled: true,
  },
  {
    name: "Nome da mãe",
    field: "motherName",
    disabled: false,
  },
];

const DataSection = ({
  client,
  inEdit,
  isAdmin,
  handleActiveEdit,
  handleSaveClient,
  onClose,
}: Props) => {
  const defaultState: Partial<Client> = {
    birthDate: client.birthDate,
    age: client.age,
    motherName: client.motherName,
    cpf: client.cpf,
  };
  const [newObj, setNewObj] = useState<Partial<Client>>(defaultState);

  const handleEdit = (field: string, value: string) => {
    setNewObj({
      ...newObj,
      [field]: value,
    });
  };

  const handleCancel = () => {
    setNewObj(defaultState);
    onClose();
  };

  return (
    <DetailsContainer>
      <InfoHeader>
        <div>
          <TbFileInvoice size={24} color="var(--red)" style={{ strokeWidth: 1.8 }} />
          <p>Dados</p>
          {!inEdit && isAdmin && (
            <TbPencil
              onClick={() => handleActiveEdit("data")}
              size={22}
              style={{ strokeWidth: 1.5, cursor: "pointer" }}
            />
          )}
        </div>
        {inEdit && (
          <EditButtons>
            <CancelButton onClick={handleCancel}>Cancelar</CancelButton>
            <SaveButton onClick={() => handleSaveClient(newObj)}>Salvar edição</SaveButton>
          </EditButtons>
        )}
      </InfoHeader>
      <RowContent>
        {subSections.map((item, index) => (
          <RowItem key={index}>
            <h3>{item.name}</h3>
            {inEdit ? (
              <TextInput
                name={item.field}
                defaultValue={newObj[item.field]}
                disabled={item.field === "cpf"}
                onChange={(value) => handleEdit(item.field, value)}
              />
            ) : (
              <p>{client[item.field]}</p>
            )}
          </RowItem>
        ))}
      </RowContent>
    </DetailsContainer>
  );
};

export default DataSection;
