import MapRepository from "@domainRepositories/MapRepository";
import showToast from "@helpers/showToast";
import { HttpClient } from "@infra/api/HttpClient";
import { Map } from "@models/Map";

export class HttpMapRepository implements MapRepository {
  private readonly baseUrl = `${process.env.REACT_APP_API_URL}/maps`;

  constructor(private readonly httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async getAll() {
    return this.httpClient
      .request({
        url: `${this.baseUrl}`,
        method: "GET",
      })
      .then((response) => response.data);
  }

  async create(data: Partial<Map>) {
    return this.httpClient
      .request({
        url: `${this.baseUrl}`,
        method: "POST",
        body: JSON.stringify(data),
      })
      .then((response) => {
        showToast(response.message, "success");
        return response.data;
      });
  }

  async update(mapId: Map["id"], data: Partial<Map>) {
    return this.httpClient
      .request({
        url: `${this.baseUrl}/${mapId}`,
        method: "PUT",
        body: JSON.stringify(data),
      })
      .then((response) => {
        showToast(response.message, "success");
        return response.data;
      });
  }

  async delete(mapId: Map["id"]) {
    return this.httpClient
      .request({
        url: `${this.baseUrl}/${mapId}`,
        method: "DELETE",
      })
      .then((response) => {
        showToast(response.message, "success");
        return response.data;
      });
  }
}
