import React, { useEffect, useState } from "react";

import {
  CancelButton,
  Container,
  SearchBar,
  SearchButton,
  SearchContainer,
  TableColumns,
  TableHeader,
  TableFooter,
  TableRows,
  TableTypeButton,
  ClearSearch,
} from "./styles";

//Icons
import { TbArrowWaveRightUp } from "react-icons/tb";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FiSearch } from "react-icons/fi";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

//Custom components
import MapItem from "@components/TableItem/MapItem/MapItem";
import DataTableFilter from "@components/Filters/DataTableFilter/DataTableFilter";
// import DownloadButton from "../DownloadButton";
import ModalDetailsAdmin from "@components/Modal/ModalDetailsAdmin/ModalDetailsAdmin";

//MUI
import Menu from "@mui/material/Menu";

//Utils
import Modal from "react-modal";
import { Client, clientAddressStatus } from "@models/Client";
import { Filter } from "@models/Filters";
import { MapClient } from "@models/MapClient";

export type TableDataType =
  | typeof clientAddressStatus.FOUND
  | typeof clientAddressStatus.NOT_FOUND
  | typeof clientAddressStatus.DIVERSE_LOCATION;

type Props = {
  title: string;
  search: string;
  tableColumns: { headerName: string; field: string }[];
  tableRows: MapClient[] | Client[];
  tableRowsBackup: MapClient[] | Client[];
  filterTypes: { headerName: string; field: string }[];
  filtersHighlights: { headerName: string; field: string }[];
  filters: Filter[];
  columns: number;
  tableDataType?: TableDataType;
  dateField: keyof Pick<MapClient, "updatedAt"> | keyof Pick<Client, "lastReportDate">;
  perPage?: number;
  setSearch: (search: string) => void;
  onClick?: (client: MapClient) => void;
  onChangeFilters: (newFiltersArray: Filter[]) => void;
  onClearFilters: () => void;
  onChangeTableType?: (type: TableDataType) => void;
};

function DataTable({
  title,
  tableColumns,
  tableRows,
  tableRowsBackup,
  filterTypes,
  filtersHighlights,
  columns,
  tableDataType,
  dateField,
  filters,
  perPage = 25,
  setSearch,
  onChangeTableType,
  onClick,
  onChangeFilters,
  onClearFilters,
}: Props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [defaultEdit, setDefaultEdit] = useState(false);

  //Table
  const [tableData, setTableData] = useState<Props["tableRows"]>([]);
  const [rangeOfList, setRangeOfList] = useState({ init: 0, finish: perPage });

  //Modal
  const [modalFullData, setModalFullData] = useState(false);
  const [modalData, setModalData] = useState<MapClient>();

  const [draftSearch, setDraftSearch] = useState("");

  useEffect(() => {
    setTableData(tableRows);
  }, [tableRows]);

  useEffect(() => {
    setRangeOfList({ init: 0, finish: perPage });
  }, [tableData]);

  useEffect(() => {
    if (!draftSearch) {
      clearSearch();
    }
  }, [draftSearch]);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const clearSearch = () => {
    setSearch("");
    setDraftSearch("");
  };

  const searchTable = () => {
    setSearch(draftSearch);
    handleMenuClose();
  };

  return (
    <Container>
      <TableHeader>
        <div>
          {!tableDataType && (
            <>
              <TbArrowWaveRightUp size={26} color="var(--red)" />
              <h3>{title}</h3>
            </>
          )}
          {tableDataType && (
            <>
              <TableTypeButton
                active={tableDataType == clientAddressStatus.FOUND ? true : false}
                onClick={() => onChangeTableType(clientAddressStatus.FOUND)}
              >
                Clientes no mapa
              </TableTypeButton>
              <TableTypeButton
                active={tableDataType == clientAddressStatus.NOT_FOUND ? true : false}
                onClick={() => onChangeTableType(clientAddressStatus.NOT_FOUND)}
              >
                Endereços não encontrados
              </TableTypeButton>
              <TableTypeButton
                active={tableDataType == clientAddressStatus.DIVERSE_LOCATION ? true : false}
                onClick={() => onChangeTableType(clientAddressStatus.DIVERSE_LOCATION)}
              >
                Localidade diversa
              </TableTypeButton>
            </>
          )}
          <FiSearch onClick={handleMenuOpen} size={20} cursor="pointer" color="var(--grey2)" />
          <ClearSearch>
            {draftSearch && (
              <>
                {draftSearch}
                <AiOutlineCloseCircle
                  cursor="pointer"
                  onClick={() => {
                    clearSearch();
                  }}
                />
              </>
            )}
          </ClearSearch>
        </div>
        <div>
          <DataTableFilter
            filters={filters}
            filterTypes={filterTypes}
            tableRowsBackup={tableRowsBackup}
            filtersHighlights={filtersHighlights}
            dateField={dateField}
            onChangeFilters={onChangeFilters}
            onClearFilters={() => onClearFilters()}
          />
          {/* <DownloadButton tableColumns={tableColumns} tableRows={tableData} /> */}
        </div>
      </TableHeader>
      <TableColumns columns={columns}>
        {tableColumns && tableColumns.map((item, index) => <h3 key={index}>{item.headerName}</h3>)}
      </TableColumns>
      <TableRows>
        {tableData &&
          tableData.slice(rangeOfList.init, rangeOfList.finish).map((item, index) => (
            <MapItem
              key={index}
              data={item}
              onClick={() => onClick(item)}
              onChangeAddress={() => {
                setDefaultEdit(true);
                setModalData(item);
                setModalFullData(true);
              }}
              onView={() => {
                setDefaultEdit(false);
                setModalData(item);
                setModalFullData(true);
              }}
              onEdit={() => {
                setDefaultEdit(true);
                setModalData(item);
                setModalFullData(true);
              }}
            />
          ))}
      </TableRows>
      <TableFooter>
        <h3>
          {rangeOfList.init} - {rangeOfList.finish}
        </h3>
        <div>
          <MdKeyboardArrowLeft
            size={20}
            cursor="pointer"
            onClick={() => {
              if (rangeOfList.finish == tableData.length) {
                const value = rangeOfList.finish - rangeOfList.init + perPage;

                const newFinish = rangeOfList.init;

                setRangeOfList({ init: rangeOfList.finish - value, finish: newFinish });
              } else {
                if (rangeOfList.init - perPage >= 0) {
                  setRangeOfList({
                    init: rangeOfList.init - perPage,
                    finish: rangeOfList.finish - perPage,
                  });
                }
              }
            }}
          />
          <MdKeyboardArrowRight
            size={20}
            cursor="pointer"
            onClick={() => {
              if (tableData.length > perPage) {
                if (rangeOfList.finish + perPage <= tableData.length) {
                  setRangeOfList({
                    init: rangeOfList.finish,
                    finish: rangeOfList.finish + perPage,
                  });
                } else {
                  if (rangeOfList.finish != tableData.length) {
                    const value = rangeOfList.finish + (tableData.length - rangeOfList.finish);

                    setRangeOfList({ init: rangeOfList.finish, finish: value });
                  }
                }
              }
            }}
          />
        </div>
        <h3>{tableData ? tableData.length : 0} clientes</h3>
      </TableFooter>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        PaperProps={{
          style: {
            width: 600,
            height: 40,
            maxHeight: 40,
            borderRadius: 20,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            overflow: "hidden",
          },
        }}
      >
        <SearchContainer
          onSubmit={(e) => {
            e.preventDefault();
            searchTable();
          }}
        >
          <FiSearch size={18} cursor="pointer" color="#626F87" strokeWidth="3" />
          <p>&#8288;</p>
          <SearchBar>
            <input
              name="draftSearch"
              onChange={(event) => setDraftSearch(event.target.value)}
              value={draftSearch}
              placeholder="Buscar..."
              autoFocus={true}
            />
          </SearchBar>
          <CancelButton onClick={handleMenuClose}>Cancelar</CancelButton>
          <SearchButton onClick={() => searchTable()}>Buscar</SearchButton>
        </SearchContainer>
      </Menu>

      <Modal
        isOpen={modalFullData}
        onRequestClose={() => setModalFullData(false)}
        overlayClassName="modal-overlay"
        className="modal-content-auto-width"
        contentLabel="Example Modal"
      >
        <ModalDetailsAdmin
          data={modalData}
          onClose={() => setModalFullData(false)}
          defaultEdit={defaultEdit}
        />
      </Modal>
    </Container>
  );
}

export default DataTable;
