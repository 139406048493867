import React from "react";

import {
  Container,
  Content,
  ActionsContent,
  CancelButton,
  ConfirmButton,
  Divider,
  FieldText,
} from "./styles";

//Utils
import Select from "react-select";
import { useEffect } from "react";
import { useState } from "react";
import Loading from "@components/Loading/Loading";
import ModalHeader from "@components/Modal/ModalHeader/ModalHeader";
import { Client } from "@models/Client";
import useUser from "@hooks/useUser";
import { SelectOption } from "@models/SelectOption";
import { GetClientUseCase } from "@dataUseCases/index";
import { HttpClientRepository } from "@dataRepositories/index";
import { api } from "@infra/api";

type Props = {
  client: Client;
  onCancel: () => void;
  onConfirm: (operator: SelectOption) => void;
};

const ModalAssignReturn = ({ client, onCancel, onConfirm }: Props) => {
  const { user, isAdmin } = useUser();

  const [operators, setOperators] = useState<SelectOption[]>();
  const [selectedOperator, setSelectedOperator] = useState<SelectOption>();

  useEffect(() => {
    processFilterOptions();
  }, [user]);

  const processFilterOptions = async () => {
    const arrayOperators = [];

    const useCase = new GetClientUseCase(new HttpClientRepository(api));

    const clientDoc = await useCase.execute(client.id);

    if (!clientDoc) {
      return [];
    }

    if (isAdmin) {
      for (const item of user.users.operators) {
        const inMap = item.mapsIds.find((x) => x.value === clientDoc.mapId);

        if (inMap && item.id != client.operatorId) {
          arrayOperators.push({
            label: item.name,
            value: item.id,
          });
        }
      }
    } else {
      for (const item of user.users.operators) {
        if (item.id != client.operatorId) {
          arrayOperators.push({
            label: item.name,
            value: item.id,
          });
        }
      }
    }

    setOperators(arrayOperators);
  };

  const customStyle = {
    container: (base) => ({
      ...base,
      borderRadius: 40,
    }),
    control: (base) => ({
      ...base,
      padding: 3,
      borderRadius: 40,
    }),
    multiValue: (base) => ({
      ...base,
      borderRadius: 40,
    }),
  };

  if (!operators) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  return (
    <Container>
      <ModalHeader title="Encaminhar cliente" onClose={onCancel} />
      <Content>
        <FieldText>Operador</FieldText>
        <Select
          isClearable={false}
          isSearchable={true}
          closeMenuOnSelect={true}
          options={operators}
          isMulti={false}
          styles={customStyle}
          autoFocus={true}
          openMenuOnFocus={true}
          onChange={(text) => {
            setSelectedOperator(text);
          }}
        />
      </Content>
      <Divider />
      <ActionsContent>
        <CancelButton onClick={onCancel}>
          <p>Voltar</p>
        </CancelButton>
        <ConfirmButton onClick={() => onConfirm(selectedOperator)}>
          <p>Encaminhar</p>
        </ConfirmButton>
      </ActionsContent>
    </Container>
  );
};

export default ModalAssignReturn;
