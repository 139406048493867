import { HttpClientRepository } from "@dataRepositories/index";
import { ToggleSpecialIconUseCase } from "@dataUseCases/index";
import { api } from "@infra/api";
import { Client } from "@models/Client";

export default function useSpecialIcon() {
  const toggleSpecialIcon = (clientId: Client["id"]) => {
    const useCase = new ToggleSpecialIconUseCase(new HttpClientRepository(api));

    useCase.execute(clientId);
  };

  return { toggleSpecialIcon };
}
